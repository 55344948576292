<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4">
    <div class="row"  *ngIf="(view === 0 && active === 1)">
        <div class="col-6 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-conferencias')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-6 mb-4 text-right">
            <span class="change-page-label"> Siguiente </span>
            <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-expositores')"></i>
        </div>
        <div class="col-12 mb-4 text-right">
            <button class="btn btn-primary px-4 btn-save-edituseradmin" id="btnAdd" (click)="changeView(1)">+ Agregar</button>
        </div>
    </div>
    <div class="row" *ngIf="view === 1 || (active === 2)">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="changeView(0, 1)"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12 mb-4">&nbsp;</div>
    </div>
    <div class="tab-style">
        <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="active">
            <!-- STANDS -->
            <li [ngbNavItem]="1" >
                <a ngbNavLink id="" (click)="changeTab('stand')" *ngIf="valid">Stands</a>
                <ng-template ngbNavContent>
                    <div class="card mb-5" >                        
                        <div class="col-12 mb-5" *ngIf="view === 0">
                            <div class="search-field">
                                <i class="search-filter" id="search"></i>
                                <input class="search-input" placeholder="Buscar..." [(ngModel)]="filtro" />
                                <i class="search-icon" id="filter"></i>
                            </div>                            
                            <div class="table-default mb-5">
                                <table class="content-table" border="1">
                                    <thead>
                                        <tr>
                                            <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of list | tableFilter:filtro:'Nombre de empresa'; let i = index">
                                            <td *ngFor="let x of item | keyvalue:originalOrder" [hidden]="x.key === 'id'">
                                                {{x.value}} </td>
                                            <td (mouseleave)="hideActions()">
                                                <button name="actions" id="actions" class="actions" (click)="showActions(item.id)" (keyup)="showActions(item.id)"></button>
                                                <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{item.id}}" (mouseleave)="hideActions()">
                                                    <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="deletedStand(item.id)">Eliminar</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="view === 1">
                            <h5 class="card-title mb-4">Asiganción de Stands</h5>
                            <form #form="ngForm">
                                <div class="row px-4 py-0">
                                    <div class="col-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Nombre de la empresa*</label>
                                                <ng-multiselect-dropdown [placeholder]="'Empresas...'" name="standCompany" id="standCompany" 
                                                    (onSelect)="onSelect('company', $event)" (onDeSelect)="onDeSelect('company', $event)"
                                                    (onFilterChange)="onFilter($event)" (onDropDownClose)="onDropDownClose()" (click)="clickDropDown('standCompany')"
                                                    [settings]="dropdownSettingsCompany" [data]="companyList" [(ngModel)]="company" [disabled]="false" required>
                                                </ng-multiselect-dropdown>
                                                <div class="err-msg" *ngIf="validation_msg('standCompany')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Nivel del piso*</label>
                                                <ng-multiselect-dropdown [placeholder]="'Pisos'" name="standFloor" id="standFloor"
                                                    (onSelect)="onSelect('floor', $event)" (onDeSelect)="onDeSelect('floor', $event)" (click)="clickDropDown('standFloor')"
                                                    [settings]="dropdownSettingsFloors" [data]="floorList" [disabled]="false" [(ngModel)]="standFloor" required>
                                                </ng-multiselect-dropdown>                                                
                                                <div class="err-msg" *ngIf="validation_msg('standFloor')">Este campo es obligatorio.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="row">                                            
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Stand*</label>
                                                <input class="form-control" name="stanDireccion"
                                                [(ngModel)]="stand.stand" required placeholder="Stand"/>
                                                <div class="err-msg" *ngIf="validation_msg('stanDireccion')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Logotipo de empresa*</label>
                                                <input class="d-none" type="file" name="input-images-file-logo" id="input-images-file-logo" (change)="addFile($event, 0)" required accept="image/*, video/*"/>
                                                <input class="form-control w-100 input-file-readonly" name="input-images-text-logo" id="input-images-text-logo" [(ngModel)]="stand.image.name" required readonly placeholder="Seleccione una imagen..." />
                                                <i class="icon-input-file" onclick="document.getElementById('input-images-file-logo').click();"></i>
                                                <div class="err-msg" *ngIf="validation_msg('input-images-text-logo')"> Este campo es obligatorio.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </li>

            <!-- MAPS -->
            <li [ngbNavItem]="2">
                <a ngbNavLink (click)="changeTab('map')">Mapa de exhibición</a>
                <ng-template ngbNavContent>
                    <div class="card mb-5">                        
                        <div class="card-body mb-5">
                            <h5 class="card-title mb-4">Información de la actividad</h5>                            
                            <form #form="ngForm">
                                <div class="row px-4 py-0 mb-5">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-lg-6 mb-3">
                                                <label class="form-label mb-3">Nombre del lugar</label>
                                                <input class="form-control" name="mapName" [(ngModel)]="map.eventName" disabled/>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <label class="form-label mb-3">Dirección del evento</label>
                                                <input class="form-control" name="mapAddress" [(ngModel)]="map.eventAddress" disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <hr class="divider-section">
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3" *ngFor="let map of map.map; let i = index">
                                        <label class="form-label mb-3">Mapa del piso {{i + 1}}{{i === 0 ? '*' : '' }}</label>
                                        <input class="d-none" type="file" name="input-images-file-map-{{i}}" id="input-images-file-map-{{i}}" (change)="addFile($event, i)" [required]="i === 0" accept="image/*"/>
                                        <input class="form-control w-100 input-file-readonly" name="input-images-text-map-{{i}}" id="input-images-text-map-{{i}}" [(ngModel)]="map.name" 
                                            (click)="click('link', map.url)" [required]="i === 0" readonly placeholder="Seleccione una imagen..." />
                                        <i class="{{map.url ? 'icon-input-delete' : 'icon-input-file'}}" (click)="click('click', 'input-images-file-map-' + i)" ></i>
                                        <i *ngIf="map.url" class="icon-input-delete" (click)="deleteDialog(map)" ></i>
                                        <i *ngIf="!map.url" class="icon-input-file" (click)="click('click', 'input-images-file-map-' + i)" ></i>
                                        <div class="err-msg" *ngIf="validation_msg('input-images-text-map-' + i)"> Este campo es obligatorio.</div>
                                    </div>                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="row" *ngIf="(view === 1) ||  (view === 0 && active === 2)">
        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary px-4 my-2 btn-cancel-edituseradmin" (click)="changeView(0, 1)" *ngIf="list.length > 0">Cancelar</button>
                &nbsp;
                <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" type="button"
                (click)="processData(active)" [disabled]="disabled || (form && (!form.valid || !form.touched))"><i class="save-icon"></i>Guardar</button>
            </div>
        </div>
    </div>
</div>
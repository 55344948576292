import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { KeyValue } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/Services/utils.service';
import { DialogComponent } from 'src/app/Modales/dialog/dialog.component';
import { ParticipantService } from 'src/app/Services/participant.service';
import { GafeteDialogComponent } from 'src/app/Modales/gafete-dialog/gafete-dialog.component';

@Component({
  selector: 'app-admin-participantes',
  templateUrl: './admin-participantes.component.html',
  styleUrls: ['./admin-participantes.component.css']
})
export class AdminParticipantesComponent implements OnInit {
  @ViewChild('file') file!: ElementRef;

  public eventData: any;
  public count: number = 0;
  public eventID: number = 0;
  public pageSize: number = 15;
  public pageNumber: number = 1;
  public totalPages: number = 0;
  public loading: boolean = false;
  public isShowDiv: boolean = true;
  public filter: string | null = '';
  public role: string | undefined = undefined;
  public env: boolean = environment.production;

  public categoriesHeading: string[] = [];
  public list: any[] = [
    { "Sector": "", "Comisión": "", "Nombre Usuario": "", "Nombre Empresa": "", "Correo electrónico": "", "NIT": "", "Asociado": "", "Rol del usuario": "", "Tipo de entrada": "", "Asistencia": "", "Registro": "" }
  ];

  lastPage = '';
  nextPage = false;


  constructor(
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private participantService: ParticipantService
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
  }

  async ngOnInit() {
    this.role = sessionStorage.getItem('Role')?.toString();
    this.eventID = Number(sessionStorage.getItem('EventID'));
    this.eventData = JSON.parse(sessionStorage.getItem('EventData')!) || {};
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, null);
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/login');
    }

    const eventType: number | undefined = Number(sessionStorage.getItem('EventType'));

    if (eventType < 4) {
      this.lastPage = '/admin/events/nuevo-evento?ID=' + this.eventID
      this.nextPage = false;
    } else {
      this.nextPage = true;
      this.lastPage = '/admin/events/admin-agenda';
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string, params?: {}) {
    (pageName === 'last') && (pageName = this.lastPage);
    this.utilsService.goToPage(pageName, params);
  }

  async onFileSelected(event: any) {
    if (event.target.files[0] != undefined) {
      this.loading = true
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      await this.participantService.importParticipant(this.eventData.id, formData).then(
        response => {
          console.log(response)
          if (response.result.registros > 0) {
            const message = `${response.message.description} Se registro ${response.result.registros} participante(s).`
            // const message = `${response.message.description} \n Se registraron ${response.result.registros} participantes.`
            this.utilsService.dialog('SUCCESS', 'Éxito', message);
          } else {
            this.utilsService.dialog('ERROR', 'Lo sentimos', 'No fue posible realizar el registro, revisa e intenta de nuevo.');
          }
          this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter);
        },
        error => {
          console.log(error)
          this.utilsService.dialog('ERROR', 'Error', error.message.description || 'No fue posible realizar el registro, revisa e intenta de nuevo.');
        }
      ).finally(() => {
        this.loading = false;
        this.file.nativeElement.value = '';
      })
      // https://es.stackoverflow.com/questions/357353/no-puedo-leer-un-mismo-archivo-dos-veces-en-javascript
    }
  }

  showActions(index: number) {
    const validator = document.getElementById(`item${index}`)!.hidden;
    validator ? document.getElementById(`item${index}`)!.hidden = false : document.getElementById(`item${index}`)!.hidden = true;

    this.list.forEach((e, i) => {
      if (i != index) {
        document.getElementById('item' + i)!.hidden = true;
      }
    },
      (`item${index}` != 'item-1') && (document.getElementById('item-1')!.hidden = true)
    );
  }

  hideActions(index: number) {
    this.list.forEach((e, i) => {
      if (i === index) {
        const element = document.getElementById(`item${i}`);
        element!.hidden = true;
      }
    },
      document.getElementById('item-1')!.hidden = true
    );
  }

  public checkbox: boolean = false
  public participantsArr: any[] = [];
  public allParticipant: boolean = true;

  async selectParticipant(event: any, all: boolean, participant_id?: number) {
    if (all) {
      for (let index = 0; index < this.list.length; index++) {
        // console.log(this.list[index].checkbox);
        this.list[index].checkbox = false;
      }
      this.participantsArr = [];
      // this.list.forEach(x => {
      //   x.checkbox = false;
      // })
      // for (const e of this.list) {
      //   e.checkbox = false;
      //   this.participantsArr = null; 
      // }
    } else {
      this.allParticipant = false;
      this.participantsArr === null ? this.participantsArr = new Array : '';

      if (event.target.checked === true) {
        this.participantsArr?.push(participant_id)
      } else {
        const index = this.participantsArr.findIndex(x => x === participant_id);
        this.participantsArr.splice(index, 1);
      }
    }
  }

  async modalConfirmation() {
    const modalRef = this.modalService.open(DialogComponent, { centered: true })

    modalRef.componentInstance.title = '';
    modalRef.componentInstance.page = null;
    modalRef.componentInstance.message = '';
    modalRef.componentInstance.type = 'DIALOG';

    await modalRef.componentInstance.response.subscribe(
      async (response: any) => {
        modalRef.closed;
        modalRef.close();
        this.sendConfirmation(response);
      }
    );
  }

  sendConfirmation(status: boolean) {
    if (status === true) {
      this.loading = true;
      const data = {
        "eventId": this.eventData.id,
        "allParticipant": this.allParticipant, // true,
        "participantsArr": this.participantsArr.length > 0 ? this.participantsArr : null  //  null
      }

      this.participantService.senConfirmationdParticipant(this.eventData.id, data).then(
        async response => {
          console.log(response)
          this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description);
        },
        error => {
          this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
        }
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  async getGafete(index: number, participantData: any, participant_id: number) {
    const getPDF = await this.participantService.getBadge(this.eventData.id, participant_id);

    try {
      // const ID = participant.userId != null ? participant?.userId?.toString() : '';
      // const participant = await this.list.find(x => x.id === participant_id);
      const participant = participantData;
      const ID = `{ \"eventId\": ${this.eventID}, \"userId\": ${participant?.userId}, \"participantId\": ${participant?.id?.toString()} }`;

      const nombrePuesto = participant.Profesion ? participant.Profesion.toString() : '';
      const nombreUsuario = participant['Nombre Usuario'] ? participant['Nombre Usuario'].toString() : '';
      const nombreEmpresa = participant['Nombre Empresa'] ? participant['Nombre Empresa'].toString() : '';
      const tipoEntrada = participant['Tipo de entrada'] ? participant['Tipo de entrada'].toString() : '';
      const imageBadgeHeader = this.eventData.imageBadgeHeader ? this.eventData.imageBadgeHeader.toString() : '';
      const imageBadgeFooter = this.eventData.imageBadgeFooter ? this.eventData.imageBadgeFooter.toString() : '';
      const invitadoEspecial = participant.invitadoEspecial ? Boolean(participant.invitadoEspecial.toString()) : '';

      const modalRef = this.modalService.open(GafeteDialogComponent);
      modalRef.componentInstance.ID = ID;
      modalRef.componentInstance.type = 'PARTICIPANTE';
      modalRef.componentInstance.tipoEntrada = tipoEntrada;
      modalRef.componentInstance.eventID = this.eventData.id;
      modalRef.componentInstance.PDF = getPDF?.result?.pdf;
      modalRef.componentInstance.nombrePuesto = nombrePuesto;
      modalRef.componentInstance.nombreEmpresa = nombreEmpresa;
      modalRef.componentInstance.nombreUsuario = nombreUsuario;
      modalRef.componentInstance.imageBadgeHeader = imageBadgeHeader;
      modalRef.componentInstance.invitadoEspecial = invitadoEspecial;
      modalRef.componentInstance.imageBadgeFooter = imageBadgeFooter;
      modalRef.componentInstance.userID = participant?.userId || null;
      modalRef.componentInstance.participantID = participant?.id || null;

      modalRef.result.then(() => {
        modalRef.closed;
        modalRef.close();
      }, (reason) => {
        modalRef.closed;
        modalRef.close();
      })
    } catch (error: any) {
      console.log(error)
      this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || 'Faltan datos para completar la operación.');
    }
  }

  async downloadToExcel(template: boolean) {
    if (template) {
      const data = [{
        'sector': '',
        'comision': '',
        'nombreUsuario': '',
        'nombreEmpresa': '',
        'nit': '',
        'asociado': '',
        'asistencia': '',
        'registro': '',
        'email': '',
        'profesion': '',
        'tipoEntrada': '',
        'fechaAsistencia': ''
      }];
      this.utilsService.downloadToExcel(data, 'Datos', 'Agexport_Plantilla_Importar_participantes_Eventos', 'xlsx'); // csv
    } else {
      this.loading = true;
      await this.participantService.getParticipantsByEvent(1, this.count, this.eventID, null).then(
        async response => {
          const list = await response.result.map((e: any) => {

            // let fechas = '';
            // e.attendances.forEach((i: any) => {
            //   fechas += this.utilsService.date_pipe(i?.dateAttendance, 'dd-MM-yyy') + ', ';
            // });
            // fechas = fechas.slice(0, -2)

            return {
              id: e?.id,
              Sector: e?.sector != null ? e?.sector[0]?.name : '',
              Comision: e?.comision != null ? e?.comision[0]?.name : '',
              "Nombre Usuario": e?.nombreUsuario,
              "Nombre Empresa": e?.nombreEmpresa,
              "Correo electrónico": e?.email,
              NIT: e?.nit,
              Profesion: e?.profesion,
              Asociado: e?.asociado === true ? 'Si' : 'No',
              "Rol del usuario": e?.rol,
              "Tipo de entrada": e?.tipoEntrada,
              Asistencia: e?.asistencia ? 'confirmado' : 'no_confirmado',
              Registro: Number(e?.registro),
              Fecha: e?.modDate ? this.utilsService.date_pipe(e?.modDate, 'dd-MM-yyy') : ''
              // Fecha: e?.modDate
            }
          });
          const eventData = JSON.parse(sessionStorage.getItem('EventData')!) || {};
          const eventName = eventData.nombreEvento ? eventData.nombreEvento.toString() : '';
          const reportName = 'PARTICIPANTES ' + eventName + ' ' + this.utilsService.getDate('dd-MM-yyy');
          this.utilsService.downloadToExcel(list, 'Participantes', reportName, 'xlsx');
        },
        error => {
          console.log(error);
          this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
        }
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  async searchReturn() {
    if (this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 1;
      await this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, null);
    }
  }

  loadDatabyEvent(page: number | null, size: number | null, event_id: number, participant_name: string | null, search?: boolean) {
    this.loading = true;

    if (search) {
      page = 1;
      this.pageNumber = 1; //0;
    }

    return this.participantService.getParticipantsByEvent(page, size, event_id, participant_name).then(
      response => {
        // console.log(response.result)
        this.count = response.count;
        this.list = response.result.map((e: any) => {
          return {
            checkbox: this.participantsArr.includes(e.id),
            id: e?.id,
            userId: e?.userId,
            Sector: e?.sector,
            Comision: e?.comision,
            "Nombre Usuario": e?.nombreUsuario,
            "Nombre Empresa": e?.nombreEmpresa,
            "Correo electrónico": e?.email,
            NIT: e?.nit,
            Asociado: e?.asociado === true ? 'Si' : 'No',
            "Rol del usuario": e?.rol,
            "Tipo de entrada": e?.tipoEntrada,
            Asistencia: e?.asistencia ? 'confirmado' : 'no_confirmado',
            // Asistencia: Number(e?.asistencia),
            Registro: Number(e?.registro),
            Profesion: e.profesion
          }
        });
        // console.log(this.list);

        this.totalPages = response.totalPages;
        // this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  updateAttendance($event: any, index: number, participant_id: number) {
    this.loading = true;
    const attendance = Boolean(Number($event.target.value))

    this.participantService.checkinParticipant(participant_id, this.eventID).then(
      response => {
        if (response.result.status === 200) {
          this.list[index].Asistencia = 'confirmado';
          const element = document.getElementById('asistance_' + index)!;
          element.classList.remove('select-disable'); // element?.classList.add('actions-enable');
          this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description);
        } else {
          this.utilsService.dialog('ERROR', 'Error', response.error.message.description);
        }
      },
      error => {
        // console.log('error: ', error);
        this.list[index].Asistencia = 'no_confirmado'; // '0';
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }


  updateRegister($event: any, index: number, participant_id: number) {
    this.loading = true;
    const register = Boolean(Number($event.target.value))

    this.participantService.updateParticipantByEvent(participant_id, { registro: register }).then(
      response => {
        console.log(response)
        this.utilsService.dialog('SUCCESS', 'Éxito', 'Participante actualizado.');
      },
      error => {
        console.log(error);
        $event.target.value = '0';
        this.list[index].Registro = 0;
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  deleteParticipant(index: number, participant_id: number) {
    this.loading = true;
    const eventID: number | undefined = Number(sessionStorage.getItem('EventID'));

    this.participantService.deleteParticipant(participant_id).then(
      response => {
        console.log(response.message.title)
        console.log(response.message.description)
        this.utilsService.dialog('SUCCESS', 'Éxito', 'Participante eliminado');
        this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter);
      },
      error => {
        console.log(error)
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter);
    }
  }

  onRight() {
    if (this.pageNumber + 1 <= this.totalPages) {
      // if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter);
    }
  }
}

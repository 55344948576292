<app-loading *ngIf="loading"></app-loading>
<div class="header">
    <div class="top-block-1">
        <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-stands')"></i>
        <span class="change-page-label"> Atrás </span>
        <div class="next-container">
            <span class="change-page-label"> Siguiente </span>
            <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-galeria')"></i>
        </div>
    </div>
    <div class="btn-group">
        <button class="agregar" (click)="goToPage('/admin/events/user-guide', {type: 'expositores', event_id: eventID})">+ Agregar</button>
    </div>
</div>
<div class="main">
    <div class="top-main">
        <div class="default-view">
            <div class="search-field">
                <i class="search-filter" id="search" (click)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" ></i>
                <input class="search-input" style="width: 90%;" placeholder="Buscar expositor..."
                [(ngModel)]="filter" (keyup.enter)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" (keyup)="searchReturn()"/>
                <!-- <button class="agregar" style="margin: -9px 0; padding: 13px 20px; right: 3px; width: 148px;" (click)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)"> Buscar </button> -->
            </div>
            <div class="table-default">
                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr *ngFor="let item of list | tableFilter:filter:'Nombre expositores'; let i = index"> -->
                        <tr *ngFor="let item of list let i = index">
                            <ng-container *ngFor="let x of item | keyvalue:originalOrder">
                                <td *ngIf="x.key != 'Estatus'" [hidden]="x.key === 'id'" >{{x.value}}</td>
                                <td *ngIf="x.key === 'Estatus'">
                                    <select name="registro_{{i}}" id="registro_{{i}}"
                                        [ngClass]="x.value === 'Sin Registro' ? 'select-enable select-disable' : 'select-enable'"
                                        [value]="x.value" (change)="updateRegister($event, i)">
                                        <option value="Registrado">Registrado</option>
                                        <option value="Sin Registro">Sin Registro</option>
                                    </select>
                                </td>
                            </ng-container>
                            <td (mouseleave)="hideActions()">
                                <button name="actions" id="actions" class="actions" (click)="showActions(item.id)"></button>
                                <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2"
                                    id="item{{item.id}}" (mouseleave)="hideActions()">
                                    <button name="delete" id="delete" class="actions-btn w-100 text-left"
                                        (click)="deleteExhibitor(item.id)">Eliminar</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-pagination">
                    <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
                    <p class="pagination-text">Página {{totalPages != 0 ? pageNumber + 1 : 0 }} de {{totalPages}}</p>
                    <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
                </div>
            </div>
        </div>
    </div>
</div>
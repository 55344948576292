import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { SponsorService } from 'src/app/Services/sponsor.service';
import { BackServiceSponsors } from 'src/app/Models/back-service.model';

@Component({
  selector: 'app-edit-patrocinadores',
  templateUrl: './edit-patrocinadores.component.html',
  styleUrls: ['./edit-patrocinadores.component.css']
})
export class EditPatrocinadoresComponent implements OnInit {
  @ViewChild('form') form!: NgForm;
  public eventID: number = 0;
  public sponsorID: number = 0;
  public loading: boolean = false;
  public disabled: boolean = false;
  public permissionCRM: boolean = false;
  public imageSizeError: boolean = false;
  public sponsorAux: any = {} as BackServiceSponsors;
  public sponsor: BackServiceSponsors = {} as BackServiceSponsors;

  constructor(
    private utilsService: UtilsService,
    private sponsorService: SponsorService,
  ) {
    this.eventID = Number(sessionStorage.getItem('EventID'))
    this.sponsor.newLogo = { data: '', extension: '', name: '', url: '' };
  }

  async ngOnInit(): Promise<void> {
    this.sponsorID = Number(this.utilsService.getParam('ID'));

    if (this.eventID > 0) {
      if (this.sponsorID === 0) {
        this.sponsor.id = 0;
        this.sponsor.eventId = this.eventID;
      } else {
        await this.getOneSponsor(this.sponsorID);
      }
    } else {
      this.utilsService.dialog('ERROR', 'Alerta', '¡No tienes seleccionado un evento!');
      this.goToPage('/admin/events/calendario-eventos');
    }
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  async addFile(event: any) {
    const file = event.target.files[0];
    const extension = file.type.split('/');
    const base64 = await this.utilsService.convertBase64(event.target.files.item(0));

    if (typeof base64 === 'string') {
      this.sponsor.newLogo = { extension: extension[1], name: file.name, data: base64, url: '' };

      // Validar las dimensiones de la imagen
      const minWidth = 720;
      const minHeight = 536;

      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= minWidth && img.height >= minHeight) {
          // La imagen cumple con las dimensiones mínimas
          this.imageSizeError = false;
        } else {
          // La imagen no cumple con las dimensiones mínimas
          this.imageSizeError = true;
          // Puedes mostrar un mensaje de error si lo deseas
          // this.utilsService.dialog('ERROR', 'Error', 'La imagen debe tener al menos 720px de ancho y 536px de alto.');
        }
      };
    }
  }

  mark_img(name: string) {
    setTimeout(() => {
      this.form.controls[name].markAsTouched();
    }, 500)
  }

  getOneSponsor(sponsor_id: number) {
    this.loading = true;
    return this.sponsorService.getOneSponsor(sponsor_id).then(
      async (response) => {
        console.log(response.result);

        this.sponsor.eventId = this.eventID;
        this.sponsor.nit = response?.result?.nit;
        this.sponsor.id = response?.result?.sponsorId;
        this.sponsor.website = response?.result?.moreInforLink;
        this.sponsor.descripcion = response?.result?.description;
        this.sponsor.nombreUsuario = response?.result?.nombreUsuario;
        this.sponsor.nombrePatrocinador = response?.result?.sponsorName;
        (response?.result?.codigoCrm != null) && (this.permissionCRM = true);

        const phone = await response?.result?.itemsDescription.find((x: any) => x.title === 'Teléfono');
        const location = await response?.result?.itemsDescription.find((x: any) => x.title === 'Ubicación');
        const email = await response?.result?.itemsDescription.find((x: any) => x.title === 'Correo Electrónico');
        this.sponsor.email = email?.description; // response?.result?.itemsDescription[1]?.title;
        this.sponsor.telefono = phone?.description; // response?.result?.itemsDescription[0]?.title;    
        this.sponsor.ubicacion = location?.description; // response?.result?.itemsDescription[2]?.title;


        const twitter = await response?.result?.socialNetworks.find((x: any) => x.title === 'Twitter');
        const facebook = await response?.result?.socialNetworks.find((x: any) => x.title === 'Facebook');
        const linkedIn = await response?.result?.socialNetworks.find((x: any) => x.title === 'LinkedIn');
        const instagram = await response?.result?.socialNetworks.find((x: any) => x.title === 'Instagram');
        this.sponsor.twitter = twitter?.social_link; // response?.result?.socialNetworks[2]?.title;
        this.sponsor.facebook = facebook?.social_link; // response?.result?.socialNetworks[1]?.title;
        this.sponsor.linkedin = linkedIn?.social_link; // response?.result?.socialNetworks[3]?.title;
        this.sponsor.instagram = instagram?.social_link; // response?.result?.socialNetworks[0]?.title;

        if (typeof response.result?.imageHeader === 'string') {
          const logotipo = response.result.imageHeader.split('/');
          this.sponsor.newLogo = { data: '', extension: '', name: logotipo[6], url: response.result?.imageHeader };
        } else {
          this.sponsor.newLogo = { data: '', extension: '', name: '', url: '' };
        }

        // console.log(response.result)
        // console.log(this.sponsor)
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || '¡Error al cargar patrocinador!');
        this.goToPage('/admin/events/admin-patrocinadores');
      }).finally(() => {
        this.loading = false
      })
  }

  processData() {
    if (this.form.valid) {
      this.sponsorAux = {} as BackServiceSponsors;
      if (this.sponsor.newLogo.data === '') { //if (this.sponsor.logotipo.data === '') {
        const { newLogo, ...data } = this.sponsor;
        this.sponsorAux = data;
      } else {
        this.sponsorAux = this.sponsor;
      }

      if (this.sponsorID > 0) {
        this.updateSponsor(this.sponsorAux);
      }
      else {
        delete this.sponsorAux.id;
        this.createSponsor(this.sponsorAux);
      }
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos obligatorios.');
    }
  }

  createSponsor(sponsorData: BackServiceSponsors) {
    console.log('CREATE ', sponsorData)
    this.loading = true;
    this.disabled = true;

    this.sponsorService.createSponsorToEvent(sponsorData).then(
      (response) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.descriptio || '¡Patrocinador registrado!');
        this.goToPage('/admin/events/admin-patrocinadores');
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || '¡Al registrar patrocinador!');
      }).finally(() => {
        this.loading = false
        this.disabled = false;
      });
  }

  updateSponsor(sponsorData: BackServiceSponsors) {
    console.log('UPDATE ', sponsorData);
    this.loading = true;
    this.disabled = true;

    this.sponsorService.updateSponsorByEvent(this.sponsorID, sponsorData).then(
      (response) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.descriptio || '¡Patrocinador actualizado!');
        this.goToPage('/admin/events/admin-patrocinadores');
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || '¡Al actualizar patrocinador!');
      }).finally(() => {
        this.loading = false
        this.disabled = false;
      });
  }
}
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UtilsService } from 'src/app/Services/utils.service';
import { ConferenceService } from 'src/app/Services/conference.service';

@Component({
  selector: 'app-admin-conferencias',
  templateUrl: './admin-conferencias.component.html',
  styleUrls: ['./admin-conferencias.component.css']
})
export class AdminConferenciasComponent implements OnInit {
  public dates: any[] = [{ date: '', data: [{ 'Hora y Lugar': '', 'Nombre de la actividad': '', 'Conferencista': '', 'Modalidad': '', 'Estatus': '' }] }];
  public eventID: number = 0;
  public modality: any[] = [];
  public loading: boolean = false;
  public checkbox: boolean = false
  public categoriesHeading: string[] = [];

  constructor(
    private utilsService: UtilsService,
    private conferenceService: ConferenceService,
  ) {
    this.categoriesHeading = Object.keys(this.dates[0].data[0]);
    this.eventID = Number(sessionStorage.getItem('EventID'));
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const getModalities = await this.utilsService.getModalities();
    this.modality = getModalities.result.modalities;
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      // this.loadDatabyEvent(eventID);
      await this.loadConferencesbyEvent(this.eventID)
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/login');
    }
    this.loading = false;
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName)
  }

  convert(key: string, data: any) {
    return data[key]
  }

  async selectAtivity(event: any, all: boolean, activity_id?: number) {
    if (all) {
      for (const e of this.dates) {
        for (const i of e.data) {
          i.checkbox = event.target.checked
        }
      }
    } else {
      this.checkbox = false;
      for (const e of this.dates) {
        for (const i of e.data) {
          if (i.id === activity_id) {
            i.checkbox = event.target.checked
          }
        }
      }
    }
  }

  showActions(event: any) {
    const element = document.getElementById('item' + event.id);
    const listItes = document.getElementsByClassName('actions-enable')[0];
    if (listItes) {
      listItes.classList.remove('actions-enable');
    }
    if (('item' + event.id) !== listItes?.id || !listItes.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  formatHour(dateTime: string): string {
    const date = new Date(dateTime);
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC'
    };
    const formattedHour = date.toLocaleString('en-US', options);
    return formattedHour;
  }

  loadConferencesbyEvent(event_id: number) {
    this.loading = true;
    return this.conferenceService.getActivityByEvent(event_id).then(
      response => {
        console.log('response: ', response.result);
        if (response.success === true) {
          this.dates = [];
          this.dates = response.result.map((e: any) => {
            const data = e?.actividades.map((i: any) => {
              const modality = this.modality.find(x => x.id === i?.modalityId);

              return {
                id: i?.id,
                checkbox: false,
                'Hora y Lugar': {
                  Lugar: i?.direccion,
                  // Hora: i.fechaHoraInicio && i?.fechaHoraFin ? i?.fechaHoraInicio?.substr(11, 5) + ' - ' + i?.fechaHoraFin?.substr(11, 5) : ''
                  Hora: i.fechaHoraInicio && i?.fechaHoraFin ? this.formatHour(i?.fechaHoraInicio) + ' - ' + this.formatHour(i?.fechaHoraFin) : ''
                },
                'Nombre de la actividad': i?.nombreActividad,
                Conferencista: i?.speaker?.nombreConferencista,
                Modalidad: modality.name ? modality.name : '',
                Estatus: i.status === true ? '1' : '0'
              }
            });
            return { date: e.group_date, data: data }
          });
          // console.log(this.dates);
          // sessionStorage.setItem('scheduleData', true);
        } else {
          console.log('error 1', response);
          this.utilsService.dialog('ERROR', 'Error', '¡Error al consultar la agenda de evento!');
        }
      },
      error => {
        console.log('error: ', error);
        this.utilsService.dialog('ERROR', error.error?.message?.title, error.error?.message?.description);
        // this.utilsService.dialog('ERROR', 'Error', 'Error al cargar agenda!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  editActivity(activity_id: number) {
    this.utilsService.goToPage('/admin/events/edit-conferencias', { ID: activity_id })
  }

  async update($event: any, activity_id: number, oldvalue: string, index_one: any, index_two: any) {
    this.loading = true;

    try {
      const activity = await this.conferenceService.getOneActivity(activity_id);
      const activity_json = {
        id: activity_id,
        eventId: this.eventID,
        status: Boolean(Number($event.target.value)),
        speakerId: activity?.result?.speakerId,
        scheduleId: activity?.result?.scheduleId,
        modalityId: activity?.result?.modalityId,
        fechaHoraFin: activity?.result?.fechaHoraFin,
        fechaHoraInicio: activity?.result?.fechaHoraInicio,
        // direccion: activity?.result?.direccion,
        // description: activity?.result?.description,
        // nombreActividad: activity?.result?.nombreActividad,
        // enlaceConferencia: activity?.result?.enlaceConferencia,
      }

      const response = await this.conferenceService.editActivity(activity_id, activity_json);
      if (response.success == true) {
        this.dates[index_one].data[index_two].Estatus = $event.target.value;
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.title || '¡Actividad actualizada!');
      } else {
        $event.target.value = oldvalue;
        this.dates[index_one].data[index_two].Estatus = oldvalue;
        this.utilsService.dialog('ERROR', 'Error', response?.error?.message?.description || 'Se encontro un error.');
      }
    } catch (error: any) {
      $event.target.value = oldvalue;
      this.dates[index_one].data[index_two].Estatus = oldvalue;
      this.utilsService.dialog('ERROR', 'Error', error.error.message.description || 'Se encontro un error.');
    }
    this.loading = false
  }
  
  removeActivity(activity_id: number) {
    this.loading = true;
    this.conferenceService.deletedActivity(activity_id).then(
      async (response) => {
        console.log(response);
        await this.loadConferencesbyEvent(this.eventID);
        this.utilsService.dialog('SUCCESS', 'Éxito', '¡La activida se elimino!');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡La activida no se elimino!');
      }).finally(() => {
        this.loading = false
      })
  }

  async removeAllActivity() {
    let count = 0;
    let errores: any[] = new Array();
    for (const e of this.dates) {
      for (const i of e.data) {
        if (i.checkbox === true) {
          count++;
          await this.conferenceService.deletedActivity(i.id).then(
            response => {
              console.log(response);
              errores.push(false)
            },
            error => {
              console.log(error);
              errores.push(true)
            }
          );
        }
      }
    }
    console.log(count, errores);
    if (count === 0) {
      this.utilsService.dialog('ERROR', 'Alerta', '¡Seleccione una actividad!');
    }
    else if (errores.includes(true) && count > 0) {
      await this.loadConferencesbyEvent(this.eventID)
      this.utilsService.dialog('ERROR', 'Error', '¡Error al eliminar una actividad!');
    } else {
      await this.loadConferencesbyEvent(this.eventID)
      this.utilsService.dialog('SUCCESS', 'Éxito', '¡Actividades eliminadas!');
    }
  }
}

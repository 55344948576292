import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationItem, NotificationResponse } from '../Models/notification.item.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private params: HttpParams;
  private headers: HttpHeaders;
  private url: string = environment.url;
  private options: { headers: HttpHeaders, params?: HttpParams };

  constructor(private http: HttpClient) {
    this.params = new HttpParams();
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('accept', 'application/json');
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.options = { headers: this.headers };
  }

  getAllNotifications(page: number | null, size: number | null, subject: string | null): Promise<any> {
    this.params = new HttpParams();
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    (subject != null && subject != '') && (this.params = this.params.set('subject', subject));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}notification/notifications`, this.options).toPromise();
  }

  getAllNotificationsDennis(page: number, size: number): Promise<NotificationResponse | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get<NotificationResponse>(`${this.url}notification/notifications/?page=${page}&size=${size}`, this.options).toPromise();
  }

  getOneNotifications(notification_id: number): Promise<NotificationResponse | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get<NotificationResponse>(`${this.url}/notification/notifications/${notification_id}`, this.options).toPromise();
  }

  createScheduleNotificacion(notification: NotificationItem): Promise<NotificationResponse | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.post<NotificationResponse>(`${this.url}notification/notifications`, notification, this.options,).toPromise();
  }

  createInstantNotificacion(notification: NotificationItem): Promise<NotificationResponse | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.post<NotificationResponse>(`${this.url}notification/notifications/instant`, notification, this.options,).toPromise();
  }

  updateNotification(notification: NotificationItem): Promise<NotificationResponse | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.put<NotificationResponse>(`${this.url}notification/notifications`, notification, this.options).toPromise();
  }

  deleteNotification(id: number): Promise<NotificationResponse | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.delete<NotificationResponse>(`${this.url}notification/notifications/${id}`, this.options).toPromise();
  }
}

import { Component, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../Services/utils.service';
import { BackService } from '../../Models/back-service.model';
import { BackofficeService } from '../../Services/backoffice.service';


@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent {
  @Output() toRecover?: string;

  email: string = '';
  loginData: BackService = {
    username: '',
    password: ''
  };

  constructor(
    private utilsService: UtilsService,
    private backofficeService: BackofficeService
  ) { }

  recovery() {
    this.loginData = {
      username: this.email
    }

    this.backofficeService.recovery(this.loginData)
      .subscribe({
        next: (res) => {
          console.log('true', res)
          sessionStorage.setItem('tempToken', res.result.token);
          sessionStorage.setItem('recoverEmail', this.email);
          this.goToPage('/code-recovery');
        },
        error: (e) => {
          console.log('false', e)
          this.utilsService.dialog('ERROR', 'Error', 'El usuario ingresado no es válido.');
        }
      })
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName)
  }

}

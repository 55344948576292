import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class LocationService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    // LOCATION    
    // Obtener ubicacion de un evento
    getLocationByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/locations/event/${event_id}`, this.options).toPromise();
    }

    // Obtener una ubicacion
    getOneLocation(location_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/locations/${location_id}`, this.options).toPromise();
    }

    // Crear una ubicacion
    createLocationToEvent(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/locations/`, data, this.options).toPromise();
    }

    // Actualiar una ubicacion
    updateLocationByEvent(location_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/locations/${location_id}`, data, this.options).toPromise();
    }

    // Eliminar una ubicacion
    deleteLocation(location_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/locations/${location_id}`, this.options).toPromise();
    }

    // PARKING
    // GET BY LOCATION eventos/parkings/location/:location_id

    // Obtener un parqueo
    getOneParking(parking_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/parkings/${parking_id}`, this.options).toPromise();
    }

    // Crear un parqueo de una ubicacion
    createParkingToLocation(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/parkings/`, data, this.options).toPromise();
    }

    // Actualiar un parqueo de una ubicacion
    updateParkingByLocation(parking_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/parkings/${parking_id}`, data, this.options).toPromise();
    }

    // Eliminar un parqueo de una ubicacion
    deleteParking(location_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/parkings/${location_id}`, this.options).toPromise();
    }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ConferenceService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    // ACTIVITY    
    getActivityByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/activities/eventBackOffice/${event_id}`, this.options).toPromise();
    }

    getOneActivity(activity_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/activities/${activity_id}`, this.options).toPromise();
    }

    createActivity(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/activities/`, data, this.options).toPromise();
        // return this.http.post(`${this.url}eventos/activities/eventBackOffice/`, data, this.headers).toPromise();
    }

    editActivity(activity_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/activities/${activity_id}`, data, this.options).toPromise();
    }

    deletedActivity(activity_id: any): Promise<any> {
        return this.http.delete(`${this.url}eventos/activities/${activity_id}`, this.options).toPromise();
    }

    // QUESTIONS BY ACTIVITY
    getQuestionsByActivity(activity_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/questions/activity/${activity_id}`, this.options).toPromise();
    }

    // QUESTIONS BY SPEAKER
    getQuestionsBySpeaker(speaker_id: number): Promise<any> {
        return this.http.get(`${this.url}/eventos/questions/speaker/${speaker_id}`, this.options).toPromise();
    }

    updateQuestion(question_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/questions/${question_id}`, data, this.options).toPromise();
    }

    deleteQuestion(question_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/questions/${question_id}`, this.options).toPromise();
    }
}

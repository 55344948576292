<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet">
<div class="main">
    <label class="label-1"> Agregar precio de evento</label>
    <div class="row-level">
        <div class="custom-input-dialog datetime-box">
            <label>Fecha de inicio</label>
            <div class="dt-row">
                <form class="row row-cols-sm-auto cal-form">
                    <div class="col-12">
                        <div class="input-group">
                            <input
                                class="form-control"
                                placeholder="yyyy-mm-dd"
                                name="dp"
                                [(ngModel)]="model"
                                ngbDatepicker
                                #d="ngbDatepicker"
                            />
                            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="custom-input-dialog">
            <label>Precio Asociados</label>
            <label class="label-precio" data-domain="Q">
            <input class="input-precio"
            placeholder="150.00"/>
            </label>
        </div>
    </div>
    <div class="row-level">
        <div class="custom-input-dialog datetime-box">
            <label>Fecha fin</label>
            <div class="dt-row">
                <form class="row row-cols-sm-auto cal-form">
                    <div class="col-12">
                        <div class="input-group">
                            <input
                                class="form-control"
                                placeholder="yyyy-mm-dd"
                                name="dp"
                                [(ngModel)]="model2"
                                ngbDatepicker
                                #e="ngbDatepicker"
                            />
                            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="e.toggle()" type="button"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="custom-input-dialog">
            <label>Precio Público</label>
            <label class="label-precio" data-domain="Q">
            <input class="input-precio"
            placeholder="150.00"/>
            </label>
        </div>
    </div>
    <div class="row-level">
        <div class="custom-input-dialog tipo-entrada">
            <label>Tipo de entrada</label>
            <select name="entrada" id="entrada" class="entrada-box">
                <option *ngFor="let x of eventList">{{x}}</option>
            </select>
        </div>
    </div>

    <div class="block-bottom-modal">
        <button class="cancle-btn-modal" (click)="close()">Cancelar</button>
        <button class="save-btn-modal" (click)="close()">Guardar</button>
    </div>

</div>
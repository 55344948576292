<app-loading *ngIf="loading"></app-loading>
<div class="main-container">
    <div class="header">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/calendario-eventos')"></i>
            <span class="change-page-label"> Atrás </span>
            <div class="next-container">
                <span class="change-page-label"> Siguiente </span>
                <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-participantes')"></i>
            </div>
        </div>
        <button class="agregar" (click)="goToPage('/admin/events/edit-agenda')" [disabled]="dateDisabled()" [ngbTooltip]="dateDisabled() ? 'Evento Finalizado' : ''">+ Agregar Actividad</button>
    </div>
    <div class="main">
        <div class="default-view">
            <div class="table-default">
                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let each of categoriesHeading">
                                <th *ngIf="each !== 'Fecha'"> {{each}} </th>
                            </ng-container>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let e of dates; let x = index">
                        <tr id="dateRow" class="date_Row">
                            <td colspan="6">{{ e.date.toString() | date:'EEEE, MMMM d, y' | titlecase }}</td>
                        </tr>
                        <tr *ngFor="let item of e.data; let i = index">
                            <ng-container>
                                <ng-container *ngFor="let x of item | keyvalue:originalOrder">
                                    <td *ngIf="x.key === 'Hora'">
                                        {{x.value | lowercase }}
                                    </td>
                                    <td [ngClass]="{'nombre' : x.key === 'Nombre de la actividad'}" [hidden]="x.key == 'id' || x.key ==  'Hora'">
                                        {{x.value}}
                                    </td>
                                </ng-container>
                                <td (mouseleave)="hideActions()">
                                    <button name="actions" id="actions" class="actions" (click)="showActions(x, i, item)"
                                        (keyup)="showActions(x, i, item)"></button>
                                    <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{x}}{{i}}{{item.id}}" (mouseleave)="hideActions()">
                                        <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left"
                                            (click)="editSchedule(item.id)">Editar</button>
                                        <button name="delete" id="delete" class="actions-btn w-100 text-left"
                                            (click)="deleteDialog(item.id)">Eliminar</button>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- WITH BOOTSTRAP -->
<!--<div class="container-fluid px-5 my-5">
    <div class="row">
        <div class="col-12 mb-4">
            <div class="row">
                <div class="col-12 col-md-6 d-sm-none d-md-block">
                    ocultar contenido
                    <i class="back-btn" id="back" (click)="goToPage('/admin/users/admin-administradores')"></i>
                    <span class="change-page-label"> Atrás </span>
                </div>
                <div class="col-12 col-md-6 text-right">
                    <span class="change-page-label"> Siguiente </span>
                    <i class="back-btn" id="back" (click)="goToPage('/admin/users/admin-administradores')"></i>
                </div>
            </div>
        </div>
        <div class="col-12 mb-3 text-right">
            <button class="btn btn-primary px-4 my-2" type="button">+ Agregar Actividad</button>
        </div>
        <div class="col-12 mb-3">
            <div class="card p-4">
                <table class="table"></table>
                <table class="table-responsive">
                    <thead>
                        <tr>
                            <th scope="col" *ngFor="let each of categoriesHeading">{{each}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider" *ngFor="let e of dates">
                        <tr *ngFor="let item of e.data; let i = index">
                            <td  *ngFor="let x of item | keyvalue:originalOrder" [hidden]="x.key === 'id'">{{x.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../Services/utils.service';
import { BackService } from '../../Models/back-service.model';
import { BackofficeService } from '../../Services/backoffice.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-password-recovery-code',
  templateUrl: './password-recovery-code.component.html',
  styleUrls: ['./password-recovery-code.component.css']
})
export class PasswordRecoveryCodeComponent implements OnInit {
  public formGroup!: FormGroup;
  public loginData = new BackService();
  public recoveryData: {} = {
    codigo: ''
  };

  ngOnInit() {
    this.loginData.username = sessionStorage.getItem('recoverEmail');

    this.formGroup = this.fb.group({
      things: this.fb.array([
        this.fb.control('', Validators.required),
        this.fb.control('', Validators.required),
        this.fb.control('', Validators.required),
        this.fb.control('', Validators.required)
      ])
    });

  }

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private backofficeService: BackofficeService
    ) { }

  recovery() {
    const data = this.formGroup.value.things;
    this.recoveryData = {
      codigo: data[0]+data[1]+data[2]+data[3]
    }

    this.backofficeService.verify(this.recoveryData, sessionStorage.getItem('tempToken'))
      .subscribe({
        next: (res) => {
          sessionStorage.setItem('tempToken', res.result.token);
          this.goToPage('/new-password');
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'El código ingresado es incorrecto.');
        }
      })
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  tabField(i: any, event: any) {
    const nextElementSiblingId = 'input' + i;
    const element = document.getElementById(nextElementSiblingId);
    const isNumber = isFinite(event.key);
    if (element && isNumber) {
      element.focus();
    }
  }

  reSend() {
    this.backofficeService.recovery(this.loginData)
      .subscribe({
        next: (res) => {
          
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'El usuario ingresado no es válido.');
        }
      })
  }
}

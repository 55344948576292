import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BackServiceSpeakers, listas } from 'src/app/Models/back-service.model';
import { BackofficeService } from 'src/app/Services/backoffice.service';
import { SpeakerService } from 'src/app/Services/speaker.service';
import { UtilsService } from 'src/app/Services/utils.service';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddDialogComponent {
  // @Input() id!: string;
  @Input() job?: string;
  @Input() image!: string;
  @Input() speaker_id?: number;
  @Input() information?: string;

  public file: string = '';
  public jobList: listas[] = [];
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public speakerData = {} as BackServiceSpeakers;
  // public speakerData = new BackServiceSpeakers();

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private utilsService: UtilsService,
    private speakerService: SpeakerService,
  ) {
    this.utilsService.getPuestos().then(
      (response) => {
        this.jobList = response.result.puestos;
      }
    )

    this.speakerData.fotoConferencista = { data: '', extension: '' }
   }

  close() {
    this.modalService.dismissAll(this);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  onFileSelected(event: any) {
    console.log(event.target.id);
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0].name;
    }
    else {
      this.file = "No hay archivos seleccionados.";
    }

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.speakerData.fotoConferencista!.extension = file.type.substring(file.type.indexOf('/') + 1);

    const reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.image = reader.result;
    this.speakerData.fotoConferencista!.data = this.image;
    this.loaded = true;
  }

  async save() {
    const puesto_id = await this.jobList.find( x => x.name === this.job );
    this.speakerData.profesion = puesto_id?.id; // this.job;

    this.speaker_id = Number(this.speaker_id);
    // this.speakerData.id = Number(this.speaker_id);
    // this.speakerData.informacionConferencista = this.information;

    let JSON = {
      id: this.speaker_id,
      profesion: this.speakerData.profesion,
      informacionConferencista: this.information,
      fotoConferencista: this.speakerData.fotoConferencista
    };

    console.log(JSON)
    if(JSON.fotoConferencista!.data === '') { 
      delete this.speakerData.fotoConferencista;
      delete JSON.fotoConferencista;
    }

    this.speakerService.updateSpeakerByEvent(this.speaker_id, JSON).then(
    // this.speakerService.updateSpeakerByEvent(this.speaker_id, this.speakerData).then(
      (response) => {
        // console.log('exito', response);
        this.close();
        this.utilsService.dialog('SUCCESS', 'Éxito', 'Expositor actualizado.');
      },
      error => {
        // console.log('exito', error);
        this.close();
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      });
  }
}

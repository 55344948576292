<app-loading *ngIf="loading"></app-loading>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet">
<!-- <form #AGForm="ngForm"> -->
<form #form="ngForm">
    <div class="main">
        <div class="top-block">
            <div class="top-block-1">
                <i class="back-btn" id="back" (click)="goToPage('/admin/events/calendario-eventos')"></i>
                <span class="back-label"> Atrás </span>
            </div>
            <div class="top-block-2">
                <span style="margin-bottom: 10px; padding-left: 10px;"> Seleccione categoría de evento</span>
                <select class="categoria-evento" [ngClass]="{'disabled' : env }" name="categoria" id="category" [(ngModel)]="eventData.eventCategoryId"
                    (change)="changeCategory()" [disabled]="env" required>
                    <option *ngFor="let x of categoryList" value="{{x.id}}">{{x.name}}</option>
                </select>
            </div>
        </div>

        <div id="mainEvento" class="main-block not-visible">
            <label class="label-1"> Información del evento</label>
            <div class="status-block">
                <span class="status-label"></span>
                <select class="status-box" name="status" id="status" [(ngModel)]="eventData.status" required>
                    <option value="finished">Finalizado</option>
                    <option value="active">Activo</option>
                    <option value="draft">Borrador</option>
                </select>
            </div>
            <i class="down-arrow" id="down"></i>
            <div class="row-1">
                <div class="col-md-1-form">
          
                    <div class="custom-input">
                        <label>Nombre del evento</label>
                        <input type="text" [ngClass]="{'disabled' : env }" name="_nombreEvento" id="_nombreEvento" [(ngModel)]="eventData.nombreEvento" #_nombreEvento="ngModel" placeholder="Nombre del evento" [readOnly]="env" required/>                        
                    </div>
                    <div class="custom-input">
                        <label>Descripción</label>
                        <textarea [ngClass]="{'disabled' : env }" name="Desc" id="Desc" cols="40" rows="5" [(ngModel)]="eventData.descripcion" [readonly]="env"></textarea>                        
                    </div>
                    <div class="custom-input">
                        <label>Dirección del evento</label>
                        <input [ngClass]="{'disabled' : env }" name="direccion" id="direccion" [(ngModel)]="eventData.direccion" [disabled]="env" placeholder="15 Avenida 14-72, Cdad" />
                    </div>                    
                </div>
                <div class="col-md-2-form">
                    <div class="custom-input">
                        <label>Dirigido a</label>
                        <select class="to-box tipo-evento" name="dirigido" id="dirigido" [ngClass]="{'disabled' : env && permissionCRM }"
                            [(ngModel)]="eventData.guestTypeId" required [disabled]="env && permissionCRM">
                            <option *ngFor="let x of guestList" value="{{x.id}}">{{x.name}}</option>
                        </select>
                    </div>
                    <div class="custom-input">
                        <label>Tipo de actividad</label>
                        <select class="actividad-box tipo-evento"  name="actividad" id="actividad" [ngClass]="{'disabled' : env && permissionCRM }" 
                            [(ngModel)]="eventData.eventTypeId" required [disabled]="env && permissionCRM">
                            <option *ngFor="let x of typeList" value="{{x.id}}">{{x.name}}</option>
                        </select>                        
                        <div class="err-msg" *ngIf="validation_msg('actividad')"> Este campo es obligatorio.</div>                        
                    </div>
                    <div class="custom-input">
                        <label>Enlace del sitio</label>
                        <input name="enlaceSitio" id="enlaceSitio" [(ngModel)]="eventData.enlaceSitio" placeholder="www.export.com.gt" />
                    </div>                
                </div>
                <div class="col-md-3-form">
                    <div class="code-form" style="text-align: center;">
                        <label style="text-align: start;">Código QR</label>
                        <qrcode #parent [qrdata]="myAngularxQrCode" [width]="230" [errorCorrectionLevel]="'M'"
                            (qrCodeURL)="onChangeURL($event)"></qrcode>
                        <a class="qr-btn" [href]="qrCodeSrc" download="qrcode">Descargar QR</a>
                    </div>
                </div>

            </div>
            <div class="row-10">
                <div class="custom-input modalidad-box">
                    <label>Modalidad*</label>
                    <select class="modalidad" [ngClass]="{'disabled' : env }" name="modalidad" id="modalidad" [(ngModel)]="eventData.modalityId" [disabled]="env" required>
                        <option *ngFor="let x of modalityList" value="{{x.id}}">{{x.name}}</option>
                    </select>
                    <div class="err-msg" *ngIf="validation_msg('modalidad')"> Este campo es obligatorio.</div>
                </div>
                <div class="custom-input">
                    <label>Enlace de evento <strong class="mod-box">(modalidad virtual)</strong></label>
                    <input [ngClass]="{'disabled' : env }" name="enlaceEvento" id="enlaceEvento" [(ngModel)]="eventData.enlaceEvento" [disabled]="env" placeholder="Enlace del evento" />
                </div>
            </div>

            <div class="row-10">
                <div class="col-md-4-form">
                    <div class="custom-input datetime-box">
                        <label>Fecha y hora de inicio</label>
                        <div class="dt-row">
                            <form class="row row-cols-sm-auto cal-form">
                                <div class="col-12">
                                    <div class="input-group" [ngClass]="{'disabled' : env }">
                                        <input class="form-control" [ngClass]="{'disabled' : env }" name="dp" id="dp" ngbDatepicker #d="ngbDatepicker"
                                        placeholder="dd-mm-yyyy" [(ngModel)]="dateStart" [value]="dateStart | customDate"
                                        [disabled]="env" required onkeydown="return false"/>
                                        <button class="btn btn-outline-secondary bi bi-calendar3" [ngClass]="{'disabled' : env }" (click)="d.toggle()"
                                            type="button" [disabled]="env"></button>
                                    </div>
                                </div>
                            </form>
                            <input type="time" class="form-control-time" [ngClass]="{'disabled' : env }" id="apptTI" name="appTI" placeholder="00:00"
                            [(ngModel)]="eventData.horaInicio" [disabled]="env" required/>
                        </div>
                    </div>   

                    <div class="custom-input datetime-box">
                        <label>Fecha y hora fin</label>
                        <div class="dt-row">
                            <form class="row row-cols-sm-auto cal-form">
                                <div class="col-12">
                                    <div class="input-group" [ngClass]="{'disabled' : env }">
                                        <input class="form-control" [ngClass]="{'disabled' : env }" name="dp2" id="dp2" ngbDatepicker #e="ngbDatepicker"
                                        placeholder="dd-mm-yyyy" [(ngModel)]="dateEnd" [value]="dateEnd | customDate"
                                        [disabled]="env" required onkeydown="return false"/>
                                        <button class="btn btn-outline-secondary bi bi-calendar3" [ngClass]="{'disabled' : env }" (click)="e.toggle()" type="button"></button>
                                    </div>
                                </div>
                            </form>
                            <input type="time" class="form-control-time" [ngClass]="{'disabled' : env }" name="appTF" id="appTF" placeholder="00:00"
                                [(ngModel)]="eventData.horaFinal" [disabled]="env" required>
                        </div>
                    </div>    
                    <div class="err-msg text-left" *ngIf="validation_date()"> La fecha final no puede ser menor a la inicial.</div>
                </div>

                <div class="col-md-5-form">
                    <div class="custom-input">
                        <label>Imagen del evento</label>
                        <input class="custom-input file-box" name="gafete-file" id="gafete-file"
                            [(ngModel)]="eventData.imageEvent.name" readonly required />
                        <i class="upload-btn" id="upload-cal" onclick="document.getElementById('fileGaf').click();"></i>
                        <input type="file" style="display:none;" name="fileGaf" id="fileGaf" #FileSelectInputDialog
                            (change)="onFileSelected($event)" accept="image/png, image/jpeg" />
                        <div class="err-msg" *ngIf="validation_msg('gafete-file')">Este campo es obligatorio.</div>
                    </div>

                    <div class="custom-input">
                        <label>Thumbnail del evento</label>
                        <input class="custom-input file-box" name="thumbnail-file" id="thumbnail-file"
                            [(ngModel)]="eventData.thumbEvent.name" readonly required />
                        <i class="upload-btn" id="upload-cal"
                            onclick="document.getElementById('thumbnailGafete').click();"></i>
                        <input type="file" style="display:none;" name="thumbnailGafete" id="thumbnailGafete"
                            #FileSelectInputDialog (change)="onFileSelected($event)" accept="image/png, image/jpeg" />
                        <div class="err-msg" *ngIf="validation_msg('thumbnail-file')">Este campo es obligatorio.</div>
                    </div>
                </div>
            </div>

            <div class="row-10 quastion">
                <div class="custom-input top-head">
                    <label class="gafete-label">¿Necesitas gafete para el evento?</label>
                    <!-- {{op_yes}} - {{op_yes === true}} -->
                    <div class="checkbox-group">
                        <input class="checkbox-item" name="groupA" type="checkbox" [(ngModel)]="op_yes"
                            (change)="changeGroup(1)" />
                        <label for="yes">Si</label>
                        <input class="checkbox-item" name="groupB" type="checkbox" [(ngModel)]="op_not"
                            (change)="changeGroup(0)" />
                        <label for="no">No</label>
                    </div>
                </div>
            </div>
            <div class="row-10">
                <div class="custom-input">
                    <input class="custom-input file-box" name="gafete-header" id="gafete-header"
                        [(ngModel)]="eventData.imageBadgeHeader.name" placeholder="Subir encabezado.jpg" readonly required="{{op_yes === true}}"/>
                    <button class="gafete-btn" id="upload-cal" onclick="document.getElementById('fileheader').click();"
                        [disabled]="op_not"></button>
                    <input type="file" style="display:none;" name="fileheader" id="fileheader" #FileSelectInputDialog
                        (change)="onFileSelected($event)" />
                        <div class="err-msg" *ngIf="validation_msg('gafete-header')">Este campo es obligatorio.</div>
                </div>
                <div class="custom-input">
                    <input class="custom-input file-box" name="gafete-footer" id="gafete-footer"
                        [(ngModel)]="eventData.imageBadgeFooter.name" placeholder="Subir pie..página.jpg" readonly [required]="op_yes === true" />
                    <button class="gafete-btn" id="upload-cal" onclick="document.getElementById('filefooter').click();"
                        [disabled]="op_not"></button>
                    <input type="file" style="display:none;" name="filefooter" id="filefooter" #FileSelectInputDialog
                        (change)="onFileSelected($event)" />
                        <div class="err-msg" *ngIf="validation_msg('gafete-footer')">Este campo es obligatorio.</div>
                </div>
            </div>

            <div class="row-10">
                <div class="custom-input">
                    <label>Sector</label>
                    <ng-multiselect-dropdown [placeholder]="'Sectores'" name="md_sector" id="md_sector" class="enabled"
                        [settings]="dropdownSettings" [data]="sectorsList" [disabled]="true"  [(ngModel)]="sectors">
                    </ng-multiselect-dropdown>
                </div>
                <div class="custom-input">
                    <label>Comisión</label>
                    <ng-multiselect-dropdown [placeholder]="'Comisiones'" name="md_comison" id="md_comison"
                        [settings]="dropdownSettings" [data]="commissonsList" [disabled]="true"  [(ngModel)]="comison">
                    </ng-multiselect-dropdown>                    

                </div>
                <div class="custom-input">
                    <label>Comité</label>
                    <ng-multiselect-dropdown [placeholder]="'Comites'" name="md_comite" id="md_comite"
                        [settings]="dropdownSettings" [data]="committeesList" [disabled]="true"  [(ngModel)]="comite">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <mat-divider class="divider-section"></mat-divider>

            <label class="header-1 label-1"> Datos del creador del evento</label>
            <div class="row-3">
                <div class="col-md-4-form">
                    <div class="custom-input">
                        <label>Nombre del organizador</label>
                        <input [ngClass]="{'disabled': env}" name="userName" id="userName"  [(ngModel)]="userFullName" readonly />
                    </div>
                    <div class="custom-input">
                        <label>Teléfono</label>
                        <input [ngClass]="{'disabled': env}" name="userPhone" id="userPhone"  [(ngModel)]="userData.phone" readonly />
                    </div>
                    <div class="custom-input">
                        <label>Área organizacional</label>
                        <input [ngClass]="{'disabled': env}" name="userAreaO" id="userAreaO" [(ngModel)]="userData.areaOrganizacional" readonly />
                    </div>
                    <div class="custom-input">
                        <label>Cargo</label>
                        <input [ngClass]="{'disabled': env}" name="userPuesto" id="userPuesto" [(ngModel)]="userData.puesto" readonly />
                    </div>
                </div>
                <div class="col-md-5-form">
                    <div class="custom-input">
                        <label>Correo electrónico</label>
                        <input [ngClass]="{'disabled': env}" name="userEmail" id="userEmail" [(ngModel)]="userData.email" type="email" readonly />
                    </div>
                    <div class="custom-input">
                        <label>Rol</label>
                        <input [ngClass]="{'disabled': env}" name="userRoles" id="userRoles"[(ngModel)]="userData.roles" readonly />
                    </div>
                    <div class="custom-input">
                        <label>Departamento</label>
                        <input [ngClass]="{'disabled': env}" name="userArea" id="userArea" [(ngModel)]="userData.area" readonly />
                    </div>
                </div>
            </div>

            <mat-divider class="divider-section"></mat-divider>

            <label class="header-1 label-1"> Cupo y Metas</label>
            <div class="row-3">
                <div class="col-md-10">
                    <div class="custom-input">
                        <label>Cupo Limite de Asistencia</label>
                        <input [ngClass]="{'disabled': env}" placeholder="50" name="cupoLimite" id="cupoLimite" [(ngModel)]="eventData.cupoLimite" [disabled]="env"/>
                    </div>
                </div>
                <div class="col-md-11">
                    <div class="custom-input">
                        <label>Número de Asociados</label>
                        <input [ngClass]="{'disabled': env}" placeholder="10" name="asociadosInvitados" id="asociadosInvitados" [(ngModel)]="eventData.asociadosInvitados" [disabled]="env"/>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="custom-input">
                        <label>Número de No Asociados</label>
                        <input [ngClass]="{'disabled': env}" placeholder="20" name="noAsociadosInvitados" id="noAsociadosInvitados" [(ngModel)]="eventData.noAsociadosInvitados" [disabled]="env"/>
                    </div>
                </div>
            </div>

        </div>

        <div class="table-block not-visible" id="priceBlock">
            <label class="header-2 label-1"> Precios del evento</label>
            <div class="row-10">
                <div class="custom-input costo-container">
                    <label>Costo del evento</label>
                    <select class="costo-box tipo-evento" [ngClass]="{'disabled': env}" name="costo" id="costo" name="costTypeId" id="costTypeId" [(ngModel)]="eventData.costTypeId" [disabled]="env" required>
                        <option *ngFor="let x of costoList" value="{{x.id}}">{{x.name}}</option>
                    </select>                   
                </div>
                <div class="custom-input cart-box">
                    <label>Enlace de carretilla</label>
                    <input type="text" class="disabled-input" [ngClass]="{'disabled': env}" name="enlaceCarretilla" id="enlaceCarretilla" placeholder="www.sample.com" [(ngModel)]="eventData.enlaceCarretilla" [disabled]="env" />
                </div>
            </div>

            <div id="priceTable" *ngIf="listPrices.length > 0">
                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Tipo de Entrada</th>
                            <th>Fecha de inicio</th>
                            <th>Fecha de fin</th>
                            <th>Precio Asociado Q</th>
                            <th>Precio Asociado $</th>
                            <th>Equivalente a puntos</th>
                            <th>Precio Público Q</th>
                            <th>Precio Público $</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listPrices; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.tipoEntrada}}</td>
                            <td>{{item.fechaInicio | date:'EEEE, MMMM d, y' | titlecase}}</td>
                            <td>{{item.fechaFin | date:'EEEE, MMMM d, y' | titlecase}}</td>
                            <td>Q {{item.precioAsociado | number:'1.2-2':'en-US'}}</td>
                            <td>$ {{item.precioDolarAsociado | number:'1.2-2':'en-US'}}</td>
                            <td>{{item.equivalentePuntos}}</td>
                            <td>Q {{item.precioPublico | number:'1.2-2':'en-US'}}</td>
                            <td>$ {{item.precioDolarPublico | number:'1.2-2':'en-US'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="block-bottom">
            <button class="next-btn-feet" (click)="submitEvent()">Guardar</button>
            <!-- <button class="next-btn" (click)="submitEvent()" [disabled]="this.form.valid">Siguiente</button> -->
        </div>
    </div>
</form>
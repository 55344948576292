import { Injectable } from '@angular/core';
import { filter } from 'cheerio/lib/api/traversing';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    login(data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.post(`${this.url}registro/auth/signin`, data, this.options).toPromise();
    }

    // USERS ADMIN    
    getAllUsersAdmin(page: number | null, size: number | null, user_role: string | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (user_role != null) && (this.params = this.params.set('userRole', user_role));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/users/`, this.options).toPromise();
    }

    // USERS & filters // En la documentación hay mas tipos de filtros.
    // getAllUsers(page: number | null, size: number | null, filter: string | null): Promise<any> {
    getAllUsers(
        page: number | null, size: number | null, status: string | null,
        roleName: string | null, userRole: string | null, codigoCrm: string | null, personalNIT: string | null
    ): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (status != null) && (this.params = this.params.set('status', status));
        (roleName != null) && (this.params = this.params.set('roleName', roleName));
        (userRole != null) && (this.params = this.params.set('userRole', userRole));
        (codigoCrm != null) && (this.params = this.params.set('codigoCrm', codigoCrm));
        (personalNIT != null) && (this.params = this.params.set('personalNIT', personalNIT));
        // (filter != null) && (this.params = this.params.set('filter', filter));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/users/`, this.options).toPromise();
    }

    // SEARCH USERS ADMIN/NOT ADMIN
    searchAllUsers(page: number | null, size: number | null, userRole: string | null, keyword: string | null) {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (userRole != null) && (this.params = this.params.set('userRole', userRole));
        (keyword != null) && (this.params = this.params.set('keyword', keyword));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/users/search/`, this.options).toPromise();
    }

    // Get user contact by company
    getContactByCompany(page: number | null, size: number | null, company_id: number | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/users/${company_id}`, this.options).toPromise();
    }

    getOneUser(user_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.get(`${this.url}registro/users/user/${user_id}`, this.options).toPromise();
    }

    getCurrentUser(): Promise<any> {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
        return this.http.get(`${this.url}registro/users/current`, this.options).toPromise();
    }

    createUser(data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.post(`${this.url}registro/users/create-user`, data, this.options).toPromise();
    }

    createUserAdmin(data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.post(`${this.url}registro/users/create-admins`, data, this.options).toPromise();
    }

    addContact(user_id: number, data: {}): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.patch(`${this.url}registro/users/contacts/${user_id}`, data, this.options).toPromise();
    }

    updateContact(user_id: number, data: {}): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.patch(`${this.url}registro/users/contacts/${user_id}`, data, this.options).toPromise();
    }

    updateUser(user_id: number, data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.put(`${this.url}registro/users/${user_id}`, data, this.options).toPromise();
    }

    updateUserAdmin(user_id: number, data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.put(`${this.url}registro/users/admins/${user_id}`, data, this.options).toPromise();
    }

    newPassword(data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.put(`${this.url}registro/auth/new-password`, data, this.options).toPromise();
    }

    recoverPassword(user_id: number, data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.get(`${this.url}registro/auth/reset-password/${user_id}`, data).toPromise();
    }

    deletedUser(user_id: number, data: {}): Promise<any> {
        this.options = { headers: this.headers };
        return this.http.put(`${this.url}registro/users/back/delete/${user_id}`, data, this.options).toPromise();
    }

    deletedContact(account_relation_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.delete(`${this.url}registro/users/contacts/${account_relation_id}`, this.options).toPromise();
    }
}

<!DOCTYPE html>
<html class="login-body">
    <div class="header">
        <img src="..\..\assets\images\Agexport-logo.svg" alt="logo" />
    </div>
    <div class="login">
        <form class="custom-form" method="post" #form>
            <h1>Recuperación de contraseña</h1>
            <hr class="solid">
            <h2 class="h2-header">Ingresa la nueva contraseña:</h2>
            <label>Contraseña</label>
            <div class="custom-input">
                <input class="custom-input"
                    [(ngModel)]="password"
                    name="password"
                    required
                    id="password"
                    [type]="show ? 'text' : 'password'"
                    oninvalid="this.setCustomValidity('Este campo es obligatorio')"
                    oninput="setCustomValidity('')"
                />
                <i class="pass-eye" id="eye" (click)="passwordVisibility()"></i>
            </div>
            <label>Confirmar contraseña</label>
            <div class="custom-input">
                <input class="custom-input"
                    [(ngModel)]="confirmpassword"
                    name="password"
                    required
                    [type]="show ? 'text' : 'password'"
                    id="confirmpassword"
                    oninvalid="this.setCustomValidity('Este campo es obligatorio')"
                    oninput="setCustomValidity('')"
                />
                <i class="pass-eye" id="eye" (click)="passwordVisibility()"></i>
            </div>
            <button type="submit" (click)="form.checkValidity()? changePassword() : null">Confirmar contraseña</button>
        </form>
    </div>
  </html>
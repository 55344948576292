import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ReportService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    //**************** Constancia de Asociados ****************//
    getAssociateCertificate(page: number | null, size: number | null, startDate: string | null, endDate: string | null, company_id: number | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (startDate != null) && (this.params = this.params.set('startDate', startDate));
        (endDate != null) && (this.params = this.params.set('endDate', endDate));
        (company_id != null) && (this.params = this.params.set('companyId', company_id));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/users/report/all-certificates`, this.options).toPromise();
    }

    //**************** Certificado de puntos ****************//
    // Reporte Certificado de Puntos
    getPointsCertificate(page: number | null, size: number | null, startDate: string | null, endDate: string | null, company_id: number | null, status: string | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (startDate != null) && (this.params = this.params.set('startDate', startDate));
        (endDate != null) && (this.params = this.params.set('endDate', endDate));
        (company_id != null) && (this.params = this.params.set('companyId', company_id));
        (status != null) && (this.params = this.params.set('status', status));
        this.options = { headers: this.headers, params: this.params };

        return this.http.get(`${this.url}puntos/points/report-certificates/`, this.options).toPromise();
    }


    // Reporte Certificado de Puntos Vencidos
    getPointsCertificateExpired(page: number | null, size: number | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}puntos/points/report-certificates-not-delivered/`, this.options).toPromise();
    }

    pointDownloadCertificate(certificate_id: number | null): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}puntos/points/download-certificate/${certificate_id}`, this.options).toPromise();
    }

    pointResendCertificate(certificate_id: number | null): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}puntos/points/resend-certificate/${certificate_id}`, this.options).toPromise();
    }

    // Datos puntos por empresa.
    getPointsByCompany(company_id: number | null): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}puntos/points/by-company/${company_id}`, this.options).toPromise();
    }

    // // // PENDIENTE // // //     ->   // Reporte puntos de usuarios por empresa.
    getPointsUserByCompany(company_id: number | null): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}puntos/points/get-points-generated/${company_id}`, this.options).toPromise();
        // return this.http.get(`${this.url}***pendiente/${company_id}`, this.options).toPromise();
    }

    //**************** Encuestas eventos ****************//
    getEventSurveys(event_id: number, page: number | null, size: number | null, startDate: string | null, endDate: string | null, title: string | null, eventId: number | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (startDate != null) && (this.params = this.params.set('startDate', startDate));
        (endDate != null) && (this.params = this.params.set('endDate', endDate));
        (event_id != null) && (this.params = this.params.set('eventId', event_id));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/surveys/report/${event_id}`, this.options).toPromise();
    }

    //**************** Eventos reporte ****************//
    /*getEventReport(page: number | null, size: number | null, startDate: string | null, endDate: string | null, status: string | null, name: string | null, event_id: number | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (startDate != null) && (this.params = this.params.set('startDate', startDate));
        (endDate != null) && (this.params = this.params.set('endDate', endDate));
        (status != null) && (this.params = this.params.set('status', status));
        (name != null) && (this.params = this.params.set('name', name));
        (event_id != null) && (this.params = this.params.set('eventId', event_id));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/events/report/data`, this.options).toPromise();
    }
*/
    getEventReport(
      page: number | null,
      size: number | null,
      startDate: string | null,
      endDate: string | null,
      status: string | null,
      name: string | null,
      event_id: number | null
    ): Promise<any> {
      this.params = new HttpParams();

      (page != null) && (this.params = this.params.set('page', page.toString()));
      (size != null) && (this.params = this.params.set('size', size.toString()));
      (startDate != null) && (this.params = this.params.set('startDate', startDate));
      (endDate != null) && (this.params = this.params.set('endDate', endDate));
      (status != null) && (this.params = this.params.set('status', status));
      (name != null) && (this.params = this.params.set('name', name));
      (event_id != null && event_id !== 0) && (this.params = this.params.set('eventId', event_id.toString()));

      this.options = { headers: this.headers, params: this.params };
      return this.http.get(`${this.url}eventos/events/report/data`, this.options).toPromise();
    }

}

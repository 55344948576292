<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet" />

<app-loading *ngIf="loading"></app-loading>
<div class="agexInsidePage">
  <div class="container">
    <div class="row mb-4">
      <div class="col col-10">
        <label class="label-1">Promociones</label>
      </div>
    </div>


    <div class="row searchContainer">
      <div class="card mb-4">
        <div class="card-body">
          <div class="row align-items-end" style="height: 100%;">
            <div class="col-12 col-md-4 col-lg-5  mb-3">
              <label>Seleccione Comercio</label>
              <ng-multiselect-dropdown class="custom-dropdown" [placeholder]="'Seleccione una empresa...'"
                [settings]="dropdownSettings" [data]="empresas" [(ngModel)]="selectedCompany"
                (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)" (onFilterChange)="onFilter($event)"
                (onDropDownClose)="onDropDownClose()" [disabled]="!isAdmin">
              </ng-multiselect-dropdown>
            </div>
            <div class="col-12 col-md-4 col-lg-4 mb-3">
              <label class="form-label ">Estatus</label>
              <select class="form-select custom-select-height" name="status" id="status" [(ngModel)]="statusPromo"
                [disabled]="!isAdmin" required>
                <option value="all" *ngIf="isAdmin">Todos</option>
                <option value="active" *ngIf="isAdmin">Activo</option>
                <option value="inactive" *ngIf="isAdmin">Inactivo</option>
                <option value="finished" *ngIf="isAdmin">Finalizado</option>
                <option value="draft">Borrador</option>
              </select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3 d-flex justify-content-center align-items-end">
              <div>
                <button class="filter-btn-clean mb-1" (click)="limpiar()">Limpiar</button>
                <button class="filter-btn mb-1" (click)="loadPromotionsByCompany()">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="column">
        <!-- <button class="add-btn" (click)="gotoCreatePromoForm()" [disabled]="selectedCompany === undefined" > -->
        <button class="add-btn" (click)="goToPage('/admin/edit-promocion', { company_id: selectedCompany[0].id })"
          [disabled]="selectedCompany === undefined">
          + Agregar beneficio
        </button>
      </div>

      <div *ngIf="promotionsList.length > 0 ">
        <div class="main">
          <div class="default-view">
            <div class="table-default">
              <table class="content-table" border="1">
                <thead>
                  <tr>
                    <th *ngFor="let each of categoriesHeading">{{ each }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of promotionsList; let i = index">
                    <ng-container *ngFor="let x of item | keyvalue : originalOrder">
                      <td *ngIf="x.key !== 'estatus' && x.key !== 'id' && x.key !== 'company_id'"> <!-- Excluir company_id -->
                        {{ x.value == "ontime" ? "A TIEMPO " : x.value == "outoftime" ? "FUERA DE TIEMPO" : x.value }}
                      </td>
                      <td class="registro-group" *ngIf="x.key === 'estatus'">

                        <select name="status" id="status" class="agex-dropdown {{x.value}}" #selects [value]="x.value"
                          (change)="updateStatusEvent($event, item.id)" [disabled]="x.value == 'soldout'">
                          <option value="finished" *ngIf="userRole === 'ROLE_SUPERADMIN' || userRole === 'ROLE_ADMIN'">
                            Finalizado</option>
                          <option value="active" *ngIf="userRole === 'ROLE_SUPERADMIN' || userRole === 'ROLE_ADMIN'">
                            Activo</option>
                          <option value="inactive" *ngIf="userRole === 'ROLE_SUPERADMIN' || userRole === 'ROLE_ADMIN'">
                            Inactivo</option>
                          <option value="soldout" disabled
                            *ngIf="userRole === 'ROLE_SUPERADMIN' || userRole === 'ROLE_ADMIN'">Agotado</option>
                          <option value="draft">Borrador</option>
                        </select>

                      </td>
                    </ng-container>
                    <td (mouseleave)="hideActions()">
                      <button name="actions" id="actions" class="actions" (click)="showActions(i)"
                        (keyup)="showActions(i)"></button>
                      <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2"
                        id="item{{i}}" (mouseleave)="hideActions()">
                        <!-- <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="goToEditPromo(item, item.id)"> -->
                        <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left"
                          (click)="goToPage('/admin/edit-promocion', { company_id: item.company_id, promotion_id: item.id})">
                          Editar
                        </button>
                        <button name="delete" id="delete" class="actions-btn w-100 text-left"
                          (click)="eliminarPromo(item.id)">
                          Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="table-pagination">
                <i class="left-btn" id="back" (click)="onLeft()"></i>
                <p class="pagination-text">
                  Página {{ pageNumber + 1 }} de {{ totalPages }}
                </p>
                <i class="right-btn" id="back" (click)="onRight()"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { KeyValue } from '@angular/common';
// import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { listas } from 'src/app/Models/back-service.model';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/Services/user.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Promotion } from 'src/app/Models/promotions-models';
import { UtilsService } from 'src/app/Services/utils.service';
import { EventService } from 'src/app/Services/event.service';
import { ReportService } from 'src/app/Services/report.service';
import { CompanyService } from 'src/app/Services/company.service';
import { PromotionsService } from 'src/app/Services/promotions.service';

@Component({
  selector: 'app-reporteria',
  templateUrl: './reporteria.component.html',
  styleUrls: ['./reporteria.component.css'],
})
export class ReporteriaComponent implements OnInit, OnDestroy {
  public count: number = 0;
  public pageSize: number = 15;
  public pageNumber: number = 0;
  public totalPages: number = 0;
  public loading: boolean = false;

  public statusSelected: string = 'active';
  public statusList: listas[] = [
    // { id: 'active', name: 'Activo' }
    { id: 'N/A', name: 'N/A' }
  ];

  public statisticsSelected: string | undefined;
  public statisticsList: Array<string> = []
  public envirement: boolean = environment.production;

  public list: {}[] = [];
  public listDownload: {}[] = [];
  public headerFilter: string = '';
  public selectedFilter: any = null;
  public categoriesHeading: string[] = [];
  public filter: { id: number | null, name: string | null } | undefined = undefined;
  public dropdownFilterItems: Array<{ id: number | null, name: string }> = [];

  // endDateString: string = '';
  // startDateString: string = '';
  // end_date: NgbDateStruct | undefined;
  // start_date: NgbDateStruct | undefined;
  public date_end: string | null = null;
  public date_start: string | null = null;
  public css_button: string = 'btn btn-primary px-4 mx-auto mt-4 w-100 w-lg-auto btn-get-report';


  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private eventService: EventService,
    private reportService: ReportService,
    private companyService: CompanyService,
    private promotionsService: PromotionsService,
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      this.loading = true;
      this.statusSelected = this.statusList[0].id;
      this.statisticsSelected = this.statisticsList[0];

      const role: string | undefined = sessionStorage.getItem('Role')?.toString();
      const permissions = await this.userService.getCurrentUser();
      // this.role = permissions?.result?.role || null;
      // this.companyId = permissions?.result?.companyId || null;
      this.statisticsList = this.val_role(role!, permissions?.result?.permissions, permissions?.result?.companyId);

      const countCompany = await this.companyService.getListCompany(0, 0);
      if (countCompany.success) {
        const companies = await this.companyService.getListCompany(0, countCompany?.count || 15000);
        const companiesArray = companies?.result?.map((e: any) => {
          return { id: e.id, name: e.nombreComercial };
        });
        localStorage.setItem('companies', JSON.stringify(companiesArray.slice(0, 1000)));
        localStorage.setItem('companiesAll', JSON.stringify(companiesArray));
      }

      const countEvent = await this.eventService.getEvents(null, 0, null, null)
      if (countEvent.success) {
        const events = await this.eventService.getEvents(null, countEvent?.count || 15000, null, null);
        const eventsArray = events.result.map((e: any) => {
          return { id: e.id, name: e.title };
        });
        localStorage.setItem('events', JSON.stringify(eventsArray));
        localStorage.setItem('eventsAll', JSON.stringify(eventsArray));
      }

      this.loading = false;
    } catch (error: any) {
      console.log(error);
      this.utilsService.goToPage('/admin/inicio');
      this.utilsService.dialog('ERROR', error?.message?.title || ' Error', error?.message?.description || 'Error al cargar el modulo, intente de nuevo.');
    }
  }
  // public role: null | string = null;
  // public companyId: null | number = null;

  ngOnDestroy() {
    localStorage.removeItem('events');
    localStorage.removeItem('eventsAll');
    localStorage.removeItem('companies');
    localStorage.removeItem('companiesAll');
  }

  val_role(role: string, permissions: string[], company_id: null | number): any {
    const permission = permissions?.includes('subir_promociones') || permissions?.includes('descargar_reporte');

    if (role == 'ROLE_SUPERADMIN' || role == 'ROLE_ADMIN') {
      return [
        'Seleccione',
        'Promoción',
        'Promoción en especifico',
        'Eventos',
        'Constancia de asociado',
        'Certificado de puntos',
        'Certificados de puntos cierre',
        'Encuestas eventos'
      ];
      // } else if ('ROLE_ALEADO_COMERCIAL') {
      //   if (company_id == null) {
      //     throw ({ message: { title: 'Error', description: 'El usuario con rol aleado comercial no tiene una empresa asignada.' } });
      //   }

      //   return [
      //     'Seleccione',
      //     'Promoción',
      //     'Promoción en especifico'
      //   ];
    } else if ('ROLE_ORGANIZADOR_EVENTOS' || permission == true) {
      return [
        'Seleccione',
        'Eventos',
        'Encuestas eventos'
      ];
    } else {
      return [];
    }
  }

  validation_date(): boolean {
    if (this.date_start && this.date_end) {
      const star = new Date(this.date_start);
      const end = new Date(this.date_end);

      if (star.getTime() > end.getTime()) {
        return true;
      } else {
        return false;
      }
    } {
      return false;
    }
  }

  areDatesValid(): boolean {
    return this.date_start !== null && this.date_end !== null;
  }

  onDateChange() {
    // Ensure date_start and date_end are updated correctly
    if (!this.date_start) {
      this.date_start = null;
    }
    if (!this.date_end) {
      this.date_end = null;
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  };

  // getDates() {
  //   this.startDateString = this.start_date
  //     ? `${this.start_date.year}-${this.start_date.month
  //       .toString()
  //       .padStart(2, '0')}-${this.start_date.day.toString().padStart(2, '0')}`
  //     : '';
  //   this.endDateString = this.end_date
  //     ? `${this.end_date.year}-${this.end_date.month
  //       .toString()
  //       .padStart(2, '0')}-${this.end_date.day.toString().padStart(2, '0')}`
  //     : '';
  // }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.searchData(false);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.searchData(false);
    }
  }

  async downloadToExcel() {
    await this.searchData(true);
    const reportName = `Reporte_${this.statisticsSelected}_${this.utilsService.getDate('dd-MM-yyy')}`;
    this.utilsService.downloadToExcel(this.listDownload, 'Reportes', reportName, 'xlsx');
  }

  async statistics(event: any) {
    let response: any;
    const type = event.target.value;
    this.selectedFilterItem = [];
    this.dropdownFilterItems = [{ id: null, name: '' }];
    this.filter = { id: null, name: null }; // = undefined
    this.categoriesHeading = [];
    this.selectedFilter = null;
    this.pageNumber = 0;
    this.count = 0;

    if (type === 'Promoción en especifico') {
      this.loading = true;
      this.list = [];
      this.categoriesHeading = [];
      this.statusSelected = 'used';
      this.statusList = [
        { id: 'new', name: 'Canjeados' },
        { id: 'used', name: 'Escaneados' },
      ];

      // if (this.role == 'ROLE_ALIADO_COMERCIAL') {
      //   response = await this.promotionsService.getAllPromotionsCommercialRole(null, null, 0, 10000, null, null, this.companyId);
      //   response.result = response.result.rows;
      // } else {
      //   response = await this.promotionsService.getAllPromotions(null, null, 0, 10000, null, null );
      // }
      // response = await this.promotionsService.getAllPromotions(this.statusSelected, null, 0, 1000, null, null);
      response = await this.promotionsService.getAllPromotions(null, null, 0, 1000, null, null);
      this.setDropdownFilter(response, 'promotion');
      this.loading = false;
    }
    else if (type === 'Constancia de asociado' || type === 'Certificado de puntos') {
      // this.statusSelected = 'active';
      this.statusList = [
        { id: 'active', name: 'Activo' },
        { id: 'draft', name: 'Borrador' },
        { id: 'finished', name: 'Finalizado' }
      ];
      response = {}; // { result: JSON.parse(localStorage.getItem('companiesAll')!) };
      this.setDropdownFilter(response, 'associated&points');
    }
    else if (type === 'Encuestas eventos') {
      this.statusSelected = 'active';
      response = {}; // { result: JSON.parse(localStorage.getItem('eventsAll')!) };
      this.setDropdownFilter(response, 'survey');
    }
    else if (type === 'Eventos') {
      response = {}; //  { result: JSON.parse(localStorage.getItem('eventsAll')!) };
      this.setDropdownFilter(response, 'event');
      this.statusList = [
        { id: 'active', name: 'Activo' },
        { id: 'draft', name: 'Borrador' },
        { id: 'finished', name: 'Finalizado' }
      ];
      this.statusSelected = 'active';
    }
    else if (type === 'Promoción') {
      this.statusSelected = 'active';
      this.statusList = [
        { id: 'active', name: 'Activo' },
        { id: 'draft', name: 'Borrador' },
        { id: 'inactive', name: 'Inactivo' },
        { id: 'finished', name: 'Finalizado' }
      ];
    }
    else if (type === 'Certificados de puntos cierre') {
      this.date_end = null;
      this.date_start = null;
      this.statusList = [
        { id: 'N/A', name: 'N/A' }
      ];
    }
  }

  setDropdownFilter(data: any, type: string) {
    this.selectedFilter = null;
    this.dropdownFilterItems = [];
    this.dropdownFilterItemsAux = [];
    this.dropdownFilterItemsAll = [];
    this.filter = { id: null, name: null };

    switch (type) {
      case 'event':
        this.dropdownFilterItems = JSON.parse(localStorage.getItem('events')!)
        this.dropdownFilterItemsAux = JSON.parse(localStorage.getItem('events')!)
        this.dropdownFilterItemsAll = JSON.parse(localStorage.getItem('eventsAll')!)
        // {
        //   data.result.map((e: any) => { // (e: EventItem)
        //     this.dropdownFilterItems.push({ id: e.id, name: e.name }); //name: e.title
        //   });
        // }
        break;
      case 'promotion':
        {
          data.result.map((e: Promotion) => {
            this.dropdownFilterItems.push({ id: e.id, name: e.name });
          });
        }
        this.dropdownFilterItemsAux = this.dropdownFilterItems;
        this.dropdownFilterItemsAll = this.dropdownFilterItems;
        break;
      case 'associated&points':
        this.dropdownFilterItems = JSON.parse(localStorage.getItem('companies')!)
        this.dropdownFilterItemsAux = JSON.parse(localStorage.getItem('companies')!)
        this.dropdownFilterItemsAll = JSON.parse(localStorage.getItem('companiesAll')!)
        // this.dropdownFilterItemsAll = JSON.parse(localStorage.getItem('companies')!)
        // {
        //   data.result.map((e: any) => {
        //     this.dropdownFilterItems.push({ id: e.id, name: e.nombreComercial });
        //   });
        // }
        break;
      case 'survey':
        this.dropdownFilterItems = JSON.parse(localStorage.getItem('events')!)
        this.dropdownFilterItemsAux = JSON.parse(localStorage.getItem('events')!)
        this.dropdownFilterItemsAll = JSON.parse(localStorage.getItem('eventsAll')!)
        // {
        //   data.result.map((e: any) => { // (e: SurveyReportModel)
        //     this.dropdownFilterItems.push({ id: e.id, name: e.name }); //name: e.eventName!
        //   });
        // }
        break;
    }
    this.dropdownFilterItemsAux = this.dropdownFilterItems;
  }

  async searchData(download: boolean) {
    // this.getDates();
    switch (this.statisticsSelected) {
      case 'Promoción':
        {
          await this.getPromotions(download);
          this.headerFilter = 'Nombre de promoción';
        }
        break;
      case 'Eventos':
        {
          await this.getEventReport(download);
          this.headerFilter = 'Nombre del evento';
        }
        break;
      case 'Promoción en especifico':
        {
          await this.getPromotionsSpecific(download);
          this.headerFilter = 'Nombre de promoción';
        }
        break;
      case 'Constancia de asociado':
        {
          await this.getAssociatedConstance(download);
          this.headerFilter = 'Nombre de la empresa';
        }
        break;
      case 'Certificado de puntos':
        {
          await this.getPointsCertificate(download);
          this.headerFilter = 'Nombre de la empresa';
        }
        break;
      case 'Certificados de puntos cierre':
        {
          await this.getPointsCertificateExpired(download);
          this.headerFilter = 'Nombre de la empresa';
        }
        break;
      case 'Encuestas eventos':
        {
          await this.getSurveyReport(download);
          this.headerFilter = 'Nombre de evento';
        }
        break;
      default:
        this.list = [];
        this.listDownload = [];
        break;
    }
  }

  async getPromotions(download: boolean) {
    this.loading = true;
    try {
      // const response = await this.promotionsService.getAllPromotions('status', null, 0, 20, '2023-01-01', '2023-07-31' , null)

      // let response: any;
      // if (this.role == 'ROLE_ALIADO_COMERCIAL') {
      //   response = await this.promotionsService.getAllPromotionsCommercialRole(
      //     this.statusSelected,
      //     null,
      //     download ? 0 : this.pageNumber,
      //     download ? this.count : this.pageSize,
      //     this.date_start,
      //     this.date_end,
      //     this.companyId
      //   );
      //   response.result = response?.result?.rows;
      // } else {
      const response = await this.promotionsService.getAllPromotions(
        this.statusSelected,
        null, //this.filter?.name,
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize,
        this.date_start, // this.startDateString,
        this.date_end, // this.endDateString
      );
      // }

      if (response?.success === true) {
        if (response.result.length > 0) {
          this.setDropdownFilter(response, 'promotion');
          this.listDownload = response.result.map((e: Promotion) => {
            return {
              'Nombre de promoción': e?.name,
              Código: e?.id,
              'Canjes de promoción': e?.redeemCount,
              Categoría: e?.categoryName,
            };
          });
          if (!download) {
            this.count = response.count;
            this.list = this.listDownload;
            this.categoriesHeading = Object.keys(this.list[0]);
            this.totalPages = Math.ceil(this.count / this.pageSize);
          }
        } else {
          this.list = [];
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', 'Advertencia', 'Sin datos de promoción');
        }
      } else {
        this.list = [];
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      this.utilsService.dialog('ERROR', error.error?.message?.title, error.error?.message?.description);
    }
    this.loading = false;
  }

  async getPromotionsSpecific(download: boolean) {
    this.loading = true;
    try {
      // const response = await this.promotionsService.getPromotionsByUser( 41, '2023-03-01', '2023-07-31', 0, 20, 'new');
      const response = await this.promotionsService.getPromotionsByUser(
        Number(this.filter!.id),
        this.date_start || null, // this.utilsService.formatDate(this.start_date) || null,
        this.date_end || null, // this.utilsService.formatDate(this.end_date) || null,
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize,
        this.statusSelected // null, // 'used' // 'new'
      );

      if (response?.success === true) {
        if (response?.count > 0) {
          this.listDownload = response.result.map((e: any) => {
            return {
              'Nombre de usuario': e?.fullName,
              'Nombre de promoción': this.filter?.name,
              Código: e?.codigoCRM,
              'Canjes de promoción': e.countRedeem,
              Correo: e?.email,
              Teléfono: e?.phone,
            };
          });
          if (!download) {
            this.list = this.listDownload;
            this.count = response.count;
            this.categoriesHeading = Object.keys(this.list[0]);
            this.totalPages = Math.ceil(this.count / this.pageSize);
          }
        }
        else {
          this.list = [];
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', 'Advertencia', 'Sin datos de promoción');
        }
      } else {
        console.log('error 1', response);
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      console.log('error 2', error)
      this.utilsService.dialog('ERROR', error.error?.message?.title, error.error?.message?.description); // 'No se encontraron promociones '
    }
    this.loading = false;
  }

  async getAssociatedConstance(download: boolean) {
    try {
      this.loading = true;
      // const response = await this.reportService.getAssociateCertificate(0, 5, '2023-01-01', '2023-07-20', 10);

      const response = await this.reportService.getAssociateCertificate(
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize,
        this.date_start, // this.utilsService.formatDate(this.start_date),
        this.date_end, // this.utilsService.formatDate(this.end_date),
        this.filter!.id
      );

      if (response?.success === true) {
        console.log(response);

        if (response?.count > 0) {
          this.listDownload = response.result.map((e: any) => { // e: AssociatedConstanceItemReport
            return {
              Sector: e?.sector,
              // Comite: e?.comite,
              "Comisión": e?.comision,
              'Nombre de la empresa': e?.company,
              'Nombre o contacto principal asignado': e?.user, //e?.mainContact,
              Nit: e?.nit,
              'Número de certificado': e?.number,
              'Fecha de emisión': e?.cretedAt
            };
          });
          if (!download) {
            this.list = this.listDownload;
            this.count = response?.count;
            this.categoriesHeading = Object.keys(this.list[0]) || [];
            this.totalPages = Math.ceil(this.count / this.pageSize);
          }
        }
        else {
          this.list = [];
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', response.message.title || 'Advertencia', 'Sin datos de constancias.');
        }
      } else {
        console.log('error 1', response);
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      console.log('error 2', error)
      this.utilsService.dialog('ERROR', error.error?.message?.title, error.error?.message?.description);
    }
    this.loading = false;
  }

  async getPointsCertificate(download: boolean) {
    try {
      this.loading = true;
      // const response = await this.reportService.getPointsCertificate(0, 10, '2023-01-01', '2023-07-20', 1, 'active');
      const response = await this.reportService.getPointsCertificate(
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize,
        this.date_start, // this.utilsService.formatDate(this.start_date),
        this.date_end, // this.utilsService.formatDate(this.end_date),
        this.filter!.id!,
        'active'
      );

      if (response?.success === true) {
        if (response?.count > 0) {
          this.listDownload = response?.result?.map((e: any) => {
            let data: any = {};
            data['Sector'] = e?.sector || undefined;
            data['Comisión'] = e?.comision || undefined;
            data['Nombre de la empresa'] = e?.company || undefined;
            data['Nombre del contacto que recibió certificado'] = e?.userName || undefined;
            data['NIT'] = e?.nit || undefined;
            data['Número de certificado'] = e?.code || undefined;
            data['Puntos acumulados'] = e?.points || undefined;
            data['Total en quetzales'] = e?.totalQtz || undefined;
            data['Fecha de emisión'] = e?.cretedAt || undefined;

            if (!download) {
              data['id'] = e?.id || undefined;
              data['Certificado de puntos'] = 'Descargar';
            }

            return data;
          });
          if (!download) {
            this.list = this.listDownload;
            this.count = response?.count;
            this.categoriesHeading = Object.keys(this.list[0]);
            this.totalPages = Math.ceil(this.count / this.pageSize);
            console.log(this.categoriesHeading);

            this.categoriesHeading.splice(9, 1)
          }
        }
        else {
          this.list = [];
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', 'Advertencia', 'Sin datos de certificados.');
        }
      } else {
        console.log('error 1', response);
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      console.log('error 2', error)
      this.utilsService.dialog('ERROR', error.error?.message?.title, error.error?.message?.description);
    }
    this.loading = false;
  }

  async getPointsCertificateExpired(download: boolean) {
    try {
      this.loading = true;
      const response = await this.reportService.getPointsCertificateExpired(
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize
      );

      if (response?.success === true) {
        if (response?.count > 0) {
          // console.log(response?.result);

          this.listDownload = response?.result?.map((e: any) => {
            let data: any = {};
            data['Sector'] = e?.sector || undefined;
            data['Comisión'] = e?.comision || undefined;
            data['Nombre de la empresa'] = e?.company || undefined;
            data['Nombre del contacto que recibió certificado'] = e?.userName || undefined;
            data['NIT'] = e?.nit || undefined;
            data['Número de certificado'] = e?.code || undefined;
            data['Puntos acumulados'] = e?.points || undefined;
            data['Total en quetzales'] = e?.totalQtz || undefined;
            data['Fecha de emisión'] = e?.cretedAt || undefined;
            data['Estatus'] = !e?.delivered ? 'No enviado' : 'Enviado';

            if (!download) {
              data['id'] = e?.id || undefined;
              data['Certificado de puntos'] = 'Descargar';
              data['Envío de certificado'] = 'Enviar por correo';
            }

            return data;
          });
          if (!download) {
            this.list = this.listDownload;
            this.count = response?.count;
            this.categoriesHeading = Object.keys(this.list[0]);
            this.totalPages = Math.ceil(this.count / this.pageSize);
            this.categoriesHeading.splice(10, 1)
          }
        }
        else {
          this.list = [];
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', 'Advertencia', 'Sin datos de certificados.');
        }
      } else {
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      this.utilsService.dialog('ERROR', error.error?.message?.title, error.error?.message?.description);
    }
    this.loading = false;
  }

  async pointsCertificateAction(type: string, data: any) {
    this.loading = true;

    try {
      let response;
      switch (type) {
        case 'Envío de certificado':
          response = await this.reportService.pointResendCertificate(data.id);
          break;
        case 'Certificado de puntos':
          response = await this.reportService.pointDownloadCertificate(data.id);
          break;
      }

      if (response.result && response.success === true) {
        switch (type) {
          case 'Envío de certificado':
            this.utilsService.dialog(
              'SUCCESS',
              response?.message?.title || 'Éxito',
              response?.message?.description || 'Se a enviado el certificado al correo.'
            );
            break;
          case 'Certificado de puntos':
            const fileName: string = `Certificado-${data['Número de certificado']} - ${data['Nombre del contacto principal o asignado']}.pdf`;
            FileSaver.saveAs(response.result, fileName);
            response = await this.reportService.pointDownloadCertificate(data.id);
            break;
        }
      } else {
        this.utilsService.dialog(
          'ERROR',
          response?.error?.message?.title || 'Error',
          response?.error?.message?.description || 'Hubo un error, intenta de nuevo o contacta al administrador.'
        );
      }
    } catch (e: any) {
      this.utilsService.dialog(
        'ERROR',
        e?.error?.message?.title || 'Error',
        e?.error?.message?.description || 'Hubo un error, intenta de nuevo o contacta al administrador.'
      );
    }
    this.loading = false;
  }

  async getSurveyReport(download: boolean) {
    try {
      this.loading = true;
      // const response = await this.reportService.getEventSurveys(661, 0, 20, '2022-01-01', '2023-12-31', null, 661); // 660 661 665
      //*
      const response = await this.reportService.getEventSurveys(
        Number(this.filter!.id!),
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize,
        this.date_start, // this.utilsService.formatDate(this.start_date),
        this.date_end, // this.utilsService.formatDate(this.end_date),
        null, // this.filter!.name!,
        Number(this.filter!.id!)
      );
      //*/
      console.log(response);

      if (response?.success === true) {
        if (response?.count > 0) {
          // this.categoriesHeading = ['Nombre de evento', 'Fecha de evento', 'Sector', 'Comisión', 'Nombre del usuario'];
          // this.categoriesHeading.push(response?.result[0]?.answers[0]?.surveyQuestion?.text || 'Pregunta 1')

          this.listDownload = response.result.map((x: any) => {
            /*if(this.categoriesHeading.length === 5 && this.categoriesHeading.length <= 5){
              // x?.answers[0] ? (this.preguntas.push(x?.answers[0]?.surveyQuestion?.text), this.categoriesHeading.push('Pregunta 1')) : '';
              x?.answers[0] ? this.preguntas.push(x?.answers[0]?.surveyQuestion?.text) : '';
              x?.answers[0] ? this.categoriesHeading.push(x?.answers[0]?.surveyQuestion?.text) : '';
            }*/

            //*
            const data: any = {
              'Nombre de evento': x?.evenName,
              'Fecha de evento': x?.eventDate,
              'Sector': x?.sector,
              'Comisión': x?.comision,
              'Nombre del usuario': x?.userName,
              'Pregunta 1': x?.answers[0] ? x?.answers[0]?.answer : '',
              'Pregunta 2': x?.answers[1] ? x?.answers[1]?.answer : '',
              'Pregunta 3': x?.answers[2] ? x?.answers[2]?.answer : ''
            };
            (x?.answers[3]?.answer) && (data['Pregunta 4'] = x?.answers[3]?.answer || '');
            (x?.answers[4]?.answer) && (data['Pregunta 5'] = x?.answers[4]?.answer || '');
            // data[x?.answers[0]?.surveyQuestion?.text  || 'Pregunta 1'] = x?.answers[0]?.answer || '';
            return data;
            //*/

            /*
            return {
              'Nombre de evento': x?.evenName,
              'Fecha de evento': x?.eventDate,
              'Sector': x?.sector,
              'Comisión': x?.comision,
              'Nombre del usuario': x?.userName,
              'Pregunta 1': x?.answers[0] ? x?.answers[0]?.surveyQuestion?.text + ' R// ' + x?.answers[0]?.answer : '',
            }
            //*/
          })

          // console.log(this.listDownload);
          if (!download) {
            this.count = response.count;
            this.list = this.listDownload;
            this.categoriesHeading = Object.keys(this.list[0]);
            this.totalPages = Math.ceil(this.count / this.pageSize);
          }
        }
        else {
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', 'Advertencia', 'Sin datos de encuestas.');
        }
      } else {
        console.log('error 1', response);
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      console.log('error 2', error)
      this.utilsService.dialog('ERROR', 'Error', error.error?.message?.description || 'Vuelve a intentar o contacta a la administración');
    }
    this.loading = false;
  }

  async getEventReport(download: boolean) {
    try {
      this.loading = true;
      // const response = await this.reportService.getEventReport(0, 20, '2023-01-01', '2023-07-20', 'active', null, event_id); // 'JUNTA DIRECTIVA SECTOR DE ACUICULTURA Y PESCA MARZO 2023'

      const response = await this.reportService.getEventReport(
        download ? 0 : this.pageNumber,
        download ? this.count : this.pageSize,
        this.date_start || null,
        this.date_end || null,
        this.statusSelected,
        null, // || this.filter?.name,
        Number(this.filter!.id!)
      );
      console.log(response);

      if (response?.success === true) {
        if (response?.count > 0) {
          this.listDownload = await response.result.map((x: any) => {
            let sectores = '';
            if (x.sectores && x.sectores.length > 0) {
              x.sectores.forEach((i: any) => {
                (i?.name) && (sectores += i?.name + '; ');
              });
            }

            let comision = '';
            if (x.comisiones && x.comisiones.length > 0) {
              x.comisiones.forEach((i: any) => {
                (i?.name) && (comision += i?.name + '; ');
              });
            }
            // the new CEO 2023
            return {
              Sector: sectores,
              'Comisión': comision,
              'Área organizacional': x?.area,
              Organizador: x?.creator,
              'Nombre del evento': x?.title,
              Costo: x?.costType.name,
              'Fecha de creación del evento': this.utilsService.date_pipe(x?.createdAt, 'dd-MM-yyy'),
              'Fecha de inicio': this.utilsService.date_pipe(x?.fechaInicio, 'dd-MM-yyy'),
              'Fecha de fin': this.utilsService.date_pipe(x?.fechaFinal, 'dd-MM-yyy'),
              'Menos de 15 días de publicado': x?.publication === 'outoftime' ? 'FUERA DE TIEMPO' : 'A TIEMPO',
              'Meta de asociados esperados': x?.metaAsociados,
              'Meta de asociados no esperados': x?.metaNoAsociados,
              'Meta total esperado': (Number(x?.metaAsociados) + Number(x?.metaNoAsociados)),
              'Real de asistentes Asociados': x?.partnersAssistance,
              'Real de asistentes No Asociados': x?.noPartnersAssistance,
              'Real total registrado': x?.registered,
              'Eficiencia': Number(x?.totalAssistancePercent) + '%',
              'Empresas Asociadas': Number(x?.totalSocios),
              'Empresas No Asociadas': Number(x?.totalEmpresas),
              'Total empresas': (Number(x?.totalSocios) + Number(x?.totalEmpresas)) // x?.totalEmpresas
            }
          })

          if (!download) {
            this.list = this.listDownload;
            this.count = response?.count;
            this.categoriesHeading = Object.keys(this.list[0]);
            this.totalPages = Math.ceil(this.count / this.pageSize);
          }
        }
        else {
          this.categoriesHeading = [];
          this.utilsService.dialog('ERROR', 'Advertencia', 'Sin datos de eventos.');
        }
      } else {
        console.log('error 1', response);
        this.utilsService.dialog('ERROR', response?.message?.title || '', response?.message?.description || '');
      }
    } catch (error: any) {
      console.log('error 2', error)
      this.utilsService.dialog('ERROR', 'Error', error.error?.message?.description || 'Vuelve a intentar o contacta a la administración');
    }
    this.loading = false;
  }

  // BUSQUEDA DE ACTIVIDADES
  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    itemsShowLimit: 1,
    singleSelection: true,
    allowSearchFilter: true,
    textField: 'name',
    allowRemoteDataSearch: true,
    closeDropDownOnSelection: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Busqueda sin resultados.'
  };

  public selectedFilterItem: any;
  public dropdownFilterItemsAux: any[] | any = [];
  public dropdownFilterItemsAll: any[] | any = [];

  onItemSelect(item: any) {
    this.filter = item;

    if (this.statisticsSelected === 'Promoción en especifico' || this.statisticsSelected === 'Eventos') {
      this.count = 0;
      this.pageNumber = 0;
      this.categoriesHeading = [];
    }
  }

  onDeSelect() {
    this.selectedFilterItem = [];
    this.filter = { id: null, name: null };
  }

  onDropDownClose() {
    this.dropdownFilterItems = this.dropdownFilterItemsAux;
  }

  onFilterChange($event: any) {
    // const value: string = (($event).trim()).toLowerCase();
    let value: string = (($event).trim()).toLowerCase();
    value = value.replace(' /g', '')

    setTimeout(async () => {
      await this.onFilter(value);
    }, 500)
  }

  async onFilter(value: string) {
    if (value.length >= 2) { //&& this.dropdownFilterItems.length > 5
      this.dropdownFilterItems = await this.dropdownFilterItemsAll.filter((d: { name: any; }, index: any) => {
        if (d.name !== null) {
          if (d.name.toLowerCase().indexOf(value) !== -1) {
            return true;
          }
          else {
            return false;
          }
        } else {
          return false;
        }
      })
    } else {
      return false;
    }
    return true;
  }

  /*
  changeFilterOLD(event: any) {
    if (event.target.value == 'null' || event.target.value == null) {
      this.filter = { id: null, name: null };
      return;
    }

    const id = Number(event.target.value);
    const result = this.dropdownFilterItems.find(x => x.id === id);
    this.filter = result;

    // Sustituir por id la validación
    if (this.statisticsSelected === 'Promoción en especifico' || this.statisticsSelected === 'Eventos') {
      this.count = 0;
      this.pageNumber = 0;
      this.categoriesHeading = [];
    }
  }
  */
}

<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet">
<div class="main">
    <div class="top-block">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-conferencistas')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
    </div>

    <div class="main-block">
        <label class="label-1"> Información del conferencista</label>
        <div class="row-1">
            <div class="col-md-1-form">
                <div class="custom-input">
                    <label>Nombre del conferencista</label>
                    <input 
                    placeholder="Nombre del conferencista"
                    [(ngModel)]="speakerData.nombreConferencista"/>
                </div>
                <div class="custom-input">
                    <label>Correo electrónico</label>
                    <input 
                    placeholder="info@company.com"
                    [(ngModel)]="speakerData.email"/>
                </div>
                <div class="custom-input">
                    <label>Información del conferencista</label>
                    <textarea class="desc-conf" name="Desc" cols="40" rows="5"
                    [(ngModel)]="speakerData.informacionConferencista"></textarea>
                </div>
            </div>
            <div class="col-md-2-form">
                <div class="custom-input">
                    <label>Profesión</label>
                    <input 
                    placeholder="Profesión"
                    [(ngModel)]="speakerData.profesion"/>
                </div>

            </div>
            <div class="col-md-3-form">
                <div class="code-form">
                    <label>Foto del conferencista</label>
                    <img class="conf-pic"
                    [src]="imageSrc" 
                    (load)="handleImageLoad()" 
                    [class.loaded]="imageLoaded"/>
                    <button class="qr-btn" onclick="document.getElementById('file').click();">Subir foto</button>
                    <input type="file" style="display:none;" id="file" name="file" #FileSelectInputDialog (change)="onFileSelected($event)"/>                    
                </div>
            </div>

        </div>

    </div>

    <div class="block-bottom">
        <button class="cancle-btn">Cancelar</button>
        <button class="save-btn" (click)="createSpeaker()"><i class="save-icon"></i>Guardar</button>
    </div>
    
</div>
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../Services/utils.service';
import { BackService } from '../../Models/back-service.model';
import { BackofficeService } from '../../Services/backoffice.service';
import { PasswordRecoveryCodeComponent } from '../password-recovery-code/password-recovery-code.component';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})

export class NewPasswordComponent implements OnInit {
  reset: any;
  show: boolean = false;
  password: string = '';
  confirmpassword: string = '';
  loginData: BackService = {
    newPassword: ''
  };

  ngOnInit() {
    if (sessionStorage.getItem('tempToken')) {
      this.reset = sessionStorage.getItem('tempToken') !== null ? sessionStorage.getItem('tempToken') : "";
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes acceso...');
      this.goToPage('/login');
    }
  }

  constructor(
    private utilsService: UtilsService,
    private backofficeService: BackofficeService,
  ) { }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName)
  }

  changePassword() {
    switch (true) {
      case this.password !== this.confirmpassword:
        {
          this.utilsService.dialog('ERROR', 'Error', 'Las claves ingresadas son distintas.');
        }
        break;
      case (!this.passwordValidator()):
        {
          this.utilsService.dialog('ERROR', 'Error', 'La contraseña debe tener como mínimo 6 caracteres siendo al menos 1 mayúscula.');
        }
        break;
      case (this.passwordValidator() && this.password === this.confirmpassword):
        this.callService();
        break;
    }
  }

  passwordValidator() {
    let plus6 = this.password.length > 5;
    let hasUpper = /[A-Z]/.test(this.password);

    if (hasUpper && plus6) {
      return true;
    }
    return false;
  }

  callService() {
    this.loginData = {
      newPassword: this.password
    }
    console.log(this.reset);

    this.backofficeService.newpassword(this.loginData, this.reset)
      .subscribe({
        next: (res) => {
          sessionStorage.removeItem('tempToken');
          sessionStorage.removeItem('recoverEmail');
          this.goToPage('/login');
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'No se pudo actualizar la contraseña. Por favor vuelve a intentar más tarde.');
        }
      })
  }

  passwordVisibility() {
    this.show = !this.show;
  }
}

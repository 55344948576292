<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4 mb-5">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-patrocinadores')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12 mb-4">
            <form #form="ngForm">
                <div class="row">
                    <div class="col-12">
                        <div class="card mb-5">
                            <div class="card-body">
                                <div class="col-6 px-4">
                                    <label class="form-label mb-3">Nombre de la encuesta*</label>
                                    <input class="form-control" name="nombreEncuesta" id="nombreEncuesta" [(ngModel)]="survey.title" required placeholder="Nombre de la encuesta"/>
                                    <div class="err-msg" *ngIf="validation_msg('nombreEncuesta')">Este campo es obligatorio.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngFor="let e of questions; let i = index">
                        <div class="card mb-3">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-10">
                                        <label class="form-label mb-3">Pregunta {{i + 1}}</label>
                                    </div>
                                    <div class="col-2 text-right">
                                        <!-- *ngIf="i > 2" -->
                                        <button class="btn btn-gray" *ngIf="!e.default" (click)="deleteDialog(i, e.id)">X</button>
                                    </div>
                                </div>
                                <div class="row px-4 py-0">
                                    <div class="col-12 col-md-4 mb-3">
                                        <label class="form-label mb-3">Tipo de pregunta*</label>                                        

                                        <!-- [disabled]="i < 3" -->
                                        <select class="form-control" name="tipoPregunta_{{i}}" id="tipoPregunta_{{i}}" [(ngModel)]="e.type"
                                            (change)="verifyQuetion($event, e, i)" [disabled]="e.default === true" required>
                                            <!-- <option value="null" selected disabled>Seleccione...</option> -->
                                            <option value="undefined" selected disabled>Seleccione...</option>
                                            <option value="open">Respuesta abierta</option>
                                            <option value="stars">Escala</option>
                                            <option value="truefalse">Si o no</option>
                                            <option value="multiple">Opción Múltiple</option>
                                        </select>
                                        <div class="err-msg" *ngIf="validation_msg('tipoPregunta_' + i)"> Este campo es obligatorio.</div>
                                    </div>
                                    <div class="col-12 col-md-8">
                                        <div class="row">
                                            <div class="col-12 mb-3">
                                                <label class="form-label mb-3">&nbsp;</label>
                                                <!-- [readonly]="i < 3"/> -->
                                                <input class="form-control" name="textoPregunta_{{i}}" id="textoPregunta_{{i}}" [(ngModel)]="e.text" required placeholder="Pregunta" [readonly]="e.default === true"/>
                                                <div class="err-msg" *ngIf="validation_msg('textoPregunta_' + i)">Este campo es obligatorio.</div>
                                            </div>                                            
                                            <div class="col-12 mb-3" *ngFor="let option of e.options; let x = index">
                                                <label class="form-label mb-3">Respuesta {{x + 1}}</label>
                                                <div class="input-group">
                                                    <input name="opcionesPregunta_{{i}}_{{x}}" class="form-control" style="border: none !important" [(ngModel)]="e.options[x][0]" required placeholder="Respuesta {{x + 1}}">
                                                    <span class="input-group-btn">
                                                        <a class="btn btn-outline-secondary" (click)="removeOption(i, x)">
                                                            <i class="btn-circle-close-icon"></i>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div class="err-msg" *ngIf="validation_msg('opcionesPregunta_' + i + '_' + x)">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-12 mb-3 text-right" *ngIf="e.options">
                                                <button class="btn btn-purple my-2" [ngClass]="!e.options ? 'showBtn' : ''" id="btn{{i}}" (click)="addOption(i)" [disabled]="e.options.length >= 4" >+ Agregar opción</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 text-right px-5" *ngIf="questions.length < 5">
            <div class="d-grid gap-2">
                <button class="btn btn-purple my-2" type="button" (click)="addQuestion()">+ Agregar Pregunta</button>
            </div>
        </div>
        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn px-4 my-2 btn-cancel-edituseradmin" (click)="cancel()" [disabled]="!form.touched" >Cancelar</button>
                &nbsp;
                <button class="btn btn-primary px-4 my-2 btn-save-editagenda" type="button" (click)="processData()" [disabled]="(disabled || !form.touched || !form.valid )">
                <i class="save-icon"></i>Guardar</button>
            </div>
        </div>
    </div>
</div>
<div class="main">
    <div class="header">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-participantes')"></i>
            <span class="change-page-label"> Atrás </span>
            <div class="next-container">
                <span class="change-page-label"> Siguiente </span>                
                <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-conferencias')"></i>
            </div>
        </div>
        <div class="btn-group">
            <button class="agregar" (click)="goToPage('/admin/events/user-guide', {type: 'conferencistas', event_id: eventID})">
                + Agregar
            </button>
         </div>
    </div>
    <div class="top-main">
        <div class="default-view">
            <div class="search-field">
                <i class="search-filter" id="search"></i>
                <input class="search-input" placeholder="Buscar conferencista..." [(ngModel)]="filtro" />
            </div>
            <div class="table-default">
                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let item of list | tableFilter:filtro:'Nombre conferencista'; let i = index">
                            <ng-container *ngFor="let x of item | keyvalue:originalOrder">                    
                                <td *ngIf="x.key != 'Estatus'" [hidden]="x.key === 'id' || x.key === 'participantId'">{{x.value}}</td>
                                <td *ngIf="x.key == 'Estatus'">
                                    <select name="registro_{{i}}" id="registro_{{i}}"
                                        [ngClass]="x.value === '0' ? 'select-enable select-disable' : 'select-enable'" [value]="x.value"
                                        (change)="updateRegister($event, i, item.participantId)" [disabled]="x.value == '1'" #selects>
                                        <option value='1'>Registrado</option>
                                        <option value='0'>Sin Registro</option>
                                    </select>    
                                </td>
                            </ng-container>
                            <td (mouseleave)="hideActions()">
                                <button name="actions" id="actions" class="actions" (click)="showActions(i)" (keyup)="showActions(i)"></button>
                                <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{i}}" (mouseleave)="hideActions()">
                                    <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="editSpeaker(item.id)">Editar</button>
                                    <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="deleteSpeaker(item.id)">Eliminar</button>
                                </div>
                            </td>                             
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="table-pagination">
                    <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
                    <p class="pagination-text">Página {{totalPages != 0 ? pageNumber + 1 : 0 }} de {{totalPages}}</p>
                    <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
                </div> -->
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ExposurMapService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    /******************** STAND ********************/
    getStandByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}map/${event_id}/stands`, this.options).toPromise();
    }

    createStand(data: any): Promise<any> {
        return this.http.post(`${this.url}stand/`, data, this.options).toPromise();
    }

    deleteStand(stand_id: number): Promise<any> {
        return this.http.delete(`${this.url}stand/${stand_id}`, this.options).toPromise();
    }

    /******************** MAPS ********************/
    getMapByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}map/${event_id}/maps`, this.options).toPromise();
    }

    createMapFloor(data: any): Promise<any> {
        return this.http.post(`${this.url}map/floors/`, data, this.options).toPromise();
    }

    updateMapFloor(data: {}): Promise<any> {
        return this.http.patch(`${this.url}map/floors/`, data, this.options).toPromise();
    }

    deleteFloor(floor_id: number): Promise<any> {
        return this.http.delete(`${this.url}map/floor/${floor_id}`, this.options).toPromise();
    }
}

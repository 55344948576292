import { MediaMatcher } from '@angular/cdk/layout';
import { UserService } from 'src/app/Services/user.service';
import { Router, NavigationStart } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

interface Menu {
  id: string,
  src: string,
  name: string,
  routerLink: string
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css'],
})
export class MainMenuComponent implements OnInit {
  public showFiller = false;
  public main_menu: Menu[] = [];
  public showHead: boolean = false;
  public arraw_reflex: string = '';
  public class_text: string = 'd-none';
  public extended_menu: boolean = false;

  public opMenuUser = {
    id: 'users',
    routerLink: 'users',
    name: 'Administración de usuarios',
    src: '../../assets/images/UsersIcon.svg',
  };

  public opMenuEvent = {
    id: 'evento',
    name: 'Eventos ',
    routerLink: 'events',
    src: '../../assets/images/EventoIcon.svg'
  };

  public opMenuReport = {
    id: 'reportes',
    name: 'Estadisticas',
    routerLink: 'reportes',
    src: '../../assets/images/ReporteIcon.svg'
  };

  public opMenuNotification = {
    id: 'notificaciones',
    name: 'Notificaciones',
    routerLink: 'notificaciones',
    src: '../../assets/images/NotificacionIcon.svg'
  };

  public opMenuPromotion = {
    id: 'promociones',
    name: 'Beneficios',
    routerLink: 'admin-promociones',
    src: '../../assets/images/promotion_icon.svg'
  }

  constructor(
    private router: Router,
    private userService: UserService,
    public mediaMatcher: MediaMatcher,
    private changeDetector: ChangeDetectorRef
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (
          event['url'] == '/login' ||
          event['url'] == '/recovery' ||
          event['url'] == '/code-recovery' ||
          event['url'] == '/new-password'
        ) {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (sessionStorage.getItem('Token')?.toString()) {
      // this.validation();
      const userRole: string | boolean = sessionStorage.getItem('Role') || false;
      const permissions = await this.getUserByToken(sessionStorage.getItem('Token') || undefined);

      if (userRole === 'ROLE_SUPERADMIN' || userRole === 'ROLE_ADMIN') {
        this.main_menu.push(this.opMenuUser);
        this.main_menu.push(this.opMenuEvent);
        this.main_menu.push(this.opMenuReport);
        this.main_menu.push(this.opMenuNotification);
        this.main_menu.push(this.opMenuPromotion);
      } else if (userRole === 'ROLE_ORGANIZADOR_EVENTOS') {
        this.main_menu.push(this.opMenuEvent);
        this.main_menu.push(this.opMenuNotification);
        this.main_menu.push(this.opMenuReport);
        (permissions?.includes('subir_promociones')) && (this.main_menu.push(this.opMenuPromotion));
      } else if (userRole === 'ROLE_ALIADO_COMERCIAL') {
        this.main_menu.push(this.opMenuPromotion);
        (permissions?.includes('descargar_reporte')) && (this.main_menu.push(this.opMenuReport));
      }
      else {
        (permissions?.includes('descargar_reporte')) && (this.main_menu.push(this.opMenuReport));
        (permissions?.includes('subir_promociones')) && (this.main_menu.push(this.opMenuPromotion));
      }
    }
  }

  async getUserByToken(token: string | undefined) {
    let response;
    response = await this.userService.getCurrentUser();
    if (response.success && response.success == true) {
      return response.result.permissions;
    } else {
      return new Array();
    }
  }

  changeView() {    
    if (this.extended_menu) {
      this.arraw_reflex = '';
      this.class_text = 'd-none'
      this.extended_menu = false;
    } else {
      this.extended_menu = true;
      this.arraw_reflex = 'reflex';
      this.class_text = 'menu-text'
    }
    window.dispatchEvent(new Event('resize'));
    /*
    const sidebarID = document.getElementById('main-menu');
    sidebarID?.setAttribute('style:', 'width: auto')
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
    */
  }  

  /*
  private subscription: any;
  private secondsCounter$: any;
  validation() {
    this.secondsCounter$ = interval(1200000); //15seg. = 15000
    this.subscription = this.secondsCounter$.subscribe(() => {
      const current = new Date().getTime();
      const getSession = localStorage.getItem('session')?.toString() || '';
      const expiration = new Date(parseInt(getSession.toString())).getTime();

      if (getSession === '' || (current >= expiration)) {
        this.logout();
        this.utilsService.dialog('ERROR', 'Error', '¡Sesión expirada!');
      }
    });
  }
  //*/

  logout() {
    sessionStorage.removeItem('Token');
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate([`/login`]);
    // this.subscription.unsubscribe();
    // this.utilsService.goToPage('/login', { logout: true })
  }
}

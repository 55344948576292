import { ActivatedRoute } from '@angular/router';
import { UserService } from '../Services/user.service';
import { UtilsService } from '../Services/utils.service';
import { BackService } from '../Models/back-service.model';
import { Component, OnInit, Injectable } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable()
export class LoginComponent implements OnInit {
  public loading: boolean = false;
  email: string = '';
  password: string = '';
  show: boolean = false;
  loginData: BackService = {
    username: '',
    password: ''
  };
  username: any;
  buttonDisabled: boolean = false;

  public activatedRoute: ActivatedRoute | null | undefined;

  constructor(
    // private router: Router,
    private userService: UserService,
    private utilsService: UtilsService
  ) { }

  async ngOnInit() {
    /* const reload = this.utilsService.getParam('logout');
    if (Boolean(reload) === true) {
      await this.router.navigate([], { relativeTo: this.activatedRoute });
      window.location.reload();
    } */

    this.buttonDisabled = false;
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  passwordVisibility() {
    this.show = !this.show;
  }

  async login() {
    this.loading = true;
    this.buttonDisabled = true;
    this.loginData = {
      username: this.email,
      password: this.password
    }

    await this.userService.login(this.loginData).then(
      async (response) => {
        // console.log(response.result);
        sessionStorage.setItem('Token', response.result.accessToken);
        // sessionStorage.setItem('TokenLogin', (new Date().getTime()).toString());
        sessionStorage.setItem('TokenExpiration', (new Date().getTime() + 86100000).toString()); // 1H->86400000; 55M->86100000; 1M->60000
        await this.loadData(response.result);
      },
      (e) => {
        // console.log(e);
        this.utilsService.dialog(
          'ERROR',
          e.error?.message?.title || 'Error',
          e.error?.message?.description || 'Error con el servidor, contacta al administrador.'
        );
        throw (e);
      }).finally(() => {
        this.loading = false
        this.buttonDisabled = false;
      })
  }

  loadData(data: any) {
    // return this.userService.getCurrentUser(data?.accessToken).then(
    return this.userService.getCurrentUser().then(
      response => {
        const permissions: string[] = response.result.permissions || new Array();
        this.username = `${response.result.firstName || ''} ${response.result.firstLastname || ''}`;

        if (this.val_role(data?.roles) || permissions?.includes('subir_promociones') || permissions?.includes('descargar_reporte')) {
          sessionStorage.setItem('EventID', '0');
          sessionStorage.setItem('EventType', '0');
          sessionStorage.setItem('UserID', data.id);
          sessionStorage.setItem('Role', data.roles);
          sessionStorage.setItem('Email', data.email);
          sessionStorage.setItem('Name', this.username);
          sessionStorage.setItem('companyId', data.companyId);
          // localStorage.setItem('session', (new Date().getTime() + 86400000).toString()); //1min. = 60000
          this.goToPage('/admin/inicio');
        } else {
          this.utilsService.dialog('ERROR', 'Alerta', 'Tipo de rol sin acceso a la plataforma.');
          this.logout();
        }
      },
      error => {
        console.log(error)
        this.utilsService.dialog('ERROR', error?.error?.message?.title, error?.error?.message?.description);
      }
    );
  }

  val_role(role: any): boolean {
    switch (role) {
      case 'ROLE_SUPERADMIN':
        return true;
        break
      case 'ROLE_ADMIN':
        return true;
        break
      case 'ROLE_ORGANIZADOR_EVENTOS':
        return true;
        break
      case 'ROLE_ALIADO_COMERCIAL':
        return true;
        break
      default:
        return false;
        break
    }
  }

  logout() {
    sessionStorage.removeItem('Token');
    // environment.token = '';
    sessionStorage.clear();
    localStorage.clear();
    this.goToPage('/login');
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SpeakerService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    // Obtener conferencista del evento
    getSpeakerByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/speakers/event/${event_id}`, this.options).toPromise();
    }

    // Obtener un conferencista
    getOneSpeaker(speaker_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/speakers/${speaker_id}`, this.options).toPromise();
    }

    // Agregar un speaker a un evento
    addSpeaker(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/speakers/`, data, this.options).toPromise();
    }

    //Actualiar un conferencista de un evento (+ asistencia/registro)
    updateSpeakerByEvent(ID: number, data: any): Promise<any> {
       return this.http.put(`${this.url}eventos/speakers/${ID}`, data, this.options).toPromise();
    }

    //updateSpeakerByEvent(ID: number, data: any): Promise<any> {
    //    return this.http.post(`${this.url}eventos/participants/back/register-participant`, data, this.headers).toPromise();
    //  }

    // Eliminar un conferencista de un evento
    deleteSpeaker(speaker_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/speakers/${speaker_id}`, this.options).toPromise();
    }

    // Eliminar un conferencista desde usuarios participantes
    deleteSpeakerByParticipant(event_id: number, participant_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/speakers/by-participant/${participant_id}/${event_id}`, this.options).toPromise();
    }
}

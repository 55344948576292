import { SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/Services/user.service';
import { PromotionsService } from 'src/app/Services/promotions.service';
import { PromotionCreateModel, promotionCategorie } from 'src/app/Models/promotions-models';

interface dateModel {
  year: number,
  day: number,
  month: number,
}

@Component({
  selector: 'app-edit-promocion',
  templateUrl: './edit-promocion.component.html',
  styleUrls: ['./edit-promocion.component.css'],
})

export class EditPromocionComponent implements OnInit {
  @ViewChild('AGForm') formulario!: NgForm;
  public qrCodeSrc!: SafeUrl;
  public dateEnd!: string;
  public loading: boolean = false;
  public dateStart!: string;
  public countRedeem: number = 0;
  // public exchanged: number | undefined = undefined;
  public disabled: boolean = false;
  public original_limit: number = 0;
  public dateInvalid: boolean = false;
  public limitInvalid: boolean = false;
  public fechaInicioError: string = '';
  public permissionStatus: boolean = true;
  public fechaInicioInvalid: boolean = false;
  public myAngularxQrCode: string = 'Promoción no guardada';
  public promoData: PromotionCreateModel = {} as PromotionCreateModel;

  imageType: string = '';
  loaded: boolean = false;
  iamgeSrc: string = '';
  hasQRdata: boolean = false;
  isAdmin: boolean = false;

  asociadoBox: boolean = false;
  noAsociadoBox: boolean = false;
  colaboradoresBox: boolean = false;
  publicoBox: boolean = false;
  promoCategories: promotionCategorie[] = [];
  limitPerUser: number[] = [];
  categorySelecte: number | undefined;

  ImageName: string = '';
  data: string | undefined;

  ImageThumbnailName: string = '';
  dataThumbnail: string | undefined;

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private promotionService: PromotionsService
  ) {
    this.promoData.limit = 0;
    this.promoData.name = '';
    this.promoData.limitUser = 0;
    this.promoData.description = '';
  }

  async ngOnInit(): Promise<void> {
    if (await this.validateAccess()) {
      this.getLimitPerUser();
      this.getPromoCategories();
      const promotion_id = this.utilsService.getParam('promotion_id');

      if (promotion_id != false) {
        await this.getPromotion(promotion_id);
      } else {
        this.publicoBox = true;
        this.promoData.roles = [0];
        this.promoData.status = 'draft';
        this.promoData.startDate = null;
        this.promoData.referral = false;
        this.promoData.finishDate = undefined;
        this.promoData.companyId = this.utilsService.getParam('company_id');
      }
    } else {
      this.utilsService.dialog('ERROR', 'Alerta', 'No tienes permiso para esta acción.');
      this.goToPage('admin/admin-promociones');
    }
  }

  async validateAccess(): Promise<boolean> {
    const currentUser = await this.userService.getCurrentUser();
    const userRole = currentUser?.result?.role;
    const permission = currentUser?.result?.permissions.includes('subir_promociones');

    if (userRole === 'ROLE_SUPERADMIN' || userRole === 'ROLE_ADMIN') {
      this.isAdmin = true;
      return true;
    }
    else if (userRole === 'ROLE_ALIADO_COMERCIAL' || permission == true) {
      this.isAdmin = false;
      const userCompanyId = currentUser?.result?.companyId;
      const currentCompanyId = this.utilsService.getParam('company_id');

      if (userCompanyId == currentCompanyId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validation_msg(name: string): boolean {
    if (this.formulario && this.formulario.controls[name]) {
      if (
        (this.formulario.controls[name].errors && this.formulario.controls[name].invalid)
        &&
        (this.formulario.controls[name].dirty || this.formulario.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validation_limit(): boolean {
    // if (Number(this.promoData.limit) && this.promoData.limitUser) {
    if (Number(this.promoData.limit) < this.promoData.limitUser) {
      this.limitInvalid = true;
      return true;
    } else {
      this.limitInvalid = false;
      return false;
    }
    // } else {
    //   this.limitInvalid = false;
    //   return false;
    // }
  }

  getPromotion(promotion_id: number) {
    this.loading = true;
    return this.promotionService.getOnePromotions(promotion_id).then(
      response => {
        console.log(response);
        this.promoData.id = response.result?.id;
        this.promoData.url = response.result?.url;
        this.promoData.phone = response.result?.phone;
        this.promoData.status = response.result?.status;
        this.promoData.address = response.result?.address;
        this.original_limit = response.result?.limit || 0;
        this.promoData.limit = response.result?.limit || 0;
        this.countRedeem = response.result?.countRedeem || 0;
        this.promoData.referral = response.result?.referidos;
        this.promoData.limitUser = response.result?.limitUser;
        this.promoData.companyId = response.result?.companyId;
        this.promoData.categoryId = response.result?.categoryId;
        this.promoData.description = response.result?.description;
        this.promoData.restrictions = response.result?.restrictions;
        this.promoData.name = response.result?.name.substring(0, 40);

        this.promoData.startHour = response.result?.startHour;
        this.promoData.finishHour = response.result?.finishHour;
        this.promoData.startDate = response.result?.startDate as string;
        this.promoData.finishDate = response.result?.finishDate as string;

        this.ImageName = response.result?.image;
        this.ImageThumbnailName = response.result?.thumbPromo;
        this.getQrData(this.promoData.id);

        (response.result?.status == 'soldout') && (this.permissionStatus = false);

        if (this.promoData.referral == true) {
          this.publicoBox = false;
          this.asociadoBox = false;
          this.promoData.roles = [];
          this.noAsociadoBox = false;
          this.colaboradoresBox = false;
        } else if (response.result?.roles != null && response.result?.roles != undefined) {
          this.promoData.roles = response.result?.roles.map((e: any) => { return e.roleId });
          this.publicoBox = false;
          this.asociadoBox = this.promoData.roles.includes(2);
          this.noAsociadoBox = this.promoData.roles.includes(1);
          this.colaboradoresBox = this.promoData.roles.includes(3);

          if (this.promoData.roles.length === 0 || this.promoData.roles.includes(0)) {
            this.promoData.roles = [0];
            this.publicoBox = true;
            this.asociadoBox = false;
            this.noAsociadoBox = false;
            this.colaboradoresBox = false;
          }
        } else {
          this.publicoBox = true;
          this.promoData.roles = [0];
        }

        // console.log(response.result);
        // console.log(this.promoData);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async getQrData(promotion_id: number) {
    try {
      const response = await this.promotionService.getQRData(promotion_id);
      if (response?.success === true) {
        // console.log(response);        
        this.hasQRdata = true;
        this.data = response.result as string;
      } else {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    } catch (error) {
      this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
    }
  }

  onChangeBox($event: any) {
    switch ($event.target.name) {
      case 'asociado':
        this.onChangeBoxAction(2, $event.target.checked);
        break;
      case 'noAsociado':
        this.onChangeBoxAction(1, $event.target.checked);
        break;
      case 'colaboradores':
        this.onChangeBoxAction(3, $event.target.checked);
        break;
      case 'publico':
        this.promoData.roles = [];
        this.asociadoBox = false;
        this.noAsociadoBox = false;
        this.colaboradoresBox = false;
        this.onChangeBoxAction(0, $event.target.checked);
        break;
      case 'referido':
        this.promoData.roles = [];
        this.publicoBox = false;
        this.asociadoBox = false;
        this.noAsociadoBox = false;
        this.colaboradoresBox = false;
        this.promoData.limitUser == null;
        this.onChangeBoxAction(0, $event.target.checked);
        break;
    }
  }

  onChangeBoxAction(role_id: number, checked: boolean) {
    if (checked) {
      this.promoData.roles.push(role_id)
    } else {
      const index = this.promoData.roles.findIndex((x: any) => x === role_id);
      this.promoData.roles.splice(index, 1);
    }
  }

  changeLimit() {
    // if (Number(this.promoData.limit) > this.original_limit) {
    //   this.permissionStatus = true;
    //   this.promoData.status = 'draft';
    // }
    // else if (Number(this.promoData.limit) <= this.original_limit) {
    //   this.permissionStatus = false;
    //   this.promoData.status = 'soldout';
    // }
    if (Number(this.promoData.limit) > this.countRedeem) {
      this.permissionStatus = true;
      this.promoData.status = 'draft';
    } else {
      this.permissionStatus = false;
      this.promoData.status = 'soldout';
    }
  }

  downloadImage() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.data!;
    link.download = `QR promoción ${this.promoData.name}.${this.getExtension(
      this.data!
    )}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private getExtension(url: string): string {
    const extension = url.split(';')[0].match(/jpeg|png|gif|jpg/)![0];
    return extension;
  }

  getFormatDateToTemplate(stringDate: string) {
    const dateParts = stringDate.split('-');
    return { day: +dateParts[0], month: +dateParts[1], year: +dateParts[2] };
  }

  async getPromoCategories() {
    try {
      const response = await this.promotionService.getPromoCategories();
      if (response?.success === true) {
        this.promoCategories = response.result;
        // if (this.editPromoModel != undefined) {
        //   this.promoData.categoryId = response.result.find((x) => x.name == this.editPromoModel?.categoryName)?.id!;
        // }
      } else {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    } catch (error) {
      this.utilsService.dialog('ERROR', 'Error', 'NO es posible cargar las categorias de promociones.');
    }
  }

  async getLimitPerUser() {
    try {
      const response = await this.promotionService.getPromoLimitsPerUser();
      if (response?.success === true) {
        this.limitPerUser = response.result;
      } else {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    } catch (error) {
      this.utilsService.dialog('ERROR', 'Error', 'NO es posible cargar las limites de canjes');
    }
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params);
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeSrc = url;
  }

  async onFileSelected(event: any) {
    this.loaded = false;
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.imageType = file.type.substring(file.type.indexOf('/') + 1);

    const reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      const fielName = event.target.files[0].name;
      if (event.target.id === 'fileIMG') {
        this.ImageName = `${event.target.files[0].name} (${event.target.files[0].size / 1000000} MB) `;
        this.promoData.newImage = {
          data: '',
          name: fielName,
          extension: this.imageType
        };
        reader.onload = await this._handleReaderLoadedBadge.bind(this);
      } else if (event.target.id === 'fileThumbnail') {
        this.ImageThumbnailName = `${event.target.files[0].name} (${event.target.files[0].size / 1000000} MB) `;
        this.promoData.newThumbPromo = {
          data: '',
          name: fielName,
          extension: this.imageType
        };
        reader.onload = await this._handleReaderLoadedThumbnail.bind(this);
      }
      reader.readAsDataURL(file);
    }
  }

  _handleReaderLoadedBadge(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.iamgeSrc = reader.result;
    this.promoData.newImage!.data = this.iamgeSrc;
  }

  _handleReaderLoadedThumbnail(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.iamgeSrc = reader.result;
    this.promoData.newThumbPromo!.data = this.iamgeSrc;
  }

  validation_date(): boolean {
    if ((this.promoData.startDate && this.promoData.startHour) && (this.promoData.finishDate && this.promoData.finishHour)
    ) {
      const endDate = new Date(`${this.promoData.finishDate} ${this.promoData.finishHour}`);
      const startDate = new Date(`${this.promoData.startDate} ${this.promoData.startHour}`);
      this.dateInvalid = !(endDate.getTime() > startDate.getTime());
      return !(endDate.getTime() > startDate.getTime());
    } else {
      return false;
    }
  }

  /*
  validateStartDate() {
    if (!this.isStartDateBeforeFinishDate()) {
      this.fechaInicioError = 'La fecha y hora de finalización debe ser mayor a la fecha de inicio.';
      this.fechaInicioInvalid = true;
    } else {
      this.fechaInicioError = '';
      this.fechaInicioInvalid = false;
    }
  }

  isStartDateBeforeFinishDate(): boolean {
    if (
      this.promoData.startDate &&
      this.promoData.finishDate &&
      typeof this.promoData.startDate === 'string' &&
      typeof this.promoData.finishDate === 'string' &&
      this.promoData.startHour &&
      this.promoData.finishHour
    ) {
      // Parse la fecha y hora de inicio
      const startDateParts = this.promoData.startDate.split('-');
      const startYear = +startDateParts[2];
      const startMonth = +startDateParts[1] - 1;
      const startDay = +startDateParts[0];
      const startTimeParts = this.promoData.startHour.split(':');
      const startHour = +startTimeParts[0];
      const startMinute = +startTimeParts[1];
      const startDate = new Date(startYear, startMonth, startDay, startHour, startMinute);

      // Parse la fecha y hora de finalización
      const finishDateParts = this.promoData.finishDate.split('-');
      const finishYear = +finishDateParts[2];
      const finishMonth = +finishDateParts[1] - 1;
      const finishDay = +finishDateParts[0];
      const finishTimeParts = this.promoData.finishHour.split(':');
      const finishHour = +finishTimeParts[0];
      const finishMinute = +finishTimeParts[1];
      const finishDate = new Date(finishYear, finishMonth, finishDay, finishHour, finishMinute);

      // Realiza la comparación
      if (startDate < finishDate) {
        return true;
      }
    }
    return false;
  }
  */

  async createPromo() {
    //*
    if (this.formulario.valid) {
      const startDate = this.promoData.startDate;
      const finishDate = this.promoData.finishDate;
      this.promoData.limit = Number(this.promoData.limit);
      this.promoData.limitUser = Number(this.promoData.limitUser);
      this.promoData.categoryId = Number(this.promoData.categoryId);
      (this.promoData.roles.length) == 0 && (this.promoData.roles = [0], this.publicoBox = true);

      try {
        this.loading = true;
        this.disabled = true;
        if (this.promoData.id != undefined) {
          var response = await this.promotionService.updatePromotion(this.promoData);
        } else {
          if (this.isAdmin) {
            var response = await this.promotionService.createPromotionByAdminRole(this.promoData);
          } else {
            var response = await this.promotionService.createPromotionByComercialRole(this.promoData);
          }
        }

        if (response?.success === true) {
          // this.returnToAdminWithParam();
          this.utilsService.dialog('SUCCESS', 'Éxito', response?.message.description);
          this.goToPage('/admin/admin-promociones', { companyId: this.promoData.companyId })
        } else {
          this.utilsService.dialog('ERROR', 'Error', response?.message.description || 'Intentar más tarde.');
        }
      } catch (error: any) {
        this.promoData.startDate = startDate;
        this.promoData.finishDate = finishDate;
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description || 'Intentar más tarde.');
      }
      this.loading = false;
      this.disabled = false;
    } else {
      this.formulario.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos obligatorios.');
    }
    //*/
  }
}

import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'back-agexport';
  showHead: boolean = false;

  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login'
          || event['url'] == '/recovery'
          || event['url'] == '/code-recovery'
          || event['url'] == '/new-password') {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
    });
  }
}

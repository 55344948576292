import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { ScheduleService } from 'src/app/Services/schedule.service';

@Component({
  selector: 'app-admin-agenda',
  templateUrl: './admin-agenda.component.html',
  styleUrls: ['./admin-agenda.component.css']
})

export class AdminAgendaComponent implements OnInit {
  public eventData: any;
  public dates: any[] = [];
  public modality: any[] = [];
  public loading: boolean = false;
  public categoriesHeading: string[] = [];
  public list: {}[] = [{ 'Hora': '', 'Lugar': '', 'Nombre de la actividad': '', 'Modalidad': '' }];

  constructor(
    private utilsService: UtilsService,
    private scheduleService: ScheduleService
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const getModalities = await this.utilsService.getModalities();
    this.modality = getModalities.result.modalities;

    const EventID = Number(sessionStorage.getItem('EventID'));
    this.eventData = JSON.parse(sessionStorage.getItem('EventData')!.toString())

    await this.loadSchedulebyEvent(EventID)
  }

  dateDisabled(): boolean {
    if (this.eventData && this.eventData.fechaFinal) {
      const fechaActual = new Date();
      const fechaActualFormateada = fechaActual.toISOString().split('T')[0];
      const fechaFinalFormateada = this.eventData.fechaFinal.split(' ')[0];
      const eventoFinalizado = fechaFinalFormateada < fechaActualFormateada;
      return eventoFinalizado;
    }
    return false;
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  showActions(super_index: number, index: number, event: any) {
    const element = document.getElementById('item' + super_index + index + event.id);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
    if (('item' + super_index + index + event.id) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  public emptyDialog: boolean = false;
  loadSchedulebyEvent(event_id: number) {
    this.loading = true;
    return this.scheduleService.getScheduleByEvent(event_id).then(
      response => {
        if (response.result.schedule_dates.length > 0) {
          this.dates = [];
          this.dates = response.result.schedule_dates.map((e: any) => {
            const data = e.schedule.map((i: any) => {
              const modality = this.modality.find(x => x.id === i.modalityId);
              const hour = e.date + ' ' + i?.hour?.substr(0, 5);
              const hourEnd = e.date + ' ' + i?.endHour?.substr(0, 5);
              return {
                id: i?.id,
                Hora: hour && hourEnd ? this.utilsService.date_pipe(hour, 'hh:mma') + ' - ' + this.utilsService.date_pipe(hourEnd, 'hh:mma') : '',
                Lugar: i?.description,
                'Nombre de la actividad': i?.name,
                Modalidad: modality ? modality.name : 'SIN DATOS DE BACKEND'
              }
            });
            return { date: e.date, data: data }
          });
        } else {
          this.dates = [];
          this.list = [];
          if (!this.emptyDialog) {
            this.emptyDialog = true;
            this.utilsService.dialog('ERROR', 'Alerta', '¡Evento sin datos de agenda!');
          }
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description || '¡Error al cargar agenda!');
        this.emptyDialog = true;
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  editSchedule(schedule_id: number) {
    this.utilsService.goToPage('/admin/events/edit-agenda', { ID: schedule_id })
  }

  deleteDialog(id: number) {
    this.utilsService.dialog('DELETE', '', '').then(
      result => {
        if (result === true) {
          this.removeSchedule(id);
        }
      },
      error => console.log(error)
    );
  }

  removeSchedule(schedule_id: number) {
    this.loading = true;
    this.scheduleService.deletedSchedule(schedule_id).then(
      async (response) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', '¡La actividad se eliminó!');
        this.emptyDialog = true;
        const EventID = Number(sessionStorage.getItem('EventID'));
        //this.cdr.detectChanges();
        await this.loadSchedulebyEvent(EventID);
      },
      error => {
        // console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡La actividad no se eliminó!');
      }).finally(() => {
        this.loading = false
      })
  }
}

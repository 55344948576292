<app-loading *ngIf="loading"></app-loading>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet" />

<div class="agexInsidePage">
  <div class="container">
    <div class="top-block-1">
      <i class="back-btn" name="back" id="back" (click)="goToPage('admin/notificaciones')"></i>
      <span class="back-label"> Atrás </span>
    </div>
    <br />
    <div class="row">
      <form #formulario="ngForm">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row align-items-end">
              <div class="col-4">
                <div class="custom-input">
                  <label>Seleccione evento o promoción</label>
                  <ng-multiselect-dropdown class="custom-dropdown" *ngIf="eventAndPromoList.length > 2"
                    name="multiselect" id="multiselect" [placeholder]="'Seleccione una empresa...'"
                    [settings]="dropdownSettings" [data]="eventAndPromoList" [(ngModel)]="selected"
                    (onSelect)="onItemSelect($event)">
                    <!-- [(ngModel)]="notification.eventId" -> este era pero no jala con name -->
                    <!-- [(ngModel)]="eventSelected" -->
                  </ng-multiselect-dropdown>
                </div>
              </div>
              <div class="col-4">
                <div class="custom-input">
                  <label>Status</label>
                  <br />
                  <select name="status" id="status" class="status-selector" [(ngModel)]="notification.notificationType"
                    (change)="onStatusChange($event)">
                    <!-- [(ngModel)]="statusSelected" -->
                    <option *ngFor="let x of statusList" value="{{ x.id }}" [disabled]="x.disabled">
                      {{ x.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <button class="btn btn-secondary btn-block" (click)="clearFields()">
                    Limpiar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5" *ngIf="notification.notificationType != 'seleccione'">
          <div class="card-body">
            <div class="row mb-4 justify-content-between">
              <div class="col col-6">
                <label for="basic-url">Asunto</label>
                <div class="input-group">
                  <input class="form-control" name="nit_persona" [(ngModel)]="notification.subject"
                    placeholder="Escriba el asunto..." />
                </div>
              </div>
              <div class="col col-5">
                <label for="">Fecha y hora de envío</label>
                <div class="row">
                  <div class="col col-6">
                    <div class="input-group">
                      <input class="form-control input-date" ngbDatepicker #d="ngbDatepicker" id="dp" name="dp"
                        [(ngModel)]="dateStart" [value]="dateStart | customDate" placeholder="dd-mm-yyyy" required
                        (click)="d.toggle()" [disabled]="isDateAndHourSelectEnabled" />
                      <div class="input-group-append">
                        <button [disabled]="isDateAndHourSelectEnabled" (click)="d.toggle()"
                          class="btn btn-outline-secondary date-button" type="button">
                          <i class="icon icon-date" id=""></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col col-6">
                    <div class="input-group">
                      <input [disabled]="isDateAndHourSelectEnabled" type="time" class="form-control custom-time-input"
                        id="appt" name="appt" placeholder="00:00:00" [ngModel]="sendHour"
                        (change)="onTimeInputChange($event)" required />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <label for="">Mensaje
                <span class="tiny-text">(Se requiere entre 20 a 160 caracteres)</span>
                <span
                  class="count-text {{ countCharacter(notification.message) > 160 ? 'text-danger' : '' }}">Caracteres:
                  {{ countCharacter(notification.message) }}</span>
              </label>
              <!-- https://www.tiny.cloud/my-account/dashboard/ -->
              <!-- <label for="">Mensaje (160 caracteres max.)</label> -->
              <editor
                    id="unique-editor-id-notificaction"
                    name="test"
                    ngZone="ES"
                    [init]="{
                            license_key: 'gpl',
                            height: 400,
                            menubar: false,
                            base_url: '/tinymce',
                            suffix: '.min',
                            plugins: 'wordcount',
                            toolbar: 'undo redo formatselect bold italic bullist numlist outdent indent alignleft aligncenter alignright justify removeformat'
                            }"
                      [(ngModel)]="notification.message"></editor>
              <!-- (onKeyPress)="test(notification.message)" -->
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <!-- [disabled]="toPlainText(notification.message) >= 6" -->
            </div>

            <div class="row justify-content-end">
              <div class="col col-2">
                <button [disabled]="validate()" class="btn btn-light btn-block" (click)="sendNotification(true)">
                  Guardar
                </button>
              </div>

              <div class="col col-2">
                <!-- [ngbTooltip]="validate() ? 'Formulario invalido.': ''" -->
                <button [disabled]="validate()" class="btn btn-primary btn-block" (click)="sendNotification(false)">
                  Enviar notificación
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="card pt-2 card-rounded">
    <div class="row">
        <div class="col-4">
            <div class="card-title">
                <p class="modal-title">Foto del conferecista</p>
            </div>
            <div class="form-image">
                <div class="div-image">
                    <img class="conf-image" [src]="image" (load)="handleImageLoad()" [class.loaded]="imageLoaded" />
                </div>
                <button class="qr-btn" onclick="document.getElementById('file').click();">Subir foto</button>
                <input type="file" style="display:none;" id="file" name="file" #FileSelectInputDialog (change)="onFileSelected($event)"/>
            </div>
        </div>
        <div class="col-7">
            <div class="row">
                <div class="col-12 mb-1">
                    <div class="card-title">
                        <div class="modal-title">Información del conferecista</div>
                        <div class="">
                            <textarea [(ngModel)]="information" maxlength="400"></textarea>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 mt-1">
                    <div class="custom-input">
                        <label>Puesto</label>
                        <select name="puesto" id="puesto" class="puesto-box" #selects [(ngModel)]="job" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let x of jobList" value="{{x.name}}">{{x.name}}</option>
                        </select>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-1 pt-2">
            <a class="pr-2" (click)="close()"><i class="info-btn-x"></i></a>
        </div>
    </div>

    <div class="row">        
        <div class="col-12 text-right pr-5">
            <button type="button" class="cancel-btn" (click)="close()">Cancelar</button>
            &nbsp;
            <button type="button" class="accept-btn" (click)="save()">Guardar</button>
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="main anchor-max">
    <!-- SHOW -->
    <div class="row-cont" *ngIf="type === 'SHOW'">
        <div class="column-cont">
            <div class="form-image">
                <div class="div-image">
                    <img class="conf-image" src={{gallery.url}} />
                </div>
                
            </div>
        </div>

        <div class="column-cont">
            <div class="custom-input">
                <label>Nombre del archivo</label>
                <input readonly [(ngModel)]="gallery.fileName"/>
            </div>
            <div class="custom-input">
                <label>Datos técnicos</label>
                <!-- <p class="datos-tec">Subido el: {{gallery.dateCreated | date:'dd-MM-yyyy'}}</p> -->
                <p class="datos-tec">Subido el: {{gallery.dateCreated}}</p>
                <p class="datos-tec">Subido por: {{gallery.userCreated}}</p>
                <p class="datos-tec">Tipo de archivo: {{gallery.type}}</p>
            </div>
            <button class="delete-btn" (click)="deleted(gallery.idEventMedia)">Eliminar</button>
        </div>
    </div>

    <!-- NEW -->
    <div class="row-cont" *ngIf="type === 'NEW'">
        <div class="column-cont">
            <div class="form-image">
                <div class="div-image">
                    <img class="conf-image" *ngIf="gallery.file" [src]="gallery.file" [class.loaded]="imageLoaded" />                    
                </div>
                <button class="btn-image" (click)="openFileInput()">Subir foto</button>
                <input type="file" style="display: none;" id="file" name="file" #FileSelectInputDialog accept="image/*, video/*"(change)="onFileSelected($event)"
                />
                <div class="err-msg" *ngIf="validationErrors && gallery.file != ''">Su archivo excede el límite de 100MB.</div>
            </div>
        </div>
        <div class="column-cont">
            <div class="custom-input">
                <label>Nombre del archivo</label>
                <input readonly [(ngModel)]="gallery.fileName"/>
            </div>
            <div class="custom-input">
                <p class="datos-tec">Tipo de archivo: {{gallery.type}}</p>
            </div>
            <button class="save-btn" (click)="save()" [disabled]="validationErrors">Guardar</button>
            
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet">
<link href="https://unpkg.com/@fortawesome/fontawesome-free@6.1.2/css/all.css" rel="stylesheet">
<link href="https://unpkg.com/angular-calendar@0.30.1/css/angular-calendar.css" rel="stylesheet">
<link href="https://unpkg.com/flatpickr@4.6.13/dist/flatpickr.css" rel="stylesheet">
<div>
    <div class="header">
        <header>Eventos</header>
        <button id="default-btn" [ngClass]="view == 0 ? 'default' : 'default-gray'" class="default" (click)="changeView($event)"></button>
        <button id="calendar-btn" [ngClass]="view == 1 ? 'calendar' : 'calendar-gray'" class="calendar" (click)="changeView($event)"></button>
        <button *ngIf="!disabled" class="agregar" (click)="goToPage('/admin/events/nuevo-evento')">+ Agregar</button>
    </div>
    <div class="main">
        <div class="default-view" id="default">
            <div class="bg-white">
                <div class="search-field">
                    <i class="search-filter" id="search" (click)="loadData(pageNumber, pageSize, filter, true)" ></i>
                    <input class="search-input" style="width: 90%;" placeholder="Buscar..."
                    [(ngModel)]="filter" (keyup.enter)="loadData(pageNumber, pageSize, filter, true)" (keyup)="searchReturn()"/>
                    <button class="agregar" style="margin: -9px 0; padding: 13px 20px; right: 3px; width: 148px;" (click)="loadData(pageNumber, pageSize, filter, true)"> Buscar </button>
                </div>
               
                <div class="table-default">
                    <table class="content-table" border="1">
                        <thead>
                            <tr>
                                <th></th>
                                <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr *ngFor="let item of list| tableFilter:filter:'Nombre del evento'; let i = index"> -->
                            <tr *ngFor="let item of list; let i = index">
                                <td>
                                    <button name="star" id="star" class="star-icon {{item.featured ? 'star-color' : 'star-line'}}" (click)="updateFeatured(item)"></button>
                                </td>
                                <ng-container *ngFor="let x of item | keyvalue:originalOrder">
                                    <td [ngClass]="{'text-success' : x.value == 'ontime', 'text-danger' : x.value == 'outoftime'}"
                                        *ngIf="x.key != 'Estatus'" [hidden]="x.key ==='id' || x.key ==='featured'">
                                        {{ x.value == 'ontime' ? 'A TIEMPO ' : x.value == 'outoftime' ? 'FUERA DE TIEMPO' :
                                        x.value }}
                                    </td>
                                    <td class="registro-group" *ngIf="x.key === 'Estatus'">
                                        <select name="registro" id="registro" class="registro-drop" #selects
                                            [value]="x.value" (change)="updateStatusEvent($event, i, item.id, item.Estatus)">
                                            <option value="finished">Finalizado</option>
                                            <option value="active">Activo</option>
                                            <option value="draft">Borrador</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <td (mouseleave)="hideActions()">
                                    <button name="actions" id="actions" class="actions" (click)="showActions(i)"
                                        (keyup)="showActions(i)"></button>
                                    <div [hidden]="true" class="card options-container float-end position-absolute translate-middle-y p-2 m-2 "
                                        id="item{{i}}" (mouseleave)="hideActions()">
                                        <button name="QR" id="QR" class="actions-btn border-bottom w-100 text-left"
                                            (click)="showGafete(item.id)">Ver QR</button>
                                        <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left"
                                            (click)="editEvent(item.id)">Editar</button>
                                        <button name="delete" id="delete" class="actions-btn w-100 text-left"
                                            (click)="deleteEvent(item.id)">Eliminar</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-pagination">
                        <i class="left-btn" id="back" (click)="onLeft()"></i>
                        <p class="pagination-text">Página {{pageNumber + 1}} de {{totalPages}}</p>
                        <i class="right-btn" id="back" (click)="onRight()"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="calendar-view" id="calendar"> 
            <div class="bg-white">            
                <div class='demo-app-main'  (viewDateChange)="viewDateChange.next(viewDate)">
                    <input type="month" (change)="onMonthChange($event)" value="{{currentDate}}" >
                    <full-calendar [events]="events" [options]="calendarOptions" (eventMouseover)="alert(1)"
                    (eventMouseout)="alert(0)">
                        <ng-template #eventContent let-arg>
                            <span>{{arg.event.title}}</span>
                        </ng-template>                       
                    </full-calendar>
                </div>
            </div>
        
            <div class="detalles">
                <div class="free-box"></div>
                <p class="detalles-label">Eventos Gratuitos</p>
                <div class="paid-box"></div>
                <p class="detalles-label">Eventos Pagados</p>
            </div>
        </div>
    </div>
</div>

<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-ubicaciones')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
    </div>
    <div class="tab-style">
        <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="active">
            <!-- UBICACIONES -->
            <li [ngbNavItem]="1" *ngIf="editType != 'parking'">
                <a ngbNavLink>Ubicaciones</a>
                <ng-template ngbNavContent>
                    <div class="card mb-5" >
                        <div class="card-body">
                            <h5 class="card-title mb-4">Agregar ubicación</h5>
                            <form #form="ngForm">
                                <div class="row px-4 py-0">
                                    <div class="col-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Nombre del lugar*</label>
                                                <input class="form-control" name="location_name"
                                                [(ngModel)]="locationData.nombreUbicacion" required placeholder="Nombre del lugar"/>
                                                <div class="err-msg" *ngIf="validation_msg('location_name')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Fecha y hora de inicio*</label>
                                                <div class="row">
                                                    <div class="col-12 col-lg-8 my-2 my-lg-0">
                                                        <input type="date" onkeydown="return false" class="form-control" name="location_date_start"
                                                            [(ngModel)]="locationData.fechaInicio" required  placeholder="dd-mm-yyyy" [min]="minDate" [max]="maxDate"/>
                                                        <div class="err-msg" *ngIf="validation_msg('location_date_start')">Este campo es obligatorio.</div>
                                                    </div>
                                                    <div class="col-12 col-lg-4">
                                                        <input type="time" class="form-control clock-mozff" name="location_hour_start"
                                                            [(ngModel)]="locationData.horaInicio" required/>
                                                        <div class="err-msg" *ngIf="validation_msg('location_hour_start')">Este campo es obligatorio.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Fecha y hora de finalización*</label>
                                                <div class="row">
                                                    <div class="col-12 col-lg-8 my-2 my-lg-0">
                                                        <input type="date" onkeydown="return false" class="form-control" name="location_date_end"
                                                            [(ngModel)]="locationData.fechaFin" required placeholder="dd-mm-yyyy" [min]="minDate" [max]="maxDate"/>
                                                        <div class="err-msg" *ngIf="validation_msg('location_date_end')">Este campo es obligatorio.</div>
                                                    </div>
                                                    <div class="col-12 col-lg-4">
                                                        <input type="time" class="form-control clock-mozff" name="location_hour_end"
                                                            [(ngModel)]="locationData.horaFin" required placeholder="dd-mm-yyyy" />
                                                        <div class="err-msg" *ngIf="validation_msg('location_hour_end')">Este campo es obligatorio.</div>
                                                    </div>
                                                    <div class="err-msg" *ngIf="validation_date()"> La fecha y hora final no puede ser menor a la inicial.</div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Dirección*</label>
                                                <input class="form-control" name="location_address"
                                                    [(ngModel)]="locationData.direccion" required placeholder="Dirección" />
                                                <div class="err-msg" *ngIf="validation_msg('location_address')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Dirección Google Maps*</label>
                                                <input class="form-control" name="location_maps"
                                                [(ngModel)]="locationData.enlaceUbicacion" placeholder="Dirección Google Maps" required autocomplete="OFF"/>
                                                <div class="err-msg" *ngIf="validation_msg('location_maps')">Este campo es obligatorio.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </li>

            <!-- PARQUEOS -->
            <li [ngbNavItem]="2" *ngIf="editType != 'location'">
                <a ngbNavLink>Parqueos</a>
                <ng-template ngbNavContent>
                    <div class="card mb-5">                        
                        <div class="card-body">
                            <h5 class="card-title mb-4">Agregar parqueo</h5>                            
                            <form #form="ngForm">
                                <div class="row px-4 py-0">
                                    <div class="col-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Nombre del lugar*</label>
                                                <input class="form-control" name="parking_name" id="parking_name"
                                                [(ngModel)]="parkingData.nombreParqueo" required placeholder="Nombre del lugar"/>
                                                <div class="err-msg" *ngIf="validation_msg('parking_name')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Fecha y hora de inicio*</label>
                                                <div class="row">
                                                    <div class="col-12 col-lg-8 my-2 my-lg-0">
                                                        <input type="date" onkeydown="return false" class="form-control" name="parking_date_start"
                                                            [(ngModel)]="parkingData.fechaInicio" required  placeholder="dd-mm-yyyy" [min]="minDate" [max]="maxDate" />
                                                        <div class="err-msg" *ngIf="validation_msg('parking_date_start')">Este campo es obligatorio.</div>
                                                    </div>
                                                    <div class="col-12 col-lg-4">
                                                        <input type="time" class="form-control clock-mozff" name="parking_hour_start"
                                                            [(ngModel)]="parkingData.horaInicio" required
                                                            placeholder="dd-mm-yyyy" />
                                                        <div class="err-msg" *ngIf="validation_msg('parking_hour_start')">Este campo es obligatorio.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Fecha y hora de finalización*</label>
                                                <div class="row">
                                                    <div class="col-12 col-lg-8 my-2 my-lg-0">
                                                        <input type="date" onkeydown="return false" class="form-control" name="parking_date_end"
                                                            [(ngModel)]="parkingData.fechaFin" required placeholder="dd-mm-yyyy" [min]="minDate" [max]="maxDate" />
                                                            <div class="err-msg" *ngIf="validation_msg('parking_date_end')">Este campo es obligatorio.</div>
                                                    </div>
                                                    <div class="col-12 col-lg-4">
                                                        <input type="time" class="form-control clock-mozff" name="parking_hour_end"
                                                            [(ngModel)]="parkingData.horaFin" required placeholder="dd-mm-yyyy" />
                                                            <div class="err-msg" *ngIf="validation_msg('parking_hour_end')">Este campo es obligatorio.</div>
                                                    </div>
                                                    <div class="err-msg" *ngIf="validation_date()"> La fecha y hora final no puede ser menor a la inicial.</div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Dirección*</label>
                                                <input class="form-control" name="parking_address" id="parking_address"
                                                    [(ngModel)]="parkingData.direccion" required placeholder="Dirección" />
                                                <div class="err-msg" *ngIf="validation_msg('parking_address')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Dirección Google Maps*</label>
                                                <input class="form-control" name="parking_maps"
                                                    [(ngModel)]="parkingData.enlaceUbicacion" placeholder="Dirección Google Maps" required autocomplete="OFF"/>
                                                <div class="err-msg" *ngIf="validation_msg('parking_maps')">Este campo es obligatorio.</div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label mb-3">Pertenece a ubicación*</label>
                                                <select class="form-control" name="locationSelect" [(ngModel)]="parkingData.locationId" required>
                                                    <option value="null" disabled>Seleccione...</option>
                                                    <option *ngFor="let x of listLocation" [value]="x.id">{{x.name}}</option>
                                                </select>
                                                <div class="err-msg" *ngIf="validation_msg('locationSelect')"> Este campo es obligatorio.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="row">
        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary px-4 my-2 btn-cancel-edituseradmin"
                (click)="goToPage('/admin/events/admin-ubicaciones')">Cancelar</button>
                &nbsp;
                <!-- <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" type="button"
                (click)="processData(active)" [disabled]="disabled || (form && (!form.valid || !form.touched)) || this.date_error"><i class="save-icon"></i>Guardar</button> -->
                <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" type="button"
                (click)="processData(active)"><i class="save-icon"></i>Guardar</button>
            </div>
        </div>
    </div>
</div>
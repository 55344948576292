import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  styleUrls: ['./loading.component.css'],
  templateUrl: './loading.component.html',
})

export class LoadingComponent implements OnInit {
  constructor(){}
  ngOnInit(){}
}

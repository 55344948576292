<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/users/admin-administradores')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12">
            <div class="card mb-5">
                <div class="card-body">
                    <h5 class="card-title mb-4">Información de usuario</h5>
                    <form #form="ngForm">
                        <div class="row p-4">
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-md-12 col-lg-6 mb-3">
                                        <label class="form-label mb-3">Primer nombre de usuario *</label>
                                        <input class="form-control" name="firstName" [(ngModel)]="user.firstName" required placeholder="Nombre del usuario" />
                                        <div class="err-msg" *ngIf="validation_msg('firstName')"> Este campo es obligatorio.</div>
                                    </div>
                                    <div class="col-md-12 col-lg-6 mb-3">
                                        <label class="form-label mb-3">Segundo nombre de usuario</label>
                                        <input class="form-control" name="secondName" [(ngModel)]="user.secondName" placeholder="Segundo nombre del usuario" />
                                    </div>
                                    <div class="col-md-12 col-lg-6 mb-3">
                                        <label class="form-label mb-3">Primer apellido de usuario*</label>
                                        <input class="form-control" name="firstLastname" [(ngModel)]="user.firstLastname" required placeholder="Apellido del usuario" />
                                        <div class="err-msg" *ngIf="validation_msg('firstLastname')"> Este campo es obligatorio.</div>
                                    </div>
                                    <div class="col-md-12 col-lg-6 mb-3">
                                        <label class="form-label mb-3">Segundo apellido de usuario</label>
                                        <input class="form-control" name="secondLastname" [(ngModel)]="user.secondLastname" placeholder="Segundo apellido del usuario" />
                                    </div>                        
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Cargo</label>
                                        <select class="form-control" name="puesto" [(ngModel)]="user.puestoId" (change)="onChangeJobs($event, 'puestoId')">
                                            <option value="null" disabled>Seleccione...</option>
                                            <option *ngFor="let x of jobList" [value]="x.id">{{x.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label class="form-label mb-3">Teléfono Empresa</label>
                                        <input type="text" class="form-control" name="businessPhone" [(ngModel)]="user.businessPhone" placeholder="Teléfono Empresa"
                                            pattern="[+]{0,1}[\d]+[ ]{0,1}[\d]+[-]{0,1}[\d]+"
                                            oninput="value = value.replace(/[^\-\+0-9 ]/,'')"/>
                                        <div class="err-msg" *ngIf="validation_msg('businessPhone')">
                                            {{form && form.controls['businessPhone'].errors!['pattern'] ? 'Teléfono no aceptado' : ''}}
                                        </div>
                                    </div>
                                    <!-- oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/,'')" -->
                                    <!-- pattern="[+]{0,1}[0-9]+[-]{0,1}[0-9]+" -->
                                    <!-- [+]{0,1}[\d]+[-]{0,1}[\d]+ -->
                                    <div class="col-12 col-md-6 mb-3">
                                        <label class="form-label mb-3">Celular</label>
                                        <input type="text" class="form-control" name="phone" [(ngModel)]="user.phone" placeholder="Celular"
                                            pattern="[+]{0,1}[\d]+[ ]{0,1}[\d]+[-]{0,1}[\d]+"
                                            oninput="value = value.replace(/[^\-\+0-9 ]/g,'')"/>
                                        <div class="err-msg" *ngIf="validation_msg('phone')">
                                            {{form && form.controls['phone'].errors!['pattern'] ? 'Teléfono no aceptado' : ''}}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <label class="form-label mb-3">Rol de usuario*</label>
                                        <select class="form-control" name="role" [(ngModel)]="user.roleId" required>
                                            <option value="null" disabled>Seleccione...</option>
                                            <option *ngFor="let x of roleList" [value]="x.id">{{x.name}}</option>
                                        </select>
                                        <div class="err-msg" *ngIf="validation_msg('role')"> Este campo es obligatorio.</div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label class="form-label mb-3">Departamento</label>
                                        <select class="form-control" name="area" [(ngModel)]="user.areaId" (change)="onChangeJobs($event, 'areaId')" placeholder="Seleccione...">
                                            <option value="null" disabled>Seleccione...</option>
                                            <option *ngFor="let x of areaList" [value]="x.id">{{x.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label class="form-label mb-3">Correo electrónico*</label>
                                        <input type="email" class="form-control" name="email" [(ngModel)]="user.email" required placeholder="Correo electrónico" />
                                        <div class="err-msg" *ngIf="validation_msg('email')"> Este campo es obligatorio.</div>
                                    </div>                        
                                    <!---<div class="col-12 col-md-6 mb-3">
                                        <label class="form-label mb-3">Nueva Contraseña</label>
                                        <div class="d-grid gap-2 mx-auto">
                                            <button type="button" class="btn btn-secondary w-100 btn-password" name="newPassword" (click)="newPassword()" [disabled]="true">Establecer nueva contraseña</button>
                                        </div>
                                    </div>---->
                                    <div class="col-12 col-md-6 mb-3" *ngIf="!creatingUser">
                                        <label class="form-label mb-3">Restablecer contraseña</label>
                                        <div class="d-grid gap-2 mx-auto">
                                            <button type="button" class="btn btn-secondary w-100 btn-password" name="recoverPassword" (click)="recoverPassword()" [disabled]="newUser">Enviar el enlace para restablecer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary px-4 my-2 btn-cancel-edituseradmin" (click)="goToPage('/admin/users/admin-administradores')">Cancelar</button>
                &nbsp;
                <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" type="button" (click)="submit()" [disabled]="form.invalid"><i class="save-icon"></i>Guardar</button>
            </div>
        </div>
    </div>
</div>

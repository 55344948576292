<app-loading *ngIf="loading"></app-loading>

<div class="container-fluid px-5 py-5" style="max-width: none;">
  <div class="row mb-3">
    <div class="col-12">
      <div class="card my-5">
        <div class="card-body">
          <h5 class="card-title mb-4">Reportes</h5>
            <div class="row px-4 py-0">
              <div class="col-12 col-md-5 mb-3">
                <label class="form-label mb-3">Seleccione la estadística</label>
                <select class="form-control" name="type" [(ngModel)]="statisticsSelected" (change)="statistics($event)">
                  <option *ngFor="let x of statisticsList">{{x}}</option>
                </select>
              </div>
              <div class="col-12 col-md-7 mb-3">
                <div class="row justify-content-end">
                  <div class="col-12 col-md-6">
                    <label class="form-label mb-3">Fecha de inicio</label>
                    <input type="date" onkeydown="return false" class="form-control" name="date_start" [(ngModel)]="date_start" onkeydown="return false"
                      [disabled]="statisticsSelected === 'Certificados de puntos cierre'" placeholder="dd-mm-yyyy" (ngModelChange)="onDateChange()" />
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="form-label mb-3">Fecha final</label>
                    <input type="date" onkeydown="return false" class="form-control" name="date_end" [(ngModel)]="date_end" onkeydown="return false"
                    [disabled]="statisticsSelected === 'Certificados de puntos cierre'" placeholder="dd-mm-yyyy" (ngModelChange)="onDateChange()" />
                  </div>
                </div>
                <div class="err-msg text-right" *ngIf="validation_date()"> La fecha final no puede ser menor a la inicial.</div>
              </div>
              <div class="col-12 col-md-5 mb-3">
                <label class="form-label mb-3">Nombre de la actividad</label>
                <ng-multiselect-dropdown *ngIf="dropdownFilterItemsAll.length <= 999"
                    class="custom-dropdown"
                    [placeholder]="'Seleccione...'"
                    [settings]="dropdownSettings"
                    [data]="dropdownFilterItems"
                    [(ngModel)]="selectedFilterItem"
                    (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onDeSelect()"
                    (onFilterChange)="dropdownFilterItemsAll"
                    (onDropDownClose)="onDropDownClose()"
                    [disabled]="dropdownFilterItemsAux.length <= 0 || (statisticsSelected == 'Seleccione' || statisticsSelected == 'Promoción' || statisticsSelected === 'Certificados de puntos cierre')">
                </ng-multiselect-dropdown>
                <ng-multiselect-dropdown *ngIf="dropdownFilterItemsAll.length >= 1000"
                    class="custom-dropdown"
                    [placeholder]="'Seleccione...'"
                    [settings]="dropdownSettings"
                    [data]="dropdownFilterItems"
                    [(ngModel)]="selectedFilterItem"
                    (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onDeSelect()"
                    (onFilterChange)="onFilterChange($event)"
                    (onDropDownClose)="onDropDownClose()"
                    [disabled]="dropdownFilterItemsAux.length <= 0 || (statisticsSelected == 'Seleccione' || statisticsSelected == 'Promoción' || statisticsSelected === 'Certificados de puntos cierre')">
                </ng-multiselect-dropdown>
                <!-- Old -->
                <!--   <select class="form-control" name="activity" [(ngModel)]="selectedFilter" (change)="changeFilter($event)"
                  [disabled]="dropdownFilterItems.length <= 1 || (statisticsSelected == 'Seleccione' || statisticsSelected == 'Promoción')">
                  <option value=null selected>Seleccione...</option>
                  <option *ngFor="let x of dropdownFilterItems" value="{{x.id}}">{{x.name}}</option>
                </select> -->
              </div>
              <div class="col-12 col-md-4 col-lg-5 mb-3">
                <label class="form-label mb-3">Estatus</label>
                <!-- (change)="statistics($event)" -->
                <select class="form-control"
                    name="status"
                    [(ngModel)]="statusSelected"
                      [disabled]="statisticsSelected === 'Constancia de asociado' || statisticsSelected === 'Certificado de puntos'">
                  <option *ngFor="let x of statusList" value="{{x.id}}">{{x.name}}</option>
                </select>
              </div>
              <div class="col-12 col-md-3 col-lg-2 mb-3 m-auto text-center">
                <!-- <button class="{{css_button}}" (click)="searchData(false)">Buscar</button> -->


                <button class="{{css_button}}"
                *ngIf="statisticsSelected === 'Seleccione' || statisticsSelected === undefined"
                disabled>Buscar</button>
              <button class="{{css_button}}" (click)="searchData(false)"
                *ngIf="statisticsSelected === 'Promoción' || statisticsSelected === 'Certificados de puntos cierre'">Buscar</button>
              <button class="{{css_button}}" (click)="searchData(false)"
                *ngIf="statisticsSelected === 'Constancia de asociado'"
                [disabled]="date_end == undefined || date_start == undefined || filter?.id === null">Buscar .</button>
              <button class="{{css_button}}" (click)="searchData(false)"
                *ngIf="statisticsSelected === 'Encuestas eventos' || statisticsSelected === 'Certificado de puntos' || statisticsSelected === 'Promoción en especifico'"
                [disabled]="filter?.id === null || (date_end == undefined || date_start == undefined)">Buscar</button>

                <button class="{{css_button}}" (click)="searchData(false)"
                *ngIf="statisticsSelected === 'Eventos'"
                [disabled]="!areDatesValid()">Buscar</button>

              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-1" *ngIf="categoriesHeading && categoriesHeading.length > 0">
    <div class="col-12">
      <div class="card my-5">
        <div class="card-body">
          <div class="col-12 mt-5 mb-2">
            <div class="table-default" style="overflow-x: auto;">
              <table class="content-table" border="1">
                <thead>
                  <tr>
                    <ng-container *ngFor="let each of categoriesHeading">
                      <th> {{each}} </th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr *ngFor="let item of list | tableFilter:filter!.name!:headerFilter;  let i = index"> -->
                  <tr *ngFor="let item of list;  let i = index">
                    <ng-container *ngFor="let x of item | keyvalue:originalOrder; let j = index">
                      <td class="group{{j}}" *ngIf="x.key != 'id' && x.key != 'Envío de certificado' && x.key != 'Certificado de puntos'"> {{x.value}} </td>

                      <td *ngIf="x.key === 'Certificado de puntos' || x.key === 'Envío de certificado'">
                        <button class="{{css_button}}" (click)="pointsCertificateAction(x.key, item)">{{x.value}}</button>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="table-pagination" *ngIf="selectedFilter <= 0 || (selectedFilter == null || selectedFilter == 'null')">
              <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
              <p class="pagination-text">Página {{pageNumber + 1}} de {{totalPages}}</p>
              <i class="btn-arrow btn-arrow-right" id="next" (click)="onRight()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5 justify-content-end" *ngIf="categoriesHeading && categoriesHeading.length > 0">
    <div class="col-3">
      <button (click)="downloadToExcel()" class="{{css_button}}">Descargar Reporte</button>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/users/admin-usuarios')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12">
            <div class="card mb-5">
                <div class="card-body">
                    <div class="row p-4 p-md-1">
                        <div class="col-12 col-lg-8">
                            <h5 class="card-title mb-4">Información de usuario</h5>
                        </div>
                                <p class="form-label mb-3">
                                    Estatus
                                    <span class="icon-container">
                                    <img src="./assets/images/icono-i.png" alt="Icono de Estado en CRM">
                                    <meta name="Estado en CRM" content="valor-del-estado">
                                    <span class="icon-tooltip">Estado en CRM</span>
                                    </span>
                                </p>
                        <div class="col-12 col-lg-2">
                            <select class="form-control btn-status_edituser" name="status" [(ngModel)]="user.status" disabled>
                                <option value="Activo">Activo</option>
                                <option value="Inactivo">Inactivo</option>
                            </select>
                        </div>
                    </div>
                    <form #form="ngForm">
                        <div class="row p-4">
                            <div class="col-md-12 col-lg-4 mb-3">
                                <label class="form-label mb-3">NIT de empresa</label>
                                <input class="form-control" name="companyNit" [(ngModel)]="company.nit" (change)="searchCompany(company.nit)" placeholder={{company.companyNITPlaceholder}}
                                    minlength="2" maxlength="10" pattern="([0-9]+[-][0-9kK])|(CF)" />
                                <div class="err-msg" *ngIf="validation_msg('companyNit')"> 
                                    {{form && form.controls['companyNit'].errors!['minlength'] ? 'Minimo caracteres 5.' : ''}}
                                    {{form && form.controls['companyNit'].errors!['pattern'] ? 'Nit no aceptado' : ''}}
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-8 mb-3">
                                <label class="form-label mb-3">Nombre comercial</label>
                                <input class="form-control" name="companyName" [(ngModel)]="company.nombreComercial" placeholder="Nombre comercial" disabled/>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Primer nombre de usuario*</label>
                                <input class="form-control" name="firstName" [(ngModel)]="user.firstName" required placeholder="Nombre del usuario" />
                                <div class="err-msg" *ngIf="validation_msg('firstName')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Segundo nombre de usuario</label>
                                <input class="form-control" name="secondName" [(ngModel)]="user.secondName" placeholder="Segundo nombre del usuario" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Primer apellido de usuario*</label>
                                <input class="form-control" name="firstLastname" [(ngModel)]="user.firstLastname" required placeholder="Apellido del usuario" />
                                <div class="err-msg" *ngIf="validation_msg('firstLastname')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Segundo apellido de usuario</label>
                                <input class="form-control" name="secondLastname" [(ngModel)]="user.secondLastname" placeholder="Segundo apellido del usuario" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Teléfono*</label>
                                <input type="text" class="form-control" name="phone" [(ngModel)]="user.phone" placeholder="00000000" required
                                    pattern="[+]{0,1}[\d]+[ ]{0,1}[\d]+[-]{0,1}[\d]+"
                                    oninput="value = value.replace(/[^\-\+0-9 ]/g,'')"/>
                                <div class="err-msg" *ngIf="validation_msg('phone')"> 
                                    {{form && form.controls['phone'].errors!['required'] ? 'Este campo es obligatorio. ' : ''}}
                                    {{form && form.controls['phone'].errors!['pattern'] ? 'Teléfono no aceptado.' : ''}}
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Rol de usuario*</label>
                                <select class="form-control" name="role" [(ngModel)]="user.roleId" required (change)="onChangeRole($event, 'select')">
                                    <option value="null" disabled>Seleccione...</option>
                                    <option *ngFor="let x of roleList" [value]="x.id">{{x.name}}</option>
                                </select>
                                <div class="err-msg" *ngIf="validation_msg('role')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Correo electrónico*</label>
                                <input type="email" class="form-control" name="email" [(ngModel)]="user.email" required placeholder="Correo electrónico" />
                                <div class="err-msg" *ngIf="validation_msg('email')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Correo electrónico adicional</label>
                                <input type="email" class="form-control" name="addtionalEmail" [(ngModel)]="user.addtionalEmail" placeholder="Correo electrónico adicional" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">NIT persona</label>
                                <input type="text" class="form-control" name="personalNIT" [(ngModel)]="user.personalNIT" placeholder="000000-0"/>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Fecha de nacimiento*</label>
                                <input type="date" onkeydown="return false" class="form-control calendario" name="birthday" [(ngModel)]="user.birthday" onkeydown="return false" required placeholder="dd-mm-yyyy" />
                                <div class="err-msg" *ngIf="validation_msg('birthday')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">Género*</label>
                                <select class="form-control" name="gender_id" required [(ngModel)]="user.gender_id">
                                    <option value="null" disabled>Seleccione...</option>
                                    <option value="1">Femenino</option>
                                    <option value="2">Masculino</option>
                                    <option value="3">Otro</option>
                                </select>
                                <div class="err-msg" *ngIf="validation_msg('gender_id')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <label class="form-label mb-3">País de Origen</label>
                                <select class="form-control" name="country_id" [(ngModel)]="user.country_id" required>
                                    <option value="null" disabled>Seleccione...</option>
                                    <option *ngFor="let x of countryList" [value]="x.id">{{x.name}}</option>
                                </select>
                                <div class="err-msg" *ngIf="validation_msg('country_id')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Área</label>
                                <select class="form-control" name="area" [(ngModel)]="this.user.areaId" (change)="onChangeJobs($event, 'areaId')" placeholder="Seleccione...">
                                    <option value="null" disabled>Seleccione...</option>
                                    <option *ngFor="let x of areaList" [value]="x.id">{{x.name}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Puesto</label>
                                <select class="form-control" name="puestoId" [(ngModel)]="this.user.puestoId" (change)="onChangeJobs($event, 'puestoId')">
                                    <option value="null" disabled>Seleccione...</option>
                                    <option *ngFor="let x of jobList" [value]="x.id">{{x.name}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label class="form-label mb-3">Sector</label>
                                <ng-multiselect-dropdown [placeholder]="'Sectores'" name="md_sector" id="md_sector"
                                            class="{{creatingUser == true ? 'enabled' : ''}}" [settings]="dropdownSettings" [data]="sectorList" [disabled]="true"
                                            [(ngModel)]="userSectors">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label class="form-label mb-3">Comisión</label>
                                <ng-multiselect-dropdown [placeholder]="'Comisiones'" name="md_comision" id="md_comision"
                                            class="{{creatingUser == true ? 'enabled' : ''}}" [settings]="dropdownSettings" [data]="comisionList" [disabled]="true"
                                            [(ngModel)]="userComision">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label class="form-label mb-3">Comité</label>
                                <ng-multiselect-dropdown [placeholder]="'Comites'" name="md_comite" id="md_comite"
                                            class="{{creatingUser == true ? 'enabled' : ''}}" [settings]="dropdownSettings" [data]="comiteList" [disabled]="true"
                                            [(ngModel)]="userComite">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-12 col-lg-2 mb-3">
                                <label class="form-label mb-3">Asociado</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" name="asociado_selection" id="associatedYes" [checked]="user.associated" (click)="changeCheck($event, 'associated', true)" [disabled]="true">
                                        <label class="form-check-label" for="associatedYes">Si</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" name="asociado_selection" id="associatedNot" [checked]="!user.associated" (click)="changeCheck($event, 'associated', false)"[disabled]="true">
                                        <label class="form-check-label" for="associatedNot">No</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2 mb-3">
                                <label class="form-label mb-3">Contacto titular</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" name="ownerContact" id="ownerContactYes" [checked]="user.ownerContact" (click)="changeCheck($event, 'owner', true)" [disabled]="false">
                                        <label class="form-check-label" for="ownerContactYes">Si</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" name="ownerContact" id="ownerContactNot" [checked]="!user.ownerContact" (click)="changeCheck($event, 'owner', false)" [disabled]="false">
                                        <label class="form-check-label" for="ownerContactNot">No</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3 text-center text-lg-left" *ngIf="!creatingUser">
                                <label class="form-label mb-3">Restablecer contraseña</label>
                                <div class="d-grid gap-2 mx-auto">
                                    <button type="button" class="btn btn-secondary w-70 btn-password" name="recoverPassword" (click)="recoverPassword()" [disabled]="false">Enviar el enlace para restablecer</button>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row p-4 mt-3">
                            <div class="col-12 mb-3">
                                <label class="form-label mb-4">Permisos</label>
                                <div class="row">
                                    <div class="col-12 col-lg-auto pr-0 ml-0" *ngFor="let permission of permissionsList; let i = index">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input mr-2" type="checkbox" name="_{{permission.name}}" [(ngModel)]="permissionsModel[permission.name]" (change)="onPermissionChange($event, permission.name)">
                                            <label class="form-check-label mr-3 txt-tiny" for="_{{permission.name}}">{{permission.label}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row p-4 mt-3">
                            <div class="col-12 mb-3">
                                <label class="form-label mb-3">Canales de comunicación</label>
                                <div class="row">
                                    <div class="col-12 col-lg-auto pr-0 ml-0" *ngFor="let channel of communicationChannelsList; let i = index">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input mr-2" type="checkbox" name="_{{channel.key}}" [(ngModel)]="communicationChannelsModel[channel.key]" (change)="onCommunicationsChange($event, channel.key)">
                                            <label class="form-check-label mr-3 txt-tiny" for="_{{channel.key}}">{{channel.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary px-4 my-2 btn-cancel-edituseradmin" (click)="goToPage('/admin/users/admin-usuarios')">Cancelar</button>
                &nbsp;
                <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" type="button" (click)="submit()" [disabled]="(!form.touched || !form.valid)"><i class="save-icon"></i>Guardar</button>
            </div>
        </div>
    </div>
</div>

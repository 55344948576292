import { NgForm } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/Services/user.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { CompanyService } from 'src/app/Services/company.service';
import { BackServiceCompanies, listas } from 'src/app/Models/back-service.model';
import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { ReportService } from 'src/app/Services/report.service';

interface Points {
  totalQtz: any,
  consumedPoints: any,
  pointsByNewUser: any,
  availablePoints: any,
  certifiedPoints: any,
  pointsByRegisterEvent: any,
}

@Component({
  selector: 'app-edit-empresa',
  templateUrl: './edit-empresa.component.html',
  styleUrls: ['./edit-empresa.component.css']
})

@Injectable()
export class EditEmpresaComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  public pageSize = 10;
  public pageNumber = 0;
  public totalPages = 0;
  public comite: {}[] = [];
  public comison: {}[] = [];
  public sectors: {}[] = [];
  public class: string = '';
  public companyId: number = 0;
  public jobsList: listas[] = [];
  public loading: boolean = false;
  public sectorsList: listas[] = [];
  public permission: boolean = false;
  public commissonsList: listas[] = [];
  public committeesList: listas[] = [];
  public permissionCRM: boolean = false;
  public companyUpdateMode: boolean = false;
  public permissionRole: string | undefined = '';
  public companylogo: undefined | string = undefined;

  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: false,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  };

  public points: Points = {
    totalQtz: '0.00',
    consumedPoints: '0',
    availablePoints: '0',
    certifiedPoints: '0',
    pointsByNewUser: '0',
    pointsByRegisterEvent: '0',
  }

  public userList: any[] = [];
  public categoriesHeading: string[] = ['Nombre del usuario', 'Código', 'Puesto', 'Teléfono', 'Fecha de creación'];

  fileStr: string = '';
  isShowDiv: boolean = true;
  companyData = new BackServiceCompanies();
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private reportService: ReportService,
    private companyService: CompanyService
  ) {
    this.companyData.newImage = { data: '', extension: '', url: '' };
  }

  async ngOnInit() {
    this.companyId = this.utilsService.getParam('ID') || 0;
    this.loadLists();
    if (this.companyId > 0) {
      await this.loadDatabyID();
      this.loadPointsByCompany();
      this.loadContactByCompany();
      this.companyUpdateMode = true;
    } else {
      this.companyData.status = 0;
      this.companyData.socio = false;
    }
    this.permission = await this.permissions();
    this.setDropdownSettings();
  }

  permissions(): boolean {
    this.permissionRole = sessionStorage.getItem('Role')?.toString();

    if (this.permissionCRM === true) {
      this.class = 'disabled';
      return false;
    } else if (this.permissionCRM === false && this.permissionRole === 'ROLE_SUPERADMIN') {
      return true;
    } else {
      this.class = 'disabled';
      return false;
    };
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setDropdownSettings() {
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: false,
      closeDropDownOnSelection: true,
      singleSelection: !this.companyUpdateMode
    };
  }

  changePartner(status: boolean) {
    this.companyData.socio = status;
    this.companyData.status = Number(status);
  }

  goToPageParams(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params);
  }

  showActions(event: any, index: number) {
    this.isShowDiv = true;
    const element = document.getElementById('item' + index);
    const listItes = document.getElementsByClassName('actions-enable')[0];
    if (listItes) {
      listItes.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItes?.id || !listItes.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  loadLists() {
    this.utilsService.getCommissions().then(
      (response) => {
        this.commissonsList = response.result.comisiones;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getCommittees().then(
      (response) => {
        this.committeesList = response.result.comites;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getSectors().then(
      (response) => {
        this.sectorsList = response.result.sectors;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getPuestos().then(
      (response) => {
        this.jobsList = response.result.puestos;
      },
      (error) => {
        console.log(error)
      });
  }

  findJob(job_id: number): string {
    const job = this.jobsList.find(x => x.id == job_id);
    return job?.name;
  }

  loadDatabyID() {
    this.loading = true;
    return this.companyService.getCompaniesbyID(this.companyId).then(
      async res => {
        console.log(res.result);
        this.companyData = res.result;
        this.companyData.socio = res?.result?.socio || false;
        this.companyData.status = this.companyData.socio ? 1 : 0;
        (res?.result?.codigoCRM != null) && (this.permissionCRM = true);

        if (res.result.image != null && res.result.image != '') {
          this.imageLoaded = true;
          this.imageSrc = res.result.image;
          this.companyData.newImage = { data: '', extension: '', url: res.result.image }
        } else {
          this.companyData.newImage = { data: '', extension: '', url: '' }
        }

        if (res.result.listComites.length > 0) {
          for (const e of res.result.listComites) {

            if (e.sector != null) {
              this.sectors = [...this.sectors];
              this.sectors.push({
                id: e.sector.id,
                name: e.sector.name,
                idCrm: e.sector.idCrm
              });
            }

            if (e.comite != null) {
              this.comite = [...this.comite];
              this.comite.push({
                id: e.comite.id,
                name: e.comite.name,
                idCrm: e.comite.idCrm
              });
            }

            if (e.comision != null) {
              this.comison = [...this.comison];
              this.comison.push({
                id: e.comision.id,
                name: e.comision.name,
                idCrm: e.comision.idCrm
              });
            }
          }
        }

        /* Anterior Metodo accountRelations
        if (res.result.accountRelations.length > 0) {
          this.userList = await res.result.accountRelations.map((e: any) => {
            return {
              id: e?.id,
              'Nombre del usuario': e?.users[0]?.firstName + ' ' + e?.users[0]?.firstName,
              'Código': e?.idCrm,
              'Puesto': e.puestoId ? this.findJob(e.puestoId) : '',
              'Teléfono': e?.phone,
              'Fecha de creación': e.createdAt ? this.utilsService.date_pipe(e.createdAt, 'dd/MM/yyy') : ''
            }
          });
          this.categoriesHeading = Object.keys(this.userList[0]);
        }
        //*/
      },
      e => {
        this.utilsService.dialog('ERROR', 'Error', 'Intentalo de nuevo mas tarde.');
      },
    ).finally(() => {
      this.loading = false;
    });
  }

  loadContactByCompany() {
    this.loading = true;
    this.userService.getContactByCompany(this.pageNumber, this.pageSize, this.companyId).then(
      response => {
        console.log(response.result);
        this.userList = response.result.map((e: any) => {
          return {
            'id': e?.id,
            'accountRelation': e?.accountRelations[0]?.id,
            'Nombre del usuario': `${e?.firstName || ''} ${e?.secondName || ''} ${e?.firstLastname || ''} ${e?.secondLastname || ''}`,
            'Código': e?.codigoCrm,
            'Puesto': e?.puesto?.name,
            'Teléfono': e?.phone,
            'Fecha de creación': this.utilsService.date_pipe(e?.fechaCreacion, 'dd-MM-yyyy')
          }
        });
        // console.log(this.userList);        
        this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      error => console.log(error)
    )
    this.loading = false;
  }

  loadPointsByCompany() {
    this.reportService.getPointsByCompany(this.companyId).then(
      response => {
        console.log(response.result);
        this.points.totalQtz = 'Q ' + (this.utilsService.number_pipe(response?.result?.totalQtz, '1.2-2') || '0');
        this.points.consumedPoints = this.utilsService.number_pipe(response?.result?.consumedPoints, '1.0-2') || '0';
        this.points.availablePoints = this.utilsService.number_pipe(response?.result?.availablePoints, '1.0-2') || '0';
        this.points.certifiedPoints = this.utilsService.number_pipe(response?.result?.certifiedPoints, '1.0-2') || '0';
        this.points.pointsByNewUser = this.utilsService.number_pipe(response?.result?.pointsByNewUser, '1.0-2') || '0';
        this.points.pointsByRegisterEvent = this.utilsService.number_pipe(response?.result?.pointsByRegisterEvent, '1.0-2') || '0';
      },
      error => console.log(error)
    )
  }

  downloadPointsByUsers() {
    this.loading = true;
    this.reportService.getPointsUserByCompany(this.companyId).then(
      async response => {
        console.log(response);
        if (response.result.puntos && response.result.puntos.length > 0) {
          const list = await response.result.puntos.map((e: any) => {
            return {
              'Correo electronico': e?.email,
              'Fecha': e?.createdAt,
              'Acción': e?.type,
              'Puntos': e?.points
            }
          });
          
          const reportName = 'PUNTOS - ' + this.companyData.nombreComercial + ' ' + this.utilsService.getDate('dd-MM-yyy');
          this.utilsService.downloadToExcel(list, 'Puntos', reportName, 'xlsx');
        } else {
          this.utilsService.dialog('ERROR', 'Alerta', 'No hay datos de puntos.');
        }
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || 'Hubo un error, contacte al administrador.');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.userList.length = 0;
      this.loadContactByCompany();
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.userList.length = 0;
      this.loadContactByCompany();
    }
  }

  deleteContact(accountRelation: number) {
    this.loading = true;
    this.userService.deletedContact(accountRelation).then(
      response => {
        console.log(response);
        this.loadContactByCompany();
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || 'Contacto eliminado');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async addFile(event: any) {
    const file = event.target.files[0];
    const extension = file.type.split('/');
    const base64 = await this.utilsService.convertBase64(event.target.files.item(0));

    if (typeof (base64) === 'string') {
      this.imageSrc = base64;
      this.imageLoaded = true;
      this.companyData.newImage!.url = base64;
      this.companyData.newImage!.data = base64;
      this.companyData.newImage!.extension = extension[1];
    }
  }

  mark_img(name: string) {
    setTimeout(() => {
      this.form.controls[name].markAsTouched();
    }, 500)
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }


  submitEvent() {
    // console.log(this.companyData);

    // validamos el campo personalNIT aquí antes de enviar los datos al servidor
    const regex = /^(?!-)[a-zA-Z0-9-]+(?<!-)$/;
    if (!regex.test(this.companyData.nit)) {
      // Mostramos un mensaje de error si la validación falla
      this.utilsService.dialog('ERROR', 'Error', 'El campo NIT, debe contener números, letras y guiones, y el guión no debe estar al principio ni al final..');
      return; // No continuamos con el envío de datos si la validación falla
    }


    if (this.form.valid) {
      this.companyId > 0 ? this.editCompany() : this.createCompany();
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos obligatorios.');
    }
  }

  createCompany() {
    this.loading = true;
    console.log("payload: ", this.companyData)
    return this.companyService.createCompany(this.companyData).then(
      async res => {
        // console.log(res);
        if (res.success === true) {
          this.utilsService.dialog('SUCCESS', 'Éxito', res.message.description, '/admin/users/admin-empresas');
        } else {
          this.utilsService.dialog('ERROR', 'Error', res.message.description);
        }
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
        throw new Error(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  editCompany() {
    this.loading = true;
    let { ...companyData } = this.companyData;
    if (this.companyData.newImage?.data == '') {
      delete companyData.newImage;
    }
    delete companyData.image;

    return this.companyService.updateComapny(this.companyId, companyData).then(
      async res => {
        // console.log(res);
        if (res.success === true) {
          this.utilsService.dialog('SUCCESS', 'Éxito', res.message.description, '/admin/users/admin-empresas');
        } else {
          this.utilsService.dialog('ERROR', 'Error', res.message.description);
        }
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
        throw new Error(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }
}

import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/user.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { SpeakerService } from 'src/app/Services/speaker.service';
import { CompanyService } from 'src/app/Services/company.service';
import { SponsorService } from 'src/app/Services/sponsor.service';
import { ExhibitorService } from 'src/app/Services/exhibitors.service';
import { ParticipantService } from 'src/app/Services/participant.service';
import { BackServiceUsers, BackServiceParticipants, userbyEvent } from 'src/app/Models/back-service.model';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})

export class UserGuideComponent implements OnInit {
  public pageSize = 15;
  public pageNumber = 0;
  public totalPages = 0;
  public usersList: any;
  public params: {} = {};
  public list: any[] = [];
  public typeGuide: string = '';
  public nameFilter: string = '';
  public motherRoute: string = '';
  public loading: boolean = false;
  public filter: string | null = '';
  public eventID: number | undefined = 0;
  public categoriesHeading: string[] = [];
  public companyID: number | undefined = 0;
  public usersData = new BackServiceUsers();
  public userbyEventData = new userbyEvent();
  public participantsData = new BackServiceParticipants();

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private speakerService: SpeakerService,
    private companyService: CompanyService,
    private sponsorService: SponsorService,
    private exhibitorService: ExhibitorService,
    private participantService: ParticipantService
  ) { }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  ngOnInit() {
    this.typeGuide = this.utilsService.getParam('type');
    this.companyID = Number(this.utilsService.getParam('company_id')) || 0;
    this.eventID = Number(this.utilsService.getParam('event_id')) || 0; // Number(sessionStorage.getItem('EventID'));

    if (this.eventID > 0 || this.companyID > 0) {
      this.loadData(this.typeGuide, false);
      this.eventID > 0 ? (this.motherRoute = '/admin/events/admin-', this.params = {}) :
        this.companyID > 0 ? (this.motherRoute = '/admin/users/edit-', this.params = { ID: this.companyID }) : '';
    } 
    else if (this.eventID == 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado una empresa');
      this.utilsService.goToPage('/login');
    }
  }

  loadData(type_Guide: string, search: boolean, page?: number | null, size?: number | null, event_id?: number, name?: string | null,) {
    switch (this.typeGuide) {
      case "participantes":
        this.nameFilter = 'usuario';
        this.loadUser(this.pageNumber, this.pageSize, this.filter, search);
        this.categoriesHeading = ['Nombre usuario', 'Nombre Empresa', 'Correo electrónico'];
        break;
      case "conferencistas":
        this.nameFilter = 'usuario';
        this.categoriesHeading = ['Nombre usuario', 'Nombre Empresa', 'Correo electrónico'];
        this.loadParticipant(this.pageNumber + 1, this.pageSize, this.eventID!, this.filter, search);
        // this.loadParticipant(this.pageNumber, this.pageSize, this.eventID!, this.filter, search);
        break;
      case "expositores":
        this.nameFilter = 'empresa';
        this.categoriesHeading = ['Nombre comercial', 'Razón social', 'Correo electrónico'];
        this.loadCompanyExhibitors(this.pageNumber, this.pageSize, this.eventID!, this.filter, search);
        break;
      case "patrocinadores":
        this.nameFilter = 'empresa';
        this.categoriesHeading = ['Nombre comercial', 'Razón social', 'Correo electrónico'];
        this.loadCompanySponsor(this.pageNumber, this.pageSize, this.eventID!, this.filter, search);
        break;
      case "empresas":
        this.nameFilter = 'usuario';
        this.loadUser(this.pageNumber, this.pageSize, this.filter, search);
        this.categoriesHeading = ['Nombre usuario', 'Nombre Empresa', 'Correo electrónico'];
        break;
      default:
        this.utilsService.dialog('ERROR', 'Error', 'Error al cargar modulo');
        this.utilsService.goToPage('/admin/inicio');
        break;
    }
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName + this.typeGuide, params);
  }

  onLeft(type_Guide: string) {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadData(this.typeGuide, false);
    }
  }

  onRight(type_Guide: string) {
    // if (this.pageNumber + 1 <= this.totalPages) {}
    // if ((this.pageNumber + 1) != this.totalPages || (this.typeGuide === 'participantes' && (this.pageNumber + 1) <= this.totalPages)) {
    if ((this.pageNumber + 1) < this.totalPages || (this.typeGuide === 'participantes' && (this.pageNumber + 1) < this.totalPages)) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadData(this.typeGuide, false);
    }
  }

  searchReturn() {
    if (this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      this.loadData(this.typeGuide, false);
    }
  }

  async loadUser(page: number | null, size: number | null, filter: string | null, search?: boolean) {
    this.loading = true;
    let response: any;

    if (this.filter != null && this.filter != '') {
      (search) && (this.pageNumber = 0);
      response = await this.userService.searchAllUsers(this.pageNumber, this.pageSize, null, this.filter);
      // response = await this.userService.searchAllUsers(this.pageNumber, this.pageSize, 'users', this.filter);
    } else {
      response = await this.userService.getAllUsers(this.pageNumber, this.pageSize, null, null, null, null, null);
    }

    if (response.success && response.success == true) {
      if (this.typeGuide === 'participantes') {
        this.list = response.result.map((e: any) => {
          return {
            id: e.id,
            'Nombre usuario': `${e.firstName || ''} ${e.secondName || ''} ${e.firstLastname || ''} ${e.secondLastname || ''}`,
            'Nombre Empresa': e.company?.nombreComercial,
            'Correo electrónico': e.email,
            exists: e.participantEvents != null ? e.participantEvents.includes(this.eventID) : false
          }
        });
      }
      else if (this.typeGuide === 'empresas') {
        console.log(response.result);
        // const result_filter = await response.result.filter((e: any) => e.role != 'ROLE_SUPERADMIN' && e.role != 'ROLE_ADMIN' && e.role != 'ROLE_ORGANIZADOR_EVENTOS');

        this.list = response.result.map((e: any) => {
          let companies = '';
          e.accountRelations.forEach((i: any) => {
            (i?.company?.nombreComercial) && (companies += i.company.nombreComercial + '; ');
          });

          let type: any;
          let accountRelation: any;

          if (e.accountRelations.length == 0) {
            type = 'add';
            accountRelation = undefined;
          }
          else if (e.accountRelations.length == 1) {                                   // 0_clean
            (e.accountRelations[0].companyId == this.companyID) ? (type = '0_deleted', accountRelation = e.accountRelations[0].id) :
              (e.accountRelations[0].companyId == null) ? (type = 'update', accountRelation = e.accountRelations[0].id) :
                (type = 'add');

          }
          else if (e.accountRelations.length >= 2) {
            const search_ar = e.accountRelations.find((e: any) => e.companyId === this.companyID);

            (e.accountRelations[0].companyId == null) ? (type = 'update', accountRelation = e.accountRelations[0].id) :
              (e.accountRelations[0].companyId == this.companyID) ? (type = 'deleted', accountRelation = e.accountRelations[0].id) :
                (search_ar != undefined) ? (type = 'deleted', accountRelation = search_ar.id) : (type = 'add');
          }
          return {
            id: e.id,
            'accountRelation': accountRelation,
            'Nombre usuario': `${e.firstName || ''} ${e.secondName || ''} ${e.firstLastname || ''} ${e.secondLastname || ''}`,
            'Nombre Empresa': companies.slice(0, companies.length - 2),
            'Correo electrónico': e.email,
            exists: type == 'deleted' || type == '0_deleted' ? true : false,
            type: type,
            disabled: e.role != 'ROLE_SUPERADMIN' && e.role != 'ROLE_ADMIN' && e.role != 'ROLE_ORGANIZADOR_EVENTOS' ? false : true
          }
        });
      }
      console.log(this.list);

      this.totalPages = Math.ceil(response.count / this.pageSize);
    } else {
      this.utilsService.dialog('ERROR', 'Error', response?.error?.message?.description || '¡Error al consultar usuarios!');
    }
    this.loading = false;
  }

  loadParticipant(page: number | null, size: number | null, event_id: number, participant_name: string | null, search?: boolean) {
    this.loading = true;

    if (search) {
      page = 1;
      this.pageNumber = 1; //0;
    }

    return this.participantService.getParticipantsByEvent(page, size, event_id, participant_name).then(
      response => {
        // console.log(response);
        this.list = response.result.map((e: any) => {
          return {
            id: e.id,
            'Nombre usuario': e?.nombreUsuario,
            'Nombre Empresa': e?.nombreEmpresa,
            'Correo electrónico': e?.email,
            exists: e?.speakers.length > 0
          }
        });
        this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  loadCompanySponsor(page: number | null, size: number | null, event_id: number, company_name: string | null, search?: boolean) {
    this.loading = true;

    if (search) {
      page = 0;
      this.pageNumber = 0;
    }

    this.companyService.companiesSponsorByEvent(page, size, event_id, company_name).then(
      response => {
        console.log(response);
        this.list = response.result.map((e: any) => {
          return {
            id: e.id,
            'Nombre Comercial': e?.nombreComercial,
            'Razón Social': e?.razonSocial,
            'Correo electrónico': e?.emailEmpresarial,
            exists: e.sponsor ? true : false,
            sponsorId: e.sponsorId ? e.sponsorId : null,
          }
        });
        this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  loadCompanyExhibitors(page: number | null, size: number | null, event_id: number, company_name: string | null, search?: boolean) {
    this.loading = true;

    if (search) {
      page = 0;
      this.pageNumber = 0;
    }

    this.companyService.companiesExhibitorsByEvent(page, size, event_id, company_name).then(
      response => {
        console.log(response);
        this.list = response.result.map((e: any) => {
          return {
            id: e.id,
            'Nombre Comercial': e?.nombreComercial,
            'Razón Social': e?.razonSocial,
            'Correo electrónico': e?.emailEmpresarial,
            exists: e.exhibitor ? true : false,
            exhibitorId: e.exhibitorId ? e.exhibitorId : null
          }
        });
        this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async addAction(type_Guide: string, id: number) {
    let json: any;
    let response: any;
    this.loading = true;

    try {
      switch (this.typeGuide) {
        case "participantes":
          json = { eventId: this.eventID, userId: id };
          response = await this.participantService.addParticipantToEvent(json);
          break;
        case "conferencistas":
          json = { eventId: this.eventID, participantId: id };
          response = await this.speakerService.addSpeaker(json);
          break;
        case "expositores":
          json = { companyId: id, eventId: this.eventID, registered: false };
          response = await this.exhibitorService.addExhibitor(json);
          break;
        case "patrocinadores":
          json = { eventId: this.eventID, companyId: id };
          response = await this.sponsorService.createSponsorToEvent(json);
          break;
        case "empresas":
          const user = await this.list.find((x: any) => x.id === id);

          user.type == 'add'
            ? json = { accountRelation: [{ companyId: this.companyID }] }
            : json = { accountRelation: [{ id: user.accountRelation, ownerContact: false, companyId: this.companyID }] };
          // json = { accountRelation: [{ companyId: this.companyID }] } // OLD
          response = await this.userService.addContact(id, json);
          break;
        default:
          response = { error: { message: { title: 'Alerta', description: '¡Método no implementado!' } } };
          break;
      }

      if (response.success === true) {
        this.loading = false;
        this.utilsService.dialog('SUCCESS', response?.message?.title, response?.message?.description);
        this.loadData(this.typeGuide, false);
      } else {
        this.loading = false;
        this.utilsService.dialog('ERROR', response.error?.message?.title, response.error?.message?.description);
      }
    } catch (error: any) {
      // console.log(error)
      this.loading = false;
      this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || 'Faltan datos para completar la operación.');
    }
  }

  async removeAction(type_Guide: string, id: number) {
    let json: any;
    let response: any;
    this.loading = true;

    try {
      switch (this.typeGuide) {
        case "participantes":
          response = await this.participantService.deleteParticipantByUser(this.eventID!, id);
          break;
        case "conferencistas":
          response = await this.speakerService.deleteSpeakerByParticipant(this.eventID!, id);
          break;
        case "expositores":
          const exhibitor = await this.list.find((x: any) => x.id === id);
          response = await this.exhibitorService.deleteExhibitorsToEvent(exhibitor.exhibitorId);
          break;
        case "patrocinadores":
          const sponsor = await this.list.find((x: any) => x.id === id);
          response = await this.sponsorService.deleteSponsorToEvent(sponsor.sponsorId);
          break;
        case "empresas":
          const user = await this.list.find((x: any) => x.id === id);
          console.log(user.type);
          if (user.type == 'deleted') {
            response = await this.userService.deletedContact(user.accountRelation)
          } else {
            json = { accountRelation: [{ id: user.accountRelation, ownerContact: false, companyId: null }] };
            response = await this.userService.updateContact(id, json);
          }
          console.log(json);
          // const accountRelation = await this.list.find((x: any) => x.id === id); // OLD
          // json = { accountRelation: [{ id: accountRelation.accountRelations, companyId: null }] } // OLD
          break;
        default:
          response = { error: { message: { title: 'Alerta', description: '¡Método no implementado!' } } };
          break;
      }

      if (response?.success === true) {
        this.utilsService.dialog('SUCCESS', response?.message?.title, response?.message?.description);
        this.loadData(this.typeGuide, false);
        this.loading = false;
      } else {
        this.loading = false;
        this.utilsService.dialog('ERROR', response?.error?.message?.title, response?.error?.message?.description);
      }
    } catch (error: any) {
      console.log(error)
      this.loading = false;
      this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || 'Error al añadir registro.');
    }
  }
}

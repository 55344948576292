import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UtilsService } from 'src/app/Services/utils.service';
import { SpeakerService } from 'src/app/Services/speaker.service';
import { AddDialogComponent } from 'src/app/Modales/add-dialog/add-dialog.component';
import { BackServiceSpeakers } from 'src/app/Models/back-service.model';

import { ParticipantService } from 'src/app/Services/participant.service';


@Component({
  selector: 'app-admin-conferencistas',
  templateUrl: './admin-conferencistas.component.html',
  styleUrls: ['./admin-conferencistas.component.css']
})
export class AdminConferencistasComponent implements OnInit {
  public totalPages = 0;
  public filtro: string = '';
  public eventID: number = 0;
  public pageSize: number = 20;
  public pageNumber: number = 0;
  public loading: boolean = true;
  public speakerData = new BackServiceSpeakers();
  public categoriesHeading: string[] = [];
  public list: any[] = [
    { "Nombre conferencista": "", "Profesión": "", "Correo electrónico": "", "Registro Participación": "" }
  ];

  constructor(
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private speakerService: SpeakerService,
    private participantService: ParticipantService
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
  }

  ngOnInit(): void {
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    this.eventID = Number(sessionStorage.getItem('EventID')) || 0;

    if (token && this.eventID > 0) {
      this.loadSpeakerbyEvent(this.eventID);
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes acceso a esta página, por favor ingresa con un usuario correcto.');
      this.utilsService.goToPage('/login');
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params);
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));

    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadSpeakerbyEvent(this.eventID);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadSpeakerbyEvent(this.eventID);
    }
  }

  loadSpeakerbyEvent(event_id: number) {
    this.loading = true;
    return this.speakerService.getSpeakerByEvent(event_id).then(
      response => {
        console.log(response)
        if (response.success === true) {
          this.list = response.result.map((e: any) => {
            return {
              id: e.id,
              participantId: e?.participantId,
              'Nombre conferencista': e.participant?.nombreUsuario, // e.nombreConferencista,
              'Profesión': e.profesion,
              'Correo electrónico': e.participant?.email,
              'Estatus': (Number(Boolean(e?.participant?.registro))).toString()
              // 'Estatus': Number(Boolean( e.participant?.registro)) 
            }
          });
          // console.log(this.list);
          this.totalPages = Math.ceil(response.result.length / this.pageSize);
        } else {
          console.log('error 1', response);
          this.utilsService.dialog('ERROR', 'Error', '¡Error al consultar la agenda de evento!');
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', 'Error al cargar agenda!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  editSpeaker(speaker_id: number) {
    this.loading = true;
    // console.log(speaker_id);
    this.speakerService.getOneSpeaker(speaker_id).then(
      (response) => {
        console.log(response.result);
        const modalRef = this.modalService.open(AddDialogComponent, { size: 'lg' });
        modalRef.componentInstance.job = response.result?.job_title;
        modalRef.componentInstance.image = response.result?.profile_image;
        modalRef.componentInstance.speaker_id = response.result?.speaker_id;
        modalRef.componentInstance.information = response.result?.information;
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }).finally(() => {
        this.loading = false;
      });
  }

  updateRegister($event: any, index: number, participant_id: number) {
    this.loading = true;
    const register = Boolean(Number($event.target.value))
    
    this.participantService.updateParticipantByEvent(participant_id, { registro: register }).then(
      response => {
        console.log(response)
        this.list[index].Estatus = '1';
        const element = document.getElementById('registro_' + index)!;
        element.classList.remove('select-disable');
        this.utilsService.dialog('SUCCESS', 'Éxito', 'Participante actualizado.');
      },
      error => {
        console.log(error);
        $event.target.value = '0';
        this.list[index].Estatus = '0';
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }


  updateAttendance($event: any, index: number, participant_id: number) {
    this.loading = true;
    const attendance = Boolean(Number($event.target.value))

    this.speakerService.updateSpeakerByEvent(participant_id, this.eventID).then(
      response => {
        if (response.result.status === 200) {
          this.list[index].Asistencia = 'confirmado';
          const element = document.getElementById('asistance_' + index)!;
          element.classList.remove('select-disable'); // element?.classList.add('actions-enable');
          this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description);
        } else {
          this.utilsService.dialog('ERROR', 'Error', response.error.message.description);
        }
      },
      error => {
        // console.log('error: ', error);
        this.list[index].Asistencia = 'no_confirmado'; // '0';
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }


  // updateRegisterTWO($event: any, index: number, speaker_id: number) {
  //   this.loading = true;

  //   const register = $event.target.value === 'Registrado' ? true : false;
  //   const JSON = {
  //     registro: register, 
  //     eventId: this.eventID
  //   }

  //   this.speakerService.updateSpeakerByEvent(speaker_id, JSON).then(
  //     response => {
  //       console.log('Exito', response)
  //       this.utilsService.dialog('SUCCESS', 'Éxito', 'Conferencista actualizado.');
  //     },
  //     error => {
  //       console.log('error', error)
  //       this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');

  //       if ($event.target.value == 'Sin Registro') {
  //         $event.target.value = 'Registrado'
  //         this.list[index].Estatus = 'Registrado'
  //       } else {
  //         $event.target.value = 'Sin Registro'
  //         this.list[index].Estatus = 'Sin Registro'
  //       }        
  //     }
  //   ).finally(() => {
  //     this.loading = false;
  //   });
  // }

  deleteSpeaker(speaker_id: number) {
    this.loading = true;
    this.speakerService.deleteSpeaker(speaker_id).then(
      async (response) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', '¡La activida se elimino!');
        await this.loadSpeakerbyEvent(this.eventID);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡La activida no se elimino!');
      }).finally(() => {
        this.loading = false
      });
  }
}

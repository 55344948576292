import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Login/login.component';
import { PasswordRecoveryComponent } from "./Password/password-recovery/password-recovery.component";
import { PasswordRecoveryCodeComponent } from './Password/password-recovery-code/password-recovery-code.component';
import { NewPasswordComponent } from './Password/new-password/new-password.component';
import { CalendarioEventosComponent } from './Eventos/calendario-eventos/calendario-eventos.component';
import { NuevoEventoComponent } from './Eventos/nuevo-evento/nuevo-evento.component';
import { AdminUsuariosComponent } from './Usuarios/admin-usuarios/admin-usuarios.component';
import { EditUsuarioComponent } from './Usuarios/edit-usuario/edit-usuario.component';
import { AdminAdminsComponent } from './Administradores/admin-admins/admin-admins.component';
import { EditAdminsComponent } from './Administradores/edit-admins/edit-admins.component';
import { AdminEmpresasComponent } from './Empresas/admin-empresas/admin-empresas.component';
import { EditEmpresaComponent } from './Empresas/edit-empresa/edit-empresa.component';
import { AdminAgendaComponent } from './Agenda/admin-agenda/admin-agenda.component';
import { EditAgendaComponent } from './Agenda/edit-agenda/edit-agenda.component';
import { AdminParticipantesComponent } from './Participantes/admin-participantes/admin-participantes.component';
import { AdminConferencistasComponent } from './Conferencistas/admin-conferencistas/admin-conferencistas.component';
import { EditConferencistaComponent } from './Conferencistas/edit-conferencista/edit-conferencista.component';
import { AdminConferenciasComponent } from './Conferencias/admin-conferencias/admin-conferencias.component';
import { EditConferenciasComponent } from './Conferencias/edit-conferencias/edit-conferencias.component';
import { HomeComponent } from './Home/home.component';
import { UserGuideComponent } from './Usuarios/user-guide/user-guide.component';
import { AdminStandsComponent } from './Stands/admin-stands/admin-stands.component';
import { EditStandsComponent } from './Stands/edit-stands/edit-stands.component';
import { AdminExpositoresComponent } from './Expositores/admin-expositores/admin-expositores.component';
import { MainMenuComponent } from './Menu/main-menu/main-menu.component';
import { UsersMenuComponent } from './Menu/users-menu/users-menu.component';
import { EventoMenuComponent } from './Menu/evento-menu/evento-menu.component';
import { AdminGaleriaComponent } from './Galeria/admin-galeria/admin-galeria.component';
import { AdminUbicacionesComponent } from './Ubicaciones/admin-ubicaciones/admin-ubicaciones.component';
import { EditUbicacionesComponent } from './Ubicaciones/edit-ubicaciones/edit-ubicaciones.component';
import { AdminPatrocinadoresComponent } from './Patrocinadores/admin-patrocinadores/admin-patrocinadores.component';
import { EditPatrocinadoresComponent } from './Patrocinadores/edit-patrocinadores/edit-patrocinadores.component';
import { AdminEncuestasComponent } from './Encuestas/admin-encuestas/admin-encuestas.component';
import { ReporteriaComponent } from './Reportes/reporteria/reporteria.component';
import { AdminNotificacionesComponent } from './Notificaciones/admin-notificaciones/admin-notificaciones.component';
import { EditNotificacionComponent } from './Notificaciones/edit-notificacion/edit-notificacion.component';
import { AdminPromocionesComponent } from './Promociones/admin-promociones/admin-promociones.component';
import { EditPromocionComponent } from './Promociones/edit-promocion/edit-promocion.component';
import { UserGuardGuard } from './Core/guard/user-guard.guard';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: "recovery", component: PasswordRecoveryComponent },
  { path: "code-recovery", component: PasswordRecoveryCodeComponent },
  { path: "new-password", component: NewPasswordComponent },
  {
    path: 'admin', component: MainMenuComponent, children: [
      { path: "inicio", component: HomeComponent },
      {
        path: 'users', component: UsersMenuComponent, children: [
          { path: "admin-usuarios", component: AdminUsuariosComponent, canActivate: [UserGuardGuard] },
          { path: "edit-usuario", component: EditUsuarioComponent, canActivate: [UserGuardGuard] },
          { path: "admin-administradores", component: AdminAdminsComponent, canActivate: [UserGuardGuard] },
          { path: "edit-administradores", component: EditAdminsComponent, canActivate: [UserGuardGuard] },
          { path: "admin-empresas", component: AdminEmpresasComponent, canActivate: [UserGuardGuard] },
          { path: "edit-empresas", component: EditEmpresaComponent, canActivate: [UserGuardGuard] },
          { path: "user-guide", component: UserGuideComponent, canActivate: [UserGuardGuard] },
          { path: '**', component: AdminEmpresasComponent },
        ]
      },          
      {
        path: 'events', component: EventoMenuComponent, children: [
          { path: "calendario-eventos", component: CalendarioEventosComponent },
          { path: "nuevo-evento", component: NuevoEventoComponent },
          { path: "admin-agenda", component: AdminAgendaComponent },
          { path: "edit-agenda", component: EditAgendaComponent },
          { path: "admin-participantes", component: AdminParticipantesComponent },
          { path: "admin-conferencistas", component: AdminConferencistasComponent },
          { path: "edit-conferencistas", component: EditConferencistaComponent },
          { path: "admin-conferencias", component: AdminConferenciasComponent },
          { path: "edit-conferencias", component: EditConferenciasComponent },
          { path: "user-guide", component: UserGuideComponent },
          { path: "admin-stands", component: AdminStandsComponent },
          { path: "edit-stands", component: EditStandsComponent },
          { path: "admin-expositores", component: AdminExpositoresComponent },
          { path: "admin-galeria", component: AdminGaleriaComponent },
          { path: "admin-ubicaciones", component: AdminUbicacionesComponent },
          { path: "edit-ubicaciones", component: EditUbicacionesComponent },
          { path: "admin-patrocinadores", component: AdminPatrocinadoresComponent },
          { path: "edit-patrocinadores", component: EditPatrocinadoresComponent, canActivate: [UserGuardGuard] },
          { path: "admin-encuestas", component: AdminEncuestasComponent },
          { path: '**', component: CalendarioEventosComponent },
        ]
      },
      { path: "reportes", component: ReporteriaComponent },
      { path: 'admin-promociones', component: AdminPromocionesComponent},
      { path: "edit-promocion", component: EditPromocionComponent},
      { path: "notificaciones", component: AdminNotificacionesComponent },
      { path: "edit-notificacion", component: EditNotificacionComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }

<!-- <div class="container-fluid px-5 pt-4" style="z-index: 5;"> -->
<div class="content-filter">
  <div class="col-8">
  </div>
  <div class="col-4 bg-white">
    <div class="row">
      <!-- TITLE -->
      <div class="col-12">
        <div class="row">
          <div class="col-9">
            <h5>Filtros</h5>
          </div>
          <div class="col-3">
            <a>X</a>
          </div>
        </div>
      </div>
      <!-- INPUTS -->
      <div class="col-12 mb-3">
        <!-- [(ngModel)]=""" -->
        <label class="form-label mb-3">Código</label>
        <input class="form-control" name="codigo" placeholder="Código" />
      </div>
      <div class="col-12 mb-3">
        <!-- [(ngModel)]=""" -->
        <label class="form-label mb-3">NIT</label>
        <input class="form-control" name="nit" placeholder="NIT" />
      </div>
      <div class="col-12 mb-3">
        <!-- [(ngModel)]=""" -->
        <label class="form-label mb-3">Estatus de empresa</label>
        <input type="radio" class="form-control" name="status" />
        <input type="radio" class="form-control" name="status" />
      </div>


      <!-- BUTTON -->
      <div class="col-12 text-center px-5">
        <div class="row px-4 py-0">
          <div class="col-6 mb-3">
            <button type="button" class="btn btn-outline-secondary px-4 my-2">Limpiar</button>
          </div>
          <div class="col-6 mb-3">
            <button type="button" class="btn btn-outline-secondary px-4 my-2">Buscar</button>
          </div>
        </div>
      </div>
      <!-- END -->
    </div>
  </div>
  <!--
<div class="modal-overlay">
  <div class="modal-container">
    <div class="modal-header">
      <h3>Filtros</h3>
    </div>
    <hr />
    <div class="modal-content">
      <ng-container *ngIf="currentView === 'empresas'; else usersFilters">
        < !-- Vista empresas Filtros -- >
        <input type="text" placeholder="Código" />
        <input type="text" placeholder="NIT" />
        <input type="text" placeholder="Sector" />
        <input type="text" placeholder="Comisión" />
        <label for="activo">Activo</label>
        <input type="radio" id="activo" name="estado" value="activo" />
        <label for="inactivo">Inactivo</label>
        <input type="radio" id="inactivo" name="estado" value="inactivo" />
      </ng-container>
      <ng-template #usersFilters>
        < !-- Vista usuarios filtros -- >
        <input type="text" placeholder="Código" />
        <input type="text" placeholder="NIT" />
        <input type="text" placeholder="Sector" />
        <input type="text" placeholder="Comisión" />
        <label for="activo">Activo</label>
        <input type="radio" id="activo" name="estado" value="activo" />
        <label for="inactivo">Inactivo</label>
        <input type="radio" id="inactivo" name="estado" value="inactivo" />
      </ng-template>

      <div class="radio-buttons">
        <label>
          <input type="radio" name="status" value="activo" /> Activo
        </label>
        <label>
          <input type="radio" name="status" value="inactivo" /> Inactivo
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn-clear" (click)="clearFilters()">Limpiar</button>
      <button class="btn-search" (click)="search()">Buscar</button>
    </div>
  </div>
</div>
-->
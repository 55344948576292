import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UserService } from 'src/app/Services/user.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

interface Filters {
  status: string | null,
  comiteId?: number | null,
  sectorId?: number | null,
  codigoCRM: string | null,
  userRole?: string | null,
  puestoId?: number | null,
  roleName?: string | null,
  comisionId?: number | null,
  personalNIT: string | null,
}
@Component({
  selector: 'app-admin-admins',
  templateUrl: './admin-admins.component.html',
  styleUrls: ['./admin-admins.component.css']
})
export class AdminAdminsComponent implements OnInit {
  public pageSize: number = 20;
  public pageNumber: number = 0;
  public totalPages: number = 0;
  public loading: boolean = false;
  public filter: string | null = null;
  public categoriesHeading: string[] = [];
  public list: any[] = [
    { 'Nombre del usuario': '', 'Nombre': '', 'Correo electrónico': '', 'Rol de usuario': '' }
  ];

  constructor(
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    // const roles = await this.utilsService.getRoles(); //getRolesAdmin();
    this.loadData(this.pageNumber, this.pageSize, this.filter, false);
    this.loading = false;
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false);
    }
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params);
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  async searchReturn() {
    if (this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      await this.loadData(this.pageNumber, this.pageSize, null, false);
    }
  }

  async loadData(page: number | null, size: number | null, filter: string | null, search?: boolean, filters?: any) {
    this.loading = true;
    let response: any;

    (search) && (this.pageNumber = 0);
    if (this.filter != null && this.filter != '') {
      this.isFilter = false;
      response = await this.userService.searchAllUsers(this.pageNumber, this.pageSize, 'admins', this.filter);
      this.filters = { personalNIT: null, codigoCRM: null, status: null, comisionId: null, comiteId: null, sectorId: null };
    } else {
      response = await this.userService.getAllUsers(
        this.pageNumber,
        this.pageSize,
        null,
        filters ? filters.roleName : null,
        filters?.roleName ? null : 'admins',
        null,
        null,
      );
    }

    if (response.success && response.success == true) {
      console.log(response.result);

      this.list = response.result.map((e: any) => {
        return {
          id: e?.id,
          'Nombre del usuario': e?.username,
          'Nombre': `${e?.firstName || ''} ${e?.secondName || ''} ${e?.firstLastname || ''} ${e?.secondLastname || ''}`,
          'Correo electrónico': e?.email,
          'Rol de usuario': this.roleValue(e?.role) //e?.role ? (e.role.slice(5, e.role.length)).replace('_', ' ') : '',
        }
      });
      this.totalPages = Math.ceil(response.count / this.pageSize);
    } else {
      this.utilsService.dialog('ERROR', 'Error', response?.error?.message?.description || '¡Error al consultar usuarios!');
    }
    this.loading = false;
  }

  deletedUser(user_id: number) {
    this.loading = true;

    const userID = sessionStorage.getItem('UserID')?.toString();
    const userNAME = sessionStorage.getItem('Name')?.toString();
    const userEMAIL = sessionStorage.getItem('Email')?.toString();
    const data = {
      'reason_delete': 'Solicitud para eliminar usuario',
      'reason_delete_description': `El usuario: ${userNAME} con id ${userID} solicito eliminar al usuario con id ${user_id}, contactalo a ${userEMAIL}`
    };

    this.userService.deletedUser(user_id, data).then(
      async (response) => {
        console.log(response);
        this.loadData(this.pageNumber, this.pageSize, this.filter, false);
        this.utilsService.dialog('SUCCESS', 'Éxito', '¡Usuario eliminado!');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡El usuario no se elimino!');
      }).finally(() => {
        this.loading = false
      })
  }

  roleValue(role: string | undefined): string {
    switch (role) {
      case 'ROLE_ALIADO_COMERCIAL':
        return 'ALIADO COMERCIAL'
        break;
      case 'ROLE_ORGANIZADOR_EVENTOS':
        return 'ORGANIZADOR EVENTOS'
        break;
      case 'ROLE_ADMIN':
        return 'ADMINISTRADOR'
        break;
      case 'ROLE_SUPERADMIN':
        return 'SUPER ADMIN'
        break;
      default: return 'SIN ROL'
        break;
    }
  }

  // FILTROS
  public isFilter: boolean = false;
  public showFilterModal: boolean = false;
  public filters: Filters = { personalNIT: null, codigoCRM: null, status: null, roleName: null };

  public role: {}[] = [];
  public roleList = [
    { "id": 4, "name": "Aliado comercial", "value": "aliado_comercial" },
    { "id": 5, "name": "Organizador eventos", "value": "organizador_eventos" },
    { "id": 6, "name": "Admin", "value": "admin" },
    { "id": 7, "name": "Administrador - All access", "value": "superadmin" }
  ]

  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: true,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  };

  searchFilter() {
    this.filter = null;
    this.isFilter = true;
    this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
  }

  async onSelect($event: any, type: string) {
    switch (type) {
      case 'roleName':
        const role = await this.roleList.find((x: any) => x.id == $event.id);
        this.filters.roleName = role?.value || null; // $event.id;
        break;
    }
  }

  onDeSelect(type: string) {
    switch (type) {
      case 'roleName':
        this.role = [];
        this.filters.roleName = null;
        break;
    }
  }

  cleanFilter(type: string) {
    switch (type) {
      case 'roleName':
        this.role = [];
        this.filters.roleName = null;
        this.loadData(this.pageNumber, this.pageSize, null, true);
        break;
      case 'all':
        this.role = [];
        this.filter = null;
        this.isFilter = false;
        this.filters = { personalNIT: null, codigoCRM: null, status: null, roleName: null }
        this.searchReturn();
        break;
    }
    this.valid_isFilter();
  }

  valid_isFilter() {
    if (this.filters.roleName == null) {
      this.isFilter = false;
      return false;
    }
    return true;
  }

  closeFilterModal() {
    this.showFilterModal = false; // Cambia el valor a false para ocultar el modal
  }
}

<app-loading *ngIf="loading"></app-loading>
<div class="agexInsidePage">
  <div class="container">
    <div class="row mb-4">
      <div class="col col-10">
        <label class="label-1">Notificaciones</label>
      </div>
      <div class="col col-2">
        <button class="btn btn-primary btn-block" (click)="goToPage('/admin/edit-notificacion')" >
          + Agregar
        </button>
      </div>
    </div>

    <div class="main">
      <div class="default-view">
          <div class="search-field">
              <i class="search-filter" id="search" (click)="loadNotifications(pageNumber, pageSize, filter, true)"></i>
              <input class="search-input" placeholder="Buscar notificación..." [(ngModel)]="filter"
              (keyup.enter)="loadNotifications(pageNumber, pageSize, filter, true)" (keyup)="searchReturn()"/>
          </div>
          <div class="table-default" >
              <table class="content-table" border="1">
                  <thead>
                      <tr>
                          <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of renderList; let i = index">
                      <!-- <td class="{{getTypeOf(x.value) === 'string' ? x.value.toLowerCase().replace(' ', '-'): ''}}" *ngFor="let x of item | keyvalue:originalOrder"> {{ x.key == 'id' ? '' : x.value }} </td> -->
                      <td>{{item['Nombre de la notificación']}}</td>
                      <td>{{item['Fecha de creación'] | date: 'dd-MM-yyy'}}</td>
                      <td>{{item['Fecha de envío'] != '0000-00-00' ? (item['Fecha de envío'] | date: 'dd-MM-yyy') : item['Fecha de envío']}}</td>
                      <td>{{item.Evento}}</td>
                      <td>{{item['Usuarios enviados']}}</td>
                      <td class="{{item.Estatus.toLowerCase()}}" >{{item.Estatus}}</td>
                      <td (mouseleave)="hideActions()">
                        <span name="actions" id="actions" class="actions"  (click)="showActions(i)"></span>
                        <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{i}}" (mouseleave)="hideActions()">
                          <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="editNotification(i)">Editar</button>
                          <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="deleteNotification(item)">Eliminar</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
              <div class="table-pagination">
                <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
                <p class="pagination-text">Página {{pageNumber + 1}} de {{totalPages}}</p>
                <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
            </div>
          </div>
      </div>
  </div>
  </div>
</div>

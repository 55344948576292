import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/Services/utils.service';
import { GalleryService } from 'src/app/Services/gallery.service';
import { BackServiceGallery } from 'src/app/Models/back-service.model';
import { ImageDialogComponent } from 'src/app/Modales/image-dialog/image-dialog.component';

@Component({
  selector: 'app-admin-galeria',
  templateUrl: './admin-galeria.component.html',
  styleUrls: ['./admin-galeria.component.css']
})
export class AdminGaleriaComponent implements OnInit {
  public days: any = []
  public types: any = []
  public eventID: number = 0;
  public allImages: any[] = [];
  public allImagesAux: any[] = [];
  public loading: boolean = false;
  public imgData = new BackServiceGallery();

  constructor(
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private galleryService: GalleryService
  ) { }

  async ngOnInit(): Promise<void> {
    this.eventID = await Number(sessionStorage.getItem('EventID'));
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      await this.loadDatabyEvent(this.eventID);
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡Debes iniciar sesión!');
      this.utilsService.goToPage('/login');
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  filterDate($event: any) {
    if ($event.target.value === 'allDays') {
      this.allImages = this.allImagesAux;
    } else {
      const filtro = this.allImagesAux.filter(x => x.dateCreated === $event.target.value);
      this.allImages = filtro;
    }
  }

  filterType($event: any) {
    if ($event.target.value === 'all') {
      this.allImages = this.allImagesAux;
    } else {
      const selectedTypes = $event.target.value.split(',');
  
      const filtro = [];
      for (const image of this.allImagesAux) {
        if (selectedTypes.includes(image.type) || selectedTypes.includes('all')) {
          filtro.push(image);
        }
      }
      
      this.allImages = filtro;
    }
  }

  async showModal(type: string, data?: any) {
    const modalRef = this.modalService.open(ImageDialogComponent, { centered: true })
    modalRef.componentInstance.type = type;
    this.imgData.userCreated = Number(sessionStorage.getItem('UserID')?.toString());
    this.imgData.eventId = this.eventID;

    if (type === 'SHOW') {
      this.imgData.url = data.url;
      this.imgData.type = data.type;
      this.imgData.fileName = data.fileName
      this.imgData.dateCreated = data.dateCreated;
      this.imgData.userCreated = data.userCreated;
      this.imgData.idEventMedia = data.idEventMedia;
    } else if (type === 'NEW') {
      this.imgData.url = '';
      this.imgData.type = '';
      this.imgData.fileName = '';
      this.imgData.dateCreated = '';
      this.imgData.idEventMedia = '';
    }

    modalRef.componentInstance.gallery = this.imgData;
    modalRef.componentInstance.response.subscribe(
      async (response: any) => {
        this.notification(response);
        await this.loadDatabyEvent(this.eventID);
      });
  }

  notification(response: any) {
    this.utilsService.dialog(response.type, response.title, response.message);
  }

  loadDatabyEvent(event_id: number) {
    this.loading = true;
    return this.galleryService.getGalleryByEvent(event_id).then(
      async response => {
        // console.log(response)
        if (response.result.length > 0) {
          this.allImages = [];
          this.allImagesAux = [];

          for (const e of response.result) {
            // console.log(e);
            const date = this.utilsService.date_pipe(e.dateCreated.substr(0, 10), 'dd-MM-yyyy')
            const groupDate = await this.days.find((x: any) => x === date);
            (!groupDate) && (this.days.push(date));

            const groupType = await this.types.find((x: any) => x === e.type);
            (!groupType) && (this.types.push(e.type));

            this.allImagesAux.push({
              // url: e.url,
              url: this.valid_type(e.type) === 'video' ? e?.thumbFile : e?.url,
              type: e.type,
              dateCreated: date,
              fileName: e.fileName,
              userCreated: e.userCreated,
              idEventMedia: e.idEventMedia,
            });
          }
          this.allImages = this.allImagesAux;
        } else {
          this.allImages = response.result;
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar galeria!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  valid_type(type: string): string{
    // const video = ['mpg4', 'vlc', 'avif', 'ogm', 'wmv', 'mpg', 'webm', 'ogv', 'mov', 'asx', 'mpeg', 'mp4', 'm4v', 'avi'];
    const image = ['imagen', 'tiff', 'jfif', 'bmp', 'gif', 'svg', 'png', 'webp', 'svgz', 'jpg', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg'];

    if(image.includes(type)){
      return 'image'
    } else {      
      return 'video'
    }
  }
}

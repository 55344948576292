<!-- GAFETE POR PARTICIPANTE -->
<div class="card pt-2 card-rounded" *ngIf="type === 'PARTICIPANTE'">
  <div class="card-title">
    <div class="row">
      <div class="col-8">
        <h4 class="modal-title">Gafete de usuario</h4>
      </div>
      <div class="col-4 pt-3">
        <a class="pr-2" (click)="close()"><i class="btn-close"></i></a>
      </div>
    </div>
  </div>

  <div class="img-badge mt-2">
    <img class="card-img-bottom" alt="Header" src="{{imageBadgeHeader}}"  crossorigin='anonymous'/>
  </div>

  <div class="card-body text-center mt-4 pt-4">
    <h5 class="card-title">{{nombreUsuario}}</h5>
    <div class="card-text-position">
      <p class="card-text">
        {{nombrePuesto}}
        <br>
        {{nombreEmpresa}}
      </p>
    </div>
    <p class="mt-3">
      <small class="text-guest">{{tipoEntrada}}</small>
      <qrcode [qrdata]="ID" [width]="300" [margin]="1" [errorCorrectionLevel]="'M'"></qrcode>
    </p>
    <!-- <div *ngIf="userID != null">
      <qrcode [qrdata]="ID" [width]="300" [margin]="1" [errorCorrectionLevel]="'M'"></qrcode>
    </div> -->
  </div>

  <div class="img-badge">
    <img class="card-img-bottom" alt="Footer" src="{{imageBadgeFooter}}" crossorigin='anonymous'/>
  </div>

  <div class="card-footer text-center">
    <button type="button" class="accept-btn" (click)="download(PDF)">Descargar gafete</button>
  </div>
</div>

<!-- CODIGO QR DE EVENTO -->
<div class="card pt-2 card-rounded" *ngIf="type === 'EVENTO'">
  <div class="card-title">
    <div class="row">
      <div class="col-8">
      </div>
      <div class="col-4 pt-3">
        <a class="pr-2" (click)="close()"><i class="info-btn"></i></a>
      </div>
    </div>
  </div>

  <div class="card-body text-center mt-4 pt-4">
    <h5 class="card-title">Código QR</h5>
    <p class="mt-3">
      <qrcode [qrdata]="ID" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
    </p>
  </div>
</div>
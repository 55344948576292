import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SurveyService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    // Obtener encuesta de un evento
    getSurveysByEvent(event_id: number, user_id: number): Promise<any> {
        // Este endpoint tiene una validación entonces puede regresar 2 response dependiendo de la validación si ya se contestó anteriormente 
        return this.http.get(`${this.url}eventos/surveys/event/${event_id}/${user_id}`, this.options).toPromise();
    }

    // Crear una encuesta
    createSurveyToEvent(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/surveys/`, data, this.options).toPromise();
    }

    // Actualizar una encuesta
    updateSurveyToEvent(survey_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/surveys/${survey_id}`, data, this.options).toPromise();
    }

    // Actualizar una pregunta
    updateQuestion(question_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/surveys/questions/${question_id}`, data, this.options).toPromise();
    }

    // Crear respuesta de una encuesta
    createSurveyAnswerToEvent(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/surveys_answer/`, data, this.options).toPromise();
    }


    // Eliminar encuesta
    deleteSurvey(survey_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/surveys/${survey_id}`, this.options).toPromise();
    }

    // Eliminar pregunta
    deleteQuestion(question_id: number): Promise<any> {
        return this.http.delete(`${this.url}eventos/surveys/question/${question_id}`, this.options).toPromise();
    }
}

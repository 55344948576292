<!-- https://material.angular.io/components/sidenav/examples -->

<mat-sidenav-container class="main-container">
  <mat-sidenav id="main-menu" name="main-menu" class="side-bar d-none d-sm-block" mode="side" [opened]="true" >
    <i class="icon-extended-menu {{arraw_reflex}}" id="extended-menu" (click)="changeView()"></i>    
    <mat-action-list class="mat-action-list">
      <mat-list-item class="menu-item" [routerLinkActive]="['is-active']" [routerLink]="['inicio']">
        <img class="menu-icon" id="home" src="..\..\assets\images\HomeIcon.svg" alt="logo" />
        <span class="{{class_text}}">Inicio</span>
      </mat-list-item>
      <mat-list-item *ngFor="let m of main_menu" class="menu-item" [routerLinkActive]="['is-active']"
        routerLink="{{m.routerLink}}">
        <img class="menu-icon" id="{{m.id}}" src="{{m.src}}" alt="logo" />
        <span class="{{class_text}}">{{m.name}}</span>
      </mat-list-item>
    </mat-action-list>
    <mat-divider class="divider-section"></mat-divider>
    <div class="content-logout">
      <button class="btn-logout" id="logout" (click)="logout()">
        <img class="menu-icon" src="..\..\assets\images\LogoutIcon.svg" alt="logo" />
        <span class="{{class_text}}">Cerrar sesión</span>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="content-container">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-loading *ngIf="loading"></app-loading>
<div class="main-container">
    <div class="header">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-conferencistas')"></i>
            <span class="change-page-label"> Atrás </span>
            <div class="next-container">
                <span class="change-page-label"> Siguiente </span>
                <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-stands')"></i>
            </div>
        </div>
        <button class="agregar" (click)="goToPage('/admin/events/edit-conferencias')">+ Agregar conferencia</button>
    </div>
    <div class="main">
        <div class="default-view">
            <div class="table-default">               
                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <th> <input class="checkbox-item" type="checkbox" [(ngModel)]="checkbox" (change)="selectAtivity($event, true)"/> </th>
                            <ng-container *ngFor="let each of categoriesHeading">
                                <th (mouseover)="hideActions()"> {{each}} </th>
                            </ng-container>
                            <th (mouseleave)="hideActions()"> 
                                <button name="actions" id="actions" class="actions actions-icon-white" (click)="showActions({ id: 'Header' })" (keyup)="showActions({ id: 'Header' })"></button>
                                <!-- <div [hidden]="isShowDiv" class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="itemHeader">                                     -->
                                <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="itemHeader" (mouseleave)="hideActions()">
                                    <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="removeAllActivity()">Eliminar</button>
                                </div>  
                            </th>
                        </tr>
                    </thead>
                    
                    <tbody *ngFor="let e of dates; let index = index">
                        <tr id="dateRow" class="date_Row">
                            <td colspan="8">{{ e.date.toString() | date:'EEEE, MMMM d, y' | titlecase }}</td>
                        </tr>
                        <tr *ngFor="let item of e.data; let i = index">
                            <ng-container>
                                <ng-container *ngFor="let x of item | keyvalue:originalOrder;" >
                                    <td *ngIf="x.key == 'checkbox'">
                                        <input type="checkbox" [(ngModel)]="x.value" (change)="selectAtivity($event, false, item.id)"/>
                                    </td>
                                    <td *ngIf="x.key == 'Hora y Lugar'">
                                        {{ convert('Hora', x.value) | lowercase }}
                                        <div class="place-table"><i class="place-btn" id="place"></i>{{ convert('Lugar', x.value) }}</div>                                        
                                    </td>
                                    <td *ngIf="x.key != 'Hora y Lugar' && x.key != 'id' && x.key != 'Estatus' && x.key != 'checkbox'" [ngClass]="{'name' : x.key === 'Nombre de la actividad'}">
                                        {{x.value}}
                                    </td>

                                    <td class="registro-group" *ngIf="x.key === 'Estatus'">
                                        <select name="registro" id="registro" class="registro-drop" #selects [value]="x.value" (change)="update($event, item.id, x.value, index, i)">
                                            <option value='1'>Iniciar</option>
                                            <option value='0'>Finalizado</option>
                                        </select>                                  
                                    </td>
                                </ng-container>
                                <td (mouseleave)="hideActions()">
                                    <button name="actions" id="actions" class="actions actions-icon-gray" (click)="showActions(item)"></button>
                                    <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{item.id}}" (mouseleave)="hideActions()">
                                        <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="editActivity(item.id)">Editar</button>                                                                           
                                        <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="removeActivity(item.id)">Eliminar</button>
                                    </div>                              
                                </td>                                
                            </ng-container>
                        </tr>
                    </tbody>                
                </table>
            </div>
        </div>
    </div>
</div>
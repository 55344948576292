<div class="main home-frame">
    <div class="row pt-sm-5 w-100 h-100 justify-content-end custom-image">
        <div class="col-12 col-sm-12 col-md-6 align-self-end text-end float-end d-none d-sm-none d-md-block custom-image">
            <img class="align-bottom img-fluid" name="hand" id="hand" src="..\..\assets\images\home-frame.svg" alt="logotipo" />
        </div>
        <div class="col-12 col-sm-8 col-md-6 align-self-start pt-5">
            <p class="topHeader text-welcome">Bienvenido (a)</p>
            <header class="username">{{username}}</header>
        </div>     
        
        <div class="col-12 col-sm-12 col-md-6 align-self-end text-end float-end d-block d-sm-block d-md-none">
            <img class="align-bottom img-fluid" name="hand" id="hand" src="..\..\assets\images\home-frame.svg" alt="logotipo" />
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(date: string | NgbDateStruct): string {
    const dateAsStruct = date as NgbDateStruct
    if (date) {
      return `${dateAsStruct.day} de ${this.getMonthName(dateAsStruct.month)} del ${dateAsStruct.year}`;
    } else {
      return '';
    }
  }

  getMonthName(monthNumber: number): string {
    let monthName: string;
    switch (monthNumber) {
      case 1:
        monthName = "enero";
        break;
      case 2:
        monthName = "febrero";
        break;
      case 3:
        monthName = "marzo";
        break;
      case 4:
        monthName = "abril";
        break;
      case 5:
        monthName = "mayo";
        break;
      case 6:
        monthName = "junio";
        break;
      case 7:
        monthName = "julio";
        break;
      case 8:
        monthName = "agosto";
        break;
      case 9:
        monthName = "septiembre";
        break;
      case 10:
        monthName = "octubre";
        break;
      case 11:
        monthName = "noviembre";
        break;
      case 12:
        monthName = "diciembre";
        break;
      default:
        monthName = "mes inválido";
        break;
    }
    return monthName;
  }
}


import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/Services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { CompanyService } from 'src/app/Services/company.service';
import { listas, AccountRelation } from 'src/app/Models/back-service.model';

interface User {
  id: number;
  status: string;
  companyId: number | null;
  password: string;
  firstName: string;
  secondName: string;
  firstLastname: string;
  secondLastname: string;
  phone: string;
  roles: any[];
  roleId: number | null | string;
  puestoId: number | null;
  gender_id: number | null;
  email: string;
  addtionalEmail: string;
  personalNIT: any;
  birthday: string;
  country_id: number | null;
  areaId: number | null;
  associated: boolean | null;
  permissions: string[];
  ownerContact: boolean;
  communicationChannel: string[];
  sectorId?: number | null;
  comiteId?: number | null;
  comisionId?: number | null;
  username: string;
  aboutMe: string;
  accountRelation: any[any];
}

interface Company {
  nit: string;
  companyNIT?: string;
  nombreComercial: string;
  companyNITPlaceholder: string;
}

@Component({
  selector: 'app-edit-usuario',
  templateUrl: './edit-usuario.component.html',
  styleUrls: ['./edit-usuario.component.css'],
})
export class EditUsuarioComponent implements OnInit {
  @ViewChild('form') form!: NgForm;
  public loading: boolean = false;
  public userID: number = 0;
  public jobList: listas[] = [];
  public roleList: listas[] = [];
  public areaList: listas[] = [];
  public user: User = {} as User;
  public sectorList: listas[] = [];
  public comiteList: listas[] = [];
  public countryList: listas[] = [];
  public comisionList: listas[] = [];
  public creatingUser: boolean = false;
  public company: Company = {} as Company;
  public dropdownSettings: IDropdownSettings = {} as IDropdownSettings;
  public accountRelation: AccountRelation = { companyId: null, areaId: null, puestoId: null, ownerContact: false } as AccountRelation;

  public userComite: {}[] = [];
  public userSectors: {}[] = [];
  public userComision: {}[] = [];

  public sectors: {}[] = [];
  public sectorsList: listas[] = [];

  public permissions: any[] = [];
  public permissionsList: any[] = [];
  public permissionsModel: any[] = [];

  public communicationChannels: any[] = [];
  public communicationChannelsList: any[] = [];
  public communicationChannelsModel: any[] = [];

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private companyService: CompanyService
  ) { 
    this.company.companyNITPlaceholder = '000000-0';
  }

  async ngOnInit() {
    await this.loadLists();
    this.userID = this.utilsService.getParam('ID') || 0;

    if (this.userID > 0) {
      // EDIT
      this.loadDatabyID();
      this.creatingUser = false;
    } else {
      // NEW
      this.user.roleId = 1;
      this.user.areaId = null;
      this.creatingUser = true;
      this.user.puestoId = null;
      this.user.comiteId = null;
      this.user.sectorId = null;
      this.user.gender_id = null;
      this.user.companyId = null;
      this.user.comisionId = null;
      this.user.country_id = null;
      this.user.associated = false;
      this.user.status = 'Inactivo';
      this.user.ownerContact = false;
      this.user.roles = ['noasociado'];
      this.user.accountRelation = new Array({} as AccountRelation);
    }
    this.setDropdownSettings();
  }

  setDropdownSettings() {
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: false,
      singleSelection: this.creatingUser
    };
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid) &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  changeCheck(event: any, type: string, value: boolean) {
    switch (type) {
      case 'owner':
        this.user.ownerContact = value;
        this.user.accountRelation[0].ownerContact = value;
        break;
      case 'associated':
        this.user.associated = value;
        break;
      default:
        break;
    }
  }

  onChangeJobs($event: any, name: any) {
    const id = Number($event.target.value);
    this.user.accountRelation[0][name] = id;
  }

  onChangeRole($event: any, type: string, getOne?: number) {
    const role_id = type == 'select' ? Number($event.target.value) : getOne;

    if (role_id === 2) {
      this.user.associated = true;
      this.user.status = 'Activo';
    } else {
      this.user.associated = false;
      this.user.status = 'Inactivo';
    }
  }

  onPermissionChange($event: any, type: string) {
    if ($event.target.checked) {
      this.permissions.push(type);
    } else {
      const index = this.permissions.indexOf(type);
      this.permissions.splice(index, 1);
    }
    // console.log(this.permissions);
  }

  onCommunicationsChange($event: any, type: string) {
    if ($event.target.checked) {
      this.communicationChannels.push(type);
    } else {
      const index = this.communicationChannels.indexOf(type);
      this.communicationChannels.splice(index, 1);
    }
    // console.log(this.communicationChannels);
  }

  loadLists() {
    this.utilsService.getCountries().then(
      (response) => {
        this.countryList = response.result.countries.map((e: any) => {
          return { id: e?.id, name: e?.name };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getPuestos().then(
      (response) => {
        this.jobList = response.result.puestos.map((e: any) => {
          return { id: e?.id, name: e?.name };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getAreas().then(
      (response) => {
        this.areaList = response.result.areas.map((e: any) => {
          return { id: e?.id, name: e?.name };
        });
      },
      (error) => this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getRoles().then(
      (response) => {
        this.roleList = response.result.roles.map((e: any) => {
          return { id: e?.id, key: e?.value, name: e?.label };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getSectors().then(
      (response) => {
        this.sectorList = response.result.sectors.map((e: any) => {
          return { id: e?.id, name: e?.name };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getCommissions().then(
      (response) => {
        this.comisionList = response.result.comisiones.map((e: any) => {
          return { id: e?.id, name: e?.name };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getCommittees().then(
      (response) => {
        this.comiteList = response.result.comites.map((e: any) => {
          return { id: e?.id, name: e?.name };
        });
      },
      (error) => this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getCommunicationChannels().then(
      (response) => {
        this.communicationChannelsList = response.result.map((e: any) => {
          return { id: e?.id, name: e?.name, key: e?.channelValue };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getPermissions().then(
      (response) => {
        this.permissionsList = response.result.permissions.map((e: any) => {
          return { id: e?.id, name: e?.name, label: e?.label };
        });
      },
      (error) =>
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );
  }

  async loadDatabyID() {
    this.loading = true;
    await this.userService.getOneUser(this.userID).then(
      async (response) => {
        console.log(response.result);
        this.user.id = response?.result?.id;
        this.user.phone = response?.result?.phone || null;
        this.user.email = response?.result?.email || null;
        this.user.roleId = response?.result?.roleId || null;
        this.user.sectorId = response?.result?.sector || null;
        this.user.comiteId = response?.result?.comite || null;
        this.user.username = response?.result?.username || null;
        this.user.aboutMe = response?.result?.aboutMe || null;
        this.user.comisionId = response?.result?.comision || null;
        this.user.firstName = response?.result?.firstName || null;
        this.user.secondName = response?.result?.secondName || null;
        this.user.gender_id = response?.result?.gender?.id || null;
        this.user.country_id = response?.result?.country?.id || null;
        this.user.associated = response?.result?.associated || false;
        this.user.personalNIT = response?.result?.personalNIT || null;
        this.user.firstLastname = response?.result?.firstLastname || null;
        this.user.secondLastname = response?.result?.secondLastname || null;
        this.user.birthday = response?.result?.birthday?.substring(0, 10) || '';
        this.user.addtionalEmail = response?.result?.addtionalEmail || null;
        this.user.roles = response?.result?.role != null ? [response.result.role.toLowerCase().slice(5)] : [];

        // LISTA DE COMITE/COMISION/SECTOR
        if (response?.result?.company != null && response?.result?.company?.listComites.length > 0) {
          for (const e of response?.result?.company?.listComites) {
            if (e.sector != null) {
              this.userSectors = [...this.userSectors];
              this.userSectors.push({
                id: e.sector.id,
                name: e.sector.name
              });
            }
            if (e.comision != null) {
              this.userComision = [...this.userComision];
              this.userComision.push({
                id: e.comision.id,
                name: e.comision.name
              });
            }
            if (e.comite != null) {
              this.userComite = [...this.userComite];
              this.userComite.push({
                id: e.comite.id,
                name: e.comite.name
              });
            }
          }
        }

        // ASOCIADO SI/NO
        this.onChangeRole(null, 'getOne', response?.result?.roleId || null);

        // PERMISOS
        this.permissions = response?.result?.permissions;
        this.user.permissions = response?.result?.permissions;
        if (response.result.permissions && response?.result?.permissions?.length > 0) {
          for (let x of this.permissionsList) {
            if (response.result.permissions.includes(x.name)) {
              this.permissionsModel[x.name] = true;
            }
          }
        }

        // CANALES DE COMUNICACION
        this.communicationChannels = response?.result?.communicationChannel;
        this.user.communicationChannel = response?.result?.communicationChannel;
        if (response.result.communicationChannel && response.result?.communicationChannel.length > 0) {
          for (let x of this.communicationChannelsList) {
            if (response.result.communicationChannel.includes(x.key)) {
              this.communicationChannelsModel[x.key] = true;
            }
          }
        }

        // RELACION CON EMPRESA
        this.user.accountRelation = response?.result?.accountRelations.length > 0
          ? response?.result?.accountRelations
          : this.user.accountRelation = new Array({} as AccountRelation);
        this.user.companyId = response?.result?.companyId;
        this.user.areaId = response?.result?.accountRelations[0]?.areaId || null;
        this.user.puestoId = response?.result?.accountRelations[0]?.puestoId || null;
        this.user.ownerContact = response?.result?.accountRelations[0]?.ownerContact || false;
        if (response.result.companyId && response.result.companyId > 0) {
          this.company.nit = response?.result?.accountRelations[0]?.company?.nit
          this.company.nombreComercial = response?.result?.accountRelations[0]?.company?.nombreComercial || response?.result?.accountRelations[0]?.company?.razonSocial
        }
      },
      (error) => {
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async submit() {
    const regex = /^[a-zA-Z0-9]+$/;
    if (this.user.personalNIT && this.user.personalNIT != '') {
      if (!regex.test(this.user.personalNIT)) {
        this.utilsService.dialog('ERROR', 'Error', 'El campo NIT personal debe contener sólo números y letras.');
        return;
      }
    }

    this.user.permissions = this.permissions || [];
    this.user.communicationChannel = this.communicationChannels || [];
    console.log(this.user);

    if (this.form.valid) {
      const role = this.roleList.find((x) => x.id === Number(this.user.roleId));
      this.user.roles = [role!.key];
      this.userID > 0 ? this.editUser() : this.createUser();
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', '¡Llena los campos obligatorios!');
    }
  }

  createUser() {
    this.loading = true;
    this.user.password = '';
    const user = { ...this.user }; 
    delete user.accountRelation;
    user.phone ? user.phone = user.phone.toString() : user.phone = '';
    this.userService.createUser(user).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description, '/admin/users/admin-usuarios');
      },
      (error) => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  editUser() {
    this.loading = true;
    this.userService.updateUser(this.userID, this.user).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description, '/admin/users/admin-usuarios');
      },
      (error) => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  recoverPassword() {
    this.loading = true;
    this.userService.recoverPassword(this.userID, this.user).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description);
      },
      (error) => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    })
  }

  searchCompany(company_nit: string) {
    if (this.form && !this.form.controls['companyNit'].errors) {
      if (company_nit.length > 0) {
        this.loading = true;
        this.companyService.getCompanies(0, 20, null, company_nit, null, null, null, null, null, null).then(
          response => {
            if (response.success && response.count == 1) {
              this.user.companyId = response?.result?.rows[0]?.id;
              this.user.accountRelation[0].companyId = response?.result?.rows[0]?.id;
              this.company.nombreComercial = response?.result?.rows[0]?.nombreComercial || response?.result?.rows[0]?.razonSocial;
            }
            else if (response.success && response.count > 0) {
              const company = response.result.rows.find((x: any) => x.socio == true);
              if (company) {
                this.user.companyId = company?.id;
                this.user.accountRelation[0].companyId = company?.id;
                this.company.nombreComercial = company?.nombreComercial || company?.razonSocial;
              } else {
                this.user.companyId = response?.result?.rows[0]?.id;
                this.user.accountRelation[0].companyId = response?.result?.rows[0]?.id;
                this.company.nombreComercial = response?.result?.rows[0]?.nombreComercial || response?.result?.rows[0]?.razonSocial;
              }
            } else {
              this.utilsService.dialog('ERROR', 'Error', response?.error?.message?.description || '¡Empresa no encontrada!');
              throw new Error(response.message);
            }
          },
          error => {
            this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
          }
        ).finally(() => {
          this.loading = false;
        })
      } else {
        this.user.companyId = null;
        this.user.accountRelation[0].companyId = null;
        (this.user.accountRelation[1]) && (
          this.user.accountRelation[1].areaId = this.user.accountRelation[0].areaId,
          this.user.accountRelation[1].puestoId = this.user.accountRelation[0].puestoId,
          // this.company.nit = this.user.accountRelation[1]?.company?.nit,
          this.company.companyNITPlaceholder = this.user.accountRelation[1]?.company?.nit,
          this.company.nombreComercial = this.user.accountRelation[1]?.company?.nombreComercial,
          this.user.accountRelation.splice(0, 1)
        )
      }
    }
  }
}
<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 ">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-agenda')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12 ml-3 mb-2">
            <h5 class="card-title mb-4">Actividades de agenda</h5>
        </div>
    </div>

    <form #form="ngForm">
        <div class="col-12" *ngFor="let schedule of schedules; let i = index">
            <div class="card mb-5">
                <div class="card-body">
                    <!-- Cambio de flujo
                    <div class="row p0 m0">
                        <div class="col-12 p0 m0 text-right">
                            <button class="btn" (click)="removeSchedule(i, schedule.id)">X</button>
                        </div>
                    </div> -->
                    <div class="row px-4 py-0">
                        <div class="col-12 col-lg-6">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label class="form-label mb-3">Nombre de la actividad*</label>
                                    <input class="form-control" name="nombreAgenda_{{i}}" id="nombreAgenda_{{i}}" [(ngModel)]="schedule.nombreAgenda" required placeholder="Nombre de actividad" />
                                    <div class="err-msg" *ngIf="validation_msg('nombreAgenda_' + i)"> Este campo es obligatorio.</div>
                                </div>                                                                

                                <div class="col-md-12 mb-3">
                                    <label class="form-label mb-3">Fecha y hora de inicio*</label>
                                    <div class="row">
                                        <div class="col-12 col-md-6 my-2 my-lg-0">
                                            <input type="date" class="form-control" onkeydown="return false" name="date_start_{{i}}" [(ngModel)]="schedule.fechaInicio" required placeholder="dd-mm-yyyy" />
                                            <div class="err-msg" *ngIf="validation_msg('date_start_' + i)"> Este campo es obligatorio.</div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="time" class="form-control clock-mozff" name="hour_start_{{i}}" [(ngModel)]="schedule.horaInicio" required placeholder="dd-mm-yyyy" />
                                            <div class="err-msg" *ngIf="validation_msg('hour_start_' + i)"> Este campo es obligatorio.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mb-3">
                                    <label class="form-label mb-3">Fecha y hora de finalización*</label>
                                    <div class="row">
                                        <div class="col-12 col-md-6 my-2 my-lg-0">
                                            <input type="date" onkeydown="return false" class="form-control" name="date_end_{{i}}" [(ngModel)]="schedule.fechaFin" required placeholder="dd-mm-yyyy" />
                                            <div class="err-msg" *ngIf="validation_msg('date_end_' + i)"> Este campo es obligatorio.</div>
                                            <div class="err-msg" *ngIf="validation_date(i, 'date_start_' + i)"> La fecha y hora final no puede ser menor a la inicial.</div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="time" class="form-control clock-mozff" name="hour_end_{{i}}" [(ngModel)]="schedule.horaFin" required="" placeholder="dd-mm-yyyy" />
                                            <div class="err-msg" *ngIf="validation_msg('hour_end_' + i)"> Este campo es obligatorio.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label class="form-label mb-3">Lugar*</label>
                                    <input class="form-control" name="lugar_{{i}}" id="lugar_{{i}}" [(ngModel)]="schedule.lugar" required placeholder="Lugar de actividad" autocomplete="OFF"/>
                                    <div class="err-msg" *ngIf="validation_msg('lugar_' + i)"> Este campo es obligatorio.</div>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label mb-3">Modalidad*</label>
                                    <select class="form-control" name="modality_{{i}}" [(ngModel)]="schedule.modalityId" required>
                                        <option value="null" disabled>Seleccione...</option>
                                        <option *ngFor="let x of modalitySchedule" [value]="x.id">{{x.name}}</option>
                                    </select>
                                    <div class="err-msg" *ngIf="validation_msg('modality_' + i)"> Este campo es obligatorio.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- Cambio de flujo
    <div class="col-12 text-right px-5" *ngIf="scheduleID === 0">
        <div class="d-grid gap-2">
            <button class="btn btn-primary px-4 my-2 btn-agregar-editagenda" type="button" (click)="addSchedule()" [disabled]="schedules.length >= 10">+ Agregar Actividad</button>
        </div>
    </div> -->
    <div class="col-12 text-right px-5">
        <div class="d-grid gap-2">
            <button class="btn btn-primary px-4 my-2 btn-save-editagenda" type="button" (click)="processOne()" [disabled]="!form.touched || !form.valid"><i class="save-icon"></i>Guardar</button>
            <!-- <button class="btn btn-primary px-4 my-2 btn-save-editagenda" type="button" (click)="processData()" [disabled]="schedules.length === 0 || !form.touched"><i class="save-icon"></i>Guardar</button> -->
        </div>
    </div>
</div>
// import { KeyValue } from '@angular/common';
// import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { BackServiceSpeakers } from 'src/app/Models/back-service.model';
import { BackofficeService } from 'src/app/Services/backoffice.service';

@Component({
  selector: 'app-edit-conferencista',
  templateUrl: './edit-conferencista.component.html',
  styleUrls: ['./edit-conferencista.component.css']
})
export class EditConferencistaComponent implements OnInit {

  file: string = '';
  speakerData = new BackServiceSpeakers();
  accessToken: any;
  speakerID: any;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';

  constructor(
    private utilsService: UtilsService,
    private backofficeService: BackofficeService
  ) { }

  ngOnInit() {

    // this.speakerID = this.Activatedroute.snapshot.queryParams['ID'] || 0;
    this.speakerID = this.utilsService.getParam('ID')

    if (sessionStorage.getItem('Token') && this.speakerID > 0) {
      this.accessToken = sessionStorage.getItem('Token');
      this.loadDatabyID();
    }
    else if (sessionStorage.getItem('Token')) {
      this.accessToken = sessionStorage.getItem('Token');
      this.speakerData.eventId = sessionStorage.getItem('EventID');
    }
    else {
      this.errorDialog("No tienes acceso...");
      this.goToPage('/login');
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  onFileSelected(event: any) {
    console.log(event.target.id);
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0].name;
    }
    else {
      this.file = "No hay archivos seleccionados.";
    }

    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.speakerData.fotoConferencista!.extension = file.type.substring(file.type.indexOf('/') + 1);

    var reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    console.log("_handleReaderLoaded")
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
    this.speakerData.fotoConferencista!.data = this.imageSrc;
    console.log(this.speakerData);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  loadDatabyID() {
    this.backofficeService.getSpeakersbyID(this.speakerID, this.accessToken)
      .subscribe({
        next: (res) => {
          this.speakerData = res.result;
        },
        error: (e) => this.errorDialog(e.error.message.description)
      })
  }

  createSpeaker() {
    console.log(this.speakerData);
    this.backofficeService.createSpeaker(this.speakerData, this.accessToken)
      .subscribe({
        next: (res) => {
          this.save('/admin/events/admin-conferencistas');
        },
        error: (e) => {
          console.log(e);
          this.errorDialog(e.error.message.description);
        }
      })
  }

  save(pageUrl?: string) {
    // const modalRef = this.modalService.open(DialogComponent, { centered: true });
    // modalRef.componentInstance.page = pageUrl;
    this.utilsService.dialog('SUCCESS', 'Éxito', '', pageUrl);
  }

  errorDialog(errorMessage: string) {
    this.utilsService.dialog('ERROR', 'Error', errorMessage);
  }

}

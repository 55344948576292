<app-loading *ngIf="loading"></app-loading>
<div class="main">
    <div class="header">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('last')"></i>
            <span class="change-page-label"> Atrás</span>
            <div class="next-container" *ngIf="nextPage === true">
                <span class="change-page-label"> Siguiente </span>
                <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-conferencistas')"></i>
            </div>
        </div>
        <div class="btn-group">
            <!-- [disabled]="env" -->
            <button class="agregar" (click)="goToPage('/admin/events/user-guide', {type: 'participantes', event_id: eventID})">
                + Agregar
            </button>
            <span class="importar" onclick="document.getElementById('file').click();" [hidden]="role == 'ROLE_ORGANIZADOR_EVENTOS'">Importar asistencia</span>
            <input type="file" style="display:none;" #file id="file" name="file" #FileSelectInputDialog (change)="onFileSelected($event)"  accept="text/csv" />            
        </div>
    </div>
    <div class="top-main">
        <div class="default-view">
            <div class="search-field">
                <i class="search-filter" id="search" (click)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" ></i>
                <input class="search-input" style="width: 90%;" placeholder="Buscar usuario..."
                [(ngModel)]="filter" (keyup.enter)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" (keyup)="searchReturn()"/>
                <button class="agregar" style="margin: -9px 0; padding: 13px 20px; right: 3px; width: 148px;" (click)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)"> Buscar </button>
            </div>


            <div class="table-default">
                <div class="table-default" style="overflow-x:auto;">                   
                    <table class="content-table" (mouseleave)="hideActions(-1)">
                        <thead>
                            <tr>
                                <th> <input class="checkbox-item" type="checkbox" [(ngModel)]="allParticipant" (change)="selectParticipant($event, true)"/> </th>
                                <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                                <th (mouseleave)="hideActions(-1)">
                                    <div class="multi_den">
                                        <div class="multi_contenedor">
                                            <button name="actions" id="actions" class="actions actions-icon-white" (click)="showActions(-1)"></button>
                                            <div class="multi_list" id="item-1" hidden>
                                                <ul class="multi_ul">
                                                    <li>
                                                        <button name="gafete" id="edit" class="multi_button" (click)="modalConfirmation()">Enviar <br> confirmación</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                        
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of list; let i = index" (mouseleave)="hideActions(-1)">                                
                                <ng-container *ngFor="let x of item | keyvalue:originalOrder">

                                    <td *ngIf="x.key == 'checkbox'">
                                        <input type="checkbox" [(ngModel)]="item.checkbox" (change)="selectParticipant($event, false, item.id)" [disabled]="allParticipant"/>
                                    </td>

                                    <td *ngIf="x.key != 'userId' && x.key != 'Asistencia' && x.key != 'Registro' && x.key != 'Profesion' && x.key != 'checkbox'" [hidden]="x.key === 'id'" (mouseout)="hideActions(i)">
                                        {{x.value}}
                                    </td>

                                    <td class="asistencia-group" *ngIf="x.key === 'Asistencia'">
                                        <select name="asistance_{{i}}" id="asistance_{{i}}" 
                                            [ngClass]="x.value === 'no_confirmado' ? 'asistance-drop select-disable' : 'asistance-drop'" [value]="x.value"
                                            (change)="updateAttendance($event, i, item.id)" [disabled]="x.value === 'confirmado'" #selects>
                                            <option value='confirmado'>Confirmado</option>
                                            <option value='no_confirmado'>No Confirmado</option>
                                        </select>
                                    </td>

                                    <td class="registro-group" *ngIf="x.key === 'Registro'" (mouseout)="hideActions(i)">
                                        <select name="registro_{{i}}" id="registro_{{i}}" class="registro-drop"
                                            [ngClass]="x.value == '0' ? 'select-disable' : ''" [value]="x.value"
                                            (change)="updateRegister($event, i, item.id)" disabled
                                            #selects>
                                            <option value=1>Registrado</option>
                                            <option value=0>Sin Registro</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <td (mouseleave)="hideActions(i)">
                                    <div class="multi_den">
                                        <div class="multi_contenedor">
                                            <button name="actions" id="actions" class="actions actions-icon-gray" (click)="showActions(i)"></button>
                                            <div class="multi_list" id="item{{i}}" hidden >
                                                <ul class="multi_ul">
                                                    <li style="border-bottom: 1px solid #dee2e6 !important;" *ngIf="eventData.needBadge === 1">
                                                        <button name="gafete" id="edit" class="multi_button" (click)="getGafete(i, item, item.id)" >Ver Gafete</button>
                                                    </li>
                                                    <li>
                                                        <button name="delete" id="delete" class="multi_button" (click)="deleteParticipant(i, item.id)">Eliminar</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                        
                                    </div>                                   
                                </td>
                            </tr>
                        </tbody>
                    </table>                
                </div>
                <div class="table-pagination">
                    <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
                    <p class="pagination-text">Página {{pageNumber}} de {{totalPages}}</p>
                    <!-- <p class="pagination-text">Página {{pageNumber + 1}} de {{totalPages}}</p> -->
                    <!-- <p class="pagination-text">Página {{totalPages != 0 ? pageNumber + 1 : 0 }} de {{totalPages}}</p> -->
                    <!-- <p class="pagination-text">Página {{pageNumber}} de {{totalPages == 0 ? totalPages + 1 : totalPages}}</p> -->
                    <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
                </div>
            </div>
        </div>        
    </div>
    <div class="block-bottom">
        <button class="cancle-btn" (click)="downloadToExcel(true)">Descargar plantilla</button>
        <button class="save-btn" (click)="downloadToExcel(false)"><i class="save-icon"></i>Descargar reporte</button>
    </div>
</div>
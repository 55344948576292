import * as xlsx from 'xlsx';
import cheerio from 'cheerio';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DialogComponent } from 'src/app/Modales/dialog/dialog.component';
import { GafeteDialogComponent } from '../Modales/gafete-dialog/gafete-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private pipeDate = new DatePipe('en-US');
  private emitChangeSource = new Subject<any>();
  public changeEmitted$ = this.emitChangeSource.asObservable();

  private params: HttpParams;
  private headers: HttpHeaders;
  private url: string = environment.url;
  private options: { headers: HttpHeaders, params?: HttpParams };

  constructor(
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private pipeNumber: DecimalPipe,
    private Activatedroute: ActivatedRoute
  ) {
    this.params = new HttpParams();
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('accept', 'application/json');
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.options = { headers: this.headers };
  }

  /////// FUNCTION ///////

  // Habilitar opciones de menu por tipo de evento
  emitChange(participants_change: boolean, fair_change: boolean, origin?: string) {
    // console.log('emitChange', change, origin);
    this.emitChangeSource.next({ enableParticipants: participants_change, enableFairs: fair_change });
  }

  // Cambiar el formato numerico
  number_pipe(number: string | number, format: string): number | string | null {
    return this.pipeNumber.transform(number, format, 'en-US');
  }

  // Obtener fecha
  getDate(format: string) {
    const today = new Date();
    return this.pipeDate.transform(today, format);
  }

  // Date pipe
  date_pipe(date: string, format: string, local?: string) {
    return this.pipeDate.transform(date, format);
  }

  // Date UTC
  async date_utc(date: string, time: string) {
    if (date != null && time != null) {
      const formatDate = new Date(`${date}T${time}`).toISOString();
      return {
        date: formatDate.slice(0, 10),
        hour: formatDate.slice(11, 19)
      }
    }

    return {
      date: null,
      hour: null
    };
  }

  formatDate(date: any) {
    let result = null;
    if (Object.entries(date).length > 0) {
      const year = date.year;
      const day = date.day.toString().padStart(2, '0');
      const month = date.month.toString().padStart(2, '0');
      result = `${year}-${month}-${day}`;
    }
    return result;
  }

  formatHour(hour: any) {
    let result = null;
    const hours = Object.values(hour)[0];
    const minutes = Object.values(hour)[1];
    const seconds = Object.values(hour)[2];

    if (hours && minutes && seconds) {
      result = `${hours}:${minutes}:${seconds}`;
    }
    return result;
  }

  toPlainText(htmlMessage: string): string {
    const $ = cheerio.load(htmlMessage);
    return $.text();
  }

  // Navegacion entre rutas
  goToPage(pageName: string, params?: any) {
    if (params) {
      this.router.navigate([pageName], { queryParams: params });
      // this.router.navigate([`/admin/users/edit-administradores`], { queryParams: { ID: 0 } });
    } else {
      this.router.navigate([`${pageName}`]);
    }
  }

  // Obtener parametros GET
  getParam(param: string, returned?: string) {
    return this.Activatedroute.snapshot.queryParams[param] || false;

    // IMPLEMENTAR
    switch (returned) {
      case 'number':
        return this.Activatedroute.snapshot.queryParams[param] || 0;
        break;
      case 'text':
        return this.Activatedroute.snapshot.queryParams[param] || '';
        break;
      case 'boolean':
        return this.Activatedroute.snapshot.queryParams[param] || false;
        break;
    }
  }

  // Esportar a Excel
  async downloadToExcel(list: any, bookName: string, reportName: string, extension: string) {
    // for (const e of this.rows){
    //     delete e.eliminarElemento; // Eliminarporpiedad no deseada
    //     e.convertirElemento = parseFloat(e.convertirElemento); // Mostrar como numero una columna
    //   };
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(list);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, bookName);
    xlsx.writeFile(wb, `${reportName.toUpperCase()}.${extension}`);
  }

  // Convertir imagnes a base 64
  async convertBase64(file: any): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // Preview image
  previewImage(file: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file.target.files[0]);
    reader.onload = (file: any) => {
      return file.target.result;
    }
  }

  // Dialogos de notificaciones
  async dialog(type: string, title: string, message: string, pageName?: string): Promise<any> {
    // const modalRef = this.modalService.open(DialogComponent, { size: 'sm', centered: true });
    const modalRef = this.modalService.open(DialogComponent, { centered: true });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.page = pageName;
    modalRef.componentInstance.message = message;

    return modalRef.result.then((result) => {
      modalRef.closed;
      modalRef.close();
      return result;
    }, (reason) => {
      modalRef.closed;
      modalRef.close()
      return reason;
    });
  }

  // Dialogos de gafetes
  dialog_gafete(type: string, id: number) { //title: string, message: string, pageName?: string) {    
    const modalRef = this.modalService.open(GafeteDialogComponent, { centered: true });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.ID = id.toString();
  }

  /////// APIS ///////
  // Modalidades de Eventos/Actividades
  getModalities(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}eventos/modalities`, this.options).toPromise();
  }

  getCommissions(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/comisiones`, this.options).toPromise();
  }

  getCommittees(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/comites`, this.options).toPromise();
  }

  getSectors(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/sectors`, this.options).toPromise();
  }

  getPuestos(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/puestos`, this.options).toPromise();
  }

  getAreas(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/areas`, this.options).toPromise();
  }

  getRoles(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/roles`, this.options).toPromise();
  }

  getRolesAdmin(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/roles/admins/`, this.options).toPromise();
  }

  getCountries(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/countries`, this.options).toPromise();
  }

  getPermissions(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/permissions/`, this.options).toPromise();
  }

  getCommunicationChannels(): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/communication_channel/`, this.options).toPromise();
  }

  getCommunicationChannelsByUser(user_id: number): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/communication_channel/${user_id}`, this.options).toPromise();
  }

  getAllCompany(): Promise<any> {
    this.options = { headers: this.headers };
    return this.http.get(`${this.url}registro/company`, this.options).toPromise();
  }

  getAllPromotions(status: string): Promise<any> {
    this.options = { headers: this.headers };
    return this.http.get(`${this.url}/promo/promo/for-admin?status=${status}`, this.options).toPromise();
  }

  /////// EN PRUEBA ///////
}

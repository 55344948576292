<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-patrocinadores')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12">
            <form #form="ngForm">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title mb-4">Información de la actividad</h5>
                        <div class="row px-4 py-0">
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Nombre del patrocinador*</label>
                                <input class="form-control" name="nombrePatrocinador"
                                    [(ngModel)]="sponsor.nombrePatrocinador" required placeholder="Nombre de patrocinador" [disabled]="permissionCRM"/>
                                <div class="err-msg" *ngIf="validation_msg('nombrePatrocinador')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Nombre del usuario*</label>
                                <input class="form-control" name="nombreUsuario"
                                    [(ngModel)]="sponsor.nombreUsuario" required placeholder="Nombre de usuario" [disabled]="permissionCRM"/>
                                <div class="err-msg" *ngIf="validation_msg('nombreUsuario')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">NIT de empresa*</label>
                                <input class="form-control" name="nit" [(ngModel)]="sponsor.nit" placeholder="0000-0"
                                    required minlength="5" maxlength="10" pattern="[0-9]+[-][0-9 kK]*" [disabled]="permissionCRM"/>
                                <div class="err-msg" *ngIf="validation_msg('nit')"> 
                                    {{form && form.controls['nit'].errors!['required'] ? ' Este campo es obligatorio.' : ''}}
                                    {{form && form.controls['nit'].errors!['minlength'] ? ' Minimo caracteres 5.' : ''}}
                                    {{form && form.controls['nit'].errors!['pattern'] ? ' Nit no aceptado' : ''}}
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Descripción*</label>
                                <input class="form-control" name="descripcion"
                                    [(ngModel)]="sponsor.descripcion" required placeholder="Descripción" />
                                <div class="err-msg" *ngIf="validation_msg('descripcion')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Sitio web* (ej: https://www.sitio-web.com)</label>
                                <input class="form-control" name="website" pattern="^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$"
                                    [(ngModel)]="sponsor.website" required placeholder="https://www.sitio-web.com" />
                                <div class="err-msg" *ngIf="validation_msg('website')">
                                    {{form && form.controls['website'].errors!['required'] ? ' Este campo es obligatorio.' : ''}}
                                    {{form && form.controls['website'].errors!['pattern'] ? ' Web no aceptada, ej: https://www.sitio-web.com' : ''}}
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Correo electrónico*</label>
                                <input class="form-control" name="email"
                                    [(ngModel)]="sponsor.email" required placeholder="info@mail.com" [disabled]="permissionCRM"/>
                                    <!-- pattern="/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/igm" -->
                                <div class="err-msg" *ngIf="validation_msg('email')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Teléfono*</label>
                                <input class="form-control" name="telefono"
                                    [(ngModel)]="sponsor.telefono" required placeholder="0000-0000" [disabled]="permissionCRM"/>
                                <div class="err-msg" *ngIf="validation_msg('telefono')"> Este campo es obligatorio.</div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <label class="form-label mb-3">Ubicación*</label>
                                <input class="form-control" name="ubicacion"
                                    [(ngModel)]="sponsor.ubicacion" required placeholder="Ubicación" />
                                <div class="err-msg" *ngIf="validation_msg('ubicacion')"> Este campo es obligatorio.</div>
                            </div>
                        </div>

                        <hr class="divider-section">
                        <h5 class="card-title mb-4">Redes sociales</h5>
                        <div class="row px-4 py-0">
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="row">
                                    <label class="col-12 col-lg-3 col-form-label form-label">Facebook</label>
                                    <div class="col-12 col-lg-9">
                                        <input class="form-control" name="facebook" [(ngModel)]="sponsor.facebook" placeholder="Facebook" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="row">
                                    <label class="col-12 col-lg-3 col-form-label form-label">Twitter</label>
                                    <div class="col-12 col-lg-9">
                                        <input class="form-control" name="twitter" [(ngModel)]="sponsor.twitter" placeholder="Twitter" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="row">
                                    <label class="col-12 col-lg-3 col-form-label form-label">Instagram</label>
                                    <div class="col-12 col-lg-9">
                                        <input class="form-control" name="instagram" [(ngModel)]="sponsor.instagram" placeholder="Instagram" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="row">
                                    <label class="col-12 col-lg-3 col-form-label form-label">LinkedIn</label>
                                    <div class="col-12 col-lg-9">
                                        <input class="form-control" name="linkedIn" [(ngModel)]="sponsor.linkedin" placeholder="LinkedIn" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="divider-section">
                        <h5 class="card-title mb-4">Imagen</h5>
                        <div class="row px-4 py-0">
                            <div class="col-12 col-lg-6 mb-3">
                                <label class="form-label mb-3">Logotipo*</label>
                                <input class="d-none" type="file" accept="image/*" name="input-images-file" id="input-images-file" (change)="addFile($event)" (click)="mark_img('input-images-text')" required />
                                <input class="form-control w-100 input-file-readonly" name="input-images-text" id="input-images-text" [(ngModel)]="sponsor.newLogo.name" required readonly placeholder="Seleccione una imagen..." />
                                <i class="icon-input-file" onclick="document.getElementById('input-images-file').click();"></i>
                                <p class="note-small">Adjuntar imágen del logtipo de al menos 720px de ancho y 536px de alto.</p>
                                <div class="err-msg" *ngIf="validation_msg('input-images-text')"> Este campo es obligatorio.</div>
                                <div class="err-msg" *ngIf="imageSizeError">La imagen debe tener al menos 720px de ancho y 536px de alto.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary px-4 my-2 btn-cancel-edituseradmin"
                    (click)="goToPage('/admin/events/admin-patrocinadores')">Cancelar</button>
                &nbsp;
                <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" (click)="processData()" [disabled]="(disabled || !form.touched || !form.valid || imageSizeError)">
                    <i class="save-icon"></i>Guardar
                </button>                
            </div>
        </div>
    </div>
</div>
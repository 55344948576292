import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-filtros',
  templateUrl: './modal-filtros.component.html',
  styleUrls: ['./modal-filtros.component.css']
})
export class ModalFiltrosComponent implements OnInit {
  @Input() currentView: string = 'empresas';

  constructor() {}

  ngOnInit(): void {

  }

  clearFilters() {
    // Lógica para limpiar los filtros
  }

  search() {
    // Lógica para realizar la búsqueda con los filtros seleccionados
  }
}


import { KeyValue } from '@angular/common';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { ConferenceService } from 'src/app/Services/conference.service';
import { NgbCalendar, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ScheduleService } from 'src/app/Services/schedule.service';
import { listas } from 'src/app/Models/back-service.model';
import { SpeakerService } from 'src/app/Services/speaker.service';

interface Activity {
  id: number;
  lugar: string;
  eventId: number;
  direccion: string;
  nombreActividad: string;
  enlaceConferencia: string // enlace
  horaFin: string,
  horaInicio: string,
  speakerId: number | null;
  // modalidadID: number;
  modalityId: number | null;
  scheduleId: number | null;
  fechaFin: string, // NgbDateStruct | string,
  fechaInicio: string, // NgbDateStruct | string;
  description: string;
  fechaHoraFin: string;
  fechaHoraInicio: string;
}

@Component({
  selector: 'app-edit-conferencias',
  templateUrl: './edit-conferencias.component.html',
  styleUrls: ['./edit-conferencias.component.css'],
})
export class EditConferenciasComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  model!: NgbDateStruct;
  model2!: NgbDateStruct;
  fileCalendar: string = '';
  fileGafete: string = '';
  isShowDiv: boolean = true;

  public listQuestionBySpeaker: any[] = new Array();
  public listQuestionByActivity: any[] = new Array();
  public categoriesHeading: string[] = ['Nombre del Usuario', 'Pregunta', 'Correo electrónico', 'Visibilidad'];

  public eventID: number = 0;
  public edit: boolean = false;
  public activityID: number = 0;
  public activities: any[] = [];
  public listSchedule: any[] = [];
  public loading: boolean = false;
  public disabled: boolean = false;
  public listSpeaker: listas[] = [];
  public listModality: listas[] = [];
  public accessToken: string | null = '';
  public activity: Activity = {} as Activity;

  constructor(
    private utilsService: UtilsService,
    private speakerService: SpeakerService,
    private scheduleService: ScheduleService,
    private conferenceService: ConferenceService,
  ) {
    this.eventID = Number(sessionStorage.getItem('EventID'))
  }

  async ngOnInit(): Promise<void> {
    this.isShowDiv = true;
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    this.activityID = Number(this.utilsService.getParam('ID'));

    if (token && this.eventID > 0) {
      this.loading = true;
      await this.loadModality();
      await this.loadSpeaker(this.eventID);
      await this.loadSchedules(this.eventID);
      await this.loadModality();

      if (this.activityID > 0) {
        this.edit = true;
        this.getOneActivity(this.activityID);
      } else {
        this.activity.id = 0;
        this.activity.speakerId = null;
        this.activity.scheduleId = null;
        this.activity.modalityId = null;
        this.activity.eventId = this.eventID;
      }
      this.loading = false;
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡No tienes acceso!');
      this.goToPage('/login');
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName)
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validation_date(): boolean {
    if (this.activity.fechaInicio && this.activity.fechaFin) {
      const end = new Date(`${this.activity.fechaFin}  ${this.activity.horaFin}`);
      const star = new Date(`${this.activity.fechaInicio}  ${this.activity.horaInicio}`);
      
      if (star.getTime() > end.getTime()) {
        return true;
      } else {
        return false;
      }
    } {
      return false;
    }
  }

  showActions(event: any, index: number) {
    this.isShowDiv = true;
    const element = document.getElementById('item' + index);
    const listItes = document.getElementsByClassName('actions-enable')[0];
    if (listItes) {
      listItes.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItes?.id || !listItes.id) {
      element?.classList.add('actions-enable');
    }
  }

  changeSchedule($event: any) {
    const id = Number($event.target.value);
    const schedule = this.listSchedule.find(x => x.id === id);
    this.activity.lugar = schedule?.lugar;
    this.activity.horaFin = schedule?.horaFin;
    this.activity.fechaFin = schedule?.fechaFin;
    this.activity.modalityId = schedule?.modalidad;
    this.activity.horaInicio = schedule?.horaInicio;
    this.activity.fechaInicio = schedule?.fechaInicio;
  }

  async changeSpeaker($event: any) {
    this.loading = true;
    const speaker_id = Number($event.target.value);
    await this.getQuestionsBySpeaker(speaker_id);
    this.loading = false;
  }

  loadSchedules(event_id: number) {
    return this.scheduleService.getScheduleByEvent(event_id).then(
      response => {
        if (response.result.schedule_dates.length > 0) {
          for (let e of response.result.schedule_dates) {
            for (let i of e.schedule) {
              this.listSchedule.push({
                id: i.id,
                name: i.name,
                fechaInicio: e.date,
                fechaFin: e.endDate,
                lugar: i.description,
                modalidad: i.modalityId,
                horaInicio: i.hour.substr(0, 5),
                horaFin: i.endHour.substr(0, 5),
              });
            };
          };
        } else {
          this.utilsService.dialog('ERROR', response.message.title || 'Alerta', response.message.description || '¡Evento sin datos de agenda!');
        }
      },
      error => {
        console.log('aca');
        console.log(error);
        this.utilsService.dialog('ERROR', error.error.message.title || 'Error', error.error.message.description || 'Error al cargar agenda!');
      });
  }

  loadSpeaker(event_id: number) {
    return this.speakerService.getSpeakerByEvent(event_id).then(
      response => {
        if (response.success === true) {
          this.listSpeaker = response.result.map((e: any) => {
            return {
              id: e.id,
              name: e.participant?.nombreUsuario
            }
          });
        } else {
          console.log('error 1', response);
          this.utilsService.dialog('ERROR', 'Error', response.message.description || '¡Error al consultar conferencistas!');
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description || '¡Error al cargar conferencistas!');
      }
    );
  }

  loadModality() {
    return this.utilsService.getModalities().then(
      (response) => {
        this.listModality = response.result.modalities;
      },
      error => {
        console.log(error)
      }
    )
  }

  getOneActivity(activity_id: number) {
    this.loading = true;
    this.conferenceService.getOneActivity(activity_id).then(
      async (response) => {
        console.log('actividad', response.result)
        // this.getQuestionsByActivity(activity_id);
        
        console.log('speaker', response.result.speakerId)
        this.getQuestionsBySpeaker(response.result.speakerId)

        this.activity.eventId = this.eventID;
        this.activity.id = response.result?.id;
        this.activity.lugar = response.result?.direccion;
        this.activity.modalityId = response.result?.modalityId || null;
        // this.activity.modalidadID = response.result.modalityId || null;
        this.activity.enlaceConferencia = response.result?.enlaceConferencia;
        this.activity.nombreActividad = response.result?.nombreActividad;
        this.activity.horaFin = response.result.fechaHoraFin ? response.result.fechaHoraFin.substr(11, 5) : null;
        this.activity.horaInicio = response.result.fechaHoraInicio ? response.result.fechaHoraInicio.substr(11, 5) : null;

        const valSchedule = this.listSchedule.find(x => x.id === Number(response.result.scheduleId || 0));
        if (valSchedule) {
          this.activity.scheduleId = valSchedule.id;
          this.activity.fechaFin = valSchedule.fechaFin;
          this.activity.fechaInicio = valSchedule.fechaInicio;
        } else {
          this.activity.fechaFin = '';
          this.activity.fechaInicio = '';
          this.activity.scheduleId = null;
        }

        const valSpeaker = this.listSpeaker.find(x => x.id === Number(response.result.speakerId || 0));
        valSpeaker ? this.activity.speakerId = response.result.speakerId : this.activity.speakerId = null;

        const valModality = this.listModality.find(x => x.id === Number(response.result.modalityId || 0));
        valModality ? this.activity.modalityId = response.result.modalityId : this.activity.modalityId = null;

      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar actividad!');
        this.goToPage('/admin/events/admin-agenda');
      }).finally(() => {
        console.log('modelo: ', this.activity);
        this.loading = false;
      })
  }

  getQuestionsByActivity(activity_id: number) {
    this.conferenceService.getQuestionsByActivity(activity_id).then(
      (response) => {
        console.log('preguntas: ', response.result)
        if (response.result.length > 0) {
          this.listQuestionByActivity = response.result.map((e: any) => {
            return {
              id: e?.id,
              'Nombre del Usuario': e?.nombreUsuario,
              'Pregunta': e?.pregunta,
              'Correo electrónico': e?.email,
              'Visibilidad': e.visible === true ? '1' : '0'
            }
          });
        } else {
          this.listQuestionByActivity = [];
        }
      },
      error => console.log(error)
      );
  }

  getQuestionsBySpeaker(speaker_id: number) {
    return this.conferenceService.getQuestionsBySpeaker(speaker_id).then(
      (response) => {
        console.log('speaker: ', response.result)
        if (response.result.length > 0) {
          this.listQuestionBySpeaker = response.result.map((e: any) => {
            return {
              id: e?.id,
              'Nombre del Usuario': e?.nombreUsuario,
              'Pregunta': e?.pregunta,
              'Correo electrónico': e?.email,
              'Visibilidad': e.visible === true ? '1' : '0'
            }
          });
        } else {
          this.listQuestionBySpeaker = [];
        }
      },
      error => console.log(error)
      );
  }

  async processData() {
    if (this.form.valid) {
      this.activity.direccion = this.activity.lugar;
      this.activity.modalityId = Number(this.activity.modalityId); // this.activity.modalidadID = Number(this.activity.modalityId);
      this.activity.fechaHoraFin = this.activity.fechaFin + ' ' + this.activity.horaFin;
      this.activity.fechaHoraInicio = this.activity.fechaInicio + ' ' + this.activity.horaInicio
      // this.activity.fechaHoraFin = `${this.activity.fechaFin.year}-${this.activity.fechaFin.month}-${this.activity.fechaFin.day} ${this.activity.horaFin}`;
      // this.activity.fechaHoraInicio = `${this.activity.fechaInicio.year}-${this.activity.fechaInicio.month}-${this.activity.fechaInicio.day} ${this.activity.horaInicio}`;

      if (this.activity.id === 0) {
        await this.save(this.activity);
      } else {
        // this.utilsService.dialog('ERROR', 'Advertencia', '¡Método no implementado!');
        await this.update(this.activity);
      }

      this.loading = false;
      this.disabled = false;
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos obligatorios.');
    }
  }

  save(activity: Activity) {
    console.log('SAVE ', activity);

    this.loading = true;
    this.disabled = true;

    return this.conferenceService.createActivity(activity).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', response.message.title, response.message.description);
        // this.utilsService.dialog('SUCCESS', 'Éxito', '¡Actividad registrada!');
        this.goToPage('/admin/events/admin-conferencias');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', error.error.message.title || 'Error', error.error.message.description || '¡Al registrar actividad!');
      }).finally(() => {
        this.loading = false
        this.disabled = false;
      });
  }

  update(activity: Activity) {
    console.log('UPDATE ', activity);

    this.loading = true;
    this.disabled = true;

    return this.conferenceService.editActivity(activity.id, activity).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.title); //  response.message.description
        // this.utilsService.dialog('SUCCESS', 'Éxito', '¡Actividad actualizada!');
        this.goToPage('/admin/events/admin-conferencias');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', error.error.message.title || 'Error', error.error.message.description || '¡No se pudo actualizar la actividad!');
      }).finally(() => {
        this.loading = false
        this.disabled = false;
      });
  }

  updateVisibility($event: any, index: number, question_id: number) {
    this.loading = true;
    let preventValue = '';
    const visible = Boolean(Number($event.target.value))
    visible === true ? preventValue = '0' : preventValue = '1';
    const element = document.getElementById('visibilidad_' + index)!;

    this.conferenceService.updateQuestion(question_id, { visible: visible }).then(
      response => {
        console.log(response)
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.title);
        visible === true ? element.classList.remove('select-disable') : element?.classList.add('select-disable');
      },
      error => {
        console.log(error);
        $event.target.value = preventValue;
        this.listQuestionByActivity[index].Visibilidad = preventValue;
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
        // visible === true ? element?.classList.add('select-disable') : element.classList.remove('select-disable');
        // this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  deleteQuestion(data: any, questions_id: number, index: number) {
    this.loading = true;
    this.conferenceService.deleteQuestion(questions_id).then(
      response => {
        // this.utilsService.dialog('SUCCESS', 'Éxito', 'Participante eliminado');
        this.getQuestionsByActivity(this.activityID);
        this.utilsService.dialog('SUCCESS', response.message.title, response.message.description);
      },
      error => {
        console.log(error)
      }
    ).finally(() => {
      this.loading = false;
    });
  }
}
function moment(value: string, DT_FORMAT: string) {
  throw new Error('Function not implemented.');
}


import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
// import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/Services/utils.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private utilsService: UtilsService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.tokenExpired()) {
      request = this.addTokenToRequest(request);
    } else {
      sessionStorage.removeItem('Token');
      this.logout();
      throw new Error;
    }
    return next.handle(request);
  }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const token = sessionStorage.getItem('Token')?.toString();
    return request.clone({
      setHeaders: {
        'x-access-token': token! || ''
      }
    });
  }

  tokenExpired(): boolean {
    const tokenDateExpiration = sessionStorage.getItem('TokenExpiration')?.toString() || '';
    const expirationDate = new Date(parseInt(tokenDateExpiration)).getTime();
    return new Date().getTime() >= expirationDate;
  }

  logout() {
    // environment.token = '';
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate([`/login`]);
    this.utilsService.dialog('ERROR', 'Alerta', 'Sesión expirada, inicia sesión de nuevo.')
  }
}

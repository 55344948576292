import * as FileSaver from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ParticipantService } from 'src/app/Services/participant.service';

@Component({
  selector: 'app-gafete-dialog',
  templateUrl: './gafete-dialog.component.html',
  styleUrls: ['./gafete-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GafeteDialogComponent {
  @Input() type?: string;
  @Input() codeValue!: string;

  @Input() ID!: string;
  @Input() PDF!: string;
  @Input() eventID!: number;
  @Input() tipoEntrada!: string;
  @Input() nombrePuesto!: string;
  @Input() nombreEmpresa!: string;
  @Input() nombreUsuario!: string;
  @Input() userID!: number | null;
  @Input() invitadoEspecial!: string;
  @Input() imageBadgeHeader!: string;
  @Input() imageBadgeFooter!: string;
  @Input() participantID!: number | null;

  constructor(
    private modalService: NgbModal,
    private participantService: ParticipantService
  ) { }

  close() {    
    this.modalService.dismissAll(this);
  }

  async download(route: string) {
    const fileName: string = `Gafete-${this.nombreUsuario}.pdf`;
    await this.participantService.getBadge(this.eventID, Number(this.participantID)).then(
      response => {
        FileSaver.saveAs(response.result.pdf, fileName);
      }
    );
    
    // METHOD 2
    // const fetching = await fetch(route);
    // const blob = new Blob([route], { type: "application/pdf"});
    // FileSaver.saveAs(blob, fileName);
    // https://github.com/eligrey/FileSaver.js/issues/557
    // https://you.com/search?q=convert+pdf+file+to+blob+javascript&tbm=youchat&cfr=chatb&cid=c2_7a7268b8-9ca2-47b0-81f9-34536088a9a9
  }

  // download2(url: any, fileName: string) {
  //   const link = document.createElement("a");
  //   link.target = "_blank";
  //   link.href = url;
  //   link.click();
  // }
}

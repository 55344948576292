<mat-sidenav-container class="main-container">
    <mat-sidenav class="side-bar-user d-none d-sm-block" mode="side" [opened]="true">
        <mat-action-list>
            <!-- <mat-list-item *ngFor="let m of user_menu" class="menu-item" [routerLinkActive]="['is-active']" routerLink="{{m.routerLink}}" [ngClass]="routerInit === '/admin/users' && m.id === 'empresas' ? 'is-active' : ''"> -->
            <mat-list-item *ngFor="let m of user_menu" class="menu-item" routerLink="{{m.routerLink}}" 
                    [ngClass]="
                    (m.id === 'empresas' && (routerInit === 'users' || routerInit === 'user-guide'))
                    || routerInit === m.routerLink || routerInit === m.routerLinkEdit ? 'is-active' : ''"
                    >
                <img class="menu-icon" id="{{m.id}}" src="{{m.src}}" alt="logo" />
                <p class="label-menu">Cuentas de<br><strong>{{m.account}}</strong></p>
            </mat-list-item>
        </mat-action-list>        
    </mat-sidenav>
    <mat-sidenav-content class="content-container"><router-outlet></router-outlet></mat-sidenav-content>
</mat-sidenav-container>
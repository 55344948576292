<mat-sidenav-container class="main-container">
    <mat-sidenav class="side-bar-event d-none d-sm-block" mode="side" [opened]="true">
        <mat-action-list>
            <!-- [routerLinkActive]="['is-active']" Se elimina para poder utilizar [ngClass] para un menu activo -->
            <!-- [ngClass]="{'is-disabled': enableFairs}" -->
            <!-- [ngClass]="routerInit === '/admin/events' ? 'is-active' : ''" -->
            <!-- [ngClass]="routerInit === 'nuevo-evento' || routerInit === 'calendario-eventos' ? 'is-active' : ''" -->

            <mat-list-item class="menu-item" [routerLink]="['calendario-eventos']"
                [ngClass]="routerInit === 'events' || routerInit === 'nuevo-evento' || routerInit === 'calendario-eventos' ? 'is-active' : ''">
                <img class="menu-icon" id="evento" src="..\..\assets\images\menuEventIcon.svg" alt="logo" />
                <p class="label-menu">Información de<br><strong>Evento</strong></p>
            </mat-list-item>

            <!-- Agenda -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-agenda']"
                [ngClass]="routerInit === 'admin-agenda' || routerInit === 'edit-agenda' ? 'is-active' : ''">
                <img class="menu-icon" id="agenda" src="..\..\assets\images\menuAgendaIcon.svg" alt="logo" />
                <p class="label-menu"><strong>Agenda</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="agenda" src="..\..\assets\images\menuAgendaIcon.svg" alt="logo" />
                <p class="label-menu-disabled"><strong>Agenda</strong></p>
            </mat-list-item>

            <!-- Participantes -->
            <mat-list-item *ngIf="enableParticipants" class="menu-item" [routerLink]="['admin-participantes']"
                [ngClass]="routerInit === 'admin-participantes' || routerInit === 'user-guide-participantes' ? 'is-active' : ''">
                <img class="menu-icon" id="participantes" src="..\..\assets\images\MenuPartIcon.svg" alt="logo" />
                <p class="label-menu"><strong>Participantes</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableParticipants" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="participantes" src="..\..\assets\images\MenuPartIcon.svg"
                    alt="logo" />
                <p class="label-menu-disabled"><strong>Participantes</strong></p>
            </mat-list-item>

            <!-- Conferencistas -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-conferencistas']"
                [ngClass]="routerInit === 'admin-conferencistas' || routerInit === 'user-guide-conferencistas' ? 'is-active' : ''">
                <img class="menu-icon" id="conferencistas" src="..\..\assets\images\MenuConferencistasIcon.svg"
                    alt="logo" />
                <p class="label-menu"><strong>Conferencistas</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="conferencistas" src="..\..\assets\images\MenuConferencistasIcon.svg"
                    alt="logo" />
                <p class="label-menu-disabled"><strong>Conferencistas</strong></p>
            </mat-list-item>

            <!-- Programa de Conferencias -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-conferencias']"
                [ngClass]="routerInit === 'admin-conferencias' || routerInit === 'edit-conferencias' ? 'is-active' : ''">
                <img class="menu-icon" id="conferencias" src="..\..\assets\images\MenuConfIcon.svg" alt="logo" />
                <p class="label-menu">Programa de<br><strong>Conferencias</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="conferencias" src="..\..\assets\images\MenuConfIcon.svg"
                    alt="logo" />
                <p class="label-menu-disabled">Programa de<br><strong>Conferencias</strong></p>
            </mat-list-item>

            <!-- Mapa de Exhibición -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-stands']"
                [ngClass]="routerInit === 'admin-stands' || routerInit === 'edit-stands' ? 'is-active' : ''">
                <img class="menu-icon" id="mapa" src="..\..\assets\images\MenuStandsIcon.svg" alt="logo" />
                <p class="label-menu">Mapa de<br><strong>Exhibición</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs"
                class="menu-item-disabled">
                <img class="menu-icon-disabled" id="mapa" src="..\..\assets\images\MenuStandsIcon.svg" alt="logo" />
                <p class="label-menu-disabled">Mapa de<br><strong>Exhibición</strong></p>
            </mat-list-item>

            <!-- Directorio de Expositores -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-expositores']"
                [ngClass]="routerInit === 'admin-expositores' || routerInit === 'user-guide-expositores' ? 'is-active' : ''">
                <img class="menu-icon" id="expositores" src="..\..\assets\images\MenuExpoIcon.svg" alt="logo" />
                <p class="label-menu">Directorio de<br><strong>Expositores</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="expositores" src="..\..\assets\images\MenuExpoIcon.svg"
                    alt="logo" />
                <p class="label-menu-disabled">Directorio de<br><strong>Expositores</strong></p>
            </mat-list-item>

            <!-- Galería de Fotos y videos -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-galeria']"
                [routerLinkActive]="['is-active']">
                <img class="menu-icon" id="galeria" src="..\..\assets\images\MenugaleryIcon.svg" alt="logo" />
                <p class="label-menu">Galería de<br><strong>Fotos y videos</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="galeria" src="..\..\assets\images\MenugaleryIcon.svg" alt="logo" />
                <p class="label-menu-disabled">Galería de<br><strong>Fotos y videos</strong></p>
            </mat-list-item>

            <!-- Ubicaciones -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-ubicaciones']"
                [ngClass]="routerInit === 'admin-ubicaciones' || routerInit === 'edit-ubicaciones' ? 'is-active' : ''">
                <img class="menu-icon" id="ubicaciones" src="..\..\assets\images\MenuLocationIcon.svg" alt="logo" />
                <!-- <p class="label-menu"><strong>Ubicaciones</strong></p> -->
                <p class="label-menu">Ubicaciones y<br><strong>Parqueos</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="ubicaciones" src="..\..\assets\images\MenuLocationIcon.svg" alt="logo" />
                <!-- <p class="label-menu-disabled"><strong>Ubicaciones</strong></p> -->
                <p class="label-menu-disabled">Ubicaciones y<br><strong>Parqueos</strong></p>
            </mat-list-item>

            <!-- Patrocinadores -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-patrocinadores']"
                [ngClass]="routerInit === 'admin-patrocinadores' || routerInit === 'edit-patrocinadores' || routerInit === 'user-guide-patrocinadores' ? 'is-active' : ''">
                <img class="menu-icon" id="patrocinadores" src="..\..\assets\images\MenuSponsorIcon.svg" alt="logo" />
                <p class="label-menu"><strong>Patrocinadores</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">            
                <img class="menu-icon-disabled" id="patrocinadores" src="..\..\assets\images\MenuSponsorIcon.svg"
                    alt="logo" />
                <p class="label-menu-disabled"><strong>Patrocinadores</strong></p>
            </mat-list-item>
            
            <!-- Encuestas -->
            <mat-list-item *ngIf="enableFairs" class="menu-item" [routerLink]="['admin-encuestas']" [routerLinkActive]="['is-active']">
                <img class="menu-icon" id="encuestas" src="..\..\assets\images\MenuEncuestaIcon.svg" alt="logo" />
                <p class="label-menu"><strong>Encuestas</strong></p>
            </mat-list-item>
            <mat-list-item *ngIf="!enableFairs" class="menu-item-disabled">
                <img class="menu-icon-disabled" id="encuestas" src="..\..\assets\images\MenuEncuestaIcon.svg"
                    alt="logo" />
                <p class="label-menu-disabled"><strong>Encuestas</strong></p>
            </mat-list-item>
        </mat-action-list>

        <!-- 
            <mat-list-item *ngIf="!environment && enableFairs" class="menu-item" [routerLink]="['admin-stands']"    <- Validación de habilitación de modulos en prod/dev
            <mat-list-item *ngIf="(!enableFairs && !environment) || (environment && (!enableFairs || enableFairs))" <- Complemento de habilitación de modulos en prod/dev
        -->
        <!-- <button class="event-btn" *ngIf="!environment" (click)="goToPage('/admin/events/nuevo-evento')">Crear evento</button> -->
        <!-- <button class="draft-btn" (click)="open()">Guardar Borrador</button> -->
    </mat-sidenav>
    <mat-sidenav-content class="content-container"><router-outlet></router-outlet></mat-sidenav-content>
</mat-sidenav-container>
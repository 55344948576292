import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // NgbActiveModal, ModalDismissReasons,
import { Component, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  // standalone: true, // Ayuda a no tener que declara la ruta en el module
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent {
  @Input() type?: string;
  @Input() page?: string;
  @Input() title?: string;
  @Input() icono?: string;
  @Input() message?: string;
  @Input() codeValue!: string;

  @Output() response: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) { }

  close(result: boolean | string) {
    this.modalService.dismissAll(result); // .dismissAll(this)
    (this.page) && ( this.goToPage(this.page) );
  }

  acept(){
    this.response.emit(true);
    this.close(true);
  }

  cancel(){
    this.response.emit(false);
    this.close(false);
  }

  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }
}

import { Time } from "@angular/common";

export class BackService {
    username?: any;
    password?: string;
    role?: string;
    accessToke?: string;
    userID?: string;
    newPassword?: string;
}

export class BackServiceUsers {
    id?: any;
    username?: any;
    firstName?: string;
    secondName?: string;
    firstLastname?: string;
    secondLastname?: string;
    email?: string;
    personalNIT?: string;
    phone?: string;
    role?: string | [any];
    roles?: string | [any];
    companyId?: any;
    birthday?: Date;
    country_id?: any;
    gender_id?: any;
    permissions?: string[];
    areaOrganizacional?: any;
    area?: any;
    puesto?: any;
    puesto_id?: any;
    areaId?: any;
    gender?: any;
    country?: any;
    password?: any;
    ownerContact?: boolean;

}

export interface AccountRelation {
    id: number | null,
    area: any | {},
    puesto: any | {},
    company: any | {},
    additional: boolean,
    idCrm: string | null,
    ownerContact: boolean,
    areaId: number | null,
    puestoId: number | null,
    companyId: number | null
  }

export class BackServiceEvents {
    id?: string;
    title?: any;
    descripcion?: string;
    direccion?: string;
    enlaceEvento?: string;
    archivoCalendar?: string;
    enlaceCarretilla?: string;
    cupoLimite?: string;
    fechaInicio?: Date | string;
    fechaFinal?: Date | string;
    horaInicio: string = ''; // ?: Time;
    horaFinal: string = ''; // ?: Time;
    creadorId?: any;
    asociadosInvitados?: any;
    noAsociadosInvitados?: any;
    status?: any;
    enlaceSitio?: any;
    nombreEvento: any;
    needBadge?: number;
    modalityId: number = 0;
    // modality: { id: number, name: string } = { id: 0, name: ''}
    costTypeId?: number;
    // costType: { id: number, name: string } = { id: 0, name: ''}
    guestTypeId: number = 0;
    // guestType: { id: number, name: string } = { id: 0, name: ''}
    eventTypeId: number = 0;
    // eventType: { id: number, name: string } = { id: 0, name: ''}
    eventCategoryId: number = 0;
    // eventCategory: { id: number, name: string } = { id: 0, name: ''}
    thumbEvent: { extension: string, data: string, name: string, url?: string } = { extension: '', data: '', name: '' }; 
    imageEvent: { extension: string, data: string, name: string, url?: string } = { extension: '', data: '', name: '' }; // imageThumbnail
    imageBadgeHeader: { extension: string, data: string, name: string, url?: string } = { extension: '', data: '', name: '' }; //: any;
    imageBadgeFooter: { extension: string, data: string, name: string, url?: string } = { extension: '', data: '', name: '' }; //: any;
}

export class BackServiceParticipants {
    id?: any;
    sector?: any;
    comision?: any;
    nombreUsuario?: any;
    nombreEmpresa?: any;
    nit?: any;
    asociado?: any;
    rol?: any;
    tipoEntrada?: any;
    asistencia?: any;
    registro?: any;
    eventId: any;
    userId: any;
    createdAt?: Date;
    updatedAt?: Date;
    event = { id: '', nombreEvento: '' }

}

export class BackServiceSpeakers {
    eventId?: any;
    id?: any;
    nombreConferencista?: any;
    profesion?: any;
    email?: any;
    informacionConferencista?: any;
    fotoConferencista?= { extension: '', data: '' };
}


export interface listas {
    id?: any;
    key?: any;
    name?: any;
}

export class userbyEvent {
    userId: any;
    eventId: any;
}

export class BackServiceCompanies {
    id?: any;
    nombreComercial?: any;
    razonSocial?: any;
    nit?: any;
    codigoCRM?: any;
    telefonoGeneral?: any;
    telefono2?: any;
    emailEmpresarial?: any;
    paginaWeb: any;
    facebook?: any;
    twitter?: any;
    instagram?: any;
    linkedin?: any;
    codigoSap?: any;
    direccion?: any;
    dataXport?: any;
    socio?: any;
    status?: any;
    createdAt?: any;
    updatedAt?: any;
    companyTypeId?: any;
    comiteId?: any;
    comisioneId?: any;
    comisionId?: any;
    companyType?= { id: '', name: '' };
    comisione?: any;
    comite?: any;
    image?: string;
    // image?= { extension: '', data: '', url: '' };
    newImage?= { extension: '', data: '', url: '' };
}

export class BackServiceGallery {
    size?: any;
    url: string = '';
    type: string = '';
    file: string = '';
    eventId?: any;
    fileName?: any;
    imageTest?: any;
    dateCreated?: any;
    idEventMedia?: any;
    userCreated?: number | string;
}

export class BackServiceLocation {
    icon?: any;
    color?: any;
    textColor?: any;
    nombrevento?: any;
    locationUrl?: any;
    backgroundColor?: any;

    //NEW STRUCT
    id: number = 0;
    eventId: number = 0;
    nombreUbicacion: string = ''; // title: string = '';
    direccion: string = '';
    locationId: number = 0;
    horaInicio: string = '';
    fechaInicio: string = ''; //  {year: number, month: number, day: number} = { year: 0, month: 0, day: 0 }; // fechaInicio?: any;
    horaFin: string = ''; // horaFin?: any;
    fechaFin: string = ''; //{year: number, month: number, day: number} = { year: 0, month: 0, day: 0 };  // fechaFin?: any;
    enlaceUbicacion: string = ''; // enlaceUbicacion?: any;

    //EXTRA EVALUAR
    fechaHoraFin: string = '';
    fechaHoraInicio: string = '';
}

export class BackServiceParking {
    color?: any;
    icon?: any;
    textColor?: any;
    nombrevento?: any;
    backgroundColor?: any;

    //NEW STRUCT
    id: number = 0;
    direccion: string = '';
    locationId: number = 0;
    horaInicio: string = '';
    fechaInicio: string = ''; //{year: number, month: number, day: number} = { year: 0, month: 0, day: 0 }; // fechaInicio?: any;
    horaFin: string = '';
    fechaFin: string = ''; //{year: number, month: number, day: number} = { year: 0, month: 0, day: 0 };  // fechaFin?: any;
    nombreParqueo: string = '';
    enlaceUbicacion: string = '';

    //EXTRA EVALUAR
    fechaHoraInicio: string = '';
    fechaHoraFin: string = '';
}

export class BackServiceActivities {
    id?: any;
    direccion?: any;
    modalityId?: any;
    fechaHoraFin?: any;
    nombreActividad?: any;
    fechaHoraInicio?: any;
}

export class BackServiceQuestions {
    id?: any;
    email?: any;
    userId?: any;
    visible?: any;
    pregunta?: any;
    nombreUsuario?: any;
}

export class BackServiceAsistencia {
    id?: any;
    userId?: any;
    activityId?: any;
}

export class BackServiceSponsors {
    id?: number;
    nit: string = '';
    email: string = '';
    eventId: number = 0;
    twitter: string = '';
    website: string = '';
    telefono: string = '';
    facebook: string = '';
    linkedin: string = '';
    instagram: string = '';
    ubicacion: string = '';
    descripcion: string = '';
    nombreUsuario: string = '';
    nombrePatrocinador: string = '';
    newLogo: { extension: string, data: string, name: any | {}, url: string } = { extension: '', data: '', name: '', url: '' };
}

export class BackServiceStand {
    id: number = 0;
    stand: string = '';
    floorId: number = 0;
    userCreated: number = 0;
    companyName: string | undefined = '';
    image64: string = '';
    image: { extension: string, name: string } = { extension: '', name: '' };
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ExhibitorService {
  private params: HttpParams;
  private headers: HttpHeaders;
  private url: string = environment.url;
  private options: { headers: HttpHeaders; params?: HttpParams };

  constructor(private http: HttpClient) {
    this.params = new HttpParams();
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('accept', 'application/json');
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.options = { headers: this.headers };
  }

  // Obtener todos los expositores
  getAllExhibitors(page: number, size: number): Promise<any> {
    return this.http
      .get(
        `${this.url}eventos/exhibitors/?page=${page}&size=${size}`,
        this.options
      )
      .toPromise();
  }

  // Obtener expositores de un evento
  getExhibitorsByEvent(page: number | null, size: number | null, event_id: number | null, filter: string | null): Promise<any> {
    this.params = new HttpParams();
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    (event_id != null) && (this.params = this.params.set('eventId', event_id));
    (filter != null) && (this.params = this.params.set('filter', filter));
    this.options = { headers: this.headers, params: this.params };

    return this.http.get(`${this.url}eventos/exhibitors/`, this.options).toPromise();
  }

  getContactByCompany(page: number | null, size: number | null, company_id: number | null): Promise<any> {
    this.params = new HttpParams();
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}registro/users/${company_id}`, this.options).toPromise();
  }


  // Obtener un expositor
  getOneExhibitors(exhibitors_id: number): Promise<any> {
    return this.http.get(`${this.url}eventos/exhibitors/${exhibitors_id}`, this.options).toPromise();
  }

  // Crear un expositor
  createExhibitorToEvent(data: any): Promise<any> {
    return this.http.post(`${this.url}eventos/exhibitors/`, data, this.options).toPromise();
  }

  // Añadir un expositor a un evento
  addExhibitor(data: {}): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers, params: this.params };
    return this.http.post(`${this.url}eventos/exhibitors/`, data, this.options).toPromise();
  }

  // Actualiar un expositor
  updateExhibitorsByEvent(exhibitors_id: number, data: any): Promise<any> {
    // const urlWithEventId = `${this.url}eventos/exhibitors/${exhibitors_id}?eventId=${eventId}`;
    // return this.http.put(urlWithEventId, data, this.options).toPromise();
    const eventId: number = data.eventId;
    this.params = new HttpParams();
    this.params = this.params.set('eventId', eventId)
    this.options = { headers: this.headers, params: this.params };
    return this.http.post(`${this.url}eventos/exhibitors/${exhibitors_id}`, data, this.options).toPromise();
  }

  // Eliminar un expositor
  deleteExhibitors(exhibitors_id: number): Promise<any> {
    return this.http
      .delete(`${this.url}eventos/exhibitors/${exhibitors_id}`, this.options)
      .toPromise();
  }

  // Eliminar un expositor desde lista de empresas
  deleteExhibitorsToEvent(exhibitors_id: number): Promise<any> {
    return this.http
      .delete(`${this.url}eventos/exhibitors/${exhibitors_id}`, this.options)
      .toPromise();
  }
}

<app-loading *ngIf="loading"></app-loading>
<!DOCTYPE html>
<html class="login-body">
<div class="header">
    <img src="..\..\assets\images\Agexport-logo.svg" alt="logo" />
</div>
<div class="login">
    <form ngNativeValidate class="custom-form" method="post" #form>
        <h1>Iniciar sesión</h1>
        <hr class="solid">
        <label>Correo Electrónico</label>
        <div class="custom-input">
            <input [(ngModel)]="email" type="email" name="email" required
                oninvalid="this.setCustomValidity('Email inválido')" oninput="setCustomValidity('')" />
        </div>
        <label>Contraseña</label>
        <div class="custom-input">
            <input class="custom-input" [(ngModel)]="password" name="password" required id="password"
                [type]="show ? 'text' : 'password'" oninvalid="this.setCustomValidity('Este campo es requerido')"
                oninput="setCustomValidity('')" />
            <i class="pass-eye" id="eye" (click)="passwordVisibility()"></i>
        </div>
        <p class="custom-label">¿Has olvidado la contraseña?<a href="/recovery"> Recuperar aquí</a></p>
        <button id="submit-btn" type="submit" (click)="form.checkValidity()? login() : null">Ingresar</button>
    </form>
</div>

</html>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class GalleryService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };
    // private accessToken: string = sessionStorage.getItem('Token') || environment.token != '' ? sessionStorage.getItem('Token') || environment.token : '';

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        // this.headers = this.headers.set('X-Access-Token', this.accessToken);
        this.options = { headers: this.headers };
    }

    // Obtener imagenes del evento
    getGalleryByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}gallery/media/?idEvent=${event_id}`, this.options).toPromise();
    }

    // Agregar una imagen
    addGalleryToEvent(data: any): Promise<any> {
        return this.http.post(`${this.url}gallery/media/`, data, this.options).toPromise();
    }

    // Eliminar una imagen de un evento
    deleteGallery(ID: number): Promise<any> {
        return this.http.delete(`${this.url}gallery/media/${ID}`, this.options).toPromise();
    }
}

<app-loading *ngIf="loading"></app-loading>
<div class="header">
  <div class="top-block-1">
    <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-expositores')"></i>
    <span class="change-page-label"> Atrás </span>
    <div class="next-container">
      <span class="change-page-label"> Siguiente </span>
      <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-ubicaciones')"></i>
    </div>
  </div>
  <div class="btn-group">
    <select name="tipo" id="tipo" class="registro-drop" #selects (change)="filterType($event)">
      <option value="all" selected>Todos los medios</option>
      <option *ngFor="let x of types" value="{{ x }}">{{ x }}</option>
    </select>
    <select name="fechas" id="fechas" class="registro-drop" #selects (change)="filterDate($event)">
      <option value="allDays" selected>Todos las fechas</option>
      <option *ngFor="let x of days" value="{{ x }}">{{ x }}</option>
    </select>
    <button class="agregar" (click)="showModal('NEW')">
      + Agregar archivo
    </button>
  </div>
</div>
<div class="main">
  <div class="top-main">
    <div>
      <mat-grid-list [cols]="9" rowHeight="2:2" [gutterSize]="'10px'">
        <mat-grid-tile class="gallery-grid" *ngFor="let x of allImages; let i = index">
          <img src="{{ x.url }}" class="tile-image" (click)="showModal('SHOW', x)" />
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
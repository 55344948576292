<app-loading *ngIf="loading"></app-loading>
<div class="main">
    <div class="header">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-galeria')"></i>
            <span class="change-page-label"> Atrás </span>
            <div class="next-container">
                <span class="change-page-label"> Siguiente </span>
                <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-patrocinadores')"></i>
            </div>
        </div>
        <div class="btn-group">
            <button class="agregar" id="addBtn" (click)="goToPage('/admin/events/edit-ubicaciones')">+ Agregar Ubicación</button>
        </div>
    </div>

    <div class="tab-container">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">

            <!-- UBICACIONES -->
            <li [ngbNavItem]="1">
                <a ngbNavLink>Ubicaciones</a>
                <ng-template ngbNavContent>
                    <div class="table-default">
                        <table class="content-table" border="1">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let each of categoriesHeading">
                                        <th *ngIf="each !== 'Fecha'" > {{each}} </th>
                                    </ng-container>
                                    <th></th>
                                </tr>
                            </thead>            

                            <tbody *ngFor="let e of datesLocation" >
                                <tr id="dateRow" class="date_Row" >
                                    <td colspan="6">{{ e.date.toString() |  date:'EEEE, MMMM d, y' | titlecase }}</td>
                                </tr>
                                <tr *ngFor="let item of e.data; let i = index">
                                    <ng-container >
                                        <ng-container *ngFor="let x of item | keyvalue:originalOrder" >
                                            <td *ngIf="x.key != 'Estatus'" [ngClass]="{'nombre' : x.key === 'Evento'}" [hidden]="x.key === 'id'"> {{x.value}} </td>
                                            <td *ngIf="x.key === 'Estatus'">
                                                <a class="btn btn-link-maps" href="{{x.value}}" target="_blank">Ver mapa</a>
                                            </td>
                                        </ng-container>
                                        <td (mouseleave)="hideActions()">
                                            <button name="actions" id="actions" class="actions" (click)="showActions(item.id)" (keyup)="showActions(item.id)"></button>
                                            <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{item.id}}" (mouseleave)="hideActions()">
                                                <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="edit('location', item.id)">Editar</button>                                                                           
                                                <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="remove('location', item.id)">Eliminar</button>
                                            </div>                              
                                        </td>                                
                                    </ng-container>
                                </tr>
                            </tbody>    
                        </table>
                    </div>
                </ng-template>
            </li>

            <!-- PARQUEOS -->
            <li [ngbNavItem]="2">
                <a ngbNavLink>Parqueos</a>
                <ng-template ngbNavContent>
                    <div class="table-default">
                        <table class="content-table" border="1">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let each of categoriesHeading">
                                        <th *ngIf="each !== 'Fecha'"> {{each}} </th>
                                    </ng-container>
                                    <th></th>
                                </tr>
                            </thead>            

                            <tbody *ngFor="let e of datesParking">
                                <tr id="dateRow" class="date_Row">
                                    <td colspan="6">{{ e.date.toString() |  date:'EEEE, MMMM d, y' | titlecase}}</td>
                                </tr>
                                <tr *ngFor="let item of e.data; let i = index">
                                    <ng-container>
                                        <ng-container *ngFor="let x of item | keyvalue:originalOrder" >
                                            <td *ngIf="x.key != 'Estatus'" [ngClass]="{'nombre' : x.key === 'Evento'}" [hidden]="x.key === 'id'"> {{x.value}} </td>
                                            <td *ngIf="x.key === 'Estatus'">
                                                <a class="btn btn-link-maps" href="{{x.value}}" target="_blank">Ver mapa</a>
                                            </td>
                                        </ng-container>
                                        <td (mouseleave)="hideActions()">
                                            <button name="actions" id="actions" class="actions" (click)="showActions(item.id)" (keyup)="showActions(item.id)"></button>
                                            <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{item.id}}" (mouseleave)="hideActions()">
                                                <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="edit('parking', item.id)">Editar</button>                                                                           
                                                <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="remove('parking', item.id)">Eliminar</button>
                                            </div>                              
                                        </td>                                
                                    </ng-container>
                                </tr>
                            </tbody>    
                        </table>
                    </div>
                </ng-template>                
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
import * as e from 'cors';
import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { listas } from 'src/app/Models/back-service.model';
import { co, cp } from '@fullcalendar/core/internal-common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/Services/user.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { CompanyService } from 'src/app/Services/company.service';
import { PromotionsService } from 'src/app/Services/promotions.service';

@Component({
  selector: 'app-admin-promociones',
  templateUrl: './admin-promociones.component.html',
  styleUrls: ['./admin-promociones.component.css']
})
export class AdminPromocionesComponent implements OnInit {
  public pageSize = 15;
  public pageNumber = 0;
  public totalPages = 0;
  public count: number = 1500;
  public selectedCompany: any;
  public isAdmin: boolean = true;
  public loading: boolean = false;
  public promotionsList: any[] = [];
  public empresasAll: any[] | any = [];
  public empresasAux: any[] | any = [];
  public categoriesHeading: string[] = [];
  public userRole: string | boolean = false;
  public empresas: any[] = [{ id: null, nombreComercial: 'Seleccione...' }];
  public statusPromo: string = "all";

  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    itemsShowLimit: 1,
    singleSelection: true,
    allowSearchFilter: true,
    textField: 'nombreComercial',
    allowRemoteDataSearch: false,
    closeDropDownOnSelection: true,
    searchPlaceholderText: 'Buscar'
  };

  public listHeading: {}[] = [
    {
      'Categoría ': '',
      'Aliado Comercial':'',
      Descripción: '',
      'Fecha de creación': '',
      'Fecha de finalización': '',
      Estatus: '',
      '': '',
    },
  ];

  constructor(
    private utilsService: UtilsService,
    private userService: UserService,
    private companyService: CompanyService,
    private promotionService: PromotionsService
  ) {
    this.categoriesHeading = Object.keys(this.listHeading[0]);
  }

  async ngOnInit(): Promise<void> {
    const currentUser = await this.userService.getCurrentUser();
    this.userRole = currentUser?.result?.role;
    const permission = currentUser?.result?.permissions.includes('subir_promociones')

    if (this.userRole === 'ROLE_SUPERADMIN' || this.userRole === 'ROLE_ADMIN') {
      this.isAdmin = true;
      const currentCompanyId = this.utilsService.getParam('companyId');
      await this.getAllCompany(currentCompanyId);
      this.loadPromotionsByCompany();
    }
    else if (this.userRole === 'ROLE_ALIADO_COMERCIAL' || permission == true) {
      this.isAdmin = false;
      const userCompanyID = currentUser?.result?.companyId;
      if (userCompanyID != 'null' && userCompanyID != null) {
        await this.getOneCompany(userCompanyID)
      } else {
        this.utilsService.dialog('ERROR', 'ALERTA', 'El usuario no tiene una empresa primaria asignada.');
      }
    }
    else {
      this.goToPage('');
    }
  }

  originalOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    return 0;
  };

  async onFilter($event: any) {
    const value: string = $event.toLowerCase();
    if (value.length >= 3) {
      this.empresas = await this.empresasAll.filter((d: { nombreComercial: any; }, index: any) => {
        if (d.nombreComercial !== null) {
          if (d.nombreComercial.toLowerCase().indexOf(value) !== -1) {
            return true;
          }
        }
        return false;
      })
    }
  }

  onItemSelect(item: any) {
    // this.selectedCompany = item;
    // this.selectedCompany = item.id;
  }

  onDeSelect(item: any) {
    this.limpiar();
  }

  onDropDownClose() {
    this.empresas = this.empresasAux;
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  async getAllCompany(currentCompanyId: number | boolean) {
    this.loading = true;
    const countCompany = await this.companyService.getListCompany(0, this.count);
    countCompany.success ? this.count = countCompany.count : this.count = 10000;

    return this.companyService.getListCompany(0, this.count).then(
      async response => {
        this.empresasAll = response.result || [];
        this.empresas = countCompany.result || [];
        this.empresasAux = countCompany.result || [];

        if (currentCompanyId != false) {
          const serach = await this.empresasAll.find((e: any) => e.id === Number(currentCompanyId));
          this.selectedCompany = [serach];
          await this.loadPromotionsByCompany();
        }
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.message.descripcion);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async getOneCompany(company_id: number) {
    this.loading = true;
    return this.companyService.getCompaniesbyID(company_id).then(
      async response => {
        if (Object.keys(response?.result).length > 0) {
          const company = { id: response?.result?.id, nombreComercial: response?.result?.nombreComercial };
          this.selectedCompany = [company];
          await this.loadPromotionsByCompany();
        } else {
          this.empresas = [];
          this.empresasAll = [];
          this.empresasAux = [];
        }
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.message.descripcion);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async loadPromotionsByCompany() {
    this.loading = true;
    this.promotionsList = [];
    try {
      let count = 0;

      if (this.isAdmin) {
        let companyId = null;
        if (this.selectedCompany && this.selectedCompany.length > 0 && this.selectedCompany[0].id != null) {
          companyId = this.selectedCompany[0].id.toString();
        }

        var response = await this.promotionService.getAllPromotions(
          this.statusPromo,
          null,
          this.pageNumber,
          this.pageSize,
          null,
          null,
          companyId //this.selectedCompany[0].id!.toString()
        );
        count = response?.count || 0;
      } else {
        var response = await this.promotionService.getAllPromotionsCommercialRole(
            '',
            null,
            this.pageNumber,
            this.pageSize,
            null,
            null,
            this.selectedCompany[0].id!
          );
        count = response?.result?.count || 0;
      }
      if (response?.success === true) {
        this.promotionsList = [];
        const result = this.isAdmin ? response.result : response.result['rows'];
        if (result.length > 0) {
          this.promotionsList = result.map((e: any) => {
            return {
              categoría: e.categoryName,
              empresa: `${e.companyName} (${e.author})`,
              descripción: e.description,
              'fecha de creación': e.createdAt ? this.utilsService.date_pipe(e.createdAt, 'dd-MM-yyy') : '',
              'fecha de finalización': e.finishDate ? this.utilsService.date_pipe(e.finishDate, 'dd-MM-yyy') : '',
              estatus: e.status,
              id: e.id,
              company_id: e.companyId
            }
          });
          this.totalPages = Math.ceil(count / this.pageSize);
        }
      } else {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    } catch (error: any) {
      this.limpiar();
      this.utilsService.dialog('ERROR', 'Lo sentimos', error?.error?.message?.description || 'No existen promociones');
    }
    this.loading = false;
  }

  limpiar() {
    this.promotionsList = [];
    this.selectedCompany = undefined; // this.empresas[0].id;
    //this.loadPromotionsByCompany();
  }

  async eliminarPromo(promotion_id: string) {
    try {
      const response = await this.promotionService.deletePromotion(promotion_id);
      if (response?.success === true) {
        this.utilsService.dialog(
          'SUCCESS',
          'Notificación',
          response?.message.description
        );
        this.loadPromotionsByCompany();
      } else {
        this.utilsService.dialog(
          'ERROR',
          'Error',
          'Por favor vuelve a intentar más tarde.'
        );
      }
      this.loadPromotionsByCompany();
    } catch (error) {
      this.utilsService.dialog('ERROR', 'Error', 'No existen promociones');
    }
  }

  async updateStatusEvent($event: any, id: number) {
    const status = $event.target.value;
    try {
      const response = await this.promotionService.changePromoStatus(id, status);
      if (response?.success === true) {
        this.utilsService.dialog(
          'SUCCESS',
          'Notificación',
          response?.message?.description
        );
        this.loadPromotionsByCompany();
      } else {
        this.utilsService.dialog(
          'ERROR',
          'Error',
          response?.message.description ?? 'Por favor vuelve a intentar más tarde.'
        );
        this.loadPromotionsByCompany();
      }
    } catch (error: any) {
      this.utilsService.dialog(
        'ERROR',
        'Error',
        error?.error?.message?.description ?? 'Por favor vuelve a intentar más tarde.'
      );
      this.loadPromotionsByCompany();
    }
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params)
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.promotionsList.length = 0;
      this.loadPromotionsByCompany();
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.promotionsList.length = 0;
      this.loadPromotionsByCompany();
    }
  }
}

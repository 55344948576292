import { Component, OnInit, AfterContentChecked, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';

interface Menu {
  id: string,
  src: string,
  account: string
  routerLink: string,
  routerLinkEdit: string,
}
@Component({
  selector: 'app-users-menu',
  templateUrl: './users-menu.component.html',
  styleUrls: ['./users-menu.component.css']
})
export class UsersMenuComponent implements OnInit, AfterContentChecked  {

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  public user_menu: Menu[] = [];
  public routerInit = 'admin/users/';
  async ngOnInit() {
    // METHOD NO. 1
    // this.routerInit = this.router.url;
      
    // METHOD NO. 2
    const url = this.router['rawUrlTree'].root.children['primary'].segments;
    this.routerInit = url[url.length - 1].path;

    const userRole: string | boolean = sessionStorage.getItem('Role') || false;

    if (userRole === 'ROLE_SUPERADMIN') {      
      this.user_menu = [
        {
          id: 'empresas',
          account: 'Empresas',
          routerLink: 'admin-empresas',
          routerLinkEdit: 'edit-empresas',
          src: '../../assets/images/MenuEmpresaIcon.svg'
        },
        {
          id: 'usuarios',
          account: 'Usuarios',
          routerLink: 'admin-usuarios',
          routerLinkEdit: 'edit-usuario',
          src: '../../assets/images/MenuUserIcon.svg'
        },
        {
          id: 'administradores',
          account: 'Administradores',
          routerLink: 'admin-administradores',
          routerLinkEdit: 'edit-administradores',
          src: '../../assets/images/MenuAdminIcon.svg'
        }
      ]
    } else if (userRole === 'ROLE_ADMIN') {
      this.user_menu = [
        {
          id: 'empresas',
          account: 'Empresas',
          routerLink: 'admin-empresas',
          routerLinkEdit: 'edit-empresas',
          src: '../../assets/images/MenuEmpresaIcon.svg'
        }
      ]
    } else {      
      this.router.navigate(['/admin/inicio']);
    }
  }

  ngAfterContentChecked(): void {
    // METHOD NO. 1
    // this.routerInit = this.router.url;
    // this.changeDetector.detectChanges();
    
    // METHOD NO. 2
    const url = this.router['rawUrlTree'].root.children['primary'].segments;
    this.routerInit = url[url.length - 1].path;
    this.changeDetector.detectChanges();
    
    // if (this.routerInit === 'user-guide') {
    //   const type = this.router['browserUrlTree'].queryParams.type;
    //   switch (type) {
    //     case 'participantes':
    //       this.routerInit = this.routerInit + '-participantes';
    //       break
    //     case 'conferencistas':
    //       this.routerInit = this.routerInit + '-conferencistas';
    //       break
    //   }
    // }
  }
}

import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { listas } from 'src/app/Models/back-service.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/Services/user.service';
import { UtilsService } from 'src/app/Services/utils.service';

interface Filters {
  status: string | null,
  comiteId?: number | null,
  sectorId?: number | null,
  codigoCRM: string | null,
  userRole?: string | null,
  puestoId?: number | null,
  roleName?: string | null,
  comisionId?: number | null,
  personalNIT: string | null,
}
@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.css']
})

export class AdminUsuariosComponent implements OnInit {
  public pageSize = 15;
  public totalPages = 0;
  public pageNumber = 0;
  public usersList: any;
  public loading: boolean = false;
  public filter: string | null = null;
  public role: {}[] = [];
  public roleList = [
    { "id": 1, "name": "No asociado", "value": "noasociado" },
    { "id": 2, "name": "Asociado", "value": "asociado" },
    { "id": 3, "name": "Colaborador", "value": "colaborador" },
    { "id": 4, "name": "Aliado comercial", "value": "aliado_comercial" },
    { "id": 5, "name": "Organizador eventos", "value": "organizador_eventos" }
  ];;
  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: true,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  };

  public list: any = [
    { "Nombre del usuario": "", "Código": "", "Puesto": "", "NIT": "", "Teléfono": "", "Correo electrónico": "" }
  ];

  public categoriesHeading: string[] = [];

  // FILTROS
  public isFilter: boolean = false;
  public showFilterModal: boolean = false;
  public filters: Filters = { personalNIT: null, codigoCRM: null, status: null, roleName: null };


  ngOnInit() {
    this.loadData(this.pageNumber, this.pageSize, this.filter, false);
  }

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  onLeft() {
    if ((this.pageNumber + 1) != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false);
    }
  }

  onRight() {
    if ((this.pageNumber + 1) != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false);
    }
  }

  goToPage(pageName: string, params?: any) {
    this.utilsService.goToPage(pageName, params);
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItes = document.getElementsByClassName('actions-enable')[0];
    if (listItes) {
      listItes.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItes?.id || !listItes.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItes = document.getElementsByClassName('actions-enable')[0];
    if (listItes) {
      listItes.classList.remove('actions-enable');
    }
  }

  async searchReturn() {
    if (this.filter === null || this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      await this.loadData(this.pageNumber, this.pageSize, null, false);
    }
  }

  async loadData(page: number | null, size: number | null, filter: string | null, search?: boolean, filters?: any) {
    let response: any;
    this.loading = true;

    (search) && (this.pageNumber = 0);
    if (this.filter != null && this.filter != '') {
      this.isFilter = false;
      response = await this.userService.searchAllUsers(this.pageNumber, this.pageSize, 'users', this.filter);
      this.filters = { personalNIT: null, codigoCRM: null, status: null, comisionId: null, comiteId: null, sectorId: null };
    } else {
      response = await this.userService.getAllUsers(
        this.pageNumber,
        this.pageSize,
        filters ? filters.status : null,
        filters ? filters.roleName : null,
        'users',
        filters ? filters.codigoCRM : null,
        filters ? filters.personalNIT : null
      );
    }

    if (response.success && response.success == true) {
      this.list = response.result.map((e: any) => {
        return {
          id: e?.id,
          'Nombre del usuario': `${e?.firstName || ''} ${e?.secondName || ''} ${e?.firstLastname || ''} ${e?.secondLastname || ''}`,
          'Codigo': e?.codigoCrm,
          'Puesto': e?.puesto?.name,
          'NIT': e?.personalNIT,
          'Teléfono': e?.phone,
          'Correo electrónico': e?.email,
        }
      });
      this.totalPages = Math.ceil(response.count / this.pageSize);
    } else {
      this.utilsService.dialog('ERROR', 'Error', response?.error?.message?.description || '¡Error al consultar usuarios!');
    }
    this.loading = false;
  }

  deletedUser(user_id: number) {
    this.loading = true;

    const userID = sessionStorage.getItem('UserID')?.toString();
    const userNAME = sessionStorage.getItem('Name')?.toString();
    const userEMAIL = sessionStorage.getItem('Email')?.toString();
    const data = {
      'reason_delete': 'Solicitud para eliminar usuario',
      'reason_delete_description': `El usuario: ${userNAME} con id ${userID} solicito eliminar al usuario con id ${user_id}, contactalo a ${userEMAIL}`
    };

    this.userService.deletedUser(user_id, data).then(
      async (response) => {
        console.log(response);
        await this.loadData(this.pageNumber, this.pageSize, this.filter, false);
        this.utilsService.dialog('SUCCESS', 'Éxito', '¡Usuario eliminado!');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡El usuario no se elimino!');
      }).finally(() => {
        this.loading = false
      })
  }

  searchFilter() {
    this.filter = null;
    this.isFilter = true;
    this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
  }

  async onSelect($event: any, type: string) {
    switch (type) {
      case 'roleName':
        const role = await this.roleList.find((x: any) => x.id == $event.id);
        this.filters.roleName = role?.value || null; // $event.id;
        if (this.filters.roleName == 'asociado' && this.filters.status == 'Inactivo') {
          this.filters.status = null;
        }
        else if (this.filters.roleName != 'asociado' && this.filters.status == 'Activo') {
          this.filters.status = null;
        }
        break;
      case 'status':
        this.filters.status = $event;
        break;
    }
  }

  onDeSelect(type: string) {
    switch (type) {
      case 'roleName':
        this.role = [];
        this.filters.roleName = null;
        break;
    }
  }

  cleanFilter(type: string) {
    switch (type) {
      case 'code':
        this.filters.codigoCRM = null;
        this.loadData(this.pageNumber, this.pageSize, null, true);
        break;
      case 'nit':
        this.filters.personalNIT = null;
        this.loadData(this.pageNumber, this.pageSize, null, true);
        break;
      case 'estatus':
        this.filters.status = null;
        this.loadData(this.pageNumber, this.pageSize, null, true);
        break;
      case 'roleName':
        this.role = [];
        this.filters.roleName = null;
        this.loadData(this.pageNumber, this.pageSize, null, true);
        break;
      case 'all':
        this.role = [];
        this.filter = null;
        this.isFilter = false;
        this.filters = { personalNIT: null, codigoCRM: null, status: null, roleName: null }
        this.searchReturn();
        break;
    }
    this.valid_isFilter();
  }

  valid_isFilter() {
    if (
      this.filters.sectorId == null && this.filters.comisionId == null &&
      this.filters.codigoCRM == null && this.filters.personalNIT == null
      && this.filters.status == null && this.filters.roleName == null
    ) {
      this.isFilter = false;
      return false;
    }
    return true;
  }

  closeFilterModal() {
    this.showFilterModal = false;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericApiResponse } from '../Models/generic-api-response.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PromotionCreateModel, PromotionResponse, RolEntity, promotionCategorie } from '../Models/promotions-models';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {
  private params: HttpParams;
  private headers: HttpHeaders;
  private url: string = environment.url;
  private options: { headers: HttpHeaders; params?: HttpParams };

  constructor(private http: HttpClient) {
    this.params = new HttpParams();
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('accept', 'application/json');
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.options = { headers: this.headers };
  }

  getOnePromotions(promotion_id: number): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get(`${this.url}promo/promo/get/${promotion_id}`, this.options).toPromise();
  }

  getAllPromotionActive(companyId: string | null = null): Promise<PromotionResponse | undefined> {
    this.params = new HttpParams();
    (companyId != null) && (this.params = this.params.set('companyId', companyId));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get<PromotionResponse>(`${this.url}promo/promo/get-all-active`, this.options).toPromise();
  }

  // All Promotion with params; for Admin.
  getAllPromotions(status: string | null = null, name: string | null = null, page: number | null = null, size: number | null = null, startDate: string | null = null, endDate: string | null = null, companyId: string | null = null): Promise<PromotionResponse | undefined> {
    this.params = new HttpParams();
    (name != null) && (this.params = this.params.set('name', name));
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    (status != null && status != "all") && (this.params = this.params.set('status', status));
    (endDate != null) && (this.params = this.params.set('end_at', endDate));
    (startDate != null) && (this.params = this.params.set('start_at', startDate));
    (companyId != null) && (this.params = this.params.set('company_id', companyId));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get<PromotionResponse>(`${this.url}promo/promo/for-admin`, this.options).toPromise();
  }

  getAllPromotionsCommercialRole(
    status: string | null, name: string | null = null, page: number | null = null, size: number | null = null,
    startDate: string | null = null, endDate: string | null = null, companyId: number | null = null
  ): Promise<PromotionResponse | undefined> {
    this.params = new HttpParams();
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    (status != null) && (this.params = this.params.set('status', status));
    (endDate != null) && (this.params = this.params.set('end_at', endDate));
    (startDate != null) && (this.params = this.params.set('start_at', startDate));
    (companyId != null) && (this.params = this.params.set('company_id', companyId));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get<PromotionResponse>(`${this.url}promo/promo/by-company/${companyId}`, this.options).toPromise();
  }

  // Reporte
  getPromotionsByUser(
    promotion_id: number | null = null, startDate: string | null = null, endDate: string | null = null,
    page: number | null = null, size: number | null = null, status: string | null = null, companyId: number | null = null
  ): Promise<PromotionResponse | undefined> {
    this.params = new HttpParams();
    (promotion_id != null) && (this.params = this.params.set('promotionId', promotion_id));
    (startDate != null) && (this.params = this.params.set('startDate', startDate));
    (endDate != null) && (this.params = this.params.set('endDate', endDate));
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    (status != null) && (this.params = this.params.set('status', status));
    (companyId != null) && (this.params = this.params.set('company_id', companyId));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get<PromotionResponse>(`${this.url}promo/promo/report-redeems`, this.options).toPromise();
  }

  deletePromotion(id: string): Promise<GenericApiResponse<any> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.post<GenericApiResponse<any>>(`${this.url}promo/promo/delete/${id}`, {}, this.options).toPromise();
  }

  createPromotionByComercialRole(promotion: PromotionCreateModel): Promise<GenericApiResponse<any> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.post<GenericApiResponse<any>>(`${this.url}promo/promo/create`, promotion, this.options).toPromise();
  }

  createPromotionByAdminRole(promotion: PromotionCreateModel): Promise<GenericApiResponse<any> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.post<GenericApiResponse<any>>(`${this.url}promo/promo/create-by-admin`, promotion, this.options).toPromise();
  }

  updatePromotion(promotion: PromotionCreateModel): Promise<GenericApiResponse<any> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.post<GenericApiResponse<any>>(`${this.url}promo/promo/update/${promotion.id}`, promotion, this.options).toPromise();
  }

  getPromoCategories(): Promise<GenericApiResponse<promotionCategorie[]> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get<GenericApiResponse<promotionCategorie[]>>(`${this.url}promo/promo-categories/all-active`, this.options).toPromise();
  }

  getPromoLimitsPerUser(): Promise<GenericApiResponse<number[]> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get<GenericApiResponse<number[]>>(`${this.url}promo/promo/promo-limits`, this.options).toPromise();
  }

  getRoles(): Promise<GenericApiResponse<RolEntity[]> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get<GenericApiResponse<RolEntity[]>>(`${this.url}registro/roles`, this.options).toPromise();
  }

  changePromoStatus(id: number, status: string): Promise<GenericApiResponse<any> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.post<GenericApiResponse<any>>(`${this.url}promo/promo/change-status/${id}`, { 'status': status }, this.options).toPromise();
  }

  getQRData(id: number): Promise<GenericApiResponse<any> | undefined> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get<GenericApiResponse<any>>(`${this.url}promo/promo//generate-qr/${id}`, this.options).toPromise();
  }

}

import { Component, OnInit, Inject, ViewEncapsulation, EventEmitter, Output, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef, HostListener, } from '@angular/core';
import { KeyValue, DOCUMENT } from '@angular/common';
import { EventService } from 'src/app/Services/event.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { BackServiceEvents } from 'src/app/Models/back-service.model';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import esLocal from '@fullcalendar/core/locales/es';
import esLocal from '@fullcalendar/core/locales/es';
import { CalendarOptions } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import { environment } from 'src/environments/environment';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-calendario-eventos',
  templateUrl: './calendario-eventos.component.html',
  styleUrls: ['./calendario-eventos.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  // providers: [{ provide: CalendarDateFormatter }]
})
export class CalendarioEventosComponent implements OnInit {

  @ViewChild('modalContent', { static: true })
  @Output() viewDateChange = new EventEmitter<Date>();
  @ViewChild(FullCalendarComponent) calendarComponent?: FullCalendarComponent;

  public pageNumber = 0;
  public pageSize = 20;
  public totalPages = 0;
  public events: any = [];
  public view: number = 0;
  public currentDate: string;
  public currentYear?: string;
  public currentMonth?: string;
  public listofEvents: any[] = [];
  public listMonth: string[] = [];
  public loading: boolean = false;
  // public isShowDiv: boolean = true;
  public filter: string | null = '';
  public viewDate: Date = new Date();
  public modalContent!: TemplateRef<any>;
  public categoriesHeading: string[] = [];
  public disabled: boolean = environment.production;

  public listAux: any[] = new Array();
  public list: any[] = [
    {
      'Nombre del evento': '',
      'Fecha de Creación': '',
      'Fecha evento': '',
      Publicación: '',
      Tipo: '',
      Estatus: '',
    },
  ];

  public calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    headerToolbar: {
      left: '',
      right: '',
      center: ''
    },
    weekends: true,
    editable: false,
    locale: 'es', //'es', // esLocal,
    events: this.events,
    initialView: 'dayGridMonth',
    titleFormat: { year: 'numeric', month: 'long' },
    eventClick: this.editEventByCalendar.bind(this)

    // eventMouseLeave(arg) {
    //   console.log(arg.event.id)
    //   alert(`${arg.event.title} \n ${arg.event}`);
    // },

    // eventMouseover: ,
    // eventMouseout: ,

    // eventMouseLeave: function (event_data) {
    //   console.log('Mouse over.');
    //   alert('Mouse over.');
    // },
  };


  originalOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    return 0;
  };

  constructor(
    private eventService: EventService,
    private utilsService: UtilsService,
    @Inject(DOCUMENT) document: Document
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
    const today = new Date();
    this.currentYear = today.getFullYear().toString();
    this.currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    this.currentDate = `${this.currentYear}-${this.currentMonth}`;
  }

  ngOnInit() {
    // this.isShowDiv = true;
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    const eventID: number | undefined = Number(sessionStorage.getItem('EventID')); // || 0;

    if (token && eventID === 0) {
      this.loadData(this.pageNumber, this.pageSize, null);
      this.utilsService.emitChange(false, false, 'calendario l114');
      sessionStorage.setItem('EventType', '0');
    } else if (eventID > 0) {
      this.utilsService.goToPage('/admin/events/nuevo-evento', { ID: eventID });
    } else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes acceso');
      this.utilsService.goToPage('/login');
    }
  }

  editEvent(event_id: any) {
    sessionStorage.setItem('EventID', event_id);
    this.utilsService.goToPage('/admin/events/nuevo-evento', { ID: event_id });
  }

  showGafete(event_id: any) {
    this.utilsService.dialog_gafete('EVENTO', event_id);
  }

  deleteEvent(event_id: number) {
    this.eventService.deleteEvent(event_id).subscribe({
      next: async (res) => {
        const index = await this.list.findIndex((x) => x.id === event_id);
        this.list.splice(index, 1);
        this.utilsService.dialog('SUCCESS', 'Éxito', 'Evento eliminado con exito.');
        this.loadData(this.pageNumber, this.pageSize, this.filter);
      },
      error: (e) => {
        this.utilsService.dialog('ERROR', 'Error', e.error.message.description);
      },
    });
  }

  async updateStatusEvent($event: any, index_list: number, event_id: number, previous: string) {
    const find = await this.listAux.find(x => x.id == event_id);
    if (find.guestType.id > 0 && find.eventType.id > 0) {
      const status = $event.target.value;
      this.eventService.editStatusEvent(event_id, { status: status }).subscribe({
        next: (res) => {
          this.utilsService.dialog('SUCCESS', 'Éxito', 'Estado de evento actualizado con éxito.');
          this.loadData(this.pageNumber, this.pageSize, this.filter);
        },
        error: async (e) => {
          $event.target.value = previous;
          this.list[index_list].Estatus = previous;
          this.utilsService.dialog('ERROR', 'Error', e?.error?.message?.description);
        },
      });
    } else {
      $event.target.value = previous;
      this.utilsService.dialog('ERROR', 'Error', 'Debes añadir datos pendientes del evento para actualizar estado.');
    }
  }

  async updateFeatured(event: any) {
    this.loading = true;
    const featured = event.featured == true ? false : true;
    await  this.eventService.editStatusEvent(event.id, { featured: featured }).subscribe({
      next: (res) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', res?.message?.description || 'Estado de evento actualizado con éxito.');
        this.loadData(this.pageNumber, this.pageSize, this.filter);
      },
      error: async (e) => {
        this.utilsService.dialog('ERROR', 'Error', e?.error?.message?.description);
      },
    });

    this.loading = false;
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));

    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  async changeView(event: any) {
    const element = document.getElementById('default');
    const calendar = document.getElementById('calendar');
    if (event.target.id === 'default-btn') {
      if (element?.classList.contains('calendar-view') && calendar) {
        element.classList.remove('calendar-view');
        element.classList.add('default-view');
        calendar.classList.remove('default-view');
        calendar.classList.add('calendar-view');
        this.view = 0;
      }
    } else {
      if (calendar?.classList.contains('calendar-view') && element) {
        await this.getEventToday();
        calendar.classList.remove('calendar-view');
        calendar.classList.add('default-view');
        element.classList.remove('default-view');
        element.classList.add('calendar-view');
        this.view = 1;
      }
    }
  }

  alert(estado: any) {
    console.log(estado)
    alert(`Hola`);
  }

  editEventByCalendar(event: any) {
    const event_id = event.event.id;
    sessionStorage.setItem('EventID', event_id);
    this.utilsService.goToPage('/admin/events/nuevo-evento', { ID: event_id });
  }

  getEventToday(year?: string, month?: string | null) {
    this.loading = true;
    return this.eventService
      .getEventsByMonth(this.pageNumber, this.pageSize, year ?? this.currentYear!, month ?? this.currentMonth!)
      .subscribe({
        next: (res) => {
          console.log(res.result);

          this.events = [];
          for (const e of res.result) {
            if (new Date(`${e.fechaInicio}  GMT-0600`).getTime() === new Date(`${e.fechaFinal}  GMT-0600`).getTime()) {
              // fechaAux.toLocaleString('en-US', { timeZone: 'America/New_York' }) // let utcDate = new Date(startDate.toLocaleString('en-US', { timeZone: "UTC" }));
              this.events.push({
                id: e?.id,
                allDay: true,
                description: e?.descripcion,
                start: new Date(`${e.fechaInicio}  ${e?.horaInicio}  GMT-0600`), //`${e?.fechaInicio}T${e?.horaInicio}`,
                color: e?.costType.id === 3 ? '#1A44DB' : '#8DA1ED',
                title: e?.horaInicio.substr(0, 5) + ' - ' + e?.title
              });
            } else {
              let fechaAux = new Date(`${e.fechaInicio}  ${e?.horaInicio}  GMT-0600`);
              const fechaFinal = new Date(`${e.fechaFinal}  ${e?.horaFinal}  GMT-0600`);
              do {
                this.events.push({
                  id: e?.id,
                  allDay: true,
                  description: e?.descripcion,
                  start: fechaAux,
                  color: e?.costType.id === 3 ? '#1A44DB' : '#8DA1ED',
                  title: e?.horaInicio.substr(0, 5) + ' - ' + e?.title
                });
                fechaAux = new Date(fechaAux.getTime() + 86400000);
              } while (fechaAux.getTime() <= fechaFinal.getTime());
            }
          }
          console.log(this.events);
          this.loading = false;
          this.calendarComponent!.getApi().render();
        },
        error: (e) => this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.')
      });
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  async searchReturn() {
    if (this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      await this.loadData(this.pageNumber, this.pageSize, null);
    }
  }

  loadData(page: number | null, size: number | null, participant_name: string | null, search?: boolean) {
    if (search) {
      page = 0;
      this.pageNumber = 0;
    }

    this.loading = true;
    this.eventService.getEvents(page, size, participant_name, null).then(
      response => {
        console.log(response);
        console.log(response.result[0].featured);

        // this.list.pop();
        this.list = [];
        this.listAux = [];
        this.listAux = response.result;
        this.list = response.result.map((e: any) => {
          return {
            id: e?.id,
            featured: e?.featured,
            'Nombre del evento': e?.title,
            'Fecha de creación': this.utilsService.date_pipe(e?.eventCreation, 'dd-MM-yyy'),
            'Fecha evento': this.utilsService.date_pipe(e?.fechaInicio, 'dd-MM-yyy'),
            Publicación: e?.publication,
            Tipo: e?.costType?.name,
            Estatus: e?.status,
          };
        });
        this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.')
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  get calendarApi(): any {
    return this.calendarComponent!.getApi();
  }

  async onMonthChange($event: any): Promise<void> {
    const [year, month] = $event.target.value.split('-');
    const newDate = new Date(parseInt(year), parseInt(month) - 1, 1);
    await this.getEventToday(year, month)
    this.calendarApi.gotoDate(newDate);
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter);
    }
  }
}

function onKeydownHandler(
  event: any,
  KeyboardEvent: {
    new(
      type: string,
      eventInitDict?: KeyboardEventInit | undefined
    ): KeyboardEvent;
    prototype: KeyboardEvent;
    readonly DOM_KEY_LOCATION_LEFT: number;
    readonly DOM_KEY_LOCATION_NUMPAD: number;
    readonly DOM_KEY_LOCATION_RIGHT: number;
    readonly DOM_KEY_LOCATION_STANDARD: number;
  }
) {
  throw new Error('Function not implemented.');
}

import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { SponsorService } from 'src/app/Services/sponsor.service';

@Component({
  selector: 'app-admin-patrocinadores',
  templateUrl: './admin-patrocinadores.component.html',
  styleUrls: ['./admin-patrocinadores.component.css']
})
export class AdminPatrocinadoresComponent implements OnInit {
  public list: any[] = [];
  public pageSize = 20;
  public totalPages = 0;
  public pageNumber = 0;
  public eventID: number = 0;
  public loading: boolean = false;
  public startDialog: boolean = true;
  public filter: string | null = null;
  public categoriesHeading: string[] = [];
  public listHeading: any[] = [{ 'Nombre del patrocinador': '', 'Nombre del usuario': '', 'Correo electrónico': '', 'Sitio web': '', 'Asociado': '' }];

  constructor(
    private utilsService: UtilsService,
    private sponsorService: SponsorService
  ) { this.categoriesHeading = Object.keys(this.listHeading[0]); }

  ngOnInit(): void {
    this.eventID = Number(sessionStorage.getItem('EventID'));
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡Debes iniciar sesión!');
      this.utilsService.goToPage('/login');
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params)
  }

  onLeft() {
    if ((this.pageNumber + 1) != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
    }
  }

  onRight() {
    if ((this.pageNumber + 1) != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
    }
  }

  async searchReturn() {
    if (this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      await this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, null);
    }
  }

  loadDatabyEvent(page: number | null, size: number | null, event_id: number, filter: string | null, search?: boolean) {
    this.loading = true;

    if (search) {
      page = 0;
      this.pageNumber = 0;
    }

    // return this.sponsorService.getSponsorByEvent(page, size, event_id, filter).then(
    return this.sponsorService.getSponsorByEvent(this.pageNumber, this.pageSize, this.eventID, this.filter).then(
      response => {
        console.log(response.result);
        if (response.result.length > 0) {
          this.list = [];
          this.list = response.result.map((e: any) => {
            return {
              id: e.id,
              'Nombre del patrocinador': e?.nombrePatrocinador,
              'Nombre del usuario': e?.nombreUsuario,
              'Correo electrónico': e?.email,
              'Sitio web': e?.website,
              Asociado: e.socio ? 'Asociado' : 'No asociado' //e?.registro ? 'Asociado' : 'No asociado' // <- No viene posición
            }
          });
        } else {
          this.list = [];
          this.totalPages = 0;
          // console.log(response);
          // if (this.startDialog) {
          //   // this.utilsService.dialog('ERROR', 'Alerta', '¡Evento sin datos de patrocinadores!');
          // }
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || 'Error al cargar ubicaciones!');
      }
    ).finally(() => {
      this.loading = false;
      this.startDialog = false;
    });
  }

  editSponsor(sponsor_id: number) {
    this.goToPage('/admin/events/edit-patrocinadores', { ID: sponsor_id })
  }

  deletedSponsor(sponsor_id: number) {
    this.loading = true;

    this.sponsorService.deleteSponsorToEvent(sponsor_id).then(
      response => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || 'Participante eliminado');
        this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || '¡Error al eliminar patrocinador!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }
}

import cheerio from 'cheerio';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EventService } from 'src/app/Services/event.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { CustomDatePipe } from 'src/app/Core/pipe/custom-date.pipe';
import { PromotionsService } from 'src/app/Services/promotions.service';
import { NotificationItem } from 'src/app/Models/notification.item.model';
import { NotificationsService } from 'src/app/Services/notifications.service';

class CustomTimeClass {
  hours: string;
  minutes: string;
  seconds: string;

  constructor(hours: string, minutes: string, seconds: string) {
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  toString() {
    return `${this.hours}:${this.minutes}:${this.seconds}`;
  }
}

@Component({
  selector: 'app-edit-notificacion',
  templateUrl: './edit-notificacion.component.html',
  styleUrls: ['./edit-notificacion.component.css'],
})

export class EditNotificacionComponent implements OnInit {
  public notificationID: any;
  public loading: boolean = false;
  // public selectedItems: any = [];
  public eventAndPromoList: any[] = [];
  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    singleSelection: true,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    searchPlaceholderText: 'Buscar'
  };
  public selected: any[] = [{ id: null, name: 'Seleccione...', type: '' }]



  public statusList = [
    { id: 'seleccione', name: 'Seleccione...', disabled: true },
    { id: 'scheduled', name: 'Programado', disabled: false },
    { id: 'instant', name: 'Instantáneo', disabled: false }
  ];

  public notification: NotificationItem = {} as NotificationItem;
  public notificationAux: NotificationItem = {} as NotificationItem;

  statusSelected: string = '';
  eventSelected: any = {};
  isDateAndHourSelectEnabled: boolean = false;

  public dateStart!: NgbDateStruct;
  public sendHour: CustomTimeClass | undefined;

  notificationId: number | null = null;
  currentEditNotificationId: NotificationItem | undefined;

  //* TEST
  @ViewChild('formulario') form!: NgForm;
  // private customDate2 = new CustomDatePipe;
  //*/

  constructor(
    private eventService: EventService,
    private utilsService: UtilsService,
    private promotionService: PromotionsService,
    private notificationsService: NotificationsService
  ) {
    this.notification.subject = '';
    this.notification.message = '';
    this.statusSelected = this.statusList[0].id;
    this.notification.notificationType = 'seleccione';
  }

  async ngOnInit() {
    await this.loadData();
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    this.notificationID = Number(this.utilsService.getParam('ID')) || 0;

    if (!token) {
      this.utilsService.dialog('ERROR', 'Error', '¡No tienes acceso!');
      this.goToPage('/login');
    }
    else if (token && this.notificationID > 0) {
      await this.getOneNotification(this.notificationID);
    }
  }

  async getOneNotification(notification_id: number) {
    this.loading = true;
    await this.notificationsService.getOneNotifications(notification_id).then(
      (response: any) => {
        this.notification.id = response.result.id;
        this.notification.eventId = response.result.eventId;
        this.notification.subject = response.result.subject;
        this.notification.message = response.result.message;
        this.notification.hourSend = response.result.hourSend;
        this.notification.dateSend = response.result.dateSend;
        this.notification.eventType = response.result.eventType;
        // this.notification.notificationType = response.result.notificationType === 'draft' ? 'seleccione' : response.notificationType;
        const findEvent = this.eventAndPromoList.find(x => x.id === (response?.result.eventType?.substr(0, 1) + response?.result?.eventId));
        this.selected = [findEvent];

        if (response.result.hourSend != '' && response.result.hourSend != null) {
          const hour = response.result.hourSend.split(':');
          this.sendHour = new CustomTimeClass(hour[0], hour[1], hour[2]);
          this.notification.notificationType = 'scheduled';
        } else {
          this.notification.notificationType = 'instant';
        }

        if (response.result.dateSend != '' && response.result.dateSend != null) {
          const date = response.result.dateSend.split('-');
          this.dateStart = { day: Number(date[2]), month: Number(date[1]), year: Number(date[0]) };
          this.notification.notificationType = 'scheduled';
        } else {
          this.notification.notificationType = 'instant';
        }
        // this.dateStart = this.customDate2.transform(this.dateStart);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.message.description);
        this.goToPage('/admin/events/admin-agenda');
      }).finally(() => {
        this.loading = false
        console.log(this.notification);
      })
    // const input = this.formulario.get('orderLines') as FormArray;
    // const globalPrice = this.mainForm.get('globalPrice').value;
    // formLines.controls.forEach(control => control.get('price').setValue(globalPrice));
  }

  async onItemSelect(item: any) {
    const selected = await this.eventAndPromoList.find(x => x.id === item.id);
    this.selected.push(item);
    this.notification.eventType = selected.type;
    this.notification.eventId = Number(selected.id.substr(1));
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  onStatusChange($event: any) {
    this.dateStart = {} as NgbDateStruct;
    this.sendHour = {} as CustomTimeClass;
    this.isDateAndHourSelectEnabled = $event.target.value === 'instant';
  }

  // getHourSend() {
  //   const hourAndMinutes = this.notificationModel?.hourSend!.split(':');
  //   const date = new Date();
  //   date.setHours(Number(hourAndMinutes![0]));
  //   date.setMinutes(Number(hourAndMinutes![1]));
  //   date.setSeconds(0);
  //   const hours = ('0' + date.getHours()).slice(-2);
  //   const minutes = ('0' + date.getMinutes()).slice(-2);
  //   const seconds = ('0' + date.getSeconds()).slice(-2);
  //   return new CustomTimeClass(hours, minutes, seconds);
  // }

  onTimeInputChange(event: any) {
    const timeValue = event.target.value;
    const [hours, minutes] = timeValue.split(':').map((str: string) => {
      return parseInt(str, 10);
    });

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    this.sendHour = new CustomTimeClass(formattedHours, formattedMinutes, '00');
  }

  async loadData() {
    this.loading = true;

    let count = 0;
    await this.promotionService.getAllPromotionActive().then(
      response => {
        this.eventAndPromoList = [...this.eventAndPromoList];
        response!.result.forEach((e: any) => {
          this.eventAndPromoList.push({
            id: 'p' + e.id,
            name: e.name,
            type: 'promotions',
          });
        });
      },
      error => {
        count++;
      }
    );

    const countEvent = await this.eventService.getEvents(null, 1, null, true);
    await this.eventService.getEvents(null, countEvent.count, null, true).then(
      response => {
        this.eventAndPromoList = [...this.eventAndPromoList];
        response!.result.map((e: any) => {
          if (e.status === 'active') {
            this.eventAndPromoList.push({
              id: 'e' + e.id,
              name: e.title,
              type: 'events'
            });
          };
        });
      },
      error => {
        count++;
      }
    );
    // console.log(count);
    (count > 0) && this.utilsService.dialog('ERROR', 'Error', 'Error al cargar datos.');
    this.loading = false;
  }

  clearFields() {
    this.selected = [{ id: null, name: 'Seleccione...', type: '' }]
    this.notification = {} as NotificationItem;
    this.notificationAux = {} as NotificationItem;
    this.notification.subject = '';
    this.notification.message = '';
    this.statusSelected = this.statusList[0].id;
    this.notification.notificationType = 'seleccione';
  }

  async sendNotification(draft: boolean) {
    this.loading = true;
    let response: any;

    this.notificationAux = this.notification;
    const notificationType = this.notification.notificationType;
    //this.notificationAux.message = await this.utilsService.toPlainText(this.notification.message);
    this.notificationAux.message = this.notification.message;
    draft === true ? this.notificationAux.notificationType = 'draft' : this.notificationAux.notificationType = this.notification.notificationType;

    if (this.notification.notificationType === 'instant' && !draft) {
      const hourSend = await this.utilsService.getDate('HH:mm:mm');
      console.log(hourSend)
      const dateSend = await this.utilsService.getDate('yyyy-MM-dd');
      console.log(dateSend)
      const dateUTC = await this.utilsService.date_utc(dateSend!, hourSend!);
      console.log(dateUTC)
      this.notificationAux.dateSend = dateUTC.date;
      this.notificationAux.hourSend = dateUTC.hour;
    
      if (Number(this.notificationAux.id) > 0) {
        response = await this.notificationsService.updateNotification(this.notificationAux);
      } else {
        response = await this.notificationsService.createInstantNotificacion(this.notificationAux);
      }
    }
    else if (this.notification.notificationType === 'scheduled' || draft) {
      const hourSend = await this.utilsService.formatHour(this.sendHour);
      const dateSend = await this.utilsService.formatDate(this.dateStart);

      if (!draft) {
        const dateUTC = await this.utilsService.date_utc(dateSend!, hourSend!);
        this.notificationAux.hourSend = dateUTC.hour;
        this.notificationAux.dateSend = dateUTC.date;
      } else {
        this.notificationAux.hourSend = hourSend;
        this.notificationAux.dateSend = dateSend;
      }

      if (this.notificationAux.id === undefined && !draft) {
        // Nueva. Programada  // console.log('if 1');
        response = await this.notificationsService.createScheduleNotificacion(this.notificationAux);
      } else if (this.notificationAux.id === undefined && draft) {
        // Nueva. Borrador  // console.log('if 2');
        response = await this.notificationsService.createScheduleNotificacion(this.notificationAux);
      } else if (Number(this.notificationAux.id) > 0) {
        // Actualizar a Borrador y programada  // console.log('if 3');
        response = await this.notificationsService.updateNotification(this.notificationAux);
      }
    }
      console.log(response)
    // console.log('AUX: ', this.notificationAux);
    if (response?.success === true) {
      this.loading = false;
      this.utilsService.dialog('SUCCESS', 'Éxito', response.message?.description);
      this.goToPage('admin/notificaciones');
    } else {
      this.loading = false;
      this.notification.notificationType = notificationType;
      this.utilsService.dialog('ERROR', 'Error', response?.message?.description ?? 'Por favor vuelve a intentar más tarde.');
    }
  }

  // NECESITA UN FIX: toPlainText + test
  // test(param: string){    
  //   const text_html = param;
  //   const $text_string = (cheerio.load(param)).text();
  //   if(($text_string.length+1) >= 6){
  //     this.notification.message = text_html.slice(0, -1)
  //     console.log(this.notification.message.length, this.notification.message);
  //   }
  // }

  countCharacter(htmlMessage: string): number {
    const $ = cheerio.load(htmlMessage);
    const text = $.text();
    return text.length
  }

  validate() {
    const $text_string = (cheerio.load(this.notification.message)).text();
    return (this.notification.subject.length < 10 || ($text_string.length < 20 || $text_string.length > 160)) ||
      (this.notification.notificationType == 'scheduled' && (Object.entries(this.dateStart).length === 0))

    // RESOLVER ERROR
    // console.log(this.sendHour, typeof this.sendHour)
    // console.log(Object.values(this.sendHour).length === 0)
    // return (this.notification.subject.length < 5 || this.notification.message.length < 17) ||
    //   (  this.notification.notificationType == 'scheduled' && (Object.entries(this.dateStart).length === 0 || Object.entries(this.dateStart).length === 0))
  }
}

<!DOCTYPE html>
<html class="login-body">
    <div class="header">
        <img src="..\..\assets\images\Agexport-logo.svg" alt="logo" />
    </div>
    <div class="login">
        <form ngNativeValidate class="custom-form" method="post" #form>
            <h1>Recuperación de contraseña</h1>
            <hr class="solid">
            <label>Ingresa el correo electrónico a donde se te enviará el código para recuperar tu contraseña:</label>
            <div class="custom-input">
                <input
                [(ngModel)]="email"
                    type="email"
                    placeholder="Correo electrónico"
                    name="email"
                    id="email"
                    required
                    oninvalid="this.setCustomValidity('Email inválido')"
                    oninput="setCustomValidity('')"
                />
            </div>

            <button type="submit" (click)="form.checkValidity() ? recovery() : null">Envíar código</button>
        </form>
    </div>
  </html>
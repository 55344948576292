import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/Services/utils.service';
import { CompanyService } from 'src/app/Services/company.service';
import { listas } from 'src/app/Models/back-service.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PromotionsService } from 'src/app/Services/promotions.service';

interface Filters {
  nit: string | null,
  socio: number | null,
  status: number | null,
  comiteId: number | null,
  sectorId: number | null,
  codigoCRM: string | null,
  comisionId: number | null
}

@Component({
  selector: 'app-admin-empresas',
  templateUrl: './admin-empresas.component.html',
  styleUrls: ['./admin-empresas.component.css']
})
export class AdminEmpresasComponent implements OnInit {
  public pageSize = 20;
  public pageNumber = 0;
  public totalPages = 0;
  public loading: boolean = false;
  public filter: string | null = '';
  // public filter: string | number | null = '';
  public role: string | undefined = undefined;
  public env: boolean = environment.production;

  public categoriesHeading: string[] = [];
  public list: {}[] | any = [{ 'Nombre de la Empresa': '', 'Código': '', 'NIT': '', 'Teléfono': '', 'Correo Empresa': '', 'Estatus Socio': '' }];

  constructor(
    private utilsService: UtilsService,
    private companyService: CompanyService,
    private promotionService: PromotionsService
  ) { this.categoriesHeading = Object.keys(this.list[0]); }

  ngOnInit() {
    this.loadData(this.pageNumber, this.pageSize, this.filter, false, this.filters);
    this.loadList();
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  editCompany(event: any, index: number) {
    const companyID = this.companyList[index].id;
    this.utilsService.goToPage('/admin/users/edit-empresas', { ID: companyID });
  }

  async deleteDialog(company: any) {
    this.loading = true;
    const response = await this.promotionService.getAllPromotionActive(company.id);
    this.loading = false;

    this.utilsService.dialog('DELETE', '', response?.result?.count > 0 ? response?.result?.message : '').then(
      result => {
        if (result === true) {
          this.removeCompany(company.id);
        }
      },
      error => console.log(error)
    );
  }

  removeCompany(company_id: number) {
    console.log(company_id);
    this.loading = true;
    this.companyService.deleteCompany(company_id).then(
      async (response) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || '¡La actividad se eliminó!');
        await this.loadData(this.pageNumber, this.pageSize, null, false, this.filters);
      },
      error => {
        // console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || '¡La actividad no se eliminó!');
      }).finally(() => {
        this.loading = false
      })
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  loadList() {
    this.utilsService.getCommissions().then(
      (response) => {
        this.commissonsList = response.result.comisiones;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getCommittees().then(
      (response) => {
        this.committeesList = response.result.comites;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getSectors().then(
      (response) => {
        this.sectorsList = response.result.sectors;
      },
      (error) => {
        console.log(error)
      });
  }

  async searchReturn() {
    if (this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      this.filters = { nit: null, codigoCRM: null, socio: null, status: null, comisionId: null, comiteId: null, sectorId: null }
      await this.loadData(this.pageNumber, this.pageSize, null, false, this.filters);
    }
  }

  loadData(page: number | null, size: number | null, filter: string | null, search?: boolean, filters?: any) { // filters?: Filters | null
    this.loading = true;

    if (search) {
      page = 0;
      this.pageNumber = 0;
    }

    (filter != null) && (this.isFilter = false, this.filters = { nit: null, codigoCRM: null, socio: null, status: null, comisionId: null, comiteId: null, sectorId: null });

    this.companyService.getCompanies(this.pageNumber, this.pageSize, this.filter,
      filters ? filters.nit : null,
      filters ? filters.socio : null,
      null, //filters ? filters.status : null,
      filters ? filters.comiteId : null,
      filters ? filters.sectorId : null,
      filters ? filters.codigoCRM : null,
      filters ? filters.comisionId : null
    ).then(
      // this.companyService.getCompanies(this.pageNumber, this.pageSize, this.filter).then(
      res => {
        this.companyList = res.result.rows;
        this.list = [];
        console.log(res.result.rows);
        if (res.success && res.result.rows) {
          this.list = [];
          this.list = res.result.rows.map((e: any) => {
            return {
              // codigoCRM: e?.codigoCRM,
              // codigoCRM: e?.codigoCRM ? true : false,
              id: e?.id,
              'Nombre de la Empresa': e?.nombreComercial || '',
              "Código": e?.codigoCRM,
              "NIT": e?.nit,
              "Teléfono": e?.telefonoGeneral,
              "Correo Empresa": e?.emailEmpresarial,
              "Estatus": e?.socio == true ? 'Activo' : 'No Activo'
            }
          });
          this.totalPages = Math.ceil(res.count / this.pageSize);
        } else {
          this.list = [];
          this.utilsService.dialog('ERROR', 'Error', res?.error?.message?.description || '¡Error al consultar empresas!');
        }
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
      }
    ).finally(() => {
      this.loading = false;
    })
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false, this.filters);
    }
  }

  onFirst() {
    if (this.pageNumber != 0) {
      this.pageNumber = 0;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false, this.filters);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false, this.filters);
    }
  }

  onLast() {
    if (this.pageNumber != this.totalPages) {
      this.pageNumber = (this.totalPages - 1);
      this.list.length = 0;
      this.loadData(this.pageNumber, this.pageSize, this.filter, false, this.filters);
    }
  }

  companyList: any;
  public comite: {}[] = [];
  public comison: {}[] = [];
  public sectors: {}[] = [];
  public sectorsList: listas[] = [];
  public commissonsList: listas[] = [];
  public committeesList: listas[] = [];
  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: true,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    searchPlaceholderText: 'Buscar...'
  };

  public isFilter: boolean = false;
  public showFilterModal: boolean = false;
  public filters: Filters = { nit: null, codigoCRM: null, socio: null, status: null, comisionId: null, comiteId: null, sectorId: null };

  onSelect($event: any, type: string) {
    switch (type) {
      case 'sectors':
        this.filters.sectorId = $event.id;
        break;
      case 'comite':
        this.filters.comiteId = $event.id;
        break;
      case 'comison':
        this.filters.comisionId = $event.id;
        break;
    }
  }

  onDeSelect(type: string, search: boolean) {
    switch (type) {
      case 'sectors':
        this.sectors = [];
        this.filters.sectorId = null;
        break;
      case 'comite':
        this.comite = [];
        this.filters.comiteId = null;
        break;
      case 'comison':
        this.comison = [];
        this.filters.comisionId = null;
        break;
    }

    if (search) {
      this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
      this.valid_isFilter();
    }
  }

  searchFilter() {
    this.filter = null;
    this.isFilter = true;
    this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
  }

  clearFilter(type: string) {
    switch (type) {
      case 'code':
        this.filter = null;
        this.filters.codigoCRM = null;
        this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
        break;
      case 'nit':
        this.filter = null;
        this.filters.nit = null;
        this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
        break;
      case 'estatus':
        this.filter = null;
        this.filters.socio = null;
        this.loadData(this.pageNumber, this.pageSize, null, true, this.filters);
        break;
      case 'all':
        this.filter = '';
        this.comite = [];
        this.sectors = [];
        this.comison = [];
        this.isFilter = false;
        this.filters = { nit: null, codigoCRM: null, socio: null, status: null, comisionId: null, comiteId: null, sectorId: null }
        this.searchReturn();
        break;
    }
    this.valid_isFilter();
  }

  valid_isFilter() {
    if (
      this.filters.codigoCRM == null && this.filters.nit == null &&
      this.filters.sectorId == null && this.filters.comisionId == null
      && this.filters.status == null
    ) {
      this.isFilter = false;
    }
  }
}
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ScheduleService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    // ACTIVITY    
    getActivityByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/activities/eventBackOffice/${event_id}`, this.options).toPromise();
    }

    // SCHEDULE
    getScheduleByEvent(event_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/schedules/event/${event_id}`, this.options).toPromise();
    }

    getOneSchedule(schedule_id: number): Promise<any> {
        return this.http.get(`${this.url}eventos/schedules/${schedule_id}`, this.options).toPromise();
    }

    createSchedule(data: any): Promise<any> {
        return this.http.post(`${this.url}eventos/schedules/`, data, this.options).toPromise();
    }

    editSchedules(schedule_id: number, data: any): Promise<any> {
        return this.http.put(`${this.url}eventos/schedules/${schedule_id}`, data, this.options).toPromise();
    }

    deletedSchedule(schedule_id: any): Promise<any> {
        return this.http.delete(`${this.url}eventos/schedules/${schedule_id}`, this.options).toPromise();
    }
}

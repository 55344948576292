<app-loading *ngIf="loading"></app-loading>
<div>
  <div class="header">
    <header>Administración - Cuenta de usuarios</header>
    <button class="agregar" (click)="goToPage('/admin/users/edit-usuario')">
      + Agregar
    </button>
  </div>
  <div class="main">
    <div class="default-view">
      <div class="search-field">
        <i class="search-filter" id="search" (click)="loadData(pageNumber, pageSize, filter, true)"></i>
        <input class="search-input" (keyup.enter)="loadData(pageNumber, pageSize, filter, true)"
          (keyup)="searchReturn()" placeholder="Buscar..." [(ngModel)]="filter" />
        <i class="search-icon" id="filter" (click)="showFilterModal = true"></i>
      </div>
      <!-- Tag Filter -->
      <div class="row px-2 mt-2" *ngIf="valid_isFilter()">
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.codigoCRM != null && isFilter">
          <span class="m-4">Código</span>
          <a class="badge-btn-close" (click)="cleanFilter('code')">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.personalNIT != null && isFilter">
          <span class="m-4">NIT</span>
          <a class="badge-btn-close" (click)="cleanFilter('nit')">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.roleName != null && isFilter">
          <span class="m-4">Rol</span>
          <a class="badge-btn-close" (click)="cleanFilter('roleName')">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.status != null && isFilter">
          <span class="m-4">Estado</span>
          <a class="badge-btn-close" (click)="cleanFilter('estatus')">X</a>
        </label>
        <a class="mx-4 my-auto text-dark cursor-pointer" (click)="cleanFilter('all')" *ngIf="isFilter">Limpiar filtros</a>
      </div>
      <div class="table-default">
        <table class="content-table" border="1">
          <thead>
            <tr>
              <th *ngFor="let each of categoriesHeading">{{ each }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of list | tableFilter:filtro:'Nombre del usuario'; let i = index" -->
            <tr *ngFor="let item of list; let i = index">
              <td *ngFor="let x of item | keyvalue : originalOrder" [hidden]="x.key === 'id'">
                {{ x.value }}
              </td>
              <td (mouseleave)="hideActions()">
                <button name="actions" id="actions" class="actions" (click)="showActions(i)"></button>
                <div hidden id="item{{i}}"
                  class="card options-container float-end position-absolute translate-middle-y p-2 m-2" (mouseleave)="hideActions()">
                  <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left btn-modal-hover"
                    (click)="goToPage('/admin/users/edit-usuario', { ID: item.id })">
                    Editar
                  </button>
                  <button name="delete" id="delete" class="actions-btn w-100 text-left btn-modal-hover" (click)="deletedUser(item.id)">
                    Eliminar
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-pagination">
          <i class="left-btn" id="back" (click)="onLeft()"></i>
          <p class="pagination-text">
            Página {{ totalPages != 0 ? pageNumber + 1 : 0 }} de
            {{ totalPages }}
          </p>
          <i class="right-btn" id="back" (click)="onRight()"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal para los filtros-->
<div class="modal-overlay" *ngIf="showFilterModal">
  <div class="modal-container">
    <div class="modal-content-types">
      <div class="modal-btn-close" (click)="showFilterModal = false">X</div>
      <h3 class="modal-title">Filtros</h3>
      <hr/>
      <div>
        <div class="col-12 mb-3">
          <div class="form-label font-weight-bold mb-3">
            <label for="codigo">Código</label>
            <input type="text" class="form-control" id="codigo" [(ngModel)]="filters.codigoCRM" placeholder="Código" />
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-label font-weight-bold mb-3">
            <label for="nit">NIT</label>
            <input type="text" class="form-control" id="nit" [(ngModel)]="filters.personalNIT" placeholder="NIT" />
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-label mb-3">
            <label class="font-weight-bold">Rol</label>
            <!-- class="enabled" -->
            <ng-multiselect-dropdown [placeholder]="'Rol...'" name="md_role" id="md_role" 
              [settings]="dropdownSettings" [data]="roleList" [(ngModel)]="role"
              (onSelect)="onSelect($event, 'roleName')" (onDeSelect)="onDeSelect('roleName')">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label class="form-label font-weight-bold mb-3" for="status">Estatus de socio</label>
          <div class="form-group">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input input-radio-lg" name="status" id="filterActive"
                [checked]="filters.status == 'Activo'" (click)="onSelect('Activo', 'status')" [disabled]="filters.roleName != 'asociado' && filters.roleName != null">
              <label class="form-check-label" for="filterActive">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input input-radio-lg" name="status" id="filterInactive"
                [checked]="filters.status == 'Inactivo'" (click)="onSelect('Inactivo', 'status')" [disabled]="filters.roleName == 'asociado'">
              <label class="form-check-label" for="filterInactive">No activo</label>
            </div>
          </div>

          <!-- <div class="form-group">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input input-radio-lg" name="status" id="filterActive"
                [checked]="filters.status == 'Activo'" (click)="filters.status = 'Activo'">
              <label class="form-check-label" for="filterActive">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input input-radio-lg" name="status" id="filterInactive"
                [checked]="filters.status == 'Inactivo'" (click)="filters.status = 'Inactivo'">
              <label class="form-check-label" for="filterInactive">No activo</label>
            </div>
          </div> -->

        </div>
        <div class="modal-buttons">
          <button class="modal-btn-clear mx-2" (click)="cleanFilter('all')">Limpiar</button>
          <button class="modal-btn-search mx-2" (click)="searchFilter(); showFilterModal = false">Buscar</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { QRCodeModule } from 'angularx-qrcode';
import {DecimalPipe} from '@angular/common';
import { FlatpickrModule } from 'angularx-flatpickr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatSidenavModule,
  MatListModule,
  MatGridListModule,
} from '@angular/material';
import {
  NgbDate,
  NgbModule,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbAlertModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';

// Core
import { HomeComponent } from './Home/home.component';
import { LoginComponent } from './Login/login.component';
import { HeaderComponent } from './Header/header.component';
import { CustomDatePipe } from './Core/pipe/custom-date.pipe';
import { TableFilterPipe } from './Core/pipe/table-filter.pipe';
import { AddDialogComponent } from './Modales/add-dialog/add-dialog.component';
import { LoadingComponent } from './Core/loading/loading.component';
import { NewPasswordComponent } from './Password/new-password/new-password.component';
import { GafeteDialogComponent } from './Modales/gafete-dialog/gafete-dialog.component';
import { PasswordRecoveryComponent } from './Password/password-recovery/password-recovery.component';
import { PasswordRecoveryCodeComponent } from './Password/password-recovery-code/password-recovery-code.component';

// Menu
import { MainMenuComponent } from './Menu/main-menu/main-menu.component';
import { UsersMenuComponent } from './Menu/users-menu/users-menu.component';
import { EventoMenuComponent } from './Menu/evento-menu/evento-menu.component';

// User
import { EditUsuarioComponent } from './Usuarios/edit-usuario/edit-usuario.component';
import { AdminUsuariosComponent } from './Usuarios/admin-usuarios/admin-usuarios.component';
import { EditAdminsComponent } from './Administradores/edit-admins/edit-admins.component';
import { AdminAdminsComponent } from './Administradores/admin-admins/admin-admins.component';
import { EditEmpresaComponent } from './Empresas/edit-empresa/edit-empresa.component';
import { AdminEmpresasComponent } from './Empresas/admin-empresas/admin-empresas.component';

// Event
import { NuevoEventoComponent } from './Eventos/nuevo-evento/nuevo-evento.component';
import { CalendarioEventosComponent } from './Eventos/calendario-eventos/calendario-eventos.component';
import { EditAgendaComponent } from './Agenda/edit-agenda/edit-agenda.component';
import { AdminAgendaComponent } from './Agenda/admin-agenda/admin-agenda.component';
import { AdminParticipantesComponent } from './Participantes/admin-participantes/admin-participantes.component';
import { EditConferencistaComponent } from './Conferencistas/edit-conferencista/edit-conferencista.component';
import { AdminConferencistasComponent } from './Conferencistas/admin-conferencistas/admin-conferencistas.component';
import { EditConferenciasComponent } from './Conferencias/edit-conferencias/edit-conferencias.component';
import { AdminConferenciasComponent } from './Conferencias/admin-conferencias/admin-conferencias.component';
import { UserGuideComponent } from './Usuarios/user-guide/user-guide.component';
import { AdminStandsComponent } from './Stands/admin-stands/admin-stands.component';
import { EditStandsComponent } from './Stands/edit-stands/edit-stands.component';
import { AdminExpositoresComponent } from './Expositores/admin-expositores/admin-expositores.component';
import { ImageDialogComponent } from './Modales/image-dialog/image-dialog.component';
import { AdminGaleriaComponent } from './Galeria/admin-galeria/admin-galeria.component';
import { EditUbicacionesComponent } from './Ubicaciones/edit-ubicaciones/edit-ubicaciones.component';
import { AdminUbicacionesComponent } from './Ubicaciones/admin-ubicaciones/admin-ubicaciones.component';
import { EditPatrocinadoresComponent } from './Patrocinadores/edit-patrocinadores/edit-patrocinadores.component';
import { AdminPatrocinadoresComponent } from './Patrocinadores/admin-patrocinadores/admin-patrocinadores.component';
import { AdminEncuestasComponent } from './Encuestas/admin-encuestas/admin-encuestas.component';

import { EventoPrecioComponent } from './Modales/evento-precio/evento-precio.component';
import { ReporteriaComponent } from './Reportes/reporteria/reporteria.component';
import { DialogComponent } from './Modales/dialog/dialog.component';
import { AdminNotificacionesComponent } from './Notificaciones/admin-notificaciones/admin-notificaciones.component';
import { EditNotificacionComponent } from './Notificaciones/edit-notificacion/edit-notificacion.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AdminPromocionesComponent } from './Promociones/admin-promociones/admin-promociones.component';
import { EditPromocionComponent } from './Promociones/edit-promocion/edit-promocion.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { ModalFiltrosComponent } from './Modales/modal-filtros/modal-filtros.component';
import { JwtInterceptor } from './Core/interceptor/jwt.interceptor';

// import { CustomDateParserFormatter } from './services/customAdapter.service';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordRecoveryComponent,
    PasswordRecoveryCodeComponent,
    NewPasswordComponent,
    CalendarioEventosComponent,
    NuevoEventoComponent,
    AdminUsuariosComponent,
    EditUsuarioComponent,
    AdminAdminsComponent,
    EditAdminsComponent,
    AdminEmpresasComponent,
    EditEmpresaComponent,
    AdminAgendaComponent,
    EditAgendaComponent,
    AdminParticipantesComponent,
    AddDialogComponent,
    DialogComponent,
    GafeteDialogComponent,
    AdminConferencistasComponent,
    EditConferencistaComponent,
    AdminConferenciasComponent,
    EditConferenciasComponent,
    AdminStandsComponent,
    HomeComponent,
    UserGuideComponent,
    EventoPrecioComponent,
    EditStandsComponent,
    AdminExpositoresComponent,
    AdminGaleriaComponent,
    HeaderComponent,
    MainMenuComponent,
    UsersMenuComponent,
    EventoMenuComponent,
    TableFilterPipe,
    CustomDatePipe,
    ImageDialogComponent,
    AdminUbicacionesComponent,
    EditUbicacionesComponent,
    AdminPatrocinadoresComponent,
    EditPatrocinadoresComponent,
    AdminEncuestasComponent,
    ReporteriaComponent,
    LoadingComponent,
    AdminNotificacionesComponent,
    EditNotificacionComponent,
    AdminPromocionesComponent,
    EditPromocionComponent,
    ModalFiltrosComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    DecimalPipe, { provide: LOCALE_ID, useValue: 'es' },
    // { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: TINYMCE_SCRIPT_SRC, useValue: '/tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    EditorModule,
    MatDialogModule,
    AppRoutingModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatGridListModule,
    BrowserAnimationsModule,
    QRCodeModule,
    NgbNavModule,
    MatSidenavModule,
    MatListModule,
    NgbModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgbAlertModule,
    FullCalendarModule,
    FlatpickrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()

    // CalendarModule.forRoot({
    //     provide: DateAdapter,
    //     useFactory: adapterFactory
    // }),
  ],
})
export class AppModule { }

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class EventService {
  private params: HttpParams;
  private headers: HttpHeaders;
  private url: string = environment.url;
  private options: { headers: HttpHeaders, params?: HttpParams };

  constructor(private http: HttpClient) {
    this.params = new HttpParams();
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('accept', 'application/json');
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.options = { headers: this.headers };
  }

  // Obtener eventos
  getEvents(page: number | null, size: number | null, event_name: string | null, eventosVigentes: boolean | null): Promise<any> {
    this.params = new HttpParams();
    (page != null) && (this.params = this.params.set('page', page));
    (size != null) && (this.params = this.params.set('size', size));
    (eventosVigentes != null) && (this.params = this.params.set('eventosVigentes', eventosVigentes));
    (event_name != null && event_name.length > 0) && (this.params = this.params.set('eventName', event_name));
    this.options = { headers: this.headers, params: this.params };
    return this.http.get(`${this.url}eventos/events/`, this.options).toPromise();
  }

  getEventsByDay(page: number, size: number, userId: number, eventDate: number): Observable<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get(`${this.url}eventos/events/app/?page=${page}&size=${size}&userId=${userId}&eventDate=${eventDate}`, this.options);
  }

  getEventsByMonth(page: number, size: number, eventYear: string, eventMonth: string): Observable<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get(`${this.url}eventos/events/?page=${page}&size=${size}&eventYear=${eventYear}&eventMonth=${eventMonth}`, this.options);
  }

  getEventsbyID(ID: number, access?: string): Promise<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.get(`${this.url}eventos/events/${ID}`, this.options).toPromise();
  }

  createEvent(data: any, access: string): Observable<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.post(`${this.url}eventos/events`, data, this.options);
  }

  editEvent(data: any, ID: number, access?: string): Observable<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.put(`${this.url}eventos/events/${ID}`, data, this.options);
  }

  editStatusEvent(ID: number, data: any, access?: string): Observable<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.put(`${this.url}eventos/events/${ID}`, data, this.options);
  }

  deleteEvent(ID: number): Observable<any> {
    this.params = new HttpParams();
    this.options = { headers: this.headers };
    return this.http.delete(`${this.url}eventos/events/${ID}`, this.options);
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-evento-precio',
  templateUrl: './evento-precio.component.html',
  styleUrls: ['./evento-precio.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EventoPrecioComponent {

  fechaInicio = new Date();
  fechaFin = new Date();
  precioAsociado : number = 0.00;
  precioPublico : number = 0.00;
  tipoEntrada : string = "";

  model!: NgbDateStruct;
  model2!: NgbDateStruct;

  eventList = [
    'Apoyo',
    'Estudiante',
    'Participante',
    'Staff' 
  ];

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  close() {
    this.modalService.dismissAll(this);
  }

}

// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
// import { BackServiceParking, BackServiceLocation } from 'src/app/Models/back-service.model';
import { UtilsService } from 'src/app/Services/utils.service';
import { LocationService } from 'src/app/Services/location.service';

@Component({
  selector: 'app-admin-ubicaciones',
  templateUrl: './admin-ubicaciones.component.html',
  styleUrls: ['./admin-ubicaciones.component.css']
})
export class AdminUbicacionesComponent implements OnInit {
  public active: number = 1;
  public eventID: number = 0;
  public loading: boolean = false;
  public datesParking: any[] = []
  public datesLocation: any[] = []
  public categoriesHeading: string[] = [];
  public listHeading: any[] = [{ 'Fecha': '', 'Lugar': '', 'Dirección': '', 'Evento': '', 'Hora': '', 'Link Map': '' }];

  constructor(
    private utilsService: UtilsService,
    private locationService: LocationService
  ) { this.categoriesHeading = Object.keys(this.listHeading[0]); }

  ngOnInit(): void {
    this.eventID = Number(sessionStorage.getItem('EventID'));
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      this.loadDatabyEvent(this.eventID);
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡Debes iniciar sesión!');
      this.utilsService.goToPage('/login');
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params)
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  loadDatabyEvent(event_id: number) {
    this.loading = true;
    return this.locationService.getLocationByEvent(event_id).then(
      response => {
        // console.log(response.result);
        // console.log(response.result.eventPlaces);

        if (response.success) {
          this.datesParking = [];
          this.datesLocation = [];
          const EventData = JSON.parse(sessionStorage.getItem('EventData')!) || {};

          if (response.result.eventPlaces.length > 0) {
            for (const e of response.result.eventPlaces) {
              const data = {
                id: e?.id,
                Lugar: e?.title,
                Direccion: e?.direccion || e?.title,
                Evento: EventData?.nombreEvento,
                Hora: e?.hour.substr(0, 5) + ' horas',
                Estatus: e?.locationUrl
              };
              const groupIndex = this.datesLocation.findIndex(x => x.date === e.date);

              if (groupIndex === -1) {
                this.datesLocation.push({ date: e.date, data: [data] });
              } else {
                this.datesLocation[groupIndex].data.push(data);
              }
            }
            // console.log(this.datesLocation)
          }
          if (response.result.parkingPlaces.length > 0) {
            for (const e of response.result.parkingPlaces) {
              const data = {
                id: e?.id,
                Lugar: e?.title,
                Direccion: e?.direccion || e?.title,
                Evento: EventData.nombreEvento,
                Hora: e?.hour.substr(0, 5) + ' horas',
                Estatus: e?.locationUrl
              };
              const groupIndex = this.datesParking.findIndex(x => x.date === e.date);

              if (groupIndex === -1) {
                this.datesParking.push({ date: e.date, data: [data] });
              } else {
                this.datesParking[groupIndex].data.push(data);
              }
            }
            // console.log(this.datesParking)
          }
          // sessionStorage.setItem('LocationData', JSON.stringify(this.datesLocation));
        }
        else {
          console.log(response);
          this.utilsService.dialog('ERROR', 'Alerta', response?.message?.description || '¡Evento sin datos de ubicacion!');
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.error?.message?.description || 'Error al cargar ubicaciones!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  edit(type: string, ID: any) {
    this.goToPage('/admin/events/edit-ubicaciones', { EVENT: 114, EDIT: type, ID: ID })
  }

  remove(type: string, ID: any) {
    this.loading = true;

    if (type === 'location') {
      this.locationService.deleteLocation(ID).then(
        async (response) => {
          this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || '¡La ubicaión se elimino!');
          await this.loadDatabyEvent(this.eventID);
        },
        error => {
          this.utilsService.dialog('ERROR', 'Error', error.error?.message?.description || '¡La ubicaión no se elimino!');
        }).finally(() => {
          this.loading = false
        })
    } else {
      this.locationService.deleteParking(ID).then(
        async (response) => {
          this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || '¡El parqueo se elimino!');
          await this.loadDatabyEvent(this.eventID);
        },
        error => {
          this.utilsService.dialog('ERROR', 'Error', error.error?.message?.description || '¡La parqueo no se elimino!');
        }).finally(() => {
          this.loading = false
        })
    }
  }
}

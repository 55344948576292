import { Component, OnInit, ViewChild } from '@angular/core';
import { KeyValue } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { QRCodeElementType } from 'angularx-qrcode';
import { SafeUrl } from '@angular/platform-browser';
import { FormControl, NgForm } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EventService } from 'src/app/Services/event.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { BackofficeService } from 'src/app/Services/backoffice.service';
import { BackServiceEvents, BackServiceUsers, listas } from 'src/app/Models/back-service.model';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-nuevo-evento',
  templateUrl: './nuevo-evento.component.html',
  styleUrls: ['./nuevo-evento.component.css']
})

export class NuevoEventoComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  public eventID: any;
  public accessToken: any;
  public comite: [] = [];
  public comison: [] = [];
  public sectors: [] = [];
  public qrCodeSrc!: SafeUrl;
  public crm: boolean = false;
  public iamgeSrc: string = '';
  public imageType: string = '';
  public eventDataAux: any = {};
  public op_not: boolean = true;
  public op_yes: boolean = false;
  public loaded: boolean = false;
  public loading: boolean = false;
  public userFullName: string = '';
  public dateEnd!: NgbDateStruct;
  public dateStart!: NgbDateStruct;
  public fileCalendar: string = '';
  public isShowDiv: boolean = true;
  public products = new FormControl();
  public imageLoaded: boolean = false;
  public files_btn: string = 'gafete-btn';
  public userData = new BackServiceUsers();
  public env: boolean = environment.production;
  public elementType = "img" as QRCodeElementType;
  public myAngularxQrCode: string = "Evento no guardado";
  public eventData: BackServiceEvents = {} as BackServiceEvents;

  public typeList: listas[] = [];
  public costoList: listas[] = [];
  public guestList: listas[] = []; // -> dirigidoList
  public sectorsList: listas[] = [];
  public modalityList: listas[] = [];
  public categoryList: listas[] = [];
  public commissonsList: listas[] = [];
  public committeesList: listas[] = [];
  public listPrices: any[] = new Array();
  public exportedDateEnd: NgbDateStruct | undefined;


  public dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: false,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  };

  constructor(
    private userService: UserService,
    private eventService: EventService,
    private utilsService: UtilsService,
    private Activatedroute: ActivatedRoute,
    private backofficeService: BackofficeService
  ) {
    this.eventData.imageEvent = { data: '', extension: '', name: '' };
    this.eventData.thumbEvent = { data: '', extension: '', name: '' };
    this.eventData.imageBadgeHeader = { data: '', extension: '', name: '' };
    this.eventData.imageBadgeFooter = { data: '', extension: '', name: '' };
  }

  async ngOnInit() {
    this.isShowDiv = true;
    this.accessToken = sessionStorage.getItem('Token');
    this.eventID = this.Activatedroute.snapshot.queryParams['ID'] || 0;
    this.loadLists();

    if (this.accessToken && this.eventID > 0) {
      // Editar Evento
      sessionStorage.setItem('EventID', this.eventID);
      this.myAngularxQrCode = this.eventID;
      await this.loadDatabyID();
    }
    else if (this.accessToken) {
      // Nuevo Evento
      await this.getCurrentUser();
      this.eventData.needBadge = 0;
      this.eventData.status = 'draft';
      this.eventData.creadorId = this.userData.id;
      sessionStorage.setItem('EventID', '0');
      sessionStorage.setItem('EventType', '0');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'Inicia sesión con un usuario válido.');
      this.goToPage('/login');
    }
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validation_date(): boolean {
    if (this.dateStart && this.dateEnd) {
      // const e = this.dateEnd;
      // const s = this.dateStart;
      // const end = new Date(`${e.year}-${e.month}-${e.day}  ${this.eventData.horaFinal}`);
      // const star = new Date(`${s.year}-${s.month}-${s.day}  ${this.eventData.horaInicio}`);

      const end = new Date(`${this.dateEnd.year}-${this.dateEnd.month}-${this.dateEnd.day}  ${this.eventData.horaFinal}`);
      const star = new Date(`${this.dateStart.year}-${this.dateStart.month}-${this.dateStart.day}  ${this.eventData.horaInicio}`);
      
      if (star.getTime() > end.getTime()) {
        return true;
      } else {
        return false;
      }
    } {
      return false;
    }
  }


  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  changeCategory() {
    const element = document.getElementById('priceBlock');
    const item = document.getElementById('mainEvento');
    item?.classList.remove('not-visible');
    element?.classList.remove('not-visible');

    if (Number(this.eventData.eventCategoryId) == 1 || Number(this.eventData.eventCategoryId) == 3 || Number(this.eventData.eventCategoryId) == 10) {
      this.utilsService.emitChange(true, false, 'nuevo-evento l157');
    }
    else {
      this.utilsService.emitChange(true, true, 'nuevo-evento l160');
    }
    sessionStorage.setItem('EventType', this.eventData.eventCategoryId.toString()); //this.eventCategory
  }

  // showActions(event: any, index: number) {
  //   this.isShowDiv = true;
  //   const element = document.getElementById('item' + index);
  //   const listItes = document.getElementsByClassName('actions-enable')[0];
  //   if (listItes) {
  //     listItes.classList.remove('actions-enable');
  //   }
  //   if (('item' + index) !== listItes?.id || !listItes.id) {
  //     element?.classList.add('actions-enable');
  //   }
  // }

  async onFileSelected(event: any) {
    this.loaded = false;
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.imageType = file.type.substring(file.type.indexOf('/') + 1);

    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {

      const fielName = event.target.files[0].name;
      if (event.target.id === "fileheader") {
        this.eventData.imageBadgeHeader = { extension: this.imageType, data: '', name: fielName };
        reader.onload = await this._handleReaderLoadedHeader.bind(this);
      }
      else if (event.target.id === "fileGaf") {
        this.eventData.imageEvent = { extension: this.imageType, data: '', name: fielName };
        reader.onload = await this._handleReaderLoadedBadge.bind(this);
      }
      else if (event.target.id === "thumbnailGafete") {
        this.eventData.thumbEvent = { extension: this.imageType, data: '', name: fielName };
        // this.eventData.imageThumbnail = { extension: this.imageType, data: '', name: fielName };
        reader.onload = await this._handleReaderLoadedThumbnail.bind(this);
      }
      else if (event.target.id === "filefooter") {
        this.eventData.imageBadgeFooter = { extension: this.imageType, data: '', name: fielName };
        reader.onload = await this._handleReaderLoadedFooter.bind(this);
      }
      reader.readAsDataURL(file);
    }
  }

  _handleReaderLoadedHeader(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.iamgeSrc = reader.result;
    this.eventData.imageBadgeHeader!.data = this.iamgeSrc;
  }

  _handleReaderLoadedBadge(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.iamgeSrc = reader.result;
    this.eventData.imageEvent!.data = this.iamgeSrc;
  }

  _handleReaderLoadedThumbnail(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.iamgeSrc = reader.result;
    this.eventData.thumbEvent!.data = this.iamgeSrc;
    // this.eventData.imageThumbnail!.data = this.iamgeSrc;
  }

  _handleReaderLoadedFooter(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.iamgeSrc = reader.result;
    this.eventData.imageBadgeFooter!.data = this.iamgeSrc;
  }

  changeGroup(option: number) {
    if (option === 1) {
      this.op_yes = true;
      this.op_not = false;
      this.eventData.needBadge = 1;
      this.files_btn = 'gafete-btn-active';
    }
    else {
      this.op_yes = false;
      this.op_not = true;
      this.eventData.needBadge = 0;
      this.files_btn = 'gafete-btn';
    }
  }

  goToPage(pageName: string) {
    sessionStorage.setItem('EventID', '0')
    this.utilsService.goToPage(pageName)
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeSrc = url;
  }

  loadLists() {
    this.backofficeService.getCategories()
      .subscribe({
        next: (res) => {
          this.categoryList = res.result.eventCategories;
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        }
      })

    this.backofficeService.getGuestType()
      .subscribe({
        next: (res) => {
          this.guestList = res.result.guestTypes;
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        }
      });

    this.backofficeService.getEventType()
      .subscribe({
        next: (res) => {
          this.typeList = res.result.eventTypes;
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        }
      });

    this.backofficeService.getModalities()
      .subscribe({
        next: (res) => {
          this.modalityList = res.result.modalities;
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        }
      });

    this.backofficeService.getCostType()
      .subscribe({
        next: (res) => {
          // console.log(res)
          this.costoList = res.result.costTypes;
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        }
      });

    this.utilsService.getCommissions().then(
      (response) => {
        this.commissonsList = response.result.comisiones;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getCommittees().then(
      (response) => {
        this.committeesList = response.result.comites;
      },
      (error) => {
        console.log(error)
      });

    this.utilsService.getSectors().then(
      (response) => {
        this.sectorsList = response.result.sectors;
      },
      (error) => {
        console.log(error)
      });
  }

  getCurrentUser() {
    this.accessToken;
    return this.userService.getCurrentUser().then(
      (response) => {
        // console.log('usuario', response.result)
        this.userData = response.result;
        this.userData.id = response.result.id;
        this.userData.area = response.result.area?.name;
        this.userData.roles = response.result.role;
        this.userData.phone = response.result.phone;
        this.userData.email = response.result.email;
        this.userData.puesto = response.result.puesto?.name;
        this.userData.areaOrganizacional = response.result?.areaOrganizacional;
        this.userFullName = this.userData.firstName + " " + this.userData.firstLastname;
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    )
  }

  public permissionCRM: boolean = false;
  loadDatabyID() {
    this.loading = true;
    return this.eventService.getEventsbyID(this.eventID, this.accessToken).then(
      async res => {
        if (res.result) {
          console.log(res.result);
          // eventData.guestTypeId
          // eventData.eventTypeId
          // idCrm
          //*
          (res?.result?.idCrm != null) && (this.permissionCRM = true);

          this.eventData = res?.result;
          this.listPrices = res?.result?.eventPrices || [];
          sessionStorage.setItem('EventData', JSON.stringify(res?.result));
          sessionStorage.setItem('EventType', JSON.stringify(res?.result?.eventCategory?.id));

          this.eventData.needBadge === 1 ? await this.changeGroup(1) : await this.changeGroup(0);
          // (typeof (res.result.imageBadgeHeader) === 'string') && (await this.changeGroup(1));
          // (typeof (res.result.imageBadgeFooter) === 'string') && (await this.changeGroup(1));

          const imageEvent = typeof res.result.imageEvent === 'string' ? res.result?.imageEvent.split('/') : '';
          const imageThumbnail = typeof res.result.thumbEvent == 'string' ? res.result?.thumbEvent.split('/') : '';
          const imageBadgeHeader = typeof res.result.imageBadgeHeader == 'string' ? res.result?.imageBadgeHeader.split('/') : '';
          const imageBadgeFooter = typeof res.result.imageBadgeFooter == 'string' ? res.result?.imageBadgeFooter.split('/') : '';

          this.eventData.imageEvent = { data: '', extension: '', name: imageEvent[6], url: res.result?.imageEvent };
          this.eventData.thumbEvent = { data: '', extension: '', name: imageThumbnail[6], url: res.result?.thumbEvent };
          this.eventData.imageBadgeHeader = { data: '', extension: '', name: imageBadgeHeader[6], url: res.result?.imageBadgeHeader };
          this.eventData.imageBadgeFooter = { data: '', extension: '', name: imageBadgeFooter[6], url: res.result?.imageBadgeFooter };

          if (this.eventData.fechaInicio && this.eventData.fechaFinal) {
            const tempEnd = this.eventData.fechaFinal.toString().split('-');
            const tempDate = this.eventData.fechaInicio.toString().split('-');
            this.dateEnd = { day: Number(tempEnd[2]), month: Number(tempEnd[1]), year: Number(tempEnd[0]) };
            this.dateStart = {
              day: Number(tempDate[2]), month: Number(tempDate[1]), year: Number(tempDate[0])
            }
          }

          this.comite = res?.result?.comites || [];
          this.sectors = res?.result?.sectors || [];
          this.comison = res?.result?.comisiones || [];

          (res?.result?.eventCategory?.id) && (await this.changeCategory());
          await this.getCurrentUser();
          //*/
        } else {
          this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        }
      },
      error => {
        console.log(error);
        sessionStorage.removeItem('EventID');
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
        this.goToPage('/admin/events/calendario-eventos');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async submitEvent() {
    if (this.form.valid && this.dateStart && this.dateEnd) {
      this.eventData.fechaFinal = new Date(this.dateEnd.year, (this.dateEnd.month - 1), this.dateEnd.day);
      this.eventData.fechaInicio = new Date(this.dateStart.year, (this.dateStart.month - 1), this.dateStart.day);

      const event: BackServiceEvents | any = {
        id: this.eventData?.id,
        creadorId: this.eventData?.creadorId,
        direccion: this.eventData?.direccion,
        horaFinal: this.eventData?.horaFinal,
        fechaFinal: this.eventData?.fechaFinal,
        cupoLimite: this.eventData?.cupoLimite,
        horaInicio: this.eventData?.horaInicio,
        fechaInicio: this.eventData?.fechaInicio,
        descripcion: this.eventData?.descripcion,
        enlaceSitio: this.eventData?.enlaceSitio,
        nombreEvento: this.eventData.nombreEvento,
        enlaceEvento: this.eventData?.enlaceEvento,
        archivoCalendar: this.eventData?.archivoCalendar,
        enlaceCarretilla: this.eventData?.enlaceCarretilla,
        asociadosInvitados: this.eventData?.asociadosInvitados,
        noAsociadosInvitados: this.eventData?.noAsociadosInvitados,
        //SELECTS
        status: this.eventData?.status,
        costTypeId: this.eventData?.costTypeId,
        modalityId: this.eventData?.modalityId,
        guestTypeId: this.eventData?.guestTypeId,
        eventTypeId: this.eventData?.eventTypeId,
        eventCategoryId: this.eventData?.eventCategoryId,
        //IMAGE
        needBadge: this.eventData?.needBadge,
        imageEvent: this.eventData?.imageEvent,
        thumbEvent: this.eventData?.thumbEvent,
        imageBadgeHeader: this.eventData?.imageBadgeHeader,
        imageBadgeFooter: this.eventData?.imageBadgeFooter,
      }

      event.imageEvent.data == '' ? delete event.imageEvent : '';
      event.thumbEvent.data == '' ? delete event.thumbEvent : '';
      event.imageBadgeHeader.data == '' ? delete event.imageBadgeHeader : '';
      event.imageBadgeFooter.data == '' ? delete event.imageBadgeFooter : '';

      this.eventID > 0 ? this.editEvent(event) : this.createEvent(event);
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos obligatorios.');
    }
  }

  createEvent(eventData: any) {
    this.eventService.createEvent(eventData, this.accessToken)
      .subscribe({
        next: (res) => {
          this.utilsService.dialog('SUCCESS', 'Éxito', 'Evento Guardado', '/admin/events/admin-participantes');
          sessionStorage.setItem('EventID', res.result.id);
          this.eventDataAux = [];
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Error al crear el evento.');
          this.eventDataAux = [];
        }
      })
  }

  editEvent(eventData: any) {
    this.eventService.editEvent(eventData, this.eventID, this.accessToken)
      .subscribe({
        next: (res) => {
          this.utilsService.dialog('SUCCESS', 'Éxito', 'Evento Guardado', '/admin/events/admin-participantes');
          this.eventDataAux = [];
        },
        error: (e) => {
          this.utilsService.dialog('ERROR', 'Error', 'Error al editar evento.');
          this.eventDataAux = [];
        }
      })
  }
}

<app-loading *ngIf="loading"></app-loading>
<!-- <app-modal-filtros *ngIf="showFilterModal"></app-modal-filtros> -->
<div>
  <div class="header">
    <header>Administración - Cuenta de Empresas</header>
    <button class="agregar" (click)="goToPage('/admin/users/edit-empresas')" [hidden]="role == 'ROLE_ADMIN'">+
      Agregar</button>
  </div>
  <div class="main">
    <div class="default-view">
      <div class="search-field">
        <i class="search-filter" id="search" (click)="loadData(pageNumber, pageSize, filter, true, {})"></i>
        <input class="search-input" (keyup.enter)="loadData(pageNumber, pageSize, filter, true, null)"
          (keyup)="searchReturn()" placeholder="Buscar empresa..." [(ngModel)]="filter" />
        <i class="search-icon" id="filter" (click)="showFilterModal = true"></i>
      </div>

      <div class="row px-2 mt-2" *ngIf="filters.codigoCRM != null || filters.nit != null ||
        filters.sectorId != null || filters.comisionId != null || filters.status != null">
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.codigoCRM != null && isFilter">
          <span class="m-4">Código</span>
          <a class="badge-btn-close" (click)="clearFilter('code')">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.nit != null && isFilter">
          <span class="m-4">NIT</span>
          <a class="badge-btn-close" (click)="clearFilter('nit')">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.sectorId != null && isFilter">
          <span class="m-4">Sector</span>
          <a class="badge-btn-close" (click)="onDeSelect('sectors', true)">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.comisionId != null && isFilter">
          <span class="m-4">Comisión</span>
          <a class="badge-btn-close" (click)="onDeSelect('comison', true)">X</a>
        </label>
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.status != null && isFilter">
          <span class="m-4">Estado</span>
          <a class="badge-btn-close" (click)="clearFilter('estatus')">X</a>
        </label>
        <a class="mx-4 my-auto text-dark cursor-pointer" (click)="clearFilter('all')" *ngIf="isFilter">Limpiar filtros</a>
      </div>

      <div class="table-default">
        <table class="content-table" border="1">
          <thead>
            <tr>
              <th *ngFor="let each of categoriesHeading"> {{each}} </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list; let i = index ">
              <td *ngFor="let x of item | keyvalue:originalOrder" [ngClass]="x.key == 'Estatus' && x.value == 'Activo' ? 'activo' : ''" [hidden]="x.key == 'id'">
                {{x.value}}
              </td>
              <td (mouseleave)="hideActions()">
                <button name="actions" id="actions" class="actions" (click)="showActions(i)"></button>
                <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2 " id="item{{i}}" (mouseleave)="hideActions()">
                  <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left"
                    (click)="editCompany(item, i)">Editar</button>
                  <button name="edit" id="edit" class="actions-btn w-100 text-left" *ngIf="item['Código'] == null || item['Código'] == undefined || item['Código'] == ''"
                    (click)="deleteDialog(item)">Eliminar</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-pagination">
          <i class="btn-arrow btn-arrow-first" id="back" (click)="onFirst()"></i>
          <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
          <p class="pagination-text">Página {{pageNumber + 1}} de {{totalPages}}</p>
          <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
          <i class="btn-arrow btn-arrow-last" id="back" (click)="onLast()"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal para los filtros-->
<div class="modal-overlay" *ngIf="showFilterModal">
  <div class="modal-container">
    <div class="modal-content-types">
      <div class="modal-btn-close" (click)="showFilterModal = false">X</div>
      <h3 class="modal-title">Filtros</h3>
      <hr />
      <div>
        <div class="col-12 mb-3">
          <div class="form-label font-weight-bold mb-3">
            <label for="codigo">Código</label>
            <input type="text" class="form-control" id="codigo" [(ngModel)]="filters.codigoCRM" placeholder="Código" />
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-label font-weight-bold mb-3">
            <label for="nit">NIT</label>
            <input type="text" class="form-control" id="nit" [(ngModel)]="filters.nit" placeholder="NIT" />
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-label mb-3">
            <label class="font-weight-bold">Sector</label>
            <ng-multiselect-dropdown [placeholder]="'Sectores'" name="md_sector" id="md_sector" class="enabled"
              [settings]="dropdownSettings" [data]="sectorsList" [(ngModel)]="sectors"
              (onSelect)="onSelect($event, 'sectors')" (onDeSelect)="onDeSelect('sectors', false)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-label mb-3">
            <label class="font-weight-bold">Comisión</label>
            <ng-multiselect-dropdown [placeholder]="'Comisiones'" name="md_comison" id="md_comison"
              [settings]="dropdownSettings" [data]="commissonsList" [(ngModel)]="comison"
              (onSelect)="onSelect($event, 'comison')" (onDeSelect)="onDeSelect('comison', false)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label class="form-label font-weight-bold mb-3" for="status">Estatus de socio</label>
          <div class="form-group">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input input-radio-lg" name="socio" id="filterActive"
              [checked]="filters.socio == 1" (click)="filters.socio = 1">
              <label class="form-check-label" for="filterActive">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input input-radio-lg" name="status" id="filterInactive"
              [checked]="filters.socio == 0" (click)="filters.socio = 0">
              <label class="form-check-label" for="filterInactive">No activo</label>
            </div>
          </div>
        </div>
        <div class="modal-buttons">
          <button class="modal-btn-clear mx-2" (click)="clearFilter('all')">Limpiar</button>
          <button class="modal-btn-search mx-2" (click)="searchFilter(); showFilterModal = false">Buscar</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { NgForm } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { ScheduleService } from 'src/app/Services/schedule.service';

interface Schedule {
  id: number;
  lugar: string;
  horaFin: string;
  fechaFin: string;
  modalityId: number;
  horaInicio: string;
  fechaInicio: string;
  nombreAgenda: string;
}

@Component({
  selector: 'app-edit-agenda',
  templateUrl: './edit-agenda.component.html',
  styleUrls: ['./edit-agenda.component.css']
})

export class EditAgendaComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  eventFinishedError: boolean[] = [];

  public eventID: number = 0;
  public schedules: any[] = [];
  public scheduleID: number = 0;
  public loading: boolean = false;
  public disabled: boolean = false;
  public modalitySchedule: any[] = [];
  public accessToken: string | null = '';
  public date_error: any[] = [false, false, false, false, false, false, false, false, false, false];

  constructor(
    private utilsService: UtilsService,
    private scheduleService: ScheduleService
  ) { this.eventID = Number(sessionStorage.getItem('EventID')) }

  async ngOnInit() {
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    this.scheduleID = Number(this.utilsService.getParam('ID')) || 0;

    if (token) {
      if (this.eventID > 0 && this.scheduleID === 0) {
        this.loadModality();
        // Cambio de flujo: this.addSchedule(); -> await this.loadSchedulebyEvent(); //Habilitar boton agregar/eliminar
        this.addSchedule();
      }
      else if (this.eventID > 0 && this.scheduleID > 0) {
        this.loadModality();
        this.getOneSchedule(this.scheduleID);
      } else {
        this.utilsService.dialog('ERROR', 'Alerta', '¡No tienes seleccionado un evento!');
        this.goToPage('/admin/events/calendario-eventos');
      }
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡No tienes acceso!');
      this.goToPage('/login');
    }
  }

  validation_date(index: number, name: string): boolean {
    const schedule = this.schedules[index];

    if (schedule.fechaInicio && schedule.fechaFin && schedule.horaInicio && schedule.horaFin) {
      const startDateTime = new Date(`${schedule.fechaInicio}T${schedule.horaInicio}`);
      const endDateTime = new Date(`${schedule.fechaFin}T${schedule.horaFin}`);

      if (startDateTime.getTime() > endDateTime.getTime()) {
        this.date_error[index] = true;
        return true;
      } else {
        this.date_error[index] = false;
        return false;
      }
    } else {
      return false;
    }
  }


  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  addSchedule() {
    this.schedules.push({
      id: 0,
      lugar: '',
      direccion: '',
      modalityId: 0,
      modalidadID: 0,
      nombreAgenda: '',
      horaFin: '',
      horaInicio: '',
      fechaFin: '',
      fechaInicio: '',
      fechaHoraFin: '',
      fechaHoraInicio: '',
      eventId: this.eventID,
    });
  }

  removeSchedule(i: number, schedule_id: number) {
    if (schedule_id === 0) {
      this.schedules.splice(i, 1);
    } else {
      this.scheduleService.deletedSchedule(schedule_id).then(
        (response) => {
          this.schedules.splice(i, 1);
        },
        error => {
          console.log(error);
          this.utilsService.dialog('ERROR', 'Error', '¡La actividad no se eliminó!');
        });
    }
  }

  loadModality() {
    this.utilsService.getModalities().then(
      (response) => {
        this.modalitySchedule = response.result.modalities;
      },
      error => {
        console.log(error)
      }
    )
  }

  loadSchedulebyEvent() {
    this.loading = true;
    return this.scheduleService.getScheduleByEvent(this.eventID).then(
      response => {
        // console.log(response.result)
        if (response.result.schedule_dates.length > 0) {
          for (const e of response.result.schedule_dates) {
            for (const i of e.schedule) {
              this.schedules.push({
                id: i?.id || 'error',
                lugar: i?.description,
                nombreAgenda: i?.name,
                eventId: this.eventID,
                modalityId: i?.modalityId || null,
                fechaFin: e?.endDate,
                fechaInicio: e?.date,
                horaInicio: i?.hour.substr(0, 5) || '',
                horaFin: i?.endHour.substr(0, 5) || '',
              });
            }
          }
        } else {
          this.addSchedule();
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getOneSchedule(schedule_id: number) {
    this.loading = true;
    this.scheduleService.getOneSchedule(schedule_id).then(
      (response) => {
        // console.log(response);
        this.schedules.push({
          id: this.scheduleID,
          eventId: this.eventID,
          lugar: response?.result?.lugar,
          modalityId: response?.result?.modalityId,
          nombreAgenda: response?.result?.nombreAgenda,
          horaInicio: response.result.fechaHoraInicio ? response.result.fechaHoraInicio.substr(11, 5) : '',
          horaFin: response.result.fechaHoraFin ? response.result.fechaHoraFin.substr(11, 5) : '',
          fechaFin: response.result.fechaHoraFin ? response.result.fechaHoraFin.substr(0, 10) : '',
          fechaInicio: response.result.fechaHoraInicio ? response.result.fechaHoraInicio.substr(0, 10) : ''
        })
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar actividad!');
        this.goToPage('/admin/events/admin-agenda');
      }).finally(() => {
        this.loading = false
      })
  }

  async processOne() {
    if (this.form.valid && !this.date_error.includes(true)) {
      let response;
      this.loading = true;
      this.disabled = true;
      try {
        const schedule: any = {
          id: this.schedules[0]?.id,
          lugar: this.schedules[0]?.lugar,
          eventId: this.schedules[0]?.eventId,
          modalityId: this.schedules[0]?.modalityId,
          nombreAgenda: this.schedules[0]?.nombreAgenda,
          modalidadID: Number(this.schedules[0]?.modalityId),
          fechaHoraFin: this.schedules[0]?.fechaFin + ' ' + this.schedules[0]?.horaFin,
          fechaHoraInicio: this.schedules[0]?.fechaInicio + ' ' + this.schedules[0]?.horaInicio
        }
        schedule.id === 0 
          ? response = await this.scheduleService.createSchedule(schedule) 
          : response = await this.scheduleService.editSchedules(schedule.id, schedule);

        if (response.success === true) {
          this.goToPage('/admin/events/admin-agenda');
          this.utilsService.dialog('SUCCESS', response?.message?.title, response?.message?.description);
        } else {
          this.utilsService.dialog('ERROR', response.error?.message?.title, response.error?.message?.description);
        }
      }
      catch (error: any) {
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || 'Hubo un error, contacte al administrador.');
      }

      this.loading = false;
      this.disabled = false;
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos correctamente.');
    }
  }

  // Procesar varias peticiones
  async processData() {
    //*
    if (this.form.valid && !this.date_error.includes(true)) {
      this.loading = true;
      this.disabled = true;

      let save: boolean = true;
      let update: boolean = true;

      for (const e of this.schedules) {
        e.modalidadID = Number(e.modalityId);
        e.fechaHoraFin = e.fechaFin + ' ' + e.horaFin;
        e.fechaHoraInicio = e.fechaInicio + ' ' + e.horaInicio;

        if (e.id === 0) {
          save = await this.save(e);
        } else {
          update = await this.update(e);
        }

        if (!save || !update) {
          this.loading = false;
          this.disabled = false;
          this.utilsService.dialog('ERROR', 'Error', '¡Hubo un error al guardar!');
          return
        }
      }

      this.utilsService.dialog('SUCCESS', 'Éxito', '¡Se guardo la información exitosamente!');
      this.goToPage('/admin/events/admin-agenda');

      this.loading = false;
      this.disabled = false;
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos correctamente.');
    }
    //*/
  }

  async save(schedule: Schedule): Promise<boolean> {
    // console.log('SAVE ', schedule);
    let status: boolean = true;
    await this.scheduleService.createSchedule(schedule).then(
      (response) => {
        console.log(response);
        status = true;
      },
      error => {
        console.log(error);
        status = false;
      });
    return status;
  }

  async update(schedule: Schedule): Promise<boolean> {
    // console.log('UPDATE ', schedule);
    let status: boolean = true;

    await this.scheduleService.editSchedules(schedule.id, schedule).then(
      (response) => {
        console.log(response);
        status = true;
      },
      error => {
        console.log(error);
        status = false;
      });
    return status;
  }
}
import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { NotificationItem } from 'src/app/Models/notification.item.model';
import { NotificationsService } from 'src/app/Services/notifications.service';

@Component({
  selector: 'app-admin-notificaciones',
  templateUrl: './admin-notificaciones.component.html',
  styleUrls: ['./admin-notificaciones.component.css'],
})
export class AdminNotificacionesComponent implements OnInit {
  constructor(
    private utilsService: UtilsService,
    private notificationsService: NotificationsService
  ) {
    this.categoriesHeading = Object.keys(this.listHeading[0]);
  }

  public nextId = 1;
  public pageSize = 20;
  public pageNumber = 0;
  public totalPages = 0;
  public renderList: any[] = [];
  public loading: boolean = false;
  public filter: string | null = null;
  public categoriesHeading: string[] = [];
  public notificationsList: NotificationItem[] = [];

  public listHeading: {}[] = [
    {
      'Nombre de la notificación': '',
      'Fecha de creación': '',
      'Fecha de envío': '',
      'Evento/Promoción': '',
      'Usuarios Enviados': '',
      Estatus: '',
      '': '',
    },
  ];

  ngOnInit(): void {
    this.loadNotifications(this.pageNumber, this.pageSize, null, false)
  }

  async searchReturn() {
    if (this.filter != null && this.filter!.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      await this.loadNotifications(this.pageNumber, this.pageSize, this.filter);
    }
  }

  async loadNotifications(pageNumber: number | null, pageSize: number | null, filter: string | null, search?: boolean) {
    this.loading = true;
    this.renderList = [];

    if (search) {
      this.pageNumber = 0;
    }

    try {
      const response = await this.notificationsService.getAllNotifications(this.pageNumber, this.pageSize, this.filter);
      if (response?.success === true) {
        console.log(response.result)

        this.notificationsList = response.result;
        this.notificationsList = this.notificationsList.reverse();

        this.renderList = response.result.map((e: any) => {
          return {
            'Nombre de la notificación': e?.subject,
            'Fecha de creación': e?.createdAt,
            'Fecha de envío': e?.dateSend,
            'Evento': e?.eventName,
            'Usuarios enviados': e?.totalUsers,
            'Estatus': this.getNotificationStatus(e.notificationType),
            'id': e.id
          };
        });

        this.totalPages = Math.ceil(response.count / this.pageSize);
      } else {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    } catch (error) {
      this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
    } finally {
      this.loading = false;
    }
  }

  getNotificationStatus(status: string) {
    switch (status) {
      case 'sent':
        return 'Enviado';
        break;
      case 'draft':
        return 'Borrador';
      case 'scheduled':
        return 'Programado';
        break;
      default:
        return 'Error';
        break;
    }
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  async deleteNotification(notification: any) {
    try {
      const response = await this.notificationsService.deleteNotification(notification.id);
      console.log(response)
      if (response?.success === true) {
        // : CAMBIAR ESTA CONDICIÓN CUANDO SE PUEDA ELIMINAR UNA NOTIFICACIÓN O BACK RETORNE UN MENSAJE DE ERROR O CÓDIGO
        if (response?.message?.description === 'Una notificacion enviada no se puede modificar o eliminar') {
          this.utilsService.dialog('ERROR', 'Error', 'Una notificación enviada no se puede modificar o eliminar.');
          return;
        } else {
          this.utilsService.dialog('SUCCESS', 'Notificación', response?.message.description);
        }
      } else {
        this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
      }
    } catch (error) {
      this.utilsService.dialog('ERROR', 'Error', 'Por favor vuelve a intentar más tarde.');
    }

    this.loadNotifications(this.pageNumber, this.pageSize, this.filter);
  }

  editNotification(index: number) {
    console.log(this.notificationsList[index])
    const notificationModel = this.notificationsList[index];
    this.utilsService.goToPage('/admin/edit-notificacion', { ID: notificationModel.id });
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.notificationsList.length = 0;
      this.loadNotifications(this.pageNumber, this.pageSize, this.filter);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.notificationsList.length = 0;
      this.loadNotifications(this.pageNumber, this.pageSize, this.filter);
    }
  }
}

<app-loading *ngIf="loading"></app-loading>

<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet">
<div class="main">
    <div class="top-block">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/users/admin-empresas')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
    </div>
    <div class="main-block">
        <form #form="ngForm">
            <!-- <form ngNativeValidate class="custom-form" method="post" #form> -->
            <label class="label-1"> Información de la empresa</label>
            <div class="status-block">
                <span class="status-label">Estatus*</span>
                <select class="status-box" [(ngModel)]="companyData.status" name="Estatus" id="Estatus" [required]="permission" disabled>
                    <option value=1>Activo</option>
                    <option value=0>Inactivo</option>
                </select>
                <div class="err-msg" *ngIf="validation_msg('Estatus')"> Este campo es obligatorio.</div>
            </div>
            <i class="down-arrow" id="down"></i>
            <div class="row-1">
                <div class="col-md-1-form">
                    <div class="custom-input">
                        <label>Nombre Comercial*</label>
                        <input type="text" class="{{class}}" name="nombreEmpresa"
                            id="nombreEmpresa" [(ngModel)]="companyData.nombreComercial" placeholder="Nombre Comercial"
                            [required]="permission" [disabled]="!permission"/>
                        <div class="err-msg" *ngIf="validation_msg('nombreEmpresa')"> Este campo es obligatorio.</div>
                    </div>
                    <div class="inner-section-form">
                        <div class="custom-input">
                            <label>Razón Social*</label>
                            <input class="{{class}}" name="razon" id="razon"
                                placeholder="Razón Social" [(ngModel)]="companyData.razonSocial" [required]="permission" [disabled]="!permission"/>
                            <div class="err-msg" *ngIf="validation_msg('razon')"> Este campo es obligatorio.</div>
                        </div>
                        <div class="custom-input">
                            <label>Teléfono*</label>
                            <input class="{{class}}" name="phone"
                                [(ngModel)]="companyData.telefonoGeneral" placeholder="Teléfono"
                                pattern="[+]{0,1}[\d]+[ ]{0,1}[\d]+[-]{0,1}[\d]+"
                                oninput="value = value.replace(/[^\-\+0-9 ]/g,'')"
                                [required]="permission" [disabled]="!permission"/>
                            <div class="err-msg" *ngIf="validation_msg('phone')"> 
                                {{form && form.controls['phone'].errors!['required'] ? 'Este campo es obligatorio. ' : ''}}
                                {{form && form.controls['phone'].errors!['pattern'] ? 'Teléfono no aceptado.' : ''}}
                            </div>
                        </div>
                    </div>
                    <div class="custom-input">
                        <label>Dirección fiscal*</label>
                        <input type="text" class="{{class}}" name="address" id="address"
                            [(ngModel)]="companyData.direccion" placeholder="Dirección" [required]="permission" [disabled]="!permission" />
                        <div class="err-msg" *ngIf="validation_msg('address')"> Este campo es obligatorio.</div>
                    </div>
                    <div class="custom-input">
                        <label>Sitio web</label>
                        <input class="{{class}}" name="website" placeholder="Website"
                            [(ngModel)]="companyData.paginaWeb" [disabled]="!permission" />
                    </div>
                </div>
                <div class="col-md-2-form">
                    <label class="mg-wdth">(*)</label>
                    <div class="image-box">
                        <i class="upload-btn" id="upload-cal" onclick="document.getElementById('input-images-file').click();" (click)="mark_img('input-images-text')"></i>
                        <input type="file" style="display:none;" id="input-images-file" #FileSelectInputDialog (change)="addFile($event)"  accept="image/png, image/jpeg" />
                        <input type="hidden" name="input-images-text" id="input-images-text" [(ngModel)]="companyData.newImage!.url" required />
                        <img class="image-den" *ngIf="imageSrc" [src]="imageSrc" [class.loaded]="imageLoaded" />
                    </div>
                    <div class="err-msg" *ngIf="validation_msg('input-images-text')"> Este campo es obligatorio.</div>
                    <div class="inner-section-form">
                        <div class="custom-input">
                            <label>ID CRM</label>
                            <input class="disabled" name="codigo"
                                [(ngModel)]="companyData.codigoCRM" placeholder="Código" readOnly />
                        </div>
                        <div class="custom-input">
                            <label>NIT*</label>
                            <input type="text" class="{{class}}" name="nit" id="nit"
                                [(ngModel)]="companyData.nit" placeholder="00000-AA" [required]="permission" [disabled]="!permission" />
                            <div class="err-msg" *ngIf="validation_msg('nit')"> Este campo es obligatorio.</div>
                        </div>
                    </div>
                    <div class="inner-section-form">
                        <div class="custom-input">
                            <label>Correo empresarial*</label>
                            <input class="{{class}}" name="email"
                                placeholder="info@correo.com" [(ngModel)]="companyData.emailEmpresarial"
                                [required]="permission" [disabled]="!permission"/>
                                <div class="err-msg" *ngIf="validation_msg('email')"> Este campo es obligatorio.</div>
                        </div>
                        <div class="custom-input radio-box" *ngIf="companyUpdateMode">
                            <label>Asociado</label>
                            <div class="radio-group">
                                <input type="radio" [checked]="companyData.socio" name="asociado_selection"
                                    (click)="changePartner(true)" disabled>
                                <label for="yes">Si</label>
                                <input type="radio" [checked]="!companyData.socio" name="asociado_selection"
                                    (click)="changePartner(false)" disabled>
                                <label for="no">No</label>
                            </div>
                        </div>
                        <!--
                        <div class="custom-input radio-box">
                            <label>Asociado</label>
                            <div class="radio-group">
                                <input type="radio" [checked]=companyData.socio name="asociado_selection" value='true'
                                    (change)=changeAssociatedState($event) [disabled]="!permission">
                                <label for="yes">Si</label>

                                <input type="radio" [checked]=!companyData.socio name="asociado_selection" value='false'
                                    (change)=changeAssociatedState($event) [disabled]="!permission">
                                <label for="no">No</label>
                            </div>
                        </div>
                        -->
                    </div>

                </div>
            </div>
            <div class="row-2">
                <div class="col-md-4-form">
                    <div class="custom-input">
                        <label>Sector</label>
                        <ng-multiselect-dropdown [placeholder]="'Sectores'" name="md_sector" id="md_sector"
                            class="enabled" [settings]="dropdownSettings" [data]="sectorsList" [disabled]="true"
                            [(ngModel)]="sectors">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="custom-input">
                        <label>Comisión</label>
                        <ng-multiselect-dropdown [placeholder]="'Comisiones'" name="md_comison" id="md_comison"
                            [settings]="dropdownSettings" [data]="commissonsList" [disabled]="true"
                            [(ngModel)]="comison">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="custom-input">
                        <label>Comité</label>
                        <ng-multiselect-dropdown [placeholder]="'Comites'" name="md_comite" id="md_comite"
                            [settings]="dropdownSettings" [data]="committeesList" [disabled]="true"
                            [(ngModel)]="comite">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>

            <mat-divider class="divider-section"></mat-divider>

            <!-- *ngIf="companyId > 0 && permission"> -->
            <div class="header-btn" *ngIf="companyUpdateMode">
                <label class="header-1 label-1 info-lbl"> Información de Contactos</label>
                <button class="agregar-contacto" (click)="goToPageParams('/admin/users/user-guide', {type: 'empresas', company_id: companyId})">+
                    Agregar contacto
                </button>
            </div>
            <div class="row-3" *ngIf="companyId > 0">
                <div class="table-default">
                    <table class="content-table" border="1">
                        <thead>
                            <tr>
                                <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of userList; let i = index">
                                <td *ngFor="let x of item | keyvalue:originalOrder" [hidden]="x.key == 'id' || x.key == 'accountRelation'">
                                    {{x.value}}
                                </td>
                                <td (mouseleave)="hideActions()">
                                    <!-- <button name="actions" id="actions" class="actions" (click)="showActions(item, i)" *ngIf="permission"></button> -->
                                    <button name="actions" id="actions" class="actions" (click)="showActions(item, i)"
                                        *ngIf="permissionRole === 'ROLE_SUPERADMIN' || (permissionRole == 'ROLE_ADMIN' && !permissionCRM)">
                                    </button>
                                    <div [hidden]="isShowDiv" (mouseleave)="hideActions()"
                                        class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{i}}">
                                        <button name="delete" id="delete" class="actions-btn w-100 text-left"
                                            (click)="deleteContact(item.accountRelation)">Eliminar</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-pagination">
                        <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
                        <p class="pagination-text">Página {{totalPages != 0 ? pageNumber + 1 : 0 }} de {{totalPages}}
                        </p>
                        <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
                    </div>
                </div>
            </div>

            <mat-divider class="divider-section" *ngIf="companyId > 0"></mat-divider>

            <label class="header-1 label-1"> Puntos Acumulados</label>
            <div class="row px-4 mt-4" *ngIf="companyId > 0">
                <div class="col-12 col-lg-3 mb-3">
                    <label class="form-label mb-3">Total puntos acumulados</label>
                    <input type="text" class="form-control {{class}}" readOnly name="p_acumulados" [(ngModel)]="points.certifiedPoints" placeholder="0" />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label class="form-label mb-3">Puntos por consumo</label>
                    <input type="text" class="form-control {{class}}" readOnly name="p_consumo" [(ngModel)]="points.consumedPoints" placeholder="0" />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label class="form-label mb-3">Puntos por registro</label>
                    <input type="text" class="form-control {{class}}" readOnly name="p_registro" [(ngModel)]="points.pointsByRegisterEvent" placeholder="0" />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label class="form-label mb-3">Puntos por descarga</label>
                    <input type="text" class="form-control {{class}}" readOnly name="p_descarga" [(ngModel)]="points.pointsByNewUser" placeholder="0" />
                </div>
                <div class="col-12 col-lg-3 mb-3 mt-lg-5">
                    <label class="form-label mb-3">Puntos disponibles</label>
                    <input type="text" class="form-control {{class}}" readOnly name="p_disponibles" [(ngModel)]="points.availablePoints" placeholder="0" />
                </div>
                <div class="col-12 col-lg-3 mb-3 mt-lg-5">
                    <label class="form-label mb-3">Equivalentes en quetzales</label>
                    <input type="text" class="form-control {{class}}" readOnly name="p_Qtotal" [(ngModel)]="points.totalQtz" placeholder="Q 0.00" />
                </div>
                <div class="col-12 col-lg-6 mb-3 mt-lg-5 text-right" *ngIf="companyData.status == 1">
                    <button class="mt-3 mt-lg-5 save-btn" (click)="downloadPointsByUsers()">Descargar reporte</button>
                </div>
            </div>

            <!-- <label class="header-1 label-1"> Puntos Acumulados</label>
            <div class="row-3">
                <div class="custom-input">
                    <label>Total puntos acumulados</label>
                    <i class="info-icon" id="info"></i>
                    <input type="text" class="{{class}}" readOnly name="p_acumulados" [(ngModel)]="points.certifiedPoints" placeholder="0" />
                </div>
                <div class="custom-input">
                    <label>Puntos por consumo</label>
                    <i class="info-icon" id="info"></i>
                    <input type="text" class="{{class}}" readOnly name="p_consumo" [(ngModel)]="points.consumedPoints" placeholder="0" />
                </div>
                <div class="custom-input">
                    <label>Puntos por registro</label>
                    <i class="info-icon" id="info"></i>
                    <input type="text" class="{{class}}" readOnly name="p_registro" [(ngModel)]="points.pointsByRegisterEvent" placeholder="0" />
                </div>
                <div class="custom-input">
                    <label>Puntos por descarga</label>
                    <i class="info-icon" id="info"></i>
                    <input type="text" class="{{class}}" readOnly name="p_descarga" [(ngModel)]="points.pointsByNewUser" placeholder="0" />
                </div>
            </div>
            <div class="row-2 onlymiddle">
                <div class="custom-input">
                    <label>Puntos disponibles</label>
                    <i class="info-icon" id="info"></i>
                    <input type="text" class="{{class}}" readOnly name="p_disponibles" [(ngModel)]="points.availablePoints" placeholder="0" />
                </div>
                <div class="custom-input">
                    <label>Equivalentes en quetzales</label>
                    <i class="info-icon" id="info"></i>
                    <input type="text" class="{{class}}" readOnly name="p_Qtotal" [(ngModel)]="points.totalQtz" placeholder="Q 0.00" />
                </div>
                <div class="custom-input">
                    <button class="save-btn" (click)="downloadPointsByUsers()"><i class="save-icon"></i>Descargar reporte</button>
                </div>
            </div>
            <label class="note-lbl">*fórmula de la conversión</label> -->

            <mat-divider class="divider-section"></mat-divider>

            <label class="header-1 label-1"> Redes sociales</label>
            <div class="row-3">
                <div class="col-md-10">
                    <div class="custom-input vertical-form">
                        <label class="social-lbl">Facebook</label>
                        <input class="{{class}}" name="facebook" placeholder="Facebook"
                            [(ngModel)]="companyData.facebook" [disabled]="!permission" />
                    </div>
                    <div class="custom-input vertical-form">
                        <label class="social-lbl">Instagram</label>
                        <input class="{{class}}" name="instagram" placeholder="Instagram"
                            [(ngModel)]="companyData.instagram" [disabled]="!permission" />
                    </div>
                </div>
                <div class="col-md-11">
                    <div class="custom-input vertical-form">
                        <label class="social-lbl">Twitter</label>
                        <input class="{{class}}" name="twitter" placeholder="Twitter"
                            [(ngModel)]="companyData.twitter" [disabled]="!permission" />
                    </div>
                    <div class="custom-input vertical-form">
                        <label class="social-lbl">LinkedIn</label>
                        <input class="{{class}}" name="linkedin" placeholder="LinkedIn"
                            [(ngModel)]="companyData.linkedin" [disabled]="!permission" />
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div class="block-bottom">
        <button class="cancle-btn" (click)="goToPage('/admin/users/admin-empresas')">Cancelar</button>
        <button class="save-btn" id="submitBtn" type="submit" (click)="submitEvent()" [disabled]="form.valid === false"><i class="save-icon"></i>Guardar</button>
        <!-- <button class="save-btn" id="submitBtn" type="submit" (click)="form.checkValidity() ? submitEvent(): null"><i class="save-icon"></i>Guardar</button> -->
    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { BackServiceUsers } from 'src/app/Models/back-service.model';
import { UtilsService } from 'src/app/Services/utils.service';
import { ExhibitorService } from 'src/app/Services/exhibitors.service';

@Component({
  selector: 'app-admin-expositores',
  templateUrl: './admin-expositores.component.html',
  styleUrls: ['./admin-expositores.component.css']
})
export class AdminExpositoresComponent implements OnInit {
  public totalPages = 0;
  public eventID: number = 0;
  public pageSize: number = 20;
  public pageNumber: number = 0;
  public loading: boolean = false;
  public filter: string | null = null;
  public categoriesHeading: string[] = [];
  public list: any[] = [{ 'Nombre expositores': '', 'Nombre Empresa': '', 'Correo electrónico': '', 'Registro Expositor': '' }];


  constructor(
    private utilsService: UtilsService,
    private exhibitorService: ExhibitorService
  ) {
    this.categoriesHeading = Object.keys(this.list[0]);
  }

  ngOnInit(): void {
    this.eventID = Number(sessionStorage.getItem('EventID'));
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, null);
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/login');
    }
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  goToPage(pageName: string, params?: {}) {
    this.utilsService.goToPage(pageName, params);
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  onLeft() {
    if (this.pageNumber + 1 != 1) {
      this.pageNumber--;
      this.list.length = 0;
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
    }
  }

  onRight() {
    if (this.pageNumber + 1 != this.totalPages) {
      this.pageNumber++;
      this.list.length = 0;
      this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
    }
  }
  
  async searchReturn() {
    if (this.filter != null && this.filter.length === 0) {
      this.filter = null;
      this.totalPages = 0;
      this.pageNumber = 0;
      await this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, null);
    }
  }  

  loadDatabyEvent(page: number | null, size: number | null, event_id: number, speaker_name: string | null, search?: boolean) {
    this.loading = true;

    if (search) {
      page = 0;
      this.pageNumber = 0;
    }

    return this.exhibitorService.getExhibitorsByEvent(this.pageNumber, this.pageSize, event_id, this.filter).then(
      response => {
        console.log(response)
        if (response.count > 0) {
          this.list = response.result.exhibitors.map((e: any) => {
            return {
              id: e.id,
              'Nombre expositores': e?.nombreExpositor,
              'Nombre Empresa': e?.nombreEmpresa,
              'Correo electrónico': e?.email,
              'Estatus': e?.registered? 'Registrado' : 'Sin Registro'
            }
          });
          // console.log(this.list);
          this.totalPages = Math.ceil(response.count / this.pageSize);
          // sessionStorage.setItem('Participant', JSON.stringify(response.result.rows));
        } else {
          this.list = [];
          this.totalPages = 0;
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  updateRegister($event: any, index: number) {
    this.loading = true;
    const participantID = this.list[index].id;
    const register = $event.target.value === 'Registrado' ? true : false;
    
  
    const data = {
      registered: register,
      eventId: this.eventID
    };
  
    this.exhibitorService.updateExhibitorsByEvent(participantID, data).then(
      response => {
        console.log('Exito');
        this.utilsService.dialog('SUCCESS', 'Éxito', 'Expositor actualizado.');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Intente mas tarde!');
  
        if ($event.target.value == 'Sin Registro') {
          $event.target.value = 'Registrado';
          this.list[index].Registro = 'Registrado';
        } else {
          $event.target.value = 'Sin Registro';
          this.list[index].Registro = 'Sin Registro';
        }
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  deleteExhibitor(exhibitor_id: number) {
    this.loading = true;
    this.exhibitorService.deleteExhibitorsToEvent(exhibitor_id).then(
      response => {
        console.log(response);
        this.loadDatabyEvent(this.pageNumber, this.pageSize, this.eventID, this.filter, false);
        this.utilsService.dialog('SUCCESS', response?.message?.title || 'Éxito', response?.message?.description || 'Expositor eliminado.');
      },
      error => {
        console.log(error)
        this.utilsService.dialog('ERROR', error?.error?.message?.title || 'Éxito', error?.error?.message?.description || 'Error al eliminar expositor.');
      }
    ).finally(() => {
      this.loading = false;
    });
  }
}

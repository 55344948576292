import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class CompanyService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    getCompanies(
        page: number | null, size: number | null, filter: string | null,
        nit: string | null, socio: number | null, status: number | null,
        comiteId: number | null,  sectorId: number | null, codigoCRM: string | null, comisionId: number | null
    ): Promise<any> {
        this.params = new HttpParams();
        (nit != null) && (this.params = this.params.set('nit', nit));
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (socio != null) && (this.params = this.params.set('socio', socio));
        (status != null) && (this.params = this.params.set('status', status));
        (comiteId != null) && (this.params = this.params.set('comiteId', comiteId));
        (sectorId != null) && (this.params = this.params.set('sectorId', sectorId));
        (codigoCRM != null) && (this.params = this.params.set('codigoCRM', codigoCRM));
        (comisionId != null) && (this.params = this.params.set('comisionId', comisionId));
        (filter != null && filter.length > 0) && (this.params = this.params.set('filter', filter));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/company`, this.options).toPromise();
    }

    // Listar empresas patrocinadoras de un evento
    companiesSponsorByEvent(page: number | null, size: number | null, event_id: number, filter: string | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (filter != null && filter.length > 0) && (this.params = this.params.set('filter', filter));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/sponsors/companies-by-event/${event_id}/`, this.options).toPromise();
    }

    // Listar empresas patrocinadoras de un evento
    companiesExhibitorsByEvent(page: number | null, size: number | null, event_id: number, filter: string | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (filter != null && filter.length > 0) && (this.params = this.params.set('filter', filter));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/exhibitors/companies-by-event/${event_id}/`, this.options).toPromise();
    }

    getListCompany(page: number, size: number): Promise<any> {
        this.params = new HttpParams();
        this.params = this.params.set('page', page);
        this.params = this.params.set('size', size);
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/company/only-names/`, this.options).toPromise();
        // return this.http.get(`${this.url}registro/company/only-names/?page=${page}&size=${size}`, this.options).toPromise();
    }

    getCompaniesbyID(company_id: number | boolean): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}registro/company/${company_id}`, this.options).toPromise();
    }

    createCompany(data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.post(`${this.url}registro/company/`, data, this.options).toPromise();
    }

    updateComapny(company_id: number | boolean, data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.put(`${this.url}registro/company/${company_id}`, data, this.options).toPromise();
    }

    getCompaniesbyNIT(data: {}): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.post(`${this.url}registro/company/empresa-asociada/`, data, this.options).toPromise();
    }

    deleteCompany(company_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.delete(`${this.url}registro/company/${company_id}`, this.options).toPromise();
    }
}
<app-loading *ngIf="loading"></app-loading>

<div style="height: 100vh;">
    <div class="top-block">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage(motherRoute, this.params)"></i>
            <span class="change-page-label"> Atrás</span>
        </div>
    </div>
    <div class="main-header">
        <div class="default-view">
            <div class="search-field">
                <label class="search-label">Buscar {{nameFilter}}</label>
                <!-- <input class="search-input" style="width: 90%;" placeholder="Buscar usuario..."
                [(ngModel)]="filter" (keyup.enter)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" (keyup)="searchParticipantReturn()"/> -->

                <input class="search-input" placeholder="Buscar {{nameFilter}}..." [(ngModel)]="filter"
                    (keyup.enter)="loadData(typeGuide, true, pageNumber, pageSize, eventID!, filter)"
                    (keyup)="searchReturn()" />
                <button class="cleanBtn" (click)="filter = ''; searchReturn()">Limpiar</button>
                <button class="searchBtn"
                    (click)="loadData(typeGuide, true, pageNumber, pageSize, eventID!, filter)">Buscar</button>
                <!-- <button class="searchBtn" (click)="search(true, pageNumber, pageSize, eventID!, filter)">Buscar</button> -->
            </div>
        </div>
    </div>

    <div class="main">
        <div class="default-view">
            <div class="table-default">
                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of list; let i = index">
                            <td *ngFor="let x of item | keyvalue:originalOrder" [hidden]="
                                x.key === 'id' || x.key === 'exists' || 
                                x.key === 'sponsorId' || x.key === 'exhibitorId'||
                                x.key === 'accountRelation' ||
                                x.key === 'type' || x.key === 'disabled'
                            ">
                                {{x.value}}
                            </td>
                            <td>
                                <button name="actions" id="actions" class="btn-user-guide btn-add" [ngbTooltip]="item.disabled == true ? 'Usuario administrador' : ''"
                                    (click)="addAction(typeGuide, item.id)" *ngIf="!item.exists" [disabled]="item.disabled == true">+ Agregar</button>
                                <button name="actions" id="actions" class="btn-user-guide btn-remove"
                                    (click)="removeAction(typeGuide, item.id)" *ngIf="item.exists">Eliminar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-pagination">
                    <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft(typeGuide)"></i>
                    <!-- <p class="pagination-text">Página {{pageNumber}} de {{totalPages}}</p> -->
                    <p class="pagination-text">Página {{totalPages == 0 ? 0 : totalPages == 1 ? 1 : pageNumber + 1 }} de
                        {{totalPages}}</p>
                    <!-- <p class="pagination-text">Página {{totalPages != 0 ? pageNumber + 1 : 0 }} de {{totalPages}}</p> -->
                    <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight(typeGuide)"></i>
                </div>
            </div>
        </div>
    </div>
</div>
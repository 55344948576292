import { Component, EventEmitter, Input, Output, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryService } from 'src/app/Services/gallery.service';
import { BackServiceGallery } from 'src/app/Models/back-service.model';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageDialogComponent implements OnDestroy {
  @ViewChild('form') form!: NgForm;

  public loaded: boolean = false;
  public loading: boolean = false;
  public imageLoaded: boolean = false;
  public validationErrors: boolean = true;

  @Input() type!: string;
  @Input() gallery!: BackServiceGallery;
  @Output() response: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private galleryService: GalleryService
  ) { }

  ngOnDestroy() {
    this.cleanModel();
  }

  openFileInput() {
    const fileInput = document.getElementById('file');
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileSelected(event: any) {
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

    const fileSizeInMB = file.size / (1024 * 1024);
    this.validationErrors = (fileSizeInMB > 100);

    // Continuar con el proceso de carga
    this.gallery.fileName = file.name;
    this.gallery.type = file.type.substring(file.type.indexOf('/') + 1);

    const reader = new FileReader();
    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    this.loaded = true;
    const reader = e.target;
    this.gallery.file = reader.result;
  }

  async save() {
    this.loading = true;
    console.log(this.gallery)
    this.gallery.dateCreated = new Date();
    await this.galleryService.addGalleryToEvent(this.gallery).then(
      response => {
        console.log(response)
        this.cleanModel();
        this.modalService.dismissAll(this);
        this.response.emit({type: 'SUCCESS', title: 'Éxito', message: 'Imagen guardada.' });
      },
      error => {
        console.log(error)
        this.cleanModel();
        this.modalService.dismissAll(this);
        this.response.emit({type: 'ERROR', title: 'Error', message: '¡Error al cargar galeria!'});
      }
    ).finally( () => {
      this.loading = false;
    });
  }

  cleanModel(){
    this.gallery.url = '';
    this.gallery.type = '';
    this.gallery.file = '';
    this.gallery.size = null;
    this.gallery.eventId = null;
    this.gallery.fileName = null;
    this.gallery.imageTest = null;
    this.gallery.dateCreated = null;
    this.gallery.idEventMedia = null;
    this.gallery.userCreated = undefined;
  }

  async deleted(gallery_id: number) {
    this.loading = true;
    await this.galleryService.deleteGallery(gallery_id).then(
      response => {
        console.log(response)
        this.modalService.dismissAll(this);
        this.response.emit({type: 'SUCCESS', title: 'Éxito', message: 'Imagen eliminada.' });
      },
      error => {
        console.log(error)
        this.modalService.dismissAll(this);
        this.response.emit({type: 'ERROR', title: 'Error', message: '¡Error al eliminar galeria!'});
      }
    ).finally( () => {
      this.loading = false;
      this.gallery.type = '';
      this.gallery.file = '';
      this.gallery.fileName = '';
    });
  }
}

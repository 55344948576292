import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  accessToken: any;
  username: any;
  email: any;

  constructor(private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {

    const token = sessionStorage.getItem('Token') || false;
    if (token != false) {
      this.accessToken = sessionStorage.getItem('Token');
      this.username = sessionStorage.getItem('Name');
      this.email = sessionStorage.getItem('Email');
    }
    else {
      this.goToPage("/login");
    }
  }

  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

}

<div class="main-modal main-modal-center" *ngIf="type === 'SUCCESS'">
  <div class="modal-header">
    <i class="info-btn" id="info"></i>
    <h4 class="modal-title-dialog" id="modal-basic-title"> {{title}} </h4>
  </div>
  <div class="modal-body"> {{message}} </div>
  <div class="modal-footer">
    <br>
    <button type="button" class="btn-modal btn-accept" (click)="close(true)">Aceptar</button>
  </div>
</div>

<div class="main-modal main-modal-center" *ngIf="type === 'UPDATE'">
  <div class="modal-header">
    <i class="info-btn" id="info"></i>
    <h4 class="modal-title-dialog" id="modal-basic-title">Cambios guardados</h4>
  </div>
  <div class="modal-body">
    Los datos fueron actualizados exitosamente
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-modal btn-accept" (click)="close(true)">Aceptar</button>
  </div>
</div>

<div class="main-modal main-modal-center" *ngIf="type === 'ERROR'">
  <div class="modal-header">
      <i class="info-btn-error" id="info-error"></i>
      <h4 class="modal-title-dialog" id="modal-basic-title">{{title}}</h4>
  </div>
  <div class="modal-body"> {{message}} </div>
  <div class="modal-footer">
    <button type="button" class="btn-modal btn-accept" (click)="close(true)">Aceptar</button>
  </div>
</div>

<div class="main-modal main-modal-center" *ngIf="type === 'DIALOG'">
  <div class="modal-header">
    <i class="info-btn-error" id="info-error"></i>
  </div>
  <div class="modal-body">
    Al momento de "enviar confirmación" se estará compartiendo 
    al usuario un correo con la información del evento y 
    Gafete si aplica al evento. ¿Deseas continuar?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-modal btn-cancel" (click)="cancel()">Cancelar</button>
    <button type="button" class="btn-modal btn-accept" (click)="acept()">Aceptar</button>
  </div>
</div>

<div class="main-modal main-modal-center" *ngIf="type === 'DELETE'">
  <div class="modal-header">
    <i class="info-btn-error" id="info-error"></i>
    <h4 class="modal-title-dialog" id="modal-basic-title">¡Alerta!</h4>
  </div>
  <div class="modal-body">
    Se eliminara el registro y no se podrá recuperar.
    <br>
    {{message}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-modal btn-cancel" (click)="cancel()">Cancelar</button>
    <button type="button" class="btn-modal btn-accept" (click)="acept()">Aceptar</button>
  </div>
</div>

<div class="main-modal main-modal-center" *ngIf="type === 'DINAMICO'">
  <div class="modal-header">
    <i class="{{icono}}" id="info"></i>
    <h4 class="modal-title-dialog" id="modal-basic-title"> {{title}} </h4>
  </div>
  <div class="modal-body"> {{message}} </div>
  <div class="modal-footer">
    <br>
    <button type="button" class="btn-modal btn-accept" (click)="close(true)">Aceptar</button>
    <!-- <button type="button" class="accept-btn" (click)="close()">Aceptar</button> -->
  </div>
</div>

import { Component, OnInit } from '@angular/core';
// import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public username: any;
  public accessToken: any;

  constructor(
    // private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    /*
    if (sessionStorage.getItem('Token')) {
      this.accessToken = sessionStorage.getItem('Token');
      this.username = sessionStorage.getItem('Name');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes acceso a esta página, por favor ingresa con un usuario correcto.');
      this.goToPage("/login");
    }
    //*/
    // this.accessToken = sessionStorage.getItem('Token');
    // this.username = sessionStorage.getItem('Name');
  }

  // goToPage(pageName: string) {
  //   this.utilsService.goToPage(pageName);
  // }

  // loadData(){
  //   this.backofficeService.getCurrentUser(this.accessToken)
  //       .subscribe({
  //         next: (res) => {
  //           console.log(res);
  //           this.username = res.result.firstName + " " + res.result.firstLastname;
  //         },
  //         error: (e) => {
  //           modalRef.componentInstance.errorMessage = "Error!! Por favor vuelve a intentar más tarde.";
  //         } 
  //       })
  // }
}

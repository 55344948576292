import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BackService } from '../Models/back-service.model';
import { LoginComponent } from '../Login/login.component';
import { environment } from 'src/environments/environment';
import { GenericApiResponse } from '../Models/generic-api-response.model';
import { CommunicationChannelsResultModel } from '../Models/communication-channels.model';

const baseUrl = environment.url;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class BackofficeService {

  constructor(private http: HttpClient) { }

  // login_Observable(data: any): Observable<any> {
  //   return this.http.post(`${baseUrl}registro/auth/signin`, data, httpOptions);
  // }

  recovery(data: any): Observable<any> {
    return this.http.put(`${baseUrl}registro/auth/forgot-password`, data, httpOptions);
  }

  verify(data: any, rest: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'reset': rest
      })
    };
    return this.http.put(`${baseUrl}registro/auth/verfication`, data, httpOptionsVerification);
  }

  newpassword(data: any, rest: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'reset': rest
      })
    };
    return this.http.put(`${baseUrl}registro/auth/new-password`, data, httpOptionsVerification);
  }

  getUsers(pageNum: any, recordSize: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };


    return this.http.get(`${baseUrl}registro/users?page=${pageNum}&size=${recordSize}`, httpOptionsVerification);
  }

  getUsersbyID(access: any, id: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };


    return this.http.get(`${baseUrl}registro/users/user/${id}`, httpOptionsVerification);
  }

  editUser(data: any, access: any, id: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}registro/users/${id}`, data, httpOptionsVerification);
  }

  createUser(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}registro/users/create-user`, data, httpOptionsVerification);
  }

  // Dependencia con promociones, trasladarlo a company.service
  getCompanies(access: any, page: number | null = null, size: number | null = null): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    // return this.http.get(`${baseUrl}registro/company`, httpOptionsVerification);

    if (page != null && size != null) {
      return this.http.get(`${baseUrl}registro/company/?page=${page}&size=${size}`, httpOptionsVerification);
    } else {
      return this.http.get(`${baseUrl}registro/company/`, httpOptionsVerification);
    }
  }

  linkCompany(data: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json'
      })
    };
    return this.http.post(`${baseUrl}registro/company/empresa-asociada`, data, httpOptionsVerification);
  }

  getCategories(): Observable<any> {
    return this.http.get(`${baseUrl}eventos/event-categories`);
  }

  getCostType(): Observable<any> {
    return this.http.get(`${baseUrl}eventos/cost-types`);
  }

  getEventType(): Observable<any> {
    return this.http.get(`${baseUrl}eventos/event-types`);
  }

  // Buscar pasarlo al servicio de eventos.
  getModalities(): Observable<any> {
    return this.http.get(`${baseUrl}eventos/modalities`);
  }

  getGuestType(): Observable<any> {
    return this.http.get(`${baseUrl}eventos/guest-types`);
  }

  getComite(): Observable<any> {
    return this.http.get(`${baseUrl}registro/comites`);
  }

  getComisiones(): Observable<any> {
    return this.http.get(`${baseUrl}registro/comisiones`);
  }

  getCountries(): Observable<any> {
    return this.http.get(`${baseUrl}registro/countries`);
  }

  getAreas(): Observable<any> {
    return this.http.get(`${baseUrl}registro/areas`);
  }

  getPuestos(): Observable<any> {
    return this.http.get(`${baseUrl}registro/puestos`);
  }

  // ELIMINAR, se paso a su propio servicio
  getSpeakersbyEvent(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/speakers/event/${ID}`, httpOptionsVerification);
  }

  getSpeakersbyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/speakers/${ID}`, httpOptionsVerification);
  }

  createSpeaker(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/speakers`, data, httpOptionsVerification);
  }

  editSpeaker(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/speakers/${ID}`, data, httpOptionsVerification);
  }

  deleteSpeaker(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/speakers/${ID}`, httpOptionsVerification);
  }

  getParticipantsbyEvent(ID: any, pageNum: any, recordSize: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/participants/all-by-event/${ID}?page=${pageNum}&size=${recordSize}`, httpOptionsVerification);
  }

  addParticipanttoEvent(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/participants/back/register-participant`, data, httpOptionsVerification);
  }

  // FIN ELIMINAR, se paso a su propio servicio

  // participantto cambiar de estado y registrado

  getLocationbyEvent(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/locations/event/${ID}`, httpOptionsVerification);
  }

  getLocationbyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/locations/${ID}`, httpOptionsVerification);
  }

  createLocation(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/locations`, data, httpOptionsVerification);
  }

  editLocation(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/locations/${ID}`, data, httpOptionsVerification);
  }

  deleteLocation(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/locations/${ID}`, httpOptionsVerification);
  }

  createParking(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/parkings`, data, httpOptionsVerification);
  }

  editParking(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/parkings/${ID}`, data, httpOptionsVerification);
  }

  deleteParking(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/parkings/${ID}`, httpOptionsVerification);
  }

  getParkingbyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/parkings/${ID}`, httpOptionsVerification);
  }

  createExhibitor(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/exhibitors`, data, httpOptionsVerification);
  }

  editExhibitor(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/exhibitors/${ID}`, data, httpOptionsVerification);
  }

  deleteExhibitor(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/exhibitors/${ID}`, httpOptionsVerification);
  }

  getExhibitorbyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/exhibitors/${ID}`, httpOptionsVerification);
  }

  getExhibitors(pageNum: any, recordSize: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/exhibitors/?page=${pageNum}&size=${recordSize}`, httpOptionsVerification);
  }

  getActivitiesbyEvent(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/activities/event/${ID}`, httpOptionsVerification);
  }

  getActivitiesbyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/activities/${ID}`, httpOptionsVerification);
  }

  createActivity(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/activities`, data, httpOptionsVerification);
  }

  editActivity(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/activities/${ID}`, data, httpOptionsVerification);
  }

  deleteActivity(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/activities/${ID}`, httpOptionsVerification);
  }

  getQuestionsbyActivityID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/questions/activity/${ID}`, httpOptionsVerification);
  }

  getQuestionsbID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/questions/${ID}`, httpOptionsVerification);
  }

  createQuestions(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/questions`, data, httpOptionsVerification);
  }

  editQuestion(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/questions/${ID}`, data, httpOptionsVerification);
  }

  deleteQuestion(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/questions/${ID}`, httpOptionsVerification);
  }

  getActivitybyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/user_activity/activity/${ID}`, httpOptionsVerification);
  }


  getActivitybyUserID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/user_activity/user/${ID}`, httpOptionsVerification);
  }

  createACtivity(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/user_activity`, data, httpOptionsVerification);
  }

  deleteUserActivity(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/user_activity/${ID}`, httpOptionsVerification);
  }

  getSponsorsbyEventID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/sponsors/event/${ID}`, httpOptionsVerification);
  }

  getSponsorsbyID(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.get(`${baseUrl}eventos/sponsors/${ID}`, httpOptionsVerification);
  }

  crerateSponsor(data: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.post(`${baseUrl}eventos/sponsors`, data, httpOptionsVerification);
  }

  editSponsor(data: any, ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.put(`${baseUrl}eventos/sponsors/${ID}`, data, httpOptionsVerification);
  }

  deleteSponsor(ID: any, access: any): Observable<any> {
    const httpOptionsVerification = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-access-token': access
      })
    };
    return this.http.delete(`${baseUrl}eventos/sponsors/${ID}`, httpOptionsVerification);
  }


}

<div class="main-container">
    <div class="header">
        <div class="top-block-1">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-ubicaciones')"></i>
            <span class="change-page-label"> Atrás </span>
            <div class="next-container">
                <span class="change-page-label"> Siguiente </span>
                <i class="next-btn" id="next" (click)="goToPage('/admin/events/admin-encuestas')"></i>
            </div>
        </div>
        <div class="btn-group">
            <button class="agregar" (click)="goToPage('/admin/events/user-guide', {type: 'patrocinadores', event_id: eventID})">+ Agregar existente</button>
            <button class="nuevo" (click)="goToPage('/admin/events/edit-patrocinadores')">+ Agregar nuevo</button>
         </div>
    </div>

    <div class="main">
        <div class="default-view">
            <div class="table-default">
                <!-- <div class="search-field">
                    <i class="search-filter" id="search"></i>
                    <input class="search-input" placeholder="Buscar..." [(ngModel)]="filtro"/>
                    <i class="search-icon" id="filter"></i>
                </div> -->

                <div class="search-field">
                    <i class="search-filter" id="search" (click)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" ></i>
                    <input class="search-input" style="width: 90%;" placeholder="Buscar patrocinador..."
                    [(ngModel)]="filter" (keyup.enter)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)" (keyup)="searchReturn()"/>
                    <button class="agregar" style="margin: -9px 0; padding: 13px 20px; right: 3px; width: 148px;" (click)="loadDatabyEvent(pageNumber, pageSize, eventID, filter, true)"> Buscar </button>
                </div>

                <table class="content-table" border="1">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let each of categoriesHeading" >
                                <th> {{each}} </th>
                            </ng-container>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <!-- <tr *ngFor="let item of list | tableFilter:filter:'Nombre del patrocinador'; let i = index"> -->
                        <tr *ngFor="let item of list; let i = index">
                            <ng-container>
                                <ng-container *ngFor="let x of item | keyvalue:originalOrder" >
                                    <td [hidden]="x.key === 'id'"> {{x.value}} </td>                                    
                                </ng-container>
                                <td (mouseleave)="hideActions()">
                                    <button name="actions" id="actions" class="actions" (click)="showActions(item.id)" (keyup)="showActions(item.id)"></button>
                                    <div hidden class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{item.id}}" (mouseleave)="hideActions()">
                                        <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left" (click)="editSponsor(item.id)">Editar</button>                                                                           
                                        <button name="delete" id="delete" class="actions-btn w-100 text-left" (click)="deletedSponsor(item.id)">Eliminar</button>
                                    </div>                              
                                </td>                                
                            </ng-container>
                        </tr>
                    </tbody>    
                </table>
                <!-- <div class="table-pagination">
                    <i class="left-btn" id="back" (click)="onLeft()"></i>
                    <p class="pagination-text">Página {{totalPages != 0 ? pageNumber + 1 : 1 }} de {{totalPages == 0 ? 1 : totalPages}}</p>
                    <i class="right-btn" id="back" (click)="onRight()"></i>
                </div> -->
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid px-5 pt-4">
    <div class="row">
        <div class="col-12 mb-4">
            <i class="back-btn" id="back" (click)="goToPage('/admin/events/admin-conferencias')"></i>
            <span class="change-page-label"> Atrás </span>
        </div>
        <div class="col-12">
            <div class="card mb-5">
                <div class="card-body">
                    <h5 class="card-title mb-4">Información de la actividad</h5>
                    <form #form="ngForm">
                        <div class="row px-4 py-0">
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <!-- <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Test Calendario*</label>
                                        <div class="form-date">
                                            <label class="form-date__label" for="input-date">Fecha de inicio</label>
                                            <input class="form-date__input" type="date" id="input-date"
                                                name="input-date-start" value="2018/07/22 ">
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <input type="text" onkeydown="return false" class="form-control"
                                        name="date_start" [value]="activity.fechaInicio | date: 'EEEE, MMMM d, y'" required disabled
                                        placeholder="dd-mm-yyyy" />
                                    </div> -->
                                    <!-- {{ e.date.toString() | date:'EEEE, MMMM d, y' | titlecase }} -->

                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Agenda*</label>
                                        <select class="form-control" name="agenda" [(ngModel)]="activity.scheduleId"
                                            (change)="changeSchedule($event)" required>
                                            <option value="null" disabled>Seleccione...</option>
                                            <option *ngFor="let x of listSchedule" [value]="x.id">{{x.name}}</option>
                                        </select>
                                        <div class="err-msg" *ngIf="validation_msg('agenda')"> Este campo es
                                            obligatorio.</div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Nombre de la actividad*</label>
                                        <input class="form-control" name="nombreActividad" id="nombreActividad"
                                            [(ngModel)]="activity.nombreActividad" required
                                            placeholder="Nombre de actividad" />
                                        <div class="err-msg" *ngIf="validation_msg('nombreActividad')"> Este campo es
                                            obligatorio.</div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Fecha y hora de inicio*</label>
                                        <div class="row">
                                            <div class="col-12 col-lg-8 my-2 my-lg-0">
                                                <input type="date" onkeydown="return false" class="form-control"
                                                    name="date_start" [(ngModel)]="activity.fechaInicio" required
                                                    disabled placeholder="dd-mm-yyyy" />
                                                <div class="err-msg" *ngIf="validation_msg('date_start')"> Este campo es
                                                    obligatorio.</div>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <input type="time" class="form-control" name="hour_start"
                                                    [(ngModel)]="activity.horaInicio" required
                                                    placeholder="dd-mm-yyyy" />
                                                <div class="err-msg" *ngIf="validation_msg('hour_start')"> Este campo es
                                                    obligatorio.</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Fecha y hora de finalización*</label>
                                        <div class="row">
                                            <div class="col-12 col-lg-8 my-2 my-lg-0">
                                                <input type="date" onkeydown="return false" class="form-control"
                                                    name="date_end" [(ngModel)]="activity.fechaFin" required disabled
                                                    placeholder="dd-mm-yyyy" />
                                                <div class="err-msg" *ngIf="validation_msg('date_end')"> Este campo es
                                                    obligatorio.</div>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <input type="time" class="form-control" name="hour_end"
                                                    [(ngModel)]="activity.horaFin" required placeholder="dd-mm-yyyy" />
                                                <div class="err-msg" *ngIf="validation_msg('hour_end')"> Este campo es
                                                    obligatorio.</div>
                                            </div>
                                            <div class="err-msg text-right" *ngIf="validation_date()"> La fecha final no puede ser menor a la inicial.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Conferencista*</label>
                                        <select class="form-control" name="conferencista"
                                            [(ngModel)]="activity.speakerId" (change)="changeSpeaker($event)" required>
                                            <option value="null" disabled>Seleccione...</option>
                                            <option *ngFor="let x of listSpeaker" [value]="x.id">{{x.name}}
                                            </option>
                                        </select>
                                        <div class="err-msg" *ngIf="validation_msg('conferencista')"> Este campo es
                                            obligatorio.</div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Modalidad*</label>
                                        <select class="form-control" name="modalidad" [(ngModel)]="activity.modalityId"
                                            required disabled>
                                            <option value="null" disabled>Seleccione...</option>
                                            <option *ngFor="let x of listModality" [value]="x.id">{{x.name}}</option>
                                        </select>
                                        <div class="err-msg" *ngIf="validation_msg('modalidad')"> Este campo es
                                            obligatorio.</div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Enlace del evento en línea</label>
                                        <input class="form-control" name="enlace" id="enlace"
                                            [(ngModel)]="activity.enlaceConferencia" placeholder="www.enlace.com"
                                            autocomplete="OFF" />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label class="form-label mb-3">Dirección*</label>
                                        <input class="form-control" name="lugar" id="lugar" [(ngModel)]="activity.lugar"
                                            required placeholder="Nombre de actividad" />
                                        <div class="err-msg" *ngIf="validation_msg('lugar')"> Este campo es obligatorio.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="edit && listQuestionBySpeaker.length > 0">
                        <hr>
                        <h5 class="card-title mb-4">Preguntas de evento</h5>
                        <div class="col-12 px-4">
                            <table>
                                <thead>
                                    <tr>
                                        <th *ngFor="let each of categoriesHeading"> {{each}} </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of listQuestionBySpeaker; let i = index">
                                        <ng-container *ngFor="let x of item | keyvalue:originalOrder">
                                            <td *ngIf="x.key != 'Visibilidad'" [hidden]="x.key === 'id'"> {{x.value}}
                                            </td>

                                            <td class="" *ngIf="x.key == 'Visibilidad'">
                                                <select name="visibilidad_{{i}}" id="visibilidad_{{i}}"
                                                    [ngClass]="x.value === '0' ? 'select-enable select-disable' : 'select-enable'"
                                                    #selects [value]="x.value"
                                                    (change)="updateVisibility($event, i, item.id)">
                                                    <option value=1>Activo</option>
                                                    <option value=0>Ocultar</option>
                                                </select>
                                            </td>
                                        </ng-container>
                                        <td>
                                            <button name="actions" id="actions" class="actions actions-icon-gray"
                                                (click)="showActions(item, i)"></button>
                                            <div [hidden]="isShowDiv"
                                                class="card options-container float-end position-absolute translate-middle-y p-2 m-2 "
                                                id="item{{i}}">
                                                <button name="edit" id="edit" class="actions-btn w-100 text-left"
                                                    (click)="deleteQuestion(item, item.id, i)">
                                                    Eliminar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-right px-5">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary px-4 my-2 btn-cancel-edituseradmin"
                    (click)="goToPage('/admin/events/admin-conferencias')">Cancelar</button>
                &nbsp;
                <button class="btn btn-primary px-4 my-2 btn-save-edituseradmin" type="button" (click)="processData()"
                    [disabled]="(disabled || !form.touched || !form.valid )"><i class="save-icon"></i>Guardar</button>
            </div>
        </div>
    </div>
</div>
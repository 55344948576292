<app-loading *ngIf="loading"></app-loading>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet">
<form #AGForm="ngForm">
  <div class="main">
    <div class="top-block">
      <div class="top-block-1">
        <i class="back-btn" id="back"
          (click)="goToPage('/admin/admin-promociones', { companyId: promoData.companyId })"></i>
        <span class="back-label"> Atrás </span>
      </div>
    </div>

    <div id="mainEvento" class="main-block not-visible">
      <label class="label-1">Promoción del socio</label>
      <div class="status-block">
        <span class="status-label">Estatus</span>

        <select class="status-box" name="status" id="status" [(ngModel)]="promoData.status" required
          [disabled]="!permissionStatus && promoData.status === 'soldout'"
          (change)="formulario.control.markAllAsTouched()">
          <option value="finished" *ngIf="isAdmin">Finalizado</option>
          <option value="active" *ngIf="isAdmin">Activo</option>
          <option value="draft">Borrador</option>
          <option value="soldout" disabled *ngIf="isAdmin">Agotado</option>
          <option value="inactive" *ngIf="isAdmin">Inactivo</option>
        </select>


      </div>
      <i class="down-arrow" id="down"></i>
      <div class="row-1">
        <div class="col-md-1-form">
          <div class="col-md-2-form">
            <div class="custom-input">
              <label>Nombre de la promoción *</label>
              <input name="nombrePromocion" id="nombrePromocion" [(ngModel)]="promoData.name"
                placeholder="Nombre de promoción" required minlength="5" maxlength="40" />
              <div class="err-msg" *ngIf="validation_msg('nombrePromocion') || promoData.name.length > 40"> El nombre
                debe
                tener entre 5 y 40 caracteres.</div>
            </div>

            <div class="custom-input">
              <label>Descripción *</label>
              <textarea name="descripcion" id="descripcion" [(ngModel)]="promoData.description" minlength="40"
                maxlength="500" placeholder="Descripción" #descripcion="ngModel" required></textarea>
              <div class="err-msg" *ngIf="validation_msg('descripcion') || promoData.description.length > 500"> La
                descripción debe tener 40 y 500 caracteres.</div>
            </div>
          </div>
          <div class="col-md-4-form">
            <!-- FECHA/HORA INICIO -->
            <div class="custom-input datetime-box">
              <label>Fecha y hora de inicio *</label>
              <div class="dt-row">
                <div class="row row-cols-sm-auto cal-form">
                  <!-- <form class="row row-cols-sm-auto cal-form"> -->
                  <div class="col-12">
                    <div class="input-group">
                      <input type="date" onkeydown="return false" class="form-control fecha-input calendar-mozff"
                        name="dp" [(ngModel)]="promoData.startDate" required max="31-12-9999"
                        (change)="formulario.control.markAllAsTouched()" />
                    </div>
                  </div>
                </div>
                <input type="time" class="form-control-time clock-mozff form-control-time-mozff" id="appt" name="appt"
                  placeholder="00:00" [(ngModel)]="promoData.startHour" required />
              </div>
              <div class="err-msg" *ngIf="validation_msg('dp') || validation_msg('appt')">La fecha y hora son
                obligatorios.</div>
            </div>
            <!-- FECHA/HORA FIN -->
            <div class="custom-input datetime-box">
              <label>Fecha y hora fin *</label>
              <div class="dt-row">
                <div class="row row-cols-sm-auto cal-form">
                  <div class="col-12">
                    <div class="input-group">
                      <input type="date" onkeydown="return false" class="form-control fecha-input calendar-mozff"
                        name="dp2" placeholder="dd-mm-yyyy" [(ngModel)]="promoData.finishDate" required
                        (change)="formulario.control.markAllAsTouched()" />
                    </div>
                  </div>
                </div>
                <input type="time" class="form-control-time clock-mozff form-control-time-mozff" name="apptF" id="apptF"
                  placeholder="00:00" [(ngModel)]="promoData.finishHour" required>
              </div>
              <div class="err-msg" *ngIf="validation_msg('dp2') || validation_msg('apptF')">La fecha y hora son
                obligatorios.</div>
              <div class="err-msg" *ngIf="validation_date()">La fecha y hora de finalización debe ser mayor a la fecha
                de inicio.</div>
            </div>
          </div>

        </div>
        <div class="col-md-2-form">
          <div class="custom-input">
            <label>Categoría *</label>
            <select name="dirigido" id="dirigido" class="categoria" [(ngModel)]="promoData.categoryId" required>
              <option *ngFor="let x of promoCategories" value="{{x.id}}">{{x.name}}</option>
            </select>
            <div class="err-msg" *ngIf="validation_msg('dirigido')">Este campo es obligatorio</div>
          </div>
          <div class="custom-input">
            <label>Teléfono *</label>
            <input name="phone" id="phone" [(ngModel)]="promoData.phone" placeholder="Teléfono" #phone="ngModel"
              minlength="6" required pattern="[+]{0,1}[\d]+[ ]{0,1}[\d]+[-]{0,1}[\d]+"
              oninput="value = value.replace(/[^\-\+0-9 ]/g,'')" />
            <div class="err-msg" *ngIf="validation_msg('phone')">
              {{formulario && formulario.controls['phone'].errors!['required'] ? 'Este campo es obligatorio. ' : ''}}
              {{formulario && formulario.controls['phone'].errors!['pattern'] ? 'Teléfono no aceptado. ' : ''}}
              {{formulario && formulario.controls['phone'].errors!['minlength'] ? ' Minimo 6 caracteres .' : ''}}
            </div>
          </div>
          <div class="custom-input">
            <label>Ubicación para canje *</label>
            <input name="ubicacionCanje" id="ubicacionCanje" [(ngModel)]="promoData.address" #ubicacionCanje="ngModel"
              minlength="10" required placeholder="ubicación para canje" />
            <div class="err-msg" *ngIf="validation_msg('ubicacionCanje')">La ubicación debe tener al menos
              10 caracteres</div>
          </div>
        </div>
        <div class="col-md-3-form">
          <div class="code-form">
            <label>Código QR</label>
            <img [width]="250" [src]="data">
            <qrcode *ngIf="!hasQRdata" #parent [qrdata]="myAngularxQrCode" [width]="230" [errorCorrectionLevel]="'M'"
              (qrCodeURL)="onChangeURL($event)"></qrcode>
            <div>
              <a [ngClass]="{'qr-btn': hasQRdata, 'qr-btn-disabled': !hasQRdata}" class="qr-btn"
                (click)="downloadImage()">Descargar QR</a>
            </div>
          </div>
        </div>

      </div>
      <div class="row-10">
        <div class="custom-input">
          <label>Restricciones </label>
          <textarea name="restricciones" id="restricciones" [(ngModel)]="promoData.restrictions"
            placeholder="Restricciones..." maxlength="200"></textarea>
        </div>

        <div class="custom-input">
          <label>Enlace del sitio web</label>
          <input name="enlaceSitioWeb" id="enlaceSitioWeb" [(ngModel)]="promoData.url" #enlaceSitioWeb="ngModel"
            placeholder="www.sitio.com" />
          <!-- <input name="enlaceSitioWeb" id="enlaceSitioWeb" [(ngModel)]="promoData.url"
                        #enlaceSitioWeb="ngModel" required minlength="10" placeholder="www.sitio.com" />
                    <div *ngIf="enlaceSitioWeb.invalid && (enlaceSitioWeb.dirty || enlaceSitioWeb.touched)"
                        class="error-message">
                        <div *ngIf="enlaceSitioWeb.errors">La url debe tener al menos 10
                            caracteres.</div>
                    </div> -->
        </div>
      </div>

      <div class="row-10">
        <div class="custom-input">
          <label>
            Portada de la promoción *
            <i class="admiration_icon" id="admiration_icon"
              ngbTooltip="La dimensión de la portada es de 960x540 pixeles"></i>
          </label>
          <ng-container *ngIf="ImageName.includes('http://') || ImageName.includes('https://')">
            <a href="{{ ImageName }}" target="_blank">
              <input class="custom-input file-box" [(ngModel)]="ImageName" name="image-file-front" id="image-file-front"
                readonly required />
            </a>
          </ng-container>
          <ng-container *ngIf="!ImageName.includes('http://') && !ImageName.includes('https://')">
            <input class="custom-input file-box" [(ngModel)]="ImageName" name="image-file-front" id="image-file-front"
              readonly required />
            <div class="err-msg" *ngIf="validation_msg('image-file-front')">Este campo es obligatorio.</div>
          </ng-container>
          <i class="upload-btn" id="upload-cal" onclick="document.getElementById('fileIMG').click();"></i>
          <input type="file" style="display:none;" name="fileIMG" id="fileIMG" #FileSelectInputDialog
            (change)="onFileSelected($event)" accept="image/png,image/jpeg" />
        </div>
        <div class="custom-input">
          <label>
            Thumbnail de la promoción*
            <i class="admiration_icon" id="admiration_icon"
              ngbTooltip="Las dimensiones del thumbnail serán de 200x200 pixeles"></i>
          </label>
          <ng-container *ngIf="ImageThumbnailName.includes('http://') || ImageThumbnailName.includes('https://')">
            <a href="{{ ImageThumbnailName }}" target="_blank">
              <input class="custom-input file-box" [(ngModel)]="ImageThumbnailName" name="image-file-thumbnail"
                id="image-file-thumbnail" readonly />
            </a>
          </ng-container>
          <ng-container *ngIf="!ImageThumbnailName.includes('http://') && !ImageThumbnailName.includes('https://')">
            <input class="custom-input file-box" [(ngModel)]="ImageThumbnailName" name="image-file-thumbnail"
              id="image-file-thumbnail" required readonly />
            <div class="err-msg" *ngIf="validation_msg('image-file-thumbnail')">Este campo es obligatorio.
            </div>
          </ng-container>
          <i class="upload-btn" id="upload-cal" onclick="document.getElementById('fileThumbnail').click();"></i>
          <input type="file" style="display:none;" name="fileThumbnail" id="fileThumbnail" #FileSelectInputDialog
            (change)="onFileSelected($event)" accept="image/png,image/jpeg" />
        </div>
      </div>
      <!-- <div class="row-1">
                <div class="custom-input">
                    <label>Aplica para</label>
                </div>
            </div>
            <div class="row-4">
                <div class="custom-input top-head">
                    <div class="checkbox-group">
                        <input class="checkbox-item" type="checkbox" name="asociado" [(ngModel)]="asociadoBox"
                            (change)="onChangeBox($event)" [disabled]="publicoBox || promoData.referral" />
                        <label for="escanear">Asociados</label>
                        <input class="checkbox-item" type="checkbox" name="noAsociado" [(ngModel)]="noAsociadoBox"
                            (change)="onChangeBox($event)" [disabled]="publicoBox || promoData.referral" />
                        <label for="subir">No asociados</label>
                        <input class="checkbox-item" type="checkbox" name="colaboradores" [(ngModel)]="colaboradoresBox"
                            (change)="onChangeBox($event)" [disabled]="publicoBox || promoData.referral" />
                        <label for="descargar">Colaboradores</label>
                        <input class="checkbox-item" type="checkbox" name="referido" [(ngModel)]="promoData.referral"
                            (change)="onChangeBox($event)" [disabled]="publicoBox"/>
                        <label for="descargar">Referidos </label>
                        <input class="checkbox-item" type="checkbox" name="publico" [(ngModel)]="publicoBox"
                            (change)="onChangeBox($event)" [disabled]="promoData.referral"/>
                        <label for="descargar">Público </label>
                    </div>
                </div>
                <div class="custom-input">
                    <label>Número de canjes por usuario *</label>
                    <select name="numeroDeCanjes" id="numeroDeCanjes" class="dropCanjes" *ngIf="!promoData.referral"
                        [(ngModel)]="promoData.limitUser" [required]="promoData.referral == false">
                        <option *ngFor="let x of limitPerUser" value="{{x}}">{{x}}</option>
                    </select>
                    <select class="dropCanjes" disabled *ngIf="promoData.referral">
                        <option selected>--</option>
                    </select>
                    <div class="err-msg" *ngIf="validation_msg('numeroDeCanjes')">Este campo es obligatorio.</div>
                </div>
            </div> -->

      <div class="row px-4">
        <div class="col-12 col-md-6">
          <label class="form-label mb-3">Aplica para</label>
          <div class="row">
            <div class="col-12 col-lg-auto pr-0 ml-0">
              <div class="form-check form-check-inline">
                <input class="mr-1" type="checkbox" name="asociado" [(ngModel)]="asociadoBox"
                  (change)="onChangeBox($event)" [disabled]="publicoBox || promoData.referral" />
                <label class="form-check-label mr-auto txt-tiny" for="escanear">Asociados</label>
              </div>
            </div>
            <div class="col-12 col-lg-auto pr-0 ml-0">
              <div class="form-check form-check-inline">
                <input class="mr-1" type="checkbox" name="noAsociado" [(ngModel)]="noAsociadoBox"
                  (change)="onChangeBox($event)" [disabled]="publicoBox || promoData.referral" />
                <label class="form-check-label mr-auto txt-tiny" for="subir">No asociados</label>
              </div>
            </div>
            <div class="col-12 col-lg-auto pr-0 ml-0">
              <div class="form-check form-check-inline">
                <input class="mr-1" type="checkbox" name="colaboradores" [(ngModel)]="colaboradoresBox"
                  (change)="onChangeBox($event)" [disabled]="publicoBox || promoData.referral" />
                <label class="form-check-label mr-auto txt-tiny" for="descargar">Colaboradores</label>
              </div>
            </div>
            <div class="col-12 col-lg-auto pr-0 ml-0">
              <div class="form-check form-check-inline">
                <input class="mr-1" type="checkbox" name="referido" [(ngModel)]="promoData.referral"
                  (change)="onChangeBox($event)" [disabled]="publicoBox" />
                <label class="form-check-label mr-auto txt-tiny" for="descargar">Referidos </label>
              </div>
            </div>
            <div class="col-12 col-lg-auto pr-0 ml-0">
              <div class="form-check form-check-inline">
                <input class="mr-1" type="checkbox" name="publico" [(ngModel)]="publicoBox"
                  (change)="onChangeBox($event)" [disabled]="promoData.referral" />
                <label class="form-check-label mr-auto txt-tiny" for="descargar">Público </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <label class="form-label mb-3">Número de canjes por usuario *</label>
          <select name="numeroDeCanjes" id="numeroDeCanjes" class="form-control bfc w-25" *ngIf="!promoData.referral"
            [(ngModel)]="promoData.limitUser" [required]="promoData.referral == false">
            <option value="0" disabled>0</option>
            <option *ngFor="let x of limitPerUser" value="{{x}}">{{x}}</option>
          </select>
          <select class="form-control" disabled *ngIf="promoData.referral">
            <option selected>--</option>
          </select>
          <div class="err-msg" *ngIf="validation_msg('numeroDeCanjes')">Este campo es obligatorio.</div>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <label class="form-label mb-3">Número de canjes totales *</label>
          <input name="maxDeCanjes" id="maxDeCanjes" class="form-control bfc w-25" type="text"
            [(ngModel)]="promoData.limit" (change)="changeLimit()" placeholder="0" maxlength="5" size="4" required
            oninput="value = value.replace(/[^0-9]/g,'')" />
          <div class="err-msg" *ngIf="validation_msg('maxDeCanjes')">Este campo es obligatorio.</div>
          <div class="err-msg" *ngIf="validation_limit()">El número de canjes totales debe ser mayor al canje por
            usuario.</div>
        </div>
      </div>
    </div>

    <div class="block-bottom">
      <button class="cancle-btn"
        (click)="goToPage('/admin/admin-promociones', { companyId: promoData.companyId })">Cancelar</button>
      <!-- <button class="save-btn" (click)="createPromo()"> -->
      <button class="save-btn" (click)="createPromo()"
        [disabled]="(disabled || dateInvalid || limitInvalid || ( formulario && (!formulario.valid || !formulario.touched)) )">
        <!-- <button class="save-btn" (click)="createPromo()" [disabled]="(disabled || fechaInicioInvalid || ( formulario && (!formulario.touched || !formulario.valid)) )"> -->
        <i class="save-icon"></i>Guardar
      </button>
    </div>
  </div>
</form>

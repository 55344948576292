import { NgForm } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { listas } from 'src/app/Models/back-service.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UtilsService } from 'src/app/Services/utils.service';
import { CompanyService } from 'src/app/Services/company.service';
import { BackServiceStand } from 'src/app/Models/back-service.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ExposurMapService } from 'src/app/Services/exposure_map.service';

interface ListCompany {
  id: any | number;
  name?: any | string;
  nombreComercial: any | string;
}

@Component({
  selector: 'app-admin-stands',
  templateUrl: './admin-stands.component.html',
  styleUrls: ['./admin-stands.component.css']
})
export class AdminStandsComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  public view = 0;
  public active = 1;
  public userID: number = 0;
  public eventID: number = 0;
  public filtro: string = '';
  public company: any = null;
  public eventName: string = '';
  public standFloor: any = null;
  public list: {}[] = new Array();
  public loading: boolean = false;
  public disabled: boolean = false;
  public eventAddress: string = '';
  public companyList: any[] | ListCompany[] = [];
  public companyListAux: any[] | ListCompany[] = [];
  public companyListAll: any[] | ListCompany[] = [];
  public stand: BackServiceStand = {} as BackServiceStand;
  public categoriesHeading: string[] = ['Nombre de empresa', 'Número de stand', 'Nivel del stand'];

  public dropdownSettingsCompany: IDropdownSettings = {
    idField: 'id',
    textField: 'nombreComercial',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: true,
    allowSearchFilter: true,
    clearSearchFilter: false,
    closeDropDownOnSelection: false,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin resultados en la busqueda...',
  };

  public dropdownSettingsFloors: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    enableCheckAll: false,
    singleSelection: true,
    closeDropDownOnSelection: false
  };

  public floorList = [
    { id: 1, name: 'Piso 1' },
    { id: 2, name: 'Piso 2' },
    { id: 3, name: 'Piso 3' },
    { id: 4, name: 'Piso 4' }
  ]

  public map = {
    eventId: 0,
    eventName: '',
    eventMapId: 0,
    userCreated: 0,
    eventAddress: '',
    map: [
      { idFloor: null, userCreated: 0, floorNumber: 1, image64: '', name: '', url: '' },
      { idFloor: null, userCreated: 0, floorNumber: 2, image64: '', name: '', url: '' },
      { idFloor: null, userCreated: 0, floorNumber: 3, image64: '', name: '', url: '' },
      { idFloor: null, userCreated: 0, floorNumber: 4, image64: '', name: '', url: '' },
    ]
  };

  constructor(
    private utilsService: UtilsService,
    private companyService: CompanyService,
    private exposurMapService: ExposurMapService
  ) { }

  public valid: boolean = false;
  async ngOnInit(): Promise<void> {
    this.cleanModel();
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    this.userID = Number(sessionStorage.getItem('UserID'));
    this.eventID = Number(sessionStorage.getItem('EventID'));

    const eventData = JSON.parse(sessionStorage.getItem('EventData') || '');
    this.eventAddress = eventData.direccion ? eventData.direccion.toString() : '';
    this.eventName = eventData.nombreEvento ? eventData.nombreEvento.toString() : '';

    if (token && this.eventID > 0) {
      this.loadAllCompany();
      const validate = await this.exposurMapService.getMapByEvent(this.eventID);
      validate.result[0] === undefined
        ? (this.changeTab('map'), this.utilsService.dialog('ERROR', 'Alerta', '¡No hay datos de piso por favor ingrese los datos!'))
        : (this.valid = true, await this.loadDataStand(this.eventID));
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/login');
    }
  }

  onSelect(type: string, item: any) {
    console.log(type, item);
    switch (type) {
      case 'company':
        this.stand.companyName = item.nombreComercial;
        break;
      case 'floor':
        this.stand.floorId = item.id;
        break;
    }
  }

  onDeSelect(type: string, item: any) {
    console.log(type, item);
    switch (type) {
      case 'company':
        this.stand.companyName = undefined;
        break;
      case 'floor':
        this.stand.floorId = 0;
        break;
    }
  }

  onDropDownClose() {
    this.companyList = this.companyListAux;
  }

  async onFilter($event: any) {
    const value: string = $event.toLowerCase();
    if (value.length >= 3) {
      this.companyList = await this.companyListAll.filter((d: { nombreComercial: any; }, index: any) => {
        if (d.nombreComercial !== null) {
          if (d.nombreComercial.toLowerCase().indexOf(value) !== -1) {
            return true;
          }
        }
        return false;
      })
    }
  }

  clickDropDown(name: string) {
    this.form.controls[name].markAsTouched();
    this.form.controls[name].markAsTouched();
  }

  showActions(index: number) {
    const element = document.getElementById('item' + index);
    const listItems = document.getElementsByClassName('actions-enable')[0];
    if (listItems) {
      listItems.classList.remove('actions-enable');
    }
    if (('item' + index) !== listItems?.id || !listItems.id) {
      element?.classList.add('actions-enable');
    }
  }

  hideActions() {
    const listItems = document.getElementsByClassName('actions-enable')[0];
    (listItems) && (listItems.classList.remove('actions-enable'));
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].touched) // || this.form.controls[name].dirty)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName)
  }

  cleanModel() {
    this.company = null;
    this.standFloor = null;
    this.stand = {} as BackServiceStand;
    this.stand.image64 = '';
    this.stand.userCreated = this.userID;
    this.stand.image = { name: '', extension: '' };

    this.map = {
      eventMapId: 0,
      eventId: this.eventID,
      userCreated: this.userID,
      eventName: this.eventName,
      eventAddress: this.eventAddress,
      map: [
        { idFloor: null, userCreated: this.userID, floorNumber: 1, image64: '', name: '', url: '' },
        { idFloor: null, userCreated: this.userID, floorNumber: 2, image64: '', name: '', url: '' },
        { idFloor: null, userCreated: this.userID, floorNumber: 3, image64: '', name: '', url: '' },
        { idFloor: null, userCreated: this.userID, floorNumber: 4, image64: '', name: '', url: '' },
      ]
    }
  }

  async changeView(view: number, active?: number) {
    this.cleanModel();
    this.view = view;
    (view === 1) && (this.loadDataMap(this.eventID));
    (active) && (this.active = active);
  }

  async changeTab(type: string) {
    this.cleanModel();
    if (type == 'stand' && this.view == 0) {
      this.loadDataStand(this.eventID);
    }
    else if (type == 'map') {
      this.active = 2;
      this.loadDataMap(this.eventID);
    }
  }

  async loadAllCompany() {
    let count = 500;
    const countCompany = await this.companyService.getListCompany(0, count);
    countCompany.success ? count = countCompany.count : count = 10000;
    this.companyService.getListCompany(0, count).then(
      response => {
        if (response.result && response.result.length > 0) {
          this.companyListAll = response.result || [];
          this.companyList = countCompany.result || [];
          this.companyListAux = countCompany.result || [];
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar empresas!');
      });
  }

  loadDataStand(event_id: number) {
    this.loading = true;
    return this.exposurMapService.getStandByEvent(event_id).then(
      response => {
        console.log(response)
        if (response.result.length > 0) {
          this.list = response.result.map((e: any) => {
            return {
              id: e?.idStand,
              'Nombre de empresa': e?.companyName,
              'Número de stand': e?.stand,
              'Nivel del stand': 'Nivel ' + e?.standLevel
            }
          });
        }
        else {
          this.list = [];
        }
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar la información!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  loadDataMap(event_id: number) {
    this.loading = true;
    return this.exposurMapService.getMapByEvent(event_id).then(
      response => {
        console.log(response)
        this.floorList = [];
        this.map.eventName = this.eventName;
        this.map.eventAddress = this.eventAddress;

        if (response?.result[0]?.floors.length > 0) {
          this.map.eventId = this.eventID;
          this.map.userCreated = this.userID;
          this.map.eventMapId = response.result[0]?.idEventMap;

          for (let i = 0; i < 4; i++) {
            const floor = response.result[0].floors.find((x: any) => x.floor == (i + 1));
            if (floor) {
              const image = floor.image.split('/');
              this.map.map[i].idFloor = floor.idFloor;
              if (image[7] != 'null' && image[7] != 'default.png') {
                this.map.map[i].name = image[7];
                this.map.map[i].url = floor.image;
                this.floorList.push({ id: floor.idFloor, name: 'Piso ' + (i + 1) });
              }
            }
          }
        }
        console.log(this.map);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar la información!');
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  click(type: string, value: string) {
    switch (type) {
      case 'link':
        (value != '') && (window.open(value, '_blank'));
        break;
      case 'click':
        const element = document.getElementById(value);
        element?.click();
        break;
    }
  }

  // @ViewChild('file') file!: ElementRef;
  async addFile(event: any, index: number) {
    this.form.control.markAsTouched();
    let base64 = undefined;
    const file = event.target.files[0];
    const extension = file.type.split('/')
    base64 = await this.utilsService.convertBase64(event.target.files.item(0));
    // console.log(event.target.id, event.target.files[0]);

    if (typeof (base64) === 'string') {
      switch (event.target.id) {
        case 'input-images-file-logo':
          this.stand.image64 = base64;
          this.stand.image = { extension: extension[1], name: file.name }
          break;
        case 'input-images-file-map-' + index:
          this.map.map[index].name = file.name;
          this.map.map[index].image64 = base64; // index + ' - ' + base64; 
          this.map.map[index].userCreated = this.userID;
          // this.file.nativeElement.value = '';
          break;
      }
    }
    // console.log(this.map.map);
  }

  async processData(active: number) {
    if (this.form.valid) {
      if (active === 1) {
        this.createStand();
      }
      else if (active === 2) {
        this.map.eventMapId > 0 ? this.upadteMap(this.map) : this.createMap(this.map);
      }
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos correctamente.');
    }
  }

  createMap(data: any) {
    let listMap: any = new Array;
    data.map.forEach((x: any) => {
      (x.image64 != '') && (
        listMap.push({
          userCreated: x.userCreated,
          floorNumber: x.floorNumber,
          image64: x.image64
        })
      );
    });

    const map = {
      eventId: data.eventId,
      userCreated: data.userCreated,
      map: listMap
    }
    console.log('created: ', map);
    this.loading = true;
    this.disabled = true;
    return this.exposurMapService.createMapFloor(map).then(
      (response) => {
        console.log(response);
        this.view = 0;
        this.active = 1;
        this.valid = true;
        this.changeTab('stand');
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || 'El mapa y los pisos se han creado de forma exitosa.');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.message?.description || 'Error al crear el mapa y los pisos.');
      }).finally(() => {
        this.loading = false;
        this.disabled = false;
      });
  }

  upadteMap(data: any) {
    let listMap: any = new Array;
    data.map.forEach((x: any) => {
      (x.image64 != '' || x.idFloor != null) && (
        listMap.push({
          userCreated: x.userCreated,
          floorNumber: x.floorNumber,
          idFloor: x.idFloor ? x.idFloor : null,
          image64: x.image64 != '' ? x.image64 : null // image64: x.image64 || null,
        })
      );
    });

    const map = { eventId: data.eventId, eventMapId: data.eventMapId, map: listMap };
    console.log('updated: ', map);
    this.loading = true;
    this.disabled = true;
    return this.exposurMapService.updateMapFloor(map).then(
      (response) => {
        console.log(response);
        this.view = 0;
        this.active = 1;
        this.loadDataStand(this.eventID);
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || 'El mapa y los pisos se han actualizado de forma exitosa.');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.message?.description || 'Error al actualizar el mapa y los pisos.');
      }).finally(() => {
        this.loading = false;
        this.disabled = false;
      });
  }

  createStand() {
    this.loading = true;
    this.disabled = true;
    this.exposurMapService.createStand(this.stand).then(
      (response) => {
        this.view = 0;
        this.cleanModel();
        this.changeTab('stand');
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.descripcion || '¡Stand creado!');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.descripcion || '¡Error al crear stand!');
      }).finally(() => {
        this.loading = false;
        this.disabled = false;
      });
  }

  deletedStand(ID: any) {
    this.loading = true;
    this.exposurMapService.deleteStand(ID).then(
      async (response) => {
        await this.loadDataStand(this.eventID);
        this.view = 0;
        this.cleanModel();
        this.changeTab('stand');
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.descripcion || '¡El stand se elimino!');
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.descripcion || '¡El stand no se elimino!');
      }).finally(() => {
        this.loading = false
      })
  }

  deleteDialog(floor: any) {
    console.log(floor);
    this.utilsService.dialog('DELETE', '', '').then(
      result => {
        if (result === true) {
          this.removeFloor(floor.idFloor);
        }
      },
      error => console.log(error)
    );
  }

  removeFloor(idFloor: number) {
    console.log(idFloor);
    this.loading = true;
    this.exposurMapService.deleteFloor(idFloor).then(
      async (response) => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.description || '¡El mapa de piso se eliminó!');
        this.view = 0;
        this.active = 1;
        this.loadDataStand(this.eventID);
      },
      error => {
        // console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description || '¡El mapa de piso no se eliminó!');
      }).finally(() => {
        this.loading = false
      })
  }
}

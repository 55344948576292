<div class="main-header">
    <div class="main-log">
        <img src="..\..\assets\images\headerLogo.svg" alt="logo" />
    </div>
    <mat-divider class="divider" [vertical]="true"></mat-divider>
    <div class="username-block d-none d-sm-block">
       <label class="user-block">{{username}}</label> 
       <label class="email-block">{{email}}</label> 
    </div>
    <div class="d-block d-sm-none" style="padding-top: 20px;">
        <!-- <i class="menu-mobil-button"></i> -->
    </div>
</div>
<!DOCTYPE html>
<html class="login-body">
<div class="header">
    <img src="..\..\assets\images\Agexport-logo.svg" alt="logo" />
</div>
<div class="login">
    <form class="custom-form" method="post" [formGroup]="formGroup" method="post" #form>
        <h1>Recuperación de contraseña</h1>
        <hr class="solid">
        <label>Ingrese el código que fue enviado a su correo electrónico:</label>
        <div class="custom-input" formArrayName="things">        
            <input type="text" maxlength="1" formControlName="0" id="input1"
                oninvalid="this.setCustomValidity('Este campo es obligatorio')" oninput="setCustomValidity('')"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');" (keyup)="tabField(2, $event)" />
            <input type="text" maxlength="1" formControlName="1" id="input2"
                oninvalid="this.setCustomValidity('Este campo es obligatorio')" oninput="setCustomValidity('')"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');" (keyup)="tabField(3, $event)" />
            <input type="text" maxlength="1" formControlName="2" id="input3"
                oninvalid="this.setCustomValidity('Este campo es obligatorio')" oninput="setCustomValidity('')"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');" (keyup)="tabField(4, $event)" />
            <input type="text" maxlength="1" formControlName="3" id="input4"
                oninvalid="this.setCustomValidity('Este campo es obligatorio')" oninput="setCustomValidity('')"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
        </div>
        <span class="resend-link">¿No recibiste ningun código? Enviarme el código <strong class="resend"
                (click)="reSend()">nuevamente</strong></span>
        <button type="submit" (click)="form.checkValidity()? recovery() : null">Confirmar código</button>
    </form>
</div>

</html>
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UtilsService } from 'src/app/Services/utils.service';
import { LocationService } from 'src/app/Services/location.service';
import { BackServiceLocation, BackServiceParking } from 'src/app/Models/back-service.model';

@Component({
  selector: 'app-edit-ubicaciones',
  templateUrl: './edit-ubicaciones.component.html',
  styleUrls: ['./edit-ubicaciones.component.css']
})
export class EditUbicacionesComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  public active: number = 1;
  public eventID: number = 0;
  public editType: string = '';
  public minDate: any = '';
  public maxDate: any = '';
  public register_id: number = 0;
  public loading: boolean = false;
  public listLocation: any[] = [];
  public disabled: boolean = false;
  public date_error: boolean = false;
  public parkingData = {} as BackServiceParking;
  public locationData = {} as BackServiceLocation;

  constructor(
    private utilsService: UtilsService,
    private locationService: LocationService
  ) { this.eventID = Number(sessionStorage.getItem('EventID')) || 0 }

  async ngOnInit(): Promise<void> {
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();
    const event: any = JSON.parse(sessionStorage.getItem('EventData')?.toString() || '{}');

    if (token) {
      if (this.eventID > 0) {
        await this.getLocationByEvent(this.eventID);
        this.register_id = await this.utilsService.getParam('ID') || 0;
        this.editType = await this.utilsService.getParam('EDIT') || '';

        if (this.editType != '' && this.register_id > 0) {
          this.getOne(this.register_id);
        }
        
        this.getLimitDate(event);
      } else {
        this.utilsService.dialog('ERROR', 'Alerta', '¡No tienes seleccionado ningun evento!');
        this.goToPage('/admin/events/calendario-eventos');
      }
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡Inicia sesión pro favor!');
      this.goToPage('/login');
    }
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validation_date(): boolean {
    if (
      (this.locationData.fechaInicio && this.locationData.fechaFin) || (this.parkingData.fechaInicio && this.parkingData.fechaFin)
    ) {
      const end = new Date(
        `${this.active === 1 ? this.locationData.fechaFin : this.parkingData.fechaFin} 
         ${this.active === 1 ? this.locationData.horaFin : this.parkingData.horaFin}`
      );

      const start = new Date(
        `${this.active === 1 ? this.locationData.fechaInicio : this.parkingData.fechaInicio}
         ${this.active === 1 ? this.locationData.horaInicio : this.parkingData.horaInicio}`
      );

      if (start.getTime() > end.getTime()) {
        this.date_error = true;
        return true;
      } else {
        this.date_error = false;
        return false;
      }
    }
    return false;
  }

  getLimitDate(event: any){
    if (Object.keys(event).length > 0) {
      const maxDate = new Date(event.fechaFinal + ' 00:00:00');
      const minDate = new Date(event.fechaInicio + ' 00:00:00');

      this.minDate = this.utilsService.date_pipe(
        (new Date(minDate.getTime() - 86400000)).toString(), //86399.9 //86400000
        'yyy-MM-dd'
      )

      this.maxDate = this.utilsService.date_pipe(
        (new Date(maxDate.getTime() + 86400000)).toString(), //86399.9 //86400000
        'yyy-MM-dd'
      )
    }
  }

  getLocationByEvent(event_id: number) {
    this.loading = true;
    return this.locationService.getLocationByEvent(event_id).then(
      (response) => {
        this.listLocation = response.result.eventPlaces.map((e: any) => {
          return {
            id: e.id,
            name: e.title
          }
        })
        // console.log(this.listLocation)
      },
      error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      })
  }

  getOne(register_id: number) {
    this.loading = true;

    if (this.editType === 'location') {
      this.active = 1;
      this.locationService.getOneLocation(register_id).then(
        (response) => {
          this.locationData.icon = response.result?.icon;
          this.locationData.color = response.result?.color;
          this.locationData.textColor = response.result?.textColor;
          this.locationData.backgroundColor = response.result?.backgroundColor;

          this.locationData.id = response.result?.id;
          this.locationData.eventId = response.result?.eventId;
          this.locationData.direccion = response.result?.direccion;
          this.locationData.nombreUbicacion = response.result?.nombreUbicacion;
          this.locationData.enlaceUbicacion = response.result?.enlaceUbicacion;
          this.locationData.horaFin = response.result?.fechaHoraFin.substr(11, 5);
          this.locationData.horaInicio = response.result?.fechaHoraInicio.substr(11, 5);
          this.locationData.fechaFin = response.result?.fechaHoraFin.substr(0, 10);
          this.locationData.fechaInicio = response.result?.fechaHoraInicio.substr(0, 10);

          console.log(response.result);
          console.log(this.locationData);
        },
        error => {
          console.log(error);
          this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar los datos!');
          this.goToPage('/admin/events/admin-agenda');
        }).finally(() => {
          this.loading = false
        })
    }
    else if (this.editType === 'parking') {
      this.active = 2;
      this.locationService.getOneParking(register_id).then(
        (response) => {
          this.parkingData.icon = response.result?.icon;
          this.parkingData.color = response.result?.color;
          this.parkingData.textColor = response.result?.textColor;
          this.parkingData.backgroundColor = response.result?.backgroundColor;

          this.parkingData.id = response.result?.id;
          this.parkingData.direccion = response.result?.direccion;
          this.parkingData.locationId = response.result?.locationId;
          this.parkingData.nombreParqueo = response.result?.nombreParqueo;
          this.parkingData.enlaceUbicacion = response.result?.enlaceUbicacion;
          this.parkingData.horaFin = response.result?.fechaHoraFin.substr(11, 5);
          this.parkingData.fechaFin = response.result?.fechaHoraFin.substr(0, 10);
          this.parkingData.horaInicio = response.result?.fechaHoraInicio.substr(11, 5);
          this.parkingData.fechaInicio = response.result?.fechaHoraInicio.substr(0, 10);

          console.log(response.result)
          console.log(this.parkingData)
        },
        error => {
          console.log(error);
          this.utilsService.dialog('ERROR', 'Error', '¡Error al cargar los datos!');
          this.goToPage('/admin/events/admin-agenda');
        }).finally(() => {
          this.loading = false
        })
    }
  }

  processData(type: number) {
    if (this.form.valid && !this.date_error) {
      if (this.register_id > 0) {
        this.update(type)
      } else {
        this.create(type);
      }
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos correctamente.');
    }
  }

  async create(type: number) {
    try {
      var response: any;
      this.loading = true;
      this.disabled = true;
      if (type === 1) {
        this.locationData.icon = 'icon';
        this.locationData.color = '#1A44DB';
        this.locationData.textColor = '#ffffff';
        this.locationData.eventId = this.eventID;
        this.locationData.backgroundColor = '#66666';
        this.locationData.fechaHoraFin = this.locationData.fechaFin + ' ' + this.locationData.horaFin;
        this.locationData.fechaHoraInicio = this.locationData.fechaInicio + ' ' + this.locationData.horaInicio;
        console.log(this.locationData);
        response = await this.locationService.createLocationToEvent(this.locationData);
      } else {
        this.parkingData.icon = 'icon';
        this.parkingData.color = '#1A44DB';
        this.parkingData.textColor = '#ffffff';
        this.parkingData.backgroundColor = '#666666';
        this.parkingData.locationId = Number(this.parkingData.locationId);
        this.parkingData.fechaHoraFin = this.parkingData.fechaFin + ' ' + this.parkingData.horaFin;
        this.parkingData.fechaHoraInicio = this.parkingData.fechaInicio + ' ' + this.parkingData.horaInicio;
        console.log(this.parkingData);
        response = await this.locationService.createParkingToLocation(this.parkingData);
      }
      if (response?.success === true) {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.title?.description || 'Registrado.');
        this.goToPage('/admin/events/admin-ubicaciones');
      } else {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.title?.description || 'Error al registrar.');
      }
    } catch (error: any) {
      this.utilsService.dialog('ERROR', 'Error', error.error.message.description || 'Intentar más tarde.');
    }
    this.loading = false;
    this.disabled = false;
  }

  async update(type: number) {
    try {
      var response: any;
      this.loading = true;
      this.disabled = true;
      if (type === 1) {
        // this.locationData.icon = 'icon';
        // this.locationData.color = '#1A44DB';
        // this.locationData.textColor = '#ffffff';
        // this.locationData.eventId = this.eventID;
        // this.locationData.backgroundColor = '#66666';
        this.locationData.fechaHoraFin = this.locationData.fechaFin + ' ' + this.locationData.horaFin;
        this.locationData.fechaHoraInicio = this.locationData.fechaInicio + ' ' + this.locationData.horaInicio;
        console.log(this.locationData);
        response = await this.locationService.updateLocationByEvent(this.locationData.id, this.locationData);
      } else {
        // this.parkingData.icon = 'icon';
        // this.parkingData.color = '#1A44DB';
        // this.parkingData.textColor = '#ffffff';
        // this.parkingData.backgroundColor = '#666666';
        this.parkingData.locationId = Number(this.parkingData.locationId);
        this.parkingData.fechaHoraFin = this.parkingData.fechaFin + ' ' + this.parkingData.horaFin;
        this.parkingData.fechaHoraInicio = this.parkingData.fechaInicio + ' ' + this.parkingData.horaInicio;
        console.log(this.parkingData);
        response = await this.locationService.updateParkingByLocation(this.parkingData.id, this.parkingData);
      }
      if (response?.success === true) {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.title?.description || 'Registro actualizado.');
        this.goToPage('/admin/events/admin-ubicaciones');
      } else {
        this.utilsService.dialog('SUCCESS', 'Éxito', response?.message?.title?.description || 'Error al actualizar.');
      }
    } catch (error: any) {
      this.utilsService.dialog('ERROR', 'Error', error.error.message.description || 'Intentar más tarde.');
    }
    this.loading = false;
    this.disabled = false;
  }
}

import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/Services/utils.service';
import { DialogComponent } from 'src/app/Modales/dialog/dialog.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-evento-menu',
  templateUrl: './evento-menu.component.html',
  styleUrls: ['./evento-menu.component.css']
})
// export class EventoMenuComponent implements OnInit {
export class EventoMenuComponent implements OnInit, AfterContentChecked {
  eventT: any;
  eventID: any;
  eventType: any;
  enableFairs: boolean;
  enableParticipants: boolean;
  public routerInit = 'events';
  public environment: boolean = environment.production;
  // public environmentProFree: boolean = true; // Modulos de feraias liberados en prod

  constructor(
    private router: Router,
    // private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.enableFairs = false;
    this.enableParticipants = false;
    this.getMenu(); // Metodo para activar opcion del menu para ferias
  }

  async ngOnInit() {
    // this.routerInit = this.router.url;    
    const url = this.router['rawUrlTree'].root.children['primary'].segments;
    this.routerInit = url[url.length - 1].path;
    
    // console.log('On init', this.routerInit)

    // const urlTree = this.router.parseUrl(this.router.url);
    // const position: any = urlTree.root.children['primary'].segments;
    // console.log(this.activatedRoute);
    // this.eventT = sessionStorage.getItem('EventType');
    // if(this.eventT === 0)
    // {
    //   this.isEnabled = false;
    // }
    // this.eventID = sessionStorage.getItem('EventID');
    // this.eventType = sessionStorage.getItem('EventType');
    // if(this.eventType != 0){
    //   await this.getMenu();
    // }

    this.eventType = Number(sessionStorage.getItem('EventType')?.toString()) || 0;

    if (this.eventType === 0) {
      this.enableFairs = false;
      this.enableParticipants = false;
    }
    else if (this.eventType === 1 || this.eventType === 3 || this.eventType === 10) {
      this.enableFairs = false;
      this.enableParticipants = true;
    }
    else {
      this.enableFairs = true;
      this.enableParticipants = true;
    }
  }

  goToPage(pageName: string) {
    (pageName === '/admin/events/nuevo-evento') && (sessionStorage.setItem('EventID', '0'));
    this.utilsService.goToPage(pageName);
    // this.router.navigate([`${pageName}`]);
  }

  open() {
    const modalRef = this.modalService.open(DialogComponent, { centered: true });
  }

  ngAfterContentChecked(): void {
    // this.routerInit = this.router.url;
    const url = this.router['rawUrlTree'].root.children['primary'].segments;
    this.routerInit = url[url.length - 1].path;

    if (this.routerInit === 'user-guide') {
      const type = this.router['browserUrlTree'].queryParams.type;
      switch (type) {
        case 'participantes':
          this.routerInit = this.routerInit + '-participantes';
          break
        case 'conferencistas':
          this.routerInit = this.routerInit + '-conferencistas';
          break
        case 'expositores':
          this.routerInit = this.routerInit + '-expositores';
          break
        case 'patrocinadores':
          this.routerInit = this.routerInit + '-patrocinadores';
          break
      }
    }
    this.changeDetector.detectChanges();
  }

  async getMenu() {
    await this.utilsService.changeEmitted$.subscribe(status => {
      // (this.environment === false) && (this.enableFairs = status.enableFairs);
      this.enableFairs = status.enableFairs
      this.enableParticipants = status.enableParticipants;
    });
  }
}

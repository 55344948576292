import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UtilsService } from 'src/app/Services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuardGuard implements CanActivate {
  
  constructor (
    private utilsService: UtilsService,
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessToken = sessionStorage.getItem('Token');
    if(!accessToken){
      // this.router.navigate(['/login']);
      this.utilsService.goToPage('/admin/inicio');
      this.utilsService.dialog('ERROR', 'Error', 'Tu usuario no tiene acceso a esta ruta, inicia sesión o contacta al administrador.');
    } else {
      return true;
    }
    return false;
  }
  
}

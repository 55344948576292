import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SponsorService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    // Obtener patrocinadores de un evento
    getSponsorByEvent(page: number | null, size: number | null, event_id: number, filter: string | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (filter != null) && (this.params = this.params.set('filter', filter));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/sponsors/event/${event_id}`, this.options).toPromise();
    }

    // Obtener una ubicacion
    getOneSponsor(sponsor_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/sponsors/${sponsor_id}`, this.options).toPromise();
    }

    // Agregar un sponsor a un evento
    createSponsorToEvent(data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.post(`${this.url}eventos/sponsors/`, data, this.options).toPromise();
    }

    // Actualiar una ubicacion
    updateSponsorByEvent(sponsor_id: number, data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.put(`${this.url}eventos/sponsors/${sponsor_id}`, data, this.options).toPromise();
    }

    // Eliminar un patrocinador desde lista de patrocinadores y lista de empresas
    deleteSponsorToEvent(sponsor_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers, params: this.params };
        return this.http.delete(`${this.url}eventos/sponsors/${sponsor_id}`, this.options).toPromise();
    }
}

<app-loading *ngIf="loading"></app-loading>
<div>
  <div class="header">
    <header>Administración - Cuenta de administrador y comercio</header>
    <button class="agregar" (click)="goToPage('/admin/users/edit-administradores')">+ Agregar</button>
  </div>
  <div class="main">
    <div class="default-view">
      <div class="search-field">
        <i class="search-filter" id="search" (click)="loadData(pageNumber, pageSize, filter, true)"></i>
        <input class="search-input" (keyup.enter)="loadData(pageNumber, pageSize, filter, true)" (keyup)="searchReturn()" placeholder="Buscar..." [(ngModel)]="filter" />
        <i class="search-icon" id="filter" (click)="showFilterModal = true"></i>
      </div>
      <!-- Tag Filter -->
      <div class="row px-2 mt-2" *ngIf="valid_isFilter()">
        <label class="badge badge-agex mx-2 mt-3 px-3 py-2" *ngIf="filters.roleName != null && isFilter">
          <span class="m-4">Rol</span>
          <a class="badge-btn-close" (click)="cleanFilter('roleName')">X</a>
        </label>
        <a class="mx-4 my-auto text-dark cursor-pointer" (click)="cleanFilter('all')" *ngIf="isFilter">Limpiar filtros</a>
      </div>
      <div class="table-default">
        <table class="content-table" border="1">
          <thead>
            <tr>
              <th *ngFor="let each of categoriesHeading"> {{each}} </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list; let i = index">
              <td *ngFor="let x of item | keyvalue:originalOrder" [hidden]="x.key === 'id'"> {{x.value}} </td>
              <td (mouseleave)="hideActions()">
                <button name="actions" id="actions" class="actions" (click)="showActions(i)"></button>
                <div hidden
                  class="card options-container float-end position-absolute translate-middle-y p-2 m-2" id="item{{i}}" (mouseleave)="hideActions()">
                  <button name="edit" id="edit" class="actions-btn border-bottom w-100 text-left"
                    (click)="goToPage('/admin/users/edit-administradores', { ID: item.id })">Editar</button>
                  <button name="delete" id="delete" class="actions-btn w-100 text-left"
                    (click)="deletedUser(item.id)">Eliminar</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-pagination">
        <i class="btn-arrow btn-arrow-left" id="back" (click)="onLeft()"></i>
        <p class="pagination-text">Página {{pageNumber + 1}} de {{totalPages}}</p>
        <i class="btn-arrow btn-arrow-right" id="back" (click)="onRight()"></i>
      </div>
    </div>
  </div>
</div>

<!-- Modal para los filtros-->
<div class="modal-overlay" *ngIf="showFilterModal">
  <div class="modal-container">
    <div class="modal-content-types">
      <div class="modal-btn-close" (click)="showFilterModal = false">X</div>
      <h3 class="modal-title">Filtros</h3>
      <hr/>
      <div>
        <div class="col-12 mb-3">
          <div class="form-label mb-3">
            <label class="font-weight-bold">Rol</label>
            <ng-multiselect-dropdown [placeholder]="'Rol...'" name="md_role" id="md_role" 
              [settings]="dropdownSettings" [data]="roleList" [(ngModel)]="role"
              (onSelect)="onSelect($event, 'roleName')" (onDeSelect)="onDeSelect('roleName')">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="modal-buttons">
          <button class="modal-btn-clear mx-2" (click)="cleanFilter('all')">Limpiar</button>
          <button class="modal-btn-search mx-2" (click)="searchFilter(); showFilterModal = false">Buscar</button>
        </div>
      </div>
    </div>
  </div>
</div>
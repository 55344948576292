import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/Services/utils.service';
import { SurveyService } from 'src/app/Services/survey.service';

interface Survey {
  id: number;
  title: string;
  eventId: number;
  answered: boolean;
  questions: any[]
};

const mandatoryQuestionConst = [
  { id: 0, type: 'stars', default: true, text: 'En escala de 1 a 10, ¿qué tan probable es, que recomiende con otras empresas este evento?' },
  { id: 0, type: 'open', default: true, text: 'Podría indicarnos ¿qué fue lo que más le gusto del evento?' },
  { id: 0, type: 'open', default: true, text: 'Podría indicarnos ¿qué fue' }
]

@Component({
  selector: 'app-admin-encuestas',
  templateUrl: './admin-encuestas.component.html',
  styleUrls: ['./admin-encuestas.component.css']
})
export class AdminEncuestasComponent implements OnInit {
  @ViewChild('form') form!: NgForm;
  public userID: number = 0;
  public eventID: number = 0;
  public loading: boolean = false;
  public disabled: boolean = false;

  public questions: any[] = [];
  public survey: Survey = {} as Survey;

  public mandatoryQuestion = [
    { id: 0, type: 'stars', default: true, text: 'En escala de 1 a 10, ¿qué tan probable es, que recomiende con otras empresas este evento?' },
    { id: 0, type: 'open', default: true, text: 'Podría indicarnos ¿qué fue lo que más le gusto del evento?' },
    { id: 0, type: 'open', default: true, text: 'Podría indicarnos ¿qué fue lo que menos le gusto o cambiaria del evento?' }
  ];

  constructor(
    private utilsService: UtilsService,
    private surveyService: SurveyService
  ) { }

  async ngOnInit(): Promise<void> {
    // Evento ID=233 <- TEST preguntas malas
    this.eventID = Number(sessionStorage.getItem('EventID'));
    this.userID = Number(sessionStorage.getItem('UserID')) | 0; // <- no deberia ser necesario
    const token: string | undefined = sessionStorage.getItem('Token')?.toString();

    if (token && this.eventID > 0) {
      /*
      const survey = await this.loadDatabyEvent(this.eventID, this.userID);
      if (survey?.surveys?.id === undefined) {
        this.survey.eventId = this.eventID;
        this.questions = this.mandatoryQuestion;
      }
      //*/ 
      //*
      await this.loadDatabyEvent(this.eventID, this.userID);
      if (this.survey.id > 0) {
        // console.log('edit')
        this.survey.eventId = this.eventID;
        this.questions = this.survey.questions;
      } else {
        // console.log('new')
        this.survey.eventId = this.eventID;
        this.questions = this.mandatoryQuestion;
      }
      //*/
    }
    else if (token && this.eventID === 0) {
      this.utilsService.dialog('ERROR', 'Error', 'No tienes seleccionado un evento');
      this.utilsService.goToPage('/admin/events/calendario-eventos');
    }
    else {
      this.utilsService.dialog('ERROR', 'Error', '¡Debes iniciar sesión!');
      this.utilsService.goToPage('/login');
    }

  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
        ||
        (this.form.controls[name].value == undefined && this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addQuestion() {
    if (this.questions.length < 8) {
      this.questions.push({ type: undefined, text: '' });
      // this.questions.push({ type: 'null', text: '' });
    }
  }

  verifyQuetion(event: any, item: any, index: number) {
    // const element = document.getElementById('btn' + index);
    if (event.target.value === 'multiple') {
      this.questions[index] = {
        type: item.type,
        text: item.text,
        options: []
      }
      console.log(this.questions[index]);
    }
    else {
      delete this.questions[index].options;
      // element?.classList.add('showBtn');
    }
  }

  addOption(index: number) {
    if (this.questions[index].options.length < 4) {
      this.questions[index].options.push({});
      // this.questions[index].options.push('')
    }
  }

  removeOption(index_one: number, index_two: number) {
    this.form.control.markAllAsTouched();
    this.questions[index_one].options.splice(index_two, 1);
  }

  deleteDialog(index: number, question_id: number) {
    // this.form.control.markAllAsTouched(); // this.questions.splice(index, 1); // this.update();
    this.utilsService.dialog('DELETE', '', '').then(
      async result => {
        if (result === true) {
          await this.removeQuestion(question_id);
        }
      },
      error => console.log(error)
    );
  }

  removeQuestion(question_id: number) {
    // console.log('entro');
    // await this.loadDatabyEvent(this.eventID, this.userID);
    // return true;
    this.loading = true;
    return this.surveyService.deleteQuestion(question_id).then(
      async (response) => {
        console.log(response);
        await this.loadDatabyEvent(this.eventID, this.userID);
        this.questions = new Array();
        this.questions = this.survey.questions;
        this.utilsService.dialog('SUCCESS', 'Éxito', '¡La pregunta se eliminó!');
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡La pregunta no se eliminó!');
      }).finally(() => {
        this.loading = false
      })
  }

  async cancel() {
    await this.loadDatabyEvent(this.eventID, this.userID);
    if (this.survey.id > 0) {
      this.survey.eventId = this.eventID;
      this.questions = this.survey.questions;
    } else {
      this.survey.eventId = this.eventID;
      this.questions = new Array();
      this.questions.push(this.mandatoryQuestion[0]);
      this.questions.push(this.mandatoryQuestion[1]);
      this.questions.push(this.mandatoryQuestion[2]);
    }    
  }

  loadDatabyEvent(event_id: number, user_id: number) {
    this.loading = true;
    return this.surveyService.getSurveysByEvent(event_id, 1).then(
      response => {
        console.log(response.result);

        if (response.success) {
          this.survey.id = response.result.surveys?.id;
          this.survey.title = response.result.surveys?.title;
          this.survey.eventId = response.result.surveys?.eventId;
          //*
          this.survey.questions = response.result.surveys.surveyQuestions.map((e: any) => {
            const question: any = { 
              id: e.id,
              type: e.type,
              text: e.text,
              surveyId: e.surveyId,
              default: e?.default  || false,
            };

            (e.type === 'multiple') && (question.options = e.options.map((i: any) => { return { 0: i }; }))
            return question;
          });
          //*/

          /*
          this.survey.questions = response.result.surveys.surveyQuestions.map((e: any) => {
            if (e.type === 'multiple') {
              const options: any[] = e.options.map((i: any) => { return { 0: i }; });
              return { id: e.id, surveyId: e.surveyId, type: e.type, text: e.text, options: options, default: e?.default || false }
            } else {
              return { id: e.id, surveyId: e.surveyId, type: e.type, text: e.text, default: e?.default  || false }
            }
          });
          //*/


          this.survey.questions.sort((curr, next) =>Number(next.default) - Number(curr.default));
        }
        else {
          console.log(response);
          this.utilsService.dialog('ERROR', 'Error', response.message.description || '¡Error al cargar encuesta!');
          // return false;
        }
      },
      error => {
        console.log(error);
        if (error.error.message.description === 'Encuesta no encontrada') {
          this.survey.id = 0;
          this.utilsService.dialog('SUCCESS', 'Alerta', '¡Encuesta nueva!');
        } else {
          this.utilsService.dialog('ERROR', 'Error', error.error.message.description || 'Error al cargar encuesta!');
        }
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  //*
  async processData() {
    const val_select = await this.questions.find(x => x.type === undefined || x.type == 'undefined')
    // const val_select = await this.questions.find(x => x.type === 'null')
    if (this.form.valid && !val_select) {
      this.survey.eventId = this.eventID;
      this.survey.questions = this.questions.map((e: any) => {
        const question: any = {
          type: e?.type,
          text: e?.text,
          default: e?.default
        };
        (e.id) && (question.id = e.id);
        (e.options) && (question.options = e.options.map((i: any) => { return i[0]; }));
        return question;
      });
      // console.log(this.survey);
      if (this.survey.id > 0) {
        console.log('update', this.survey);
        this.update()
      } else {
        console.log('create', this.survey);
        this.create();
      }
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos correctamente.');
    }
  }
  //*/

  /*
  async processData() {
    const val_select = await this.questions.find(x => x.type === undefined || x.type == 'undefined')
    // const val_select = await this.questions.find(x => x.type === 'null')
    if (this.form.valid && !val_select) {
      this.survey.eventId = this.eventID;
      this.survey.questions = this.questions.map((e: any) => {
        if (e.options) {
          const options: any[] = e.options.map((i: any) => { return i[0]; });
          return e.id ? { id: e.id, type: e.type, text: e.text, options: options } : { type: e.type, text: e.text, options: options };
        } else {
          return e.id ? { id: e.id, type: e.type, text: e.text } : { type: e.type, text: e.text };
        }
      });
      // console.log(this.survey);
      if (this.survey.id > 0) {
        console.log('update', this.survey);
        this.update()
      } else {
        console.log('create', this.survey);
        // this.create();
      }
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos correctamente.');
    }
  }
  //*/

  create() {
    console.log('create: ', this.survey);

    this.loading = true;
    this.disabled = true;
    this.surveyService.createSurveyToEvent(this.survey).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', response.message.description.title || 'Éxito', response.message.description || '¡Encuesta creada!');
        this.form.control.markAsUntouched();
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al crear encuesta!');
      }).finally(() => {
        this.loading = false
        this.disabled = false;
      })
  }

  update() {
    console.log('update: ', this.survey);

    this.loading = true;
    this.disabled = true;
    this.surveyService.updateSurveyToEvent(this.survey.id, this.survey).then(
      (response) => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', response.message.description.title || 'Éxito', response.message.description || '¡Encuesta actualizada!');
        this.form.control.markAsUntouched();
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', '¡Error al actualizar encuesta!');
      }).finally(() => {
        this.loading = false
        this.disabled = false;
      })
  }
}
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/Services/user.service';
import { UtilsService } from 'src/app/Services/utils.service';
import { AccountRelation, listas } from 'src/app/Models/back-service.model';

interface UserAdmin {
  id: number;
  firstName: string,
  secondName: string,
  firstLastname: string,
  secondLastname: string,
  email: string,
  puestoId: number | null,
  areaId: number | null,
  phone: number,
  businessPhone: number,
  roles: [string],
  accountRelation: any[],
  roleId: number | null
}

@Component({
  selector: 'app-edit-admins',
  templateUrl: './edit-admins.component.html',
  styleUrls: ['./edit-admins.component.css']
})
export class EditAdminsComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  public userID: any;
  public password: string = '';
  public jobList: listas[] = [];
  public areaList: listas[] = [];
  public roleList: listas[] = [];
  public newUser: boolean = true;
  public loading: boolean = false;
  public user: UserAdmin = {} as UserAdmin;
  public creatingUser: boolean = true;
  constructor(
    private userService: UserService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.userID = Number(this.utilsService.getParam('ID') || null);

    if (this.userID > 0) {
      this.getOneUser(this.userID);
      this.user.id = this.userID;
      this.newUser = false;
      this.creatingUser = false;
    } else {
      this.user.accountRelation = new Array({} as AccountRelation);
    }
    this.loadLists();
  }

  goToPage(pageName: string) {
    this.utilsService.goToPage(pageName);
  }

  validation_msg(name: string): boolean {
    if (this.form && this.form.controls[name]) {
      if (
        (this.form.controls[name].errors || this.form.controls[name].invalid)
        &&
        (this.form.controls[name].dirty || this.form.controls[name].touched)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loadLists() {
    this.utilsService.getPuestos().then(
      response => {
        // console.log('puesto: ', response);
        this.jobList = response.result.puestos.map((e: any) => {
          return { id: e?.id, name: e?.name }
        })
      },
      error => this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getAreas().then(
      response => {
        // console.log('area: ', response);
        this.areaList = response.result.areas.map((e: any) => {
          return { id: e?.id, name: e?.name }
        })
      },
      error => this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );

    this.utilsService.getRolesAdmin().then(
      response => {
        // console.log('role: ', response);
        this.roleList = response.result.roles.map((e: any) => {
          return { id: e?.id, key: e?.value, name: e?.label }
        })
      },
      error => this.utilsService.dialog('ERROR', 'Error', error.error.message.description)
    );
  }

  getOneUser(user_id: number) {
    this.loading = true;

    this.userService.getOneUser(user_id).then(
      response => {
        console.log(response.result);
        this.user.id = response.result?.id;
        this.user.phone = response.result?.phone;
        this.user.email = response.result?.email;
        this.user.roleId = response.result?.roleId;
        this.user.firstName = response.result?.firstName;
        this.user.secondName = response.result?.secondName;
        this.user.businessPhone = response.result?.businessPhone;
        this.user.firstLastname = response.result?.firstLastname;
        this.user.secondLastname = response.result?.secondLastname;
        this.user.accountRelation = response?.result?.accountRelations.length > 0
          ? response?.result?.accountRelations
          : this.user.accountRelation = new Array({} as AccountRelation);
        this.user.areaId = response.result?.accountRelations[0]?.areaId || null;
        this.user.puestoId = response.result?.accountRelations[0]?.puestoId || null;

        this.user.roles = [(response.result.role.toLowerCase()).slice(5)];
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error?.error?.message?.description)
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  test($event: any) {
    console.log($event);

  }

  submit() {
    console.log(this.form.controls);

    if (this.form.valid) {
      const role = this.roleList.find(x => x.id === Number(this.user.roleId));
      this.user.roles = [role!.key]
      this.userID > 0 ? this.editUser() : this.createUser();
    } else {
      this.form.control.markAllAsTouched();
      this.utilsService.dialog('ERROR', 'Error', 'Por favor llena los campos obligatorios.');
    }
  }

  createUser() {
    console.log('save: ', this.user);
    this.loading = true;
    return this.userService.createUserAdmin(this.user).then(
      async response => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description, '/admin/users/admin-administradores');
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
        throw new Error(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  editUser() {
    console.log('edit: ', this.user);
    this.loading = true;
    return this.userService.updateUserAdmin(this.userID, this.user).then(
      async response => {
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description, '/admin/users/admin-administradores');
      },
      error => {
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
        throw new Error(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  newPassword() {
    this.loading = true;
    this.userService.newPassword({ newPassword: this.password }).then(
      response => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    })
  }

  recoverPassword() {
    this.loading = true;
    this.userService.recoverPassword(this.userID, this.user).then(
      response => {
        console.log(response);
        this.utilsService.dialog('SUCCESS', 'Éxito', response.message.description);
      },
      error => {
        console.log(error);
        this.utilsService.dialog('ERROR', 'Error', error.error.message.description);
      }
    ).finally(() => {
      this.loading = false;
    })
  }

  onChangeJobs($event: any, name: any) {
    const id = Number($event.target.value);
    this.user.accountRelation[0][name] = id;
  }
}
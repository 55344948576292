import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ParticipantService {
    private params: HttpParams;
    private headers: HttpHeaders;
    private url: string = environment.url;
    private options: { headers: HttpHeaders, params?: HttpParams };

    constructor(private http: HttpClient) {
        this.params = new HttpParams();
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('accept', 'application/json');
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.options = { headers: this.headers };
    }

    getParticipantsByEvent(page: number | null, size: number | null, event_id: number, participant_name: string | null): Promise<any> {
        this.params = new HttpParams();
        (page != null) && (this.params = this.params.set('page', page));
        (size != null) && (this.params = this.params.set('size', size));
        (event_id != null) && (this.params = this.params.set('eventId', event_id));
        (participant_name != null && participant_name.length > 0) && (this.params = this.params.set('userName', participant_name));
        this.options = { headers: this.headers, params: this.params };
        return this.http.get(`${this.url}eventos/participants/all-by-event/`, this.options).toPromise();
    }

    // Agregar un participante
    addParticipantToEvent(data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.post(`${this.url}eventos/participants/back/register-participant/`, data, this.options).toPromise();
    }

    // Actualiar un participante de un evento (+ asistencia/registro)
    checkinParticipant(participant_id: number, event_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.post(`${this.url}eventos/participants/back/checkin-participant/${participant_id}/event/${event_id}`, {}, this.options).toPromise();
    }

    updateParticipantByEvent(ID: number, data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.post(`${this.url}eventos/participants/back/register-participant`, data, this.options).toPromise();
    }

    // Eliminar un participante de un evento desde participante
    deleteParticipant(ID: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.delete(`${this.url}eventos/participants/${ID}`, this.options).toPromise();
    }

    // Eliminar un participante de un evento desde usuarios
    deleteParticipantByUser(event_id: number, user_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.delete(`${this.url}eventos/participants/delete-by-user/${event_id}/${user_id}`, this.options).toPromise();
    }

    // Gafete
    getBadge(event_id: number, participant_id: number): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.get(`${this.url}eventos/participants/download-badge/${event_id}/${participant_id}`, this.options).toPromise();
    }

    // Importar participantes
    importParticipant(event_id: number, formData: FormData): Promise<any> {
        this.params = new HttpParams();
        this.options = {
            headers: new HttpHeaders({
                'x-access-token': sessionStorage.getItem('Token')!.toString()
            }),
            params: this.params
        };
        return this.http.put(`${this.url}eventos/participants/import-participants/?eventId=${event_id}`, formData, this.options).toPromise();
    }

    // Envia Confirmar
    senConfirmationdParticipant(event_id: number, data: any): Promise<any> {
        this.params = new HttpParams();
        this.options = { headers: this.headers };
        return this.http.post(`${this.url}eventos/participants/confirm-participants/${event_id}`, data, this.options).toPromise();
    }
}
